import appLocaleData from 'react-intl/locale-data/es';
import thTH from '../locales/thTH';
import thFooter from '../locales/element/thFooter';
import thNavBar from '../locales/element/thNavBar';
import thHome from '../locales/pages/thHome';
import thTerms from '../locales/pages/thTermCondition';
import thPrivacy from '../locales/pages/thPrivacy';
// import thCommunity from '../locales/pages/thCommunity';
// import thTermBusiness from '../locales/pages/thTermConditionBusiness';
import thForBrand from '../locales/pages/thForBrand';
import thForAgency from '../locales/pages/thForAgency';
import thForCreator from '../locales/pages/thCreator';
import thInquiryForm from '../locales/pages/thInquiryForm';
import thAwareness from '../locales/pages/thAwareness';
import thProductLaunch from '../locales/pages/thProductLaunch';
import thEngagement from '../locales/pages/thEngagement';
import thSales from '../locales/pages/thSales';
import thTechnologyCapabilities from '../locales/pages/thTechnologyCapabilities';
import thContact from '../locales/pages/thContact';
import thCalculatorER from '../locales/pages/thCalculatorER';

const ThLang = {
    messages: {
        ...thTH,
        ...thHome,
        ...thFooter,
        ...thNavBar,
        ...thTerms,
        ...thPrivacy,
        // ...thCommunity,
        // ...thTermBusiness,
        ...thForBrand,
        ...thForAgency,
        ...thForCreator,
        ...thInquiryForm,
        ...thAwareness,
        ...thProductLaunch,
        ...thEngagement,
        ...thSales,
        ...thTechnologyCapabilities,
        ...thContact,
        ...thCalculatorER
    },
    locale: 'en-TH',
    data: appLocaleData
};
export default ThLang;