import appLocaleData from 'react-intl/locale-data/es';
import idID from '../locales/idID';
import idFooter from '../locales/element/idFooter';
import idNavBar from '../locales/element/idNavBar';
import idHome from '../locales/pages/idHome';
import idTerms from '../locales/pages/idTermCondition';
import idPrivacy from '../locales/pages/idPrivacy';
import idCommunity from '../locales/pages/idCommunity';
import idTermBusiness from '../locales/pages/idTermConditionBusiness';
import idForBrand from '../locales/pages/idForBrand';
import idForAgency from '../locales/pages/idForAgency';
import idForCreator from '../locales/pages/idCreator';
import idInquiryForm from '../locales/pages/idInquiryForm';
import idAwareness from '../locales/pages/idAwareness';
import idProductLaunch from '../locales/pages/idProductLaunch';
import idEngagement from '../locales/pages/idEngagement';
import idSales from '../locales/pages/idSales';
import idTechnologyCapabilities from '../locales/pages/idTechnologyCapabilities';
import idContact from '../locales/pages/idContact';
import idCalculatorER from '../locales/pages/idCalculatorER';

const IdLang = {
    messages: {
        ...idID,
        ...idHome,
        ...idFooter,
        ...idNavBar,
        ...idTerms,
        ...idPrivacy,
        ...idCommunity,
        ...idTermBusiness,
        ...idForBrand,
        ...idForAgency,
        ...idForCreator,
        ...idInquiryForm,
        ...idAwareness,
        ...idProductLaunch,
        ...idEngagement,
        ...idSales,
        ...idTechnologyCapabilities,
        ...idContact,
        ...idCalculatorER
    },
    locale: 'en-ID',
    data: appLocaleData
};
export default IdLang;