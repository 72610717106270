import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { NumericFormat } from 'react-number-format';
import { genAPI } from '../helpers/genAPI';
import { toastShow } from './toast';

import AOS from 'aos';
import 'aos/dist/aos.css';

const currency = [
  {
    title: "IDR (Indonesian rupiah)",
    value: "IDR"
  },
  {
    title: "USD ( United State Dollar)",
    value: "USD"
  },
  {
    title: "THB (Thai Baht)",
    value: "THB"
  },
  {
    title: "MYR (Malaysian Ringgit)",
    value: "MYR"
  },
  {
    title: "SGD (Singapore Dollar)",
    value: "SGD"
  },
  {
    title: "KHR (Cambodiang Riel)",
    value: "KHR"
  },
]

const codePhone = [
  {
    flag: '/assets/img/flag-indonesia.svg',
    title: "Indonesia (+62)",
    value: "62"
  },
  {
    flag: '/assets/img/flag-thailand.svg',
    title: "Thailand (+66)",
    value: "66"
  },
  {
    flag: '/assets/img/flag-malaysia.svg',
    title: "Malaysia (+60)",
    value: "60"
  },
  {
    flag: '/assets/img/flag-cambodia.svg',
    title: "Cambodia (+855)",
    value: "855"
  },
  {
    flag: '/assets/img/flag-singapore.svg',
    title: "Singapore (+65)",
    value: "65"
  },
]
const initialState = {
  name: "",
  email: "",
  codePhone: "62",
  phone: "",
  company: "",
  link:"",
  instagram:"",
  tiktok:"",
  currency:"IDR",
  budget:"",
  message:""
}

function InquiryFormContact(props) {
  // console.log("props",props);

  const [formPosition, setformPosition] = useState(1);
  const [btnDisabled1, setBtnDisabled1] = useState(true);
  const [btnDisabled2, setBtnDisabled2] = useState(true);
  const [btnSending, setBtnSending] = useState(false);
  const [form, setForm] = useState(initialState);
  const [inquiryID, setInquiryID] = useState(0);

  const [statusSendFormFirst, setStatusSendFormFirst] = useState(false);
  const [selectBintanGO, setSelectBintanGO] = useState('');

  function saveInquiryForm(id){
    if(id==1){
      setformPosition(2);
      if(!statusSendFormFirst){
        setStatusSendFormFirst(true);
        postInquiryForm(1);
      }
    }else{
      postInquiryForm(2);
    }
  }

  function postInquiryForm(type) {
    
    if(type==1){
      var input = { 
        "inq_name" : form.name,
        "inq_email": form.email,
        "inq_phone" : form.codePhone+form.phone,
        "inq_source" : '/contact',
      };
    }else{
      var input = { 
        "id" : inquiryID,
        "inq_name" : form.name,
        "inq_email": form.email,
        "inq_phone" : form.codePhone+form.phone,
        "inq_company_name" : form.company,
        "inq_url_link" : form.link,
        "inq_instagram" : form.instagram,
        "inq_tiktok" : form.tiktok,
        "inq_budget_currency" : form.currency,
        "inq_budget" : parseInt(form.budget),
        "inq_type" : selectBintanGO,
        "inq_source" : '/contact',
      };
      if(form.message!=""){
        input.inq_message = form.message;
      }
      setBtnSending(true);
    }
    
    var componentAPI = {
        method: "POST",
        url: process.env.REACT_APP_API_REFERRAL_URL + "/corporate/inquiry",
        post: input,
    }
    // console.log("input",input);

    genAPI(componentAPI).then(data => {
        // console.log("test",data);
        if(data.error === false)
        {
          if(type!=1){
            setBtnSending(false);
            toastShow("success", "Success sending the message");
            setformPosition(1);
            setForm(initialState);
            setInquiryID(0);
            setStatusSendFormFirst(false);
          }
          setInquiryID(data.data.inquiry_id);
        }
        else
        {
          if(type!=1){
            setBtnSending(false);
            toastShow("error", data.data.desc);
          }
        }
    });
  }

  function urlPatternValidation(URL) {
    var res = URL.match(/^(http|https):\/\/(\w+:{0,1}\w*)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res !== null?true:false;
  };

  useEffect(() => {
    if(form.name!="" && form.email!="" && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email) && form.phone!="" && form.phone.length>=5){
      setBtnDisabled1(false);
    }else{
      setBtnDisabled1(true);
    }

    if(form.company!="" && form.link!="" && form.instagram!="" && urlPatternValidation(form.link) && form.budget!="" && selectBintanGO!=''){
      setBtnDisabled2(false);
    }else{
      setBtnDisabled2(true);
    }
  });

  useEffect(() => {    
    AOS.init({ duration : 2000 });
  }, []);

  return(
    <section id="inquiry-form">
      {/* <div className="container-xl"> */}
        <div className="row">
            <div className="col-lg-12" data-aos="fade-up" data-aos-duration="900">
                <div className="the-form">
                    <div className="container-inquiry d-flex align-items-center">
                        <div className={formPosition==2?"line-inquiry active":"line-inquiry"}></div>
                        <div className="pic w-50">
                            <div className="number active d-flex align-items-center justify-content-center mb-2">1</div>
                            <span className="fz-12 fw-700"><FormattedMessage id="form.picInformation" /></span>
                        </div>
                        <div className="business w-50">
                            <div className={formPosition==2?"number active d-flex align-items-center justify-content-center mb-2":"number d-flex align-items-center justify-content-center mb-2"}>2</div>
                            <span className="fz-12 fw-700"><FormattedMessage id="form.businessInformation" /></span>
                        </div>
                    </div>
                    <hr className="my-0" />
                    <div className={formPosition==1?"container-inquiry":"container-inquiry d-none"}>
                        <h3 className="fz-mont-16 mt-2 mb-0"><FormattedMessage id="form.picInformation" /></h3>
                        <div className="form-inquiry">
                            <label><FormattedMessage id="form.yourName" /></label>
                            <input 
                                type="text" 
                                placeholder="Ex : Jhon Doe"
                                value={form.name} 
                                onChange={e => setForm(previousState => {
                                  return { ...previousState, name: e.target.value }
                                })} 
                            />
                        </div>
                        <div className="form-inquiry">
                            <label><FormattedMessage id="form.yourEmail" /></label>
                            <input 
                                type="text" 
                                placeholder="Ex : bintango@email.com"
                                value={form.email} 
                                onChange={e => setForm(previousState => {
                                  return { ...previousState, email: e.target.value }
                                })} 
                            />
                        </div>
                        <div className="form-inquiry">
                            <label><FormattedMessage id="form.yourPhone" /></label>
                            <div className="form-no-border d-flex align-items-center">
                              <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className='select-custom-noborder d-flex align-items-center mr-2'>
                                <div class="mr-auto d-flex align-items-center"><img className="mr-2" src={codePhone.find((element) => element.value == form.codePhone).flag} alt="flag" /> (+{form.codePhone})</div>
                                <img className='ml-2' src="/assets/img/chevron-black.svg" alt="chevron" />
                              </div>
                              <ul className="dropdown-menu dropdown-menu-left py-0">
                              {codePhone.map((data, index) => {
                                  return (
                                    <li onClick={()=> setForm(previousState => {
                                      return { ...previousState, codePhone: data.value }
                                    }) } className={form.codePhone==data.value?'d-flex align-items-center active':'d-flex align-items-center'}><img className="mr-2" src={data.flag} alt="flag" />{data.title}</li>
                                  );
                                })}
                              </ul>
                              <div className="w-100 pl-3 border-left">
                                <input 
                                  type="number"
                                  placeholder="Enter your phone number"
                                  value={form.phone} 
                                  onChange={e => setForm(previousState => {
                                    return { ...previousState, phone: e.target.value }
                                  })} 
                                />
                              </div>
                            </div>
                        </div>
                        <button 
                        type="submit" 
                        className="button-primary my-2"
                        disabled={btnDisabled1}
                        onClick={()=> saveInquiryForm(1) }><FormattedMessage id="form.next" /></button>
                    </div>
                    <div className={formPosition==2?"container-inquiry":"container-inquiry d-none"}>
                        <h3 className="fz-mont-16 mt-2 mb-0"><FormattedMessage id="form.businessInformation" /></h3>
                        <div className="form-inquiry">
                            <label><FormattedMessage id="form.yourCompany" /></label>
                            <input 
                                type="text" 
                                placeholder="Ex : BintanGO"
                                value={form.company} 
                                onChange={e => setForm(previousState => {
                                  return { ...previousState, company: e.target.value }
                                })} 
                            />
                        </div>
                        <div className="form-inquiry">
                            <label><FormattedMessage id="form.yourLink" /></label>
                            <input 
                                type="text" 
                                placeholder="Ex : https://bintango.com"
                                value={form.link} 
                                onChange={e => setForm(previousState => {
                                  return { ...previousState, link: e.target.value }
                                })} 
                            />
                        </div>
                        <div className="form-inquiry">
                            <label><FormattedMessage id="form.instagramUsername" /></label>
                            <div className="form-no-border d-flex align-items-center">
                              <div className="d-flex align-items-center url-sosmed">
                                <div>https://www.instagram.com/</div>
                              </div>
                              <div className="w-100">
                                <input 
                                  type="text" 
                                  placeholder="your username"
                                  value={form.username} 
                                  onChange={e => setForm(previousState => {
                                    return { ...previousState, instagram: e.target.value }
                                  })} 
                                />
                              </div>
                            </div>
                        </div>
                        <div className="form-inquiry">
                            <label><FormattedMessage id="form.tiktokUsername" /></label>
                            <div className="form-no-border d-flex align-items-center">
                              <div className="d-flex align-items-center url-sosmed">
                                <div>https://www.tiktok.com/@</div>
                              </div>
                              <div className="w-100">
                                <input 
                                  type="text" 
                                  placeholder="your username"
                                  value={form.tiktok} 
                                  onChange={e => setForm(previousState => {
                                    return { ...previousState, tiktok: e.target.value }
                                  })} 
                                />
                              </div>
                            </div>
                        </div>
                        <div className='form-inquiry'>
                          <label><FormattedMessage id="form.yourNeed" /></label>
                          <div onClick={()=> setSelectBintanGO('cm') } className={selectBintanGO=="cm"?'select-bintango active mb-20':'select-bintango mb-20'}>
                            <div className='d-flex'>
                              <div className='select-radio'></div>
                              <div>
                                <div className='select-title'><FormattedMessage id="form.creatorMarketing" /></div>
                                <div className='select-desc'><FormattedMessage id="form.creatorMarketingDesc" /></div>
                              </div>
                            </div>
                          </div>
                          <div onClick={()=> setSelectBintanGO('lc') } className={selectBintanGO=="lc"?'select-bintango active':'select-bintango'}>
                            <div className='d-flex'>
                              <div className='select-radio'></div>
                              <div>
                                <div className='select-title'><FormattedMessage id="form.liveCommerce" /></div>
                                <div className='select-desc'><FormattedMessage id="form.liveCommerceDesc" /></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-inquiry form-calculator-er">
                          <div class="row">
                            <div class="col-lg-3">
                              <label><FormattedMessage id="form.currency" /></label>
                              <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className='select-custom d-flex align-items-center'>
                                <span class="mr-auto">{form.currency}</span>
                                <img src="/assets/img/chevron-black.svg" alt="chevron" />
                              </div>
                              <ul className="dropdown-menu dropdown-menu-left py-0">
                              {currency.map((data, index) => {
                                  return (
                                    <li onClick={()=> setForm(previousState => {
                                      return { ...previousState, currency: data.value }
                                    }) } className={form.currency==data.value?'d-flex align-items-center active':'d-flex align-items-center'}>{data.title}</li>
                                  );
                                })}
                              </ul>
                            </div>
                            <div class="col-lg-9">
                                <label><FormattedMessage id="form.yourBudget" /></label>
                                <NumericFormat 
                                  placeholder="Ex : 50.000.000" 
                                  allowLeadingZeros 
                                  allowNegative={false}
                                  thousandSeparator={'.'} 
                                  decimalSeparator={','} 
                                  value={form.budget}
                                  onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    setForm(previousState => {
                                      return { ...previousState, budget: value }
                                    })
                                  }}
                                />
                              </div>
                          </div>
                        </div>
                        <div className="form-inquiry">
                            <label><FormattedMessage id="form.yourMessage" /></label>
                            <textarea 
                              type="text" 
                              rows={3} 
                              value={form.message} 
                              placeholder='you can descibe you company or your marketing goals with us in here'
                              onChange={e => setForm(previousState => {
                                return { ...previousState, message: e.target.value }
                              })} 
                            />
                        </div>
                        <div className='row'>
                          <div className='col-3'>
                          <button 
                            type="submit" 
                            className="button-text my-2"
                            onClick={()=>setformPosition(1)}><FormattedMessage id="form.back" /></button>
                          </div>
                          <div className='col-9'>
                          {
                            !btnSending?
                            <button 
                            id="button-sending"
                            type="submit" 
                            className="button-primary my-2"
                            disabled={btnDisabled2}
                            onClick={()=> saveInquiryForm(2)}><FormattedMessage id="form.send" /></button>
                            :
                            <button 
                            type="button" 
                            className="button-sending my-2"
                            disabled={btnDisabled2}><div className="d-flex align-items-center justify-content-center"><div className="loader_button mr-2"></div><FormattedMessage id="form.sending" /></div></button>
                          }
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    {/* </div> */}
  </section>
  )
}

export { InquiryFormContact };