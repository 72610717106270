/* Languange TH */

var thTechnologyCapabilities = {
    "technology.title"	: "เทคโนโลยีที่หลากหลายของเราตอบสนองความต้องการเฉพาะของคุณ",
    "technology.desc"	: "เราช่วยปรับปรุงกระบวนการทำงานของคุณ ลดขั้นตอนที่ยุ่งยาก และรับประกันแหล่งรายได้ที่สม่ำเสมอ",

    "technology.integrationTitle"	: "การเชื่อมต่อเทคโนโลยีของเรา",
    "technology.integrationDesc"	: "เทคโนโลยีของเราทำงานร่วมกับโซเชียลมีเดียและแพลตฟอร์มอีคอมเมิร์ซชั้นนำ ดังนั้นคุณจึงสามารถใช้งานแคมเปญที่ขับเคลื่อนผลลัพธ์ได้อย่างราบรื่น",

    "technology.sosmedTitle"	: "การเชื่อมต่อโซเชียลมีเดีย",
    "technology.sosmedHeadline"	: "การตัดสินใจที่ขับเคลื่อนด้วยข้อมูลด้วยข้อมูลที่น่าเชื่อถือของเรา",
    "technology.sosmedDesc"	: "เรานำการคาดเดาออกจากการตลาดแบบใช้อินฟลูเอนเซอร์ แพลตฟอร์มของเราทำงานร่วมกับแพลตฟอร์มโซเชียลมีเดียชั้นนำและใช้ข้อมูลเพื่อช่วยคุณค้นหาอินฟูที่เหมาะสำหรับแบรนด์ของคุณและดำเนินแคมเปญที่ประสบความสำเร็จ",
    "technology.ecommerceTitle"	: "การเชือมต่อระบบอีคอมเมิร์ซ",
    "technology.ecommerceHeadline"	: "จัดการผลิตภัณฑ์อินฟลูเอนเซอร์ของคุณภายในแพลตฟอร์ม",
    "technology.ecommerceDesc"	: "จัดการแคมเปญของคุณ ติดตามการคลิกและการขายได้อย่างง่ายดาย แพลตฟอร์มการตลาดแบบอินฟลูเอนเซอร์ของเราทำงานร่วมกับแพลตฟอร์มอีคอมเมิร์ซชั้นนำเพื่อช่วยให้คุณบรรลุเป้าหมายทางการตลาด",

    "technology.canDoTitle"	: "ดูว่า BintanGO ทำอะไรได้บ้าง",
    "technology.canDoDesc"	: "เราใช้เทคโนโลยีเพื่อขับเคลื่อนแคมเปญการตลาดของคุณ ดังนั้นคุณจึงสามารถได้รับผลลัพธ์ที่ต้องการ ตรวจสอบความสามารถของเรา",

    "technology.creatorAnalysis"	: "การวิเคราะห์ครีเอเตอร์",
    "technology.creatorAnalysisTitle"	: "ค้นหาอินฟูที่เหมาะสม เทคโนโลยีของเราวิเคราะห์อินฟูเอนเซอร์",
    "technology.creatorAnalysisDesc"	: "เราใช้ข้อมูลที่น่าเชื่อถือเพื่อเลือกอินฟูเอนเซอร์ที่เหมาะสมสำหรับแคมเปญแบรนด์ของคุณ เทคโนโลยีของเราจะวิเคราะห์อินฟูเอนเซอร์ในตัวชี้วัดที่หลากหลาย รวมถึงข้อมูลประชากรของผู้ชม อัตราการมีส่วนร่วม และการจัดตำแหน่งแบรนด์ เพื่อช่วยให้คุณมั่นใจได้ว่าคุณกำลังทำงานร่วมกับครีเอเตอร์ที่เหมาะสมเพื่อบรรลุเป้าหมายทางการตลาดของคุณ",

    "technology.creatorDiscovery"	: "การค้นพบครีเอเตอร์",
    "technology.creatorDiscoveryTitle"	: "ค้นพบอินฟูเอ็นเซอร์อันดับต้นๆ โดยใช้ตัวกรองขั้นสูงและข้อมูลที่น่าเชื่อถือ",
    "technology.creatorDiscoveryDesc"	: "ค้นหาอินฟลูเอนเซอร์มากกว่า 50,000 รายโดยใช้เกณฑ์ที่หลากหลาย รวมถึงข้อมูลประชากร อัตราการมีส่วนร่วม และการจัดตำแหน่งแบรนด์ ทำให้ง่ายต่อการระบุครีเอเตอร์ที่มีแนวโน้มมากที่สุดที่จะโดนใจผู้ชมเป้าหมายของคุณและขับเคลื่อนผลลัพธ์",

    "technology.creatorShortlisting"	: "การคัดเลือกครีเอเตอร์",
    "technology.creatorShortlistingTitle"	: "คัดสรรผู้ครีเอเตอร์ของคุณที่ตรงกับความต้องการด้านแคมเปญของคุณ",
    "technology.creatorShortlistingDesc"	: "เลือกครีเอเตอร์ที่เหมาะกับแคมเปญของคุณมากที่สุด โดยได้รับการสนับสนุนจากข้อมูลที่น่าเชื่อถือ อนุมัติหรือปฏิเสธได้อย่างง่ายดาย",

    "technology.reporting"	: "การรายงานแบบเรียลไทม์",
    "technology.reportingTitle"	: "รับรายงานทันทีสำหรับแคมเปญของคุณ",
    "technology.reportingDesc"	: "คุณไม่จำเป็นต้องรวบรวมภาพหน้าจออีกต่อไป รับข้อมูลเชิงลึกทันทีเกี่ยวกับประสิทธิภาพของครีเอเตอร์ของคุณด้วยแดชบอร์ดแบบครบวงจรของเรา",

    "technology.brandedContentAds"	: "โฆษณาเนื้อหาที่มีตราสินค้า",
    "technology.brandedContentAdsTitle"	: "ส่งเสริมให้ครีเอเตอร์ของคุณทำงานกับโฆษณาเพื่อให้เข้าถึง เข้าชม และมีส่วนร่วมได้มากขึ้น",
    "technology.brandedContentAdsDesc"	: "แพลตฟอร์มโฆษณาของเราทำให้การสร้างและจัดการแคมเปญโฆษณาที่มีประสิทธิภาพสำหรับงานครีเอเตอร์ของคุณเป็นเรื่องง่าย ด้วยตัวเลือกการกำหนดเป้าหมายและเครื่องมือการจัดทำงบประมาณที่หลากหลาย คุณสามารถสร้างแคมเปญที่เข้าถึงผู้คนที่เหมาะสมและได้รับผลลัพธ์ที่คุณต้องการ",

    "technology.weOfferTitle"	: "เราเสนอความยืดหยุ่นตามความต้องการด้านเทคนิคของคุณ",
    "technology.weOfferDesc"	: "เราสามารถปรับแต่งได้ตามความต้องการของคุณ ตรวจสอบกระบวนการของเราจากการรู้ว่าคุณต้องการอะไรในการเปิดตัว",
    "technology.weOfferTitle1"	: "การประเมิน",
    "technology.weOfferDesc1"	: "เราจะดำเนินการสัมภาษณ์หลายครั้งกับผู้มีส่วนได้ส่วนเสียหลักต่างๆ ในบริษัทเพื่ออำนวยความสะดวกในการรับบริการของเรา",
    "technology.weOfferTitle2"	: "ออกแบบ",
    "technology.weOfferDesc2"	: "เราจะออกแบบแดชบอร์ดให้สอดคล้องกับคำชี้แจงปัญหา",
    "technology.weOfferTitle3"	: "การปรับใช้",
    "technology.weOfferDesc3"	: "ดำเนินการทดสอบการยอมรับของผู้ใช้ผลิตภัณฑ์และธุรกิจ (UAT) เพื่อให้มั่นใจในการแก้ไขปัญหาของผู้มีส่วนได้ส่วนเสียที่สำคัญ",
    "technology.weOfferTitle4"	: "การฝึกอบรมทีม",
    "technology.weOfferDesc4"	: "เราจะช่วยเหลือทีมในการเริ่มต้นใช้งาน เพื่อให้มั่นใจว่าทีมลูกค้าจะประสบความสำเร็จในการนำเทคโนโลยีมาใช้เพื่อการใช้ประโยชน์สูงสุดและความสามารถในการขยายขนาด",
    "technology.weOfferTitle5"	: "การตรวจสอบ",
    "technology.weOfferDesc5"	: "เราจะติดตามผลการดำเนินงานและระบุโอกาสในการปรับปรุงเทคโนโลยีและติดตามแนวโน้มการเติบโตใหม่",
    "technology.weOfferTitle6"	: "ประเมินประสิทธิภาพของ Affiliate Marketing รายเดือน",
    "technology.weOfferDesc6"	: "ประเมินประสิทธิภาพของแคมเปญและปรับปรุงหากจำเป็น",

    "technology.bannerTitle"	: "คุณต้องการทราบข้อมูลเพิ่มเติมเกี่ยวกับความสามารถของเราหรือไม่?",
    "technology.bannerDesc"	: "ให้เราช่วยให้คุณบรรลุเป้าหมายทางการตลาดด้วยความสามารถด้านเทคโนโลยีของเรา ตั้งแต่การค้นพบไปจนถึงการรายงาน เราช่วยคุณได้ มาหารือเกี่ยวกับแคมเปญถัดไปของคุณกันดีกว่า!",
};
export default thTechnologyCapabilities;