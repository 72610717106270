/* Languange EN */
var enPrivacy = {
    // Intro
    "privacy.intro.title"   : "PRIVACY POLICY",
    "privacy.intro.desc"    : "Hello CreatorGO! BintanGO highly values ​​your privacy and is committed to safeguarding your trust by protecting your personal information.\n\nThis Privacy Policy explains how BintanGO treats any Personal Data you provide to us in connection with your use of our services through the BintanGO Platform, or other services that refer to or link to this Privacy Policy.\n\nYou are required to read this Privacy Policy carefully before using this Platform. If you continue to use this Service through the BintanGO Platform, You acknowledge that you have read, understand, and agree to the terms in this Privacy Policy. Therefore you grant BintanGO the right to process, collect, process, analyze, store, display, transfer, disclose and/or delete your Personal Data for the purposes referred to in this Privacy Policy, following the prevailing laws and regulations in Indonesia, including but not limited to:",
    "privacy.intro.desc.i"  : "Law of the Republic of Indonesia Number 19 of 2016 juncto Law Number 11 of 2008 concerning Information and Electronic Transactions;",
    "privacy.intro.desc.ii" : "Government Regulation of the Republic of Indonesia Number 71 of 2019 concerning the Implementation of Electronic Systems and Transactions;",
    "privacy.intro.desc.iii": "Regulation of the Minister of Communication and Information of the Republic of Indonesia Number 20 of 2016 concerning Protection of Personal Data in Electronic Systems; and",
    "privacy.intro.desc.iv" : "any other relevant laws and regulations, including any amendments, additions, or variations of any of the above laws and regulations from time to time.",
    "privacy.title.1"      : "Scope of Privacy Policy",
    "privacy.desc.1"       : "This Privacy Policy applies to all aspects of the BintanGO website and application and information that we may collect offline.\n\nThis site may link to or combine with websites or content hosted and served by third parties that we do not control, such as online property websites, platforms, social media, or systems, each of which is governed by the privacy policies and business practices of the third-parties. You understand and agree that BintanGO is not responsible for any third party's security, privacy or business practices.",
    "privacy.title.2"      : "Information We Collect",
    "privacy.desc.2"       : "We collect information from you directly, automatically, when you visit the BintanGO site and application, and we receive it from third parties (if any). In general, BintanGO will identify, contact, or locate the person or device associated with such information (“Personal Information”) or “Personal Data”), which is protected by law according to your jurisdiction of residence.\n\nSome information may be considered Personal Information under various applicable laws. We consider information that identifies you as a specific personally identifiable individual (such as your name, phone number, and email address) and additional information, including IP addresses and cookie identifiers, where required by applicable law. If we combine information that we consider non-personal with personal information, we will treat the combined information as personal information under this Privacy Policy. You can choose not to provide us with information, but in general, some information about you is necessary for you to receive the BintanGO Services, ask us questions, or make other requests on the BintanGO Platform.",
    "privacy.title.2.a"    : "Information You Provide",
    "privacy.desc.2.a"     : "BintanGO may collect Personal Information from you when you:",
    "privacy.desc.2.a.i"   : "Register an Account at, Log in to, or interact with the BintanGO site and application. We collect your name, email address, and other information you provide, such as a phone number, when you register, log in, update, or add information or content to your account. We require you to create a username and password if you create an account, although you can activate it through other methods, such as through your different email account or Apple ID. BintanGO also collects certain information related to your social media platform account to log in or link to your BintanGO account (such as username, number of followers, or other information if needed).",
    "privacy.desc.2.a.ii"  : "Participate in Contests or other Promotional Activities. BintanGO collects the information you provide when you enter contests or other promotional activities (“Promotions'') through the BintanGO website and application. Each Promotion has its terms and conditions or official rules, which you must review as other requirements must be met.",
    "privacy.desc.2.a.iii" : "EarlyPay Product Submission. BintanGO requires you to use the EarlyPay product to add some additional Personal Information, including but not limited to the EarlyPay Application Form; Photo of National Identity Card (KTP) or other identification, including all information listed on the KTP; Bank transfer for the last 6 (six) months on your behalf; Company legality documents and/or legal entity/business identity (if you are a legal entity/business); The contract of the project to be financed; Details of the project to be funded; Full identity of guarantor (if any); and other information that will be requested if it is deemed necessary by BintanGO.",
    "privacy.desc.2.a.iv"  : "Contact Customer Service via the Site or Application. BintanGO collects the information you provide in connection with us providing customer service to you.",
    "privacy.title.2.b"    : "Information from Third Parties",
    "privacy.desc.2.b"     : "BintanGO may also obtain information, such as contact details (e.g., name and email address), IP address, cookie information, hardware and software information, and other technical information about you from third parties, such as other site users who upload or synchronize contacts, analytics companies, social media platforms, and other third-party business partners.\n\nBintanGO and our third-party business partners may collect information about your visits and activities on our site and other websites and services. We may combine this information with other personal information we collected from or about you. We may share certain information with third-party business partners for similar purposes.",
    "privacy.title.3"      : "How We Use Information",
    "privacy.desc.3"       : "Through this platform, BintanGO may use your collected Personal Information for any of the following purposes as permitted by applicable laws and regulations (“Purposes”):",
    "privacy.desc.3.a"     : "to identify and register you as a user of the BintanGO platform and to manage, verify, deactivate or administer your account;",
    "privacy.desc.3.b"     : "to facilitate or enable any verification that BintanGO deems necessary at BintanGO's discretion before registering you as a BintanGO user;",
    "privacy.desc.3.c"     : "to maintain, develop, test, improve and personalize the BintanGO platform to meet your and your preferences for a better experience;",
    "privacy.desc.3.d"     : "to contact you about administrative matters, such as applicable Terms and Conditions or other issues; and",
    "privacy.desc.3.e"     : "for other reasons that (i) we disclose when asking you to provide your information; (ii) with your consent; or (iii) as described in this Privacy Policy.",
    "privacy.desc.3(i)"    : "Based on your information, BintanGO may also use your Personal Information to send you information about products, services, advertisements, vouchers, surveys, and information on special offers and/or other promotions of BintanGO, subject to which BintanGO has obtained your prior consent, under applicable laws and regulations.",
    "privacy.title.4"      : "Your Rights",
    "privacy.desc.4"       : "Based on the applicable laws and regulations, you have the following rights:",
    "privacy.desc.4.a"     : "Access to your Personal Information. You have the right to request a copy of the personal information we hold about you.",
    "privacy.desc.4.b"     : "We are correcting your Personal Information. We want to have accurate Personal Information about you, so please contact us if you think the information we hold is not up to date, and we will correct it for you.",
    "privacy.desc.4.c"     : "Deletion of your Personal Information. You have the right to ask us to delete your Personal Information when it is no longer needed for the purposes for which it was collected. You have withdrawn your consent, and you have a valid objection to us using your Personal Information, or our use of your Personal Information is contrary to the law or our other legal obligations. Based on your information, BintanGO may also use your Personal Information to send you information about products, services, advertisements, vouchers, surveys, and information on special offers and/or other promotions of BintanGO, subject to which BintanGO has obtained your prior consent, under applicable laws and regulations.",
    "privacy.desc.4.d"     : "Objection to the Purpose of Use of Information. You have the right at any time to ask us to stop using your Personal Information for marketing purposes. In addition, if we use your Personal Information for reasons of legitimate interest and you object, then we will stop using your Personal Information unless there is a valid reason to continue.",
    "privacy.desc.4.e"     : "Restrictions on Use of Information. Sometimes, you may ask us to limit how we use your Personal Information. This right may apply, for example, when we check the accuracy of the personal information we hold about you or assess the validity of any objections you make to our use of your data. Such rights may also apply if there is no longer any basis for using your Personal Information, but you do not want us to delete the information. We will use Personal Information relevant to your consent for legal claims or where there are other public interest reasons to do so.",
    "privacy.desc.4.f"     : "Automatic Processing. Suppose we use your Personal Information automatically to make decisions that significantly affect you. In that case, you have the right to request that those decisions be reviewed by individuals who can represent you and challenge those decisions. This right only applies if we use your information with your consent or as part of a contractual relationship with you.",
    "privacy.desc.4.g"     : "Withdrawal of Consent to Use of Information. If we use your Personal Information without your consent, you can withdraw that consent at any time, and we will stop using your Personal Information for the purposes for which you gave your consent. The withdrawal will not affect any previous processing that has occurred.",
    "privacy.title.5"      : "Cookies and Other Digital Markers",
    "privacy.desc.5"       : "When you access and/or use the BintanGO Platform or interact with us, we may automatically collect information through cookies and other digital markers. Cookies and digital bookmarks are small computer files that can be stored on your computer's hard drive or embedded on a page of the BintanGO Platform that allows us to identify you and track your visit to the BintanGO Platform.\n\nWe may use third-party business partners to collect and process your Personal Information through cookies and other digital markers on behalf of BintanGO. You can disable cookies on your computer by changing the preferences or options menu settings on your browser or device. If you choose to disable cookies, you may not be able to access certain areas of the Platform.",
    "privacy.title.6"      : "Cross-Border Information Transfer",
    "privacy.desc.6"       : "BintanGO may cooperate and/or engage with third-party providers to support and/or assist us in conducting and/or implementing the provision of BintanGO services. Accordingly, we may have to share your Personal Data with third parties to enable the condition of the requested services. In addition, BintanGO may share your Personal Data with its affiliates for data consolidation, storage, simplified customer information management, reporting, and other internal uses.\n\nBintanGO may be required to share information with third parties under applicable laws and regulations. For example, BintanGO is required to disclose Personal Information due to a court order, subpoena, or warrant. In addition, BintanGO may, under applicable law, voluntarily provide information to assist law enforcement investigations or where disclosure is necessary to protect our systems, our business, or the rights of others.\n\nAs a concern for you, BintanGO will not provide your email address to BintanGO's business partners, and BintanGO will only share your Personal Data in the ways set out here and in ways permitted by applicable laws and regulations.",
    "privacy.title.7"      : "Business Transition",
    "privacy.desc.7"       : "If BintanGO undergoes a business transition, such as a merger or acquisition by another company or the sale of all or part of our assets, your Personal Information is likely to be one of the assets transferred.",
    "privacy.title.8"      : "Information Security",
    "privacy.desc.8"       : "We do not guarantee or guarantee the security of the BintanGO servers. We also cannot guarantee that the information you provide through the BintanGO Platform will not be intercepted when transmitted over the internet. We follow generally accepted industry technical standards to protect your Personal Data sent to us during transmission and after BintanGO has received it. BintanGO encrypts sensitive information using secure socket layer technology when you enter sensitive information.\n\nIn processing your Personal Information for marketing purposes or with your consent, BintanGO processes the data until you ask us to stop and for a short time after this (to allow us to carry out your request). BintanGO also notes that you have asked us not to send you direct marketing or process your data indefinitely so that we can fulfill your submissions in the future. Where BintanGO processes personal information in connection with the performance of contracts or the provision of services, or for competitions, BintanGO retains the information under our internal compliance procedures, which are implemented from time to time.",
    "privacy.title.9"      : "Changes and Updates",
    "privacy.desc.9"       : "To ensure that this Privacy Policy complies with the applicable laws and regulations, BintanGO is committed to changing or updating this Privacy Policy from time to time. You are required to check this Privacy Policy periodically. In this regard, we may also provide notice to you regarding changes or updates to this Privacy Policy. Still, suppose you have continued to use the BintanGO Platform and our Services after we have changed either part or all of this Privacy Policy. In that case, you are deemed to have acknowledged, agreed to, and obtained approval from the relevant third party to the changes. You automatically submit to and bind yourself to the changes to the Privacy Policy.",
    "privacy.title.10"      : "Contact Us",
    "privacy.desc.10"       : "If you have questions, complaints, comments, or requests related to this Privacy Policy or you want to gain access and/or make changes to your Personal Data, please contact us via our contact number +6281280777870 or email to info@bintango.id.",
    "privacy.desc.11.title" : "Can also contact:",
    "privacy.desc.11"       : "PT STARGO DIGITAL INDONESIA\n+6281280777870 / info@bintango.id\n\nDirektorat Jenderal Perlindungan Konsumen dan Tertib Niaga\nKementerian Perdagangan RI\nWhatsApp: +62 853 1111 1010",
    "privacy.getinfo.fullTitle"        : "Types of Information BINTANGO Collect and Use",
    "privacy.getinfo.desc1"            : "In general, BINTANGO receive and collect any information You enter on our Platforms or give us in any other way. This includes information which identifies or can be used to identify, contact or locate the person or device to whom that information pertains (“Personal Information” or “Personal Data”) which are subjected to protection under the law in the jurisdiction in which You reside. You may choose not to provide information to us, but in general some information about You is required in order for You to receive services BINTANGO provide, ask us a question or initiate other requests on our Platforms that BINTANGO provide.",
    "privacy.getinfo.desc2"            : "The Platforms collect information through a variety of different ways and for different purposes as follows. If You choose to register with any of the Platforms to receive updates from us, to manage Your account and/or use our self-service portal, then You will be requested to provide Personal Information. BINTANGO shall use this information pursuant to our legitimate business interests to provide our services and/or to contact You about the services that BINTANGO provide in which You have expressed interest.",
    "privacy.getinfo.desc3"            : "The Platforms automatically collect technical information about Your visit, such as browser type, Internet service provider, platform type, internet protocol (IP) addresses, referring/exit pages, operating system, and date/time stamp. BINTANGO aggregate this information for reporting about the Platforms to analyse trends, diagnose problems with our server and administer the Platforms, to track user movement and use, and to gather broad demographic information.",
    "privacy.getinfo.desc4"            : "You also have the option to provide demographic information (such as type of business, size of business, locations, etc.). BINTANGO use this demographic information to understand Your needs and interests and to provide You a more personalized experience on BINTANGO locations. The information is used by us to process Your orders, enable participation in promotions (subject to Your marketing preferences), and so that BINTANGO can provide our services to You.",
    "privacy.getinfo.desc5"            : "If BINTANGO speak to You over the phone, You may provide us with personal data during the call. Such calls may be recorded for training and quality purposes. You will be informed at the outset of a call if it is to be recorded, giving You an opportunity to end the call if You are not comfortable with recording.",
    "privacy.doinfo.fullTitle"   : "What BINTANGO Do with The Information BINTANGO Collect",
    "privacy.doinfo.desc1"       : "Through this Platform, BINTANGO may use Your Personal Information collected for any of the following purposes as permitted by the applicable laws and regulations (“Purposes”):",
    "privacy.doinfo.list1"       : "to identify You and to register You as a user of the Platforms and to administer, verify, deactivate or manage your account as such;",
    "privacy.doinfo.list2"       : "to facilitate or enable any verification as BINTANGO consider necessary based on BINTANGO discretion prior to registering you as BINTANGO user;",
    "privacy.doinfo.list3"       : "to process and facilitate orders and payment transaction made by You, including, transactions made through any payment getaway that BINTANGO assign to assist in provide payment gateway; and",
    "privacy.doinfo.list4"       : "to maintain, develop, test, enhance and personalize the Platforms to meet Your needs and preferences for a better experience. ",
    "privacy.doinfo.desc2"       : "Pursuant to information collected from You in regards to payment transaction, such information shall be used only to bill You for orders that You have purchased. BINTANGO may also use Your Personal Information to send You information about other BINTANGO products, services, advertisements, vouchers, surveys and information on special offers and/or promotions, which subject to BINTANGO having obtained Your prior consent in accordance with the prevailing law and regulation.",
    "privacy.rights.fullTitle"   : "Your Rights",
    "privacy.rights.desc1"   : "Under applicable law, you have the following rights:",
    "privacy.rights.list1"   : "Access to your information: You have the right to request a copy of the personal information we hold about you.",
    "privacy.rights.list2"   : "Correcting your information: We want to have accurate personal information about you. Please contact us if you think the personal information we hold is not up to date or correct and we will correct it for you.",
    "privacy.rights.list3"   : "Deletion of your information: You have the right to ask us to delete personal information about you if it no longer is required for the purpose it was collected, you have withdrawn your consent, you have a valid objection to us using your personal information, or our use of your personal information is contrary to law or our other legal obligations.",
    "privacy.rights.list4"   : "Objecting to how we may use your information: You have the right at any time to require us to stop using your personal information for direct marketing purposes. In addition, where we use your personal information on legitimate interest grounds then, if you ask us to, we will stop using that personal information unless there are overriding legitimate grounds to continue.",
    "privacy.rights.list5"   : "Restricting how we may use your information: In some cases, you may ask us to restrict how we use your personal information. This right might apply, for example, where we are checking the accuracy of personal information about you that we hold or assessing the validity of any objection you have made to our use of your information. The right might also apply where there is no longer a basis for using your personal information but you don't want us to delete the information. Where this right to validly exercised, we may only use the relevant personal information with your consent, for legal claims or where there are other public interest grounds to do so.",
    "privacy.rights.list6"   : "Automated processing: If we use your personal information on an automated basis to make decisions that significantly affect you, you have the right to ask that the decision be reviewed by an individual to whom you may make representations and contest the decision. This right only applies where we use your information with your consent or as part of a contractual relationship with you.",
    "privacy.rights.list7"   : "Withdrawing consent using your personal information: Where we use your personal information with your consent you may withdraw that consent at any time and we will stop using your personal information for the purpose(s) for which consent was given. Note, however, that this will not affect any processing that has already taken place.",
    "privacy.rights.desc2"   : "Please contact us if you wish to exercise any of these rights. You can find the contact details below.",
    "privacy.cookie.fullTitle"   : "Cookies and Other Digital Markers",
    "privacy.cookie.desc1"       : "Whenever You access and/or use the Platforms it may automatically gather information through the use of cookies and other digital markers. Cookies and digital markers are small computer files that may be stored on Your computer’s hard drive or embedded in our Platforms pages that enable us to identify You and track Your visit on the Platforms. BINTANGO may use the services of third-parties to collect and process Personal Information through the use of cookies and other digital markers on BINTANGO behalf. You may disable cookies on Your computer by changing the settings on the preferences or options menu in Your browser or device. If You choose to disable cookies, You may not be able to access certain areas of the Platforms.",
    "privacy.com.fullTitle"   : "Electronic Communications",
    "privacy.com.desc1"       : "If You submit Your queries, BINTANGO will send You a respond and provide You information about our services You request. BINTANGO will communicate with You by email or telephone and will make every effort to honour Your preference.",
    "privacy.shareinfo.fullTitle"   : "Sharing of Information and Cross-Border Transfers",
    "privacy.shareinfo.desc1"       : "BINTANGO may contract and/or engage with third-party providers to aupport and/or assist BINTANGO in performing and/or conducting BINTANGO’s providence of services, hence, BINTANGO may have to share Your Personal Data with such third-parties in order to allow for the provision of requested services and to You. In addition, BINTANGO may share Your Personal Data with affiliates for the purposes of data consolidation, storage, simplified customer information management, reporting and other internal uses.",
    "privacy.shareinfo.desc2"       : "BINTANGO may be required to share information with third parties as a result of prevailing law and regulation. For example, BINTANGO may be required to disclose Personal Information as a result of a court order, subpoena or warrant. In addition, BINTANGO may, subject to applicable law, voluntarily provide information in order to assist in a law enforcement investigation or where the disclosure is necessary to protect our systems, our business or the rights of others.",
    "privacy.shareinfo.desc3"       : "Please note that BINTANGO do not provide Your email address to BINTANGO’s business partners and BINTANGO will only share Your Personal Data through ways stipulated herein and ways allowed by the prevailing data protection regulation.",
    "privacy.bizztrans.fullTitle"   : "Business Transitions",
    "privacy.bizztrans.desc1"       : "In the event that BINTANGO go through a business transition, such as a merger or acquisition by another company or sale of all or a portion of our assets, Your Personal Information will likely be among the assets transferred.",
    "privacy.security.fullTitle"   : "Keeping Your Information Secure",
    "privacy.security.desc1"       : "BINTANGO do not guarantee or warrant the security of BINTANGO’s servers nor can BINTANGO guarantee that information You supply through the Platforms will not be intercepted while being transmitted over the Internet. BINTANGO follow generally accepted industry technical standards to protect the personal data submitted to us, both during transmission and once BINTANGO receive it. When You enter sensitive information, BINTANGO encrypt that information using secured socket layer technology.",
    "privacy.security.desc2"       : "Where BINTANGO process personal information for marketing purposes or with Your consent, BINTANGO process the data until You ask us to stop and for a short period after this (to allow us to implement Your requests). BINTANGO also keep a record of the fact that You have asked us not to send You direct marketing or to process Your data indefinitely so that BINTANGO can respect Your request in the future. Where BINTANGO process personal information in connection with performing a contract or providing a service, or for a competition, BINTANGO keep the information in accordance with our internal compliance procedures implemented from time to time.",
    "privacy.changes.fullTitle"  : "Changes to This Privacy Policy",
    "privacy.changes.desc1"      : "Please read this Privacy Policy carefully and revisit this page from time to time to review any changes that may have been made. All changes shall automatically take effect on the date set out in the posted Privacy Policy, unless otherwise specified.",
    "privacy.terms.fullTitle"  : "Terms & Conditions",
    "privacy.terms.desc1"      : "If You choose to visit our Platforms, Your visit and any dispute over privacy is subject to this Privacy Policy and the Terms & Conditions, including, but not limited to, disclaimers of warranty, limitations of liability, and arbitration of disputes. If You have any questions regarding our use of Your personal information please contact to our contact information on the Platforms.",
};
export default enPrivacy;
