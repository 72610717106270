import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function ProductLaunchCampaign() {
  const sectionStrategy = useRef(null);

  const scrollNextSection = () => {
    sectionStrategy.current?.scrollIntoView({behavior: 'smooth',block: 'start'});
  };

  useEffect(() => {
    AOS.init({
      duration : 2000
    })
  }, []);
  
  return (
    <>
      <div id="live-commerce">
        <div className='container-xl mb-170'>
          <div className='title-live-commerce text-center'>
            <h3 className='mr-3'>360 DEGREE MARKETING CAMPAIGN</h3>
            <h1 className='mb-40'><FormattedMessage id="productLaunch.360degree" values={{ tiger: <span className="tiger"><FormattedMessage id="productLaunch.360degreeTitleTiger1" /></span>}} /></h1>
          </div>
          <div className='text-center row justify-content-center'>
            <div className='col-lg-8'>
              <p className='mb-60 mx-auto'><FormattedMessage id="productLaunch.360degreeDesc" /></p>
              <div className='mx-auto mb-60'>
                <button onClick={()=> scrollNextSection() } type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="lookHowWeWork" /></button>
              </div>
            </div>
          </div>
          
          <section id="partner" className='container-xl'>
            <div className='d-flex-desktop align-items-center'>
              <div className="title"><FormattedMessage id="trustedByBrands" values={{ number: <span className="number">500</span> }} /></div>
              <div className='carousel'>
                <div className='gradient'></div>
                <OwlCarousel 
                  items={5}
                  loop={true} 
                  margin={20} 
                  autoplay={true} 
                  // smartSpeed={1000}
                  slideTransition={'linear'} 
                  autoplayTimeout={2000} 
                  autoplaySpeed={3000} 
                  // autoplayHoverPause={false}
                  autoWidth={true} 
                  nav={false} 
                  dots={false} > 
                {
                    listBrands.map(function(image, index) {
                    return (
                        <div className="item" key={index}><img height={64} src={"/assets/img/for-brand/" + image} /></div>
                        );
                    })
                }
                </OwlCarousel>
              </div>
            </div>
          </section>  

          <div className='row'>
            <div className='col-lg-6 mb-lg-0 mb-5'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-live-commerce'>
                <div className='d-flex align-items-center mb-4'>
                  <img width={75} className='mr-3' src="/assets/img/icon-product-launch-1.svg" alt="User Image" />
                  <div className='title mb-2'><FormattedMessage id="productLaunch.increaseTitle" /></div>
                </div>
                <p><FormattedMessage id="productLaunch.increaseDesc" /></p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='card-live-commerce'>
                <div className='d-flex align-items-center mb-4'>
                  <img width={75} className='mr-3' src="/assets/img/icon-product-launch-2.svg" alt="User Image" />
                  <div className='title mb-2'><FormattedMessage id="productLaunch.boostTitle" /></div>
                </div>
                <p><FormattedMessage id="productLaunch.boostDesc" /></p>
              </div>
            </div>
          </div>
          <div id='dont-use-360'>
            <div className='row'>
              <div className='col-lg-6 mb-lg-0 mb-5'>
                <h1 className='mb-32'><FormattedMessage id="productLaunch.dontUse" /></h1>
                <Link to="/contact"><button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="letsDiscuss" /></button></Link>
              </div>
              <div className='col-lg-6'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='list-dont-use-360 mb-64'>
                  <h4 className='mb-4'><FormattedMessage id="productLaunch.dontUseTitle1" /></h4>
                  <p className='mb-0'><FormattedMessage id="productLaunch.dontUseDesc1" /></p>
                  <img src="/assets/img/missed-opportunities.svg" alt="User Image" />
                </div>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='list-dont-use-360 mb-64'>
                  <h4 className='mb-4'><FormattedMessage id="productLaunch.dontUseTitle2" /></h4>
                  <p className='mb-0'><FormattedMessage id="productLaunch.dontUseDesc2" /></p>
                  <img src="/assets/img/disjointed-messaging.svg" alt="User Image" />
                </div>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="300" className='list-dont-use-360'>
                  <h4 className='mb-4'><FormattedMessage id="productLaunch.dontUseTitle3" /></h4>
                  <p className='mb-0'><FormattedMessage id="productLaunch.dontUseDesc3" /></p>
                  <img src="/assets/img/Frame-80.svg" alt="User Image" />
                </div>
              </div>
            </div>
          </div>
          <section id="strategy-live-commerce" ref={sectionStrategy}>
            <div className='text-center mb-80'>
              <h1 className='title-section mb-4'><FormattedMessage id="productLaunch.strategyTitle" /></h1>
              <p className='desc-section w-60 mx-auto mb-80-mobile'><FormattedMessage id="productLaunch.strategyDesc" /></p>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="productLaunch.planningStrategizing" /></h3>
                <h1 className='mb-4'><FormattedMessage id="productLaunch.planningStrategizingTitle" /></h1>
                <p className='mb-40'><FormattedMessage id="productLaunch.planningStrategizingDesc" /></p>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/planning-and-strategizing.png" />
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/pre-launch-activity.png" />
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='content-live-commerce col-lg-6 order-lg-last order-first'>
                <h3><FormattedMessage id="productLaunch.preLaunchActivity" /></h3>
                <h1 className='mb-4'><FormattedMessage id="productLaunch.preLaunchActivityTitle" /></h1>
                <p className='mb-40'><FormattedMessage id="productLaunch.preLaunchActivityDesc" /></p>
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="productLaunch.amplification" /></h3>
                <h1 className='mb-4'><FormattedMessage id="productLaunch.amplificationTitle" /></h1>
                <p className='mb-40'><FormattedMessage id="productLaunch.amplificationDesc" /></p>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/amplification-and-sustain.png" />
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/tracking-and-post-campaign-analysis.png" />
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='content-live-commerce col-lg-6 order-lg-last order-first'>
                <h3><FormattedMessage id="productLaunch.tracking" /></h3>
                <h1 className='mb-4'><FormattedMessage id="productLaunch.trackingTitle" /></h1>
                <p className='mb-40'><FormattedMessage id="productLaunch.trackingDesc" /></p>
              </div>
            </div>
          </section>
          {/* <div id="case-studies">
            <div className='row justify-content-center'>
              <div className='col-lg-10 mb-32'>
                <div className='d-flex align-items-end'>
                  <h1 className='mr-auto mb-0'>Case Studies</h1>
                  <p className='mb-0'>See All Case Study</p>
                </div>
              </div>
              <div className='col-lg-5 mb-lg-0 mb-md-0 mb-5'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-case-studies'>
                  <div className='info mb-20'>Product Launch</div>
                  <h3 className='mb-4'>Gaming Gold - Influencer Marketing in the Gaming Industry</h3>
                  <p className='mb-32'>A lifestyle apparel brand collaborated with fitness influencers to promote their activewear collection.</p>
                  <div className='row'>
                    <div className='col-4'>
                      <img className='mb-2' src="/assets/img/icon-reach.svg" alt="User Image" />
                      <span className='title mb-1'>Reach</span>
                      <span className='value'>1.2 Mill</span>
                    </div>
                    <div className='col-4'>
                      <img className='mb-2' src="/assets/img/icon-view.svg" alt="User Image" />
                      <span className='title mb-1'>View</span>
                      <span className='value'>900.233</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-5'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='card-case-studies'>
                  <div className='info mb-20'>Product Launch</div>
                  <h3 className='mb-4'>Fitness Company, Wellness Journey with Influencers</h3>
                  <p className='mb-32'>Discover how a fitness and nutrition brand collaborated with wellness influencers to inspire healthier lifestyles. The campaign focused on guiding followers through fitness routines, nutrition tips, and mental wellness practices, promoting overall well-being.</p>
                  <div className='row'>
                    <div className='col-4'>
                      <img className='mb-2' src="/assets/img/icon-reach.svg" alt="User Image" />
                      <span className='title mb-1'>Reach</span>
                      <span className='value'>1.2 Mill</span>
                    </div>
                    <div className='col-4'>
                      <img className='mb-2' src="/assets/img/icon-view.svg" alt="User Image" />
                      <span className='title mb-1'>View</span>
                      <span className='value'>900.233</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <section id="banner">
          <div className='container-xl'>
            <div className='row'>
              <div className='col-lg-12 d-flex justify-content-center'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-banner text-center'>
                  <h1 className='mb-20'><FormattedMessage id="bannerStrategicTitle" /></h1>
                  <p className='mb-60'><FormattedMessage id="bannerStrategicDesc" /></p>
                  <Link to={"/contact"}><button type="button" className="btn-launcher h-48"><FormattedMessage id="letsDiscuss" /></button></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}


export default ProductLaunchCampaign;