/* Languange EN */
var enContact = {
    "contact.headline"                : "SEND US A MESSAGE",
    "contact.title"                   : "Start collaborate with BintanGO",

    "contact.iambrand"                : "Im A Brand",
    "contact.iambrandDesc"            : "We can help you with any marketing activity and achieve your brand marketing goals.",
    "contact.iamagency"               : "Im An Agency",
    "contact.iamagencyDesc"           : "With our platform, we'll help you manage campaigns and creators with ease.",
    "contact.iamcreator"              : "Im A Creator",
    "contact.iamcreatorDesc"          : "We help creators find work easily and support them to #GrowBeyond.",
};

export default enContact;