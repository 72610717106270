import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

export default class TermsBusiness extends Component {
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>BintanGO - Syarat dan Ketentuan</title>
                    <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
                    <meta property="og:image"              content="/logo-black-300.jpg" />
                    <meta property="og:url"                content={window.location.href} />
                    <meta property="og:title"              content="BintanGO - Syarat dan Ketentuan" />
                    <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
                </Helmet>
                <div>
                    <div className="header-page">
                        <h1 className="container"><FormattedMessage id="termBusiness.intro.title" /></h1>
                    </div>
                    <div className="container page-content">
                        <ol type="1" className="parent-title">
                            <li>
                            <FormattedMessage id="termBusiness.title.A" />
                            <p className="mb-4"><FormattedMessage id="termBusiness.desc.A.1" /></p>
                            <p className="mb-4"><FormattedMessage id="termBusiness.desc.A.2" /></p>
                            <p className="mb-4"><FormattedMessage id="termBusiness.desc.A.2" /></p>
                            </li>
                            <li>
                                <FormattedMessage id="termBusiness.title.B" />
                                <p><FormattedMessage id="termBusiness.desc.B" /></p>
                                <div className="border-full-black-1 col-12">
                                    <div className="row">
                                        <p className="col-1 bg-color-table-title d-flex border-bottom-black-1 my-0"><b className="m-auto">No</b></p>
                                        <p className="col-5 bg-color-table-title d-flex border-side-black-1 border-bottom-black-1 my-0"><b className="my-auto"><FormattedMessage id="termBusiness.desc.B.1" /></b></p>
                                        <p className="col-6 bg-color-table-title d-flex border-bottom-black-1 my-0"><b className="my-auto"><FormattedMessage id="termBusiness.desc.B.2" /></b></p>
                                    </div>
                                    
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-1 my-0">a.</p>
                                        <p className="border-bottom-black-1 border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.1.a.1" /></p>
                                        <p className="border-bottom-black-1 col-6 my-0"><FormattedMessage id="termBusiness.desc.B.1.a.2" /></p>
                                    </div>
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-1 my-0">b.</p>
                                        <p className="border-bottom-black-1 border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.1.b.1" /></p>
                                        <p className="border-bottom-black-1 col-6 my-0"><FormattedMessage id="termBusiness.desc.B.1.b.2" /></p>
                                    </div>
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-1 my-0">c.</p>
                                        <p className="border-bottom-black-1 border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.1.c.1" /></p>
                                        <p className="border-bottom-black-1 col-6 my-0"><FormattedMessage id="termBusiness.desc.B.1.c.2" /></p>
                                    </div>
                                    
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-12 my-0">
                                            <b className="m-auto"><FormattedMessage id="termBusiness.desc.B.2" /></b>
                                        </p>
                                    </div>
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-1 my-0">a.</p>
                                        <p className="border-bottom-black-1 border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.2.a.1" /></p>
                                        <p className="border-bottom-black-1 col-6 my-0"><FormattedMessage id="termBusiness.desc.B.2.a.2" /></p>
                                    </div>
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-1 my-0">b.</p>
                                        <p className="border-bottom-black-1 border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.2.b.1" /></p>
                                        <p className="border-bottom-black-1 col-6 my-0"><FormattedMessage id="termBusiness.desc.B.2.b.2" /></p>
                                    </div> 
                                    
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-12 my-0">
                                            <b className="m-auto"><FormattedMessage id="termBusiness.desc.B.3" /></b>
                                        </p>
                                    </div>
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-1 my-0">a.</p>
                                        <p className="border-bottom-black-1 border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.3.a.1" /></p>
                                        <p className="border-bottom-black-1 col-6 my-0"><FormattedMessage id="termBusiness.desc.B.3.a.2" /></p>
                                    </div>
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-1 my-0">b.</p>
                                        <p className="border-bottom-black-1 border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.3.b.1" /></p>
                                        <p className="border-bottom-black-1 col-6 my-0"><FormattedMessage id="termBusiness.desc.B.3.b.2" /></p>
                                    </div>
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-1 my-0">c.</p>
                                        <p className="border-bottom-black-1 border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.3.c.1" /></p>
                                        <p className="border-bottom-black-1 col-6 my-0"><FormattedMessage id="termBusiness.desc.B.3.c.2" /></p>
                                    </div>

                                    <div className="row">
                                        <p className="border-bottom-black-1 col-12 my-0">
                                            <b className="m-auto"><FormattedMessage id="termBusiness.desc.B.4" /></b>
                                        </p>
                                    </div>
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-1 my-0">a.</p>
                                        <p className="border-bottom-black-1 border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.4.a.1" /></p>
                                        <p className="border-bottom-black-1 col-6 my-0"><FormattedMessage id="termBusiness.desc.B.4.a.2" /></p>
                                    </div>
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-1 my-0">b.</p>
                                        <p className="border-bottom-black-1 border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.4.b.1" /></p>
                                        <p className="col-6 my-0"><FormattedMessage id="termBusiness.desc.B.4.b.2" /></p>
                                    </div>
                                    <div className="row">
                                        <p className="border-bottom-black-1 col-1 my-0">c.</p>
                                        <p className="border-bottom-black-1 border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.4.c.1" /></p>
                                        <p className="border-bottom-black-1 col-6 my-0"></p>
                                    </div><div className="row">
                                        <p className="col-1 my-0">c.</p>
                                        <p className="border-side-black-1 col-5 my-0"><FormattedMessage id="termBusiness.desc.B.4.d.1" /></p>
                                        <p className="col-6 my-0"><FormattedMessage id="termBusiness.desc.B.4.d.2" /></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <FormattedMessage id="termBusiness.title.C" />
                                <p><FormattedMessage id="termBusiness.desc.C1" /></p>
                                <ol type="a" className="child-title">
                                    <li>
                                        <FormattedMessage id="termBusiness.desc.C.a" />
                                        <p className="ml-4"><FormattedMessage id="termBusiness.desc.C.a.desc" /></p>
                                    </li>
                                    <li>
                                        <FormattedMessage id="termBusiness.desc.C.b" />
                                        <p className="ml-4"><FormattedMessage id="termBusiness.desc.C.b.desc" /></p>
                                    </li>
                                    <li>
                                        <FormattedMessage id="termBusiness.desc.C.c" />
                                        <p className="ml-4"><FormattedMessage id="termBusiness.desc.C.c.desc" /></p>
                                    </li>
                                    <li>
                                        <FormattedMessage id="termBusiness.desc.C.d" />
                                        <p className="ml-4"><FormattedMessage id="termBusiness.desc.C.d.desc" /></p>
                                    </li>
                                    <li>
                                        <FormattedMessage id="termBusiness.desc.C.e" />
                                        <p className="ml-4"><FormattedMessage id="termBusiness.desc.C.e.desc" /></p>
                                    </li>
                                    <li>
                                        <FormattedMessage id="termBusiness.desc.C.f" />
                                        <p className="ml-4"><FormattedMessage id="termBusiness.desc.C.f.desc" /></p>
                                    </li>
                                </ol>
                                <p><FormattedMessage id="termBusiness.desc.C2" /></p>
                            </li>
                            <li>
                                <FormattedMessage id="termBusiness.title.D" />
                                <p><FormattedMessage id="termBusiness.desc.D" /></p>
                            </li>
                            <li>
                                <FormattedMessage id="termBusiness.title.E" />
                                <p><FormattedMessage id="termBusiness.desc.E" /></p>
                            <ol type="1" className="child-title">
                                <li>
                                    <p><FormattedMessage id="termBusiness.desc.E.1" /></p>
                                    <ol type="a" className="child-title">
                                        <li><p><FormattedMessage id="termBusiness.desc.E.1.a" /></p></li>
                                        <li><p><FormattedMessage id="termBusiness.desc.E.1.b" /></p></li>
                                        <li><p><FormattedMessage id="termBusiness.desc.E.1.c" /></p></li>
                                        <li><p><FormattedMessage id="termBusiness.desc.E.1.d" /></p></li>
                                        <li>
                                            <p><FormattedMessage id="termBusiness.desc.E.1.e" /></p>
                                            <ol type="i" className="child-title">
                                                <li><p><FormattedMessage id="termBusiness.desc.E.1.e.a" /></p></li>
                                                <li><p><FormattedMessage id="termBusiness.desc.E.1.e.b" /></p></li>
                                                <li><p><FormattedMessage id="termBusiness.desc.E.1.e.c" /></p></li>
                                            </ol>
                                        </li>
                                        <li><p><FormattedMessage id="termBusiness.desc.E.1.f" /></p></li>
                                    </ol>
                                </li>
                                <li><p><FormattedMessage id="termBusiness.desc.E.2" /></p></li>
                                <li><p><FormattedMessage id="termBusiness.desc.E.3" /></p></li>
                                <li><p><FormattedMessage id="termBusiness.desc.E.4" /></p></li>
                            </ol>
                            </li>
                            <li>
                                <FormattedMessage id="termBusiness.title.F" />
                                <p><FormattedMessage id="termBusiness.desc.F" /></p>
                            </li>
                            <li>
                                <FormattedMessage id="termBusiness.title.G" />
                                <p><FormattedMessage id="termBusiness.desc.G" /></p>
                            </li>
                            <li>
                                <FormattedMessage id="termBusiness.title.H" />
                                <p><FormattedMessage id="termBusiness.desc.H" /></p>
                            </li>
                            <li>
                                <FormattedMessage id="termBusiness.title.I" />
                                <p><FormattedMessage id="termBusiness.desc.I" /></p>
                                <ol type="1" className="child-title">
                                    <li><p><FormattedMessage id="termBusiness.desc.I.1" /></p></li>
                                    <li><p><FormattedMessage id="termBusiness.desc.I.2" /></p></li>
                                    <li><p><FormattedMessage id="termBusiness.desc.I.3" /></p></li>
                                    <li><p><FormattedMessage id="termBusiness.desc.I.4" /></p></li>
                                    <li><p><FormattedMessage id="termBusiness.desc.I.5" /></p></li>
                                </ol>
                            </li>
                            <li>
                                <FormattedMessage id="termBusiness.title.J" />
                                <p><FormattedMessage id="termBusiness.desc.J" /></p>
                            </li>
                            <li>
                                <FormattedMessage id="termBusiness.title.K" />
                                <p><FormattedMessage id="termBusiness.desc.K" /></p>
                            </li>
                            <li>
                                <FormattedMessage id="termBusiness.title.L" />
                                <p><FormattedMessage id="termBusiness.desc.L" /></p>
                            </li>
                        </ol>

                        <p className="mt-4 text-center"><FormattedMessage id="termBusiness.ending" /></p>
                    </div>
                </div>
            </>
        );  
    }
}