/* Languange ID */

var idID = {  
  "learnMore"               : "Pelajari Lebih",
  "letsDiscuss"             : "Hubungi Kami",
  "discussWithOurTeam"      : "Diskusi dengan tim kami",
  "discussWithUs"           : "Hubungi Tim Kami",
  "letsDiscussYourCampaign" : "Ayok Diskusikan Campaign Kamu",
  "letsDiscussAdsCampaign"  : "Let's Discuss ads Campaign",
  "letsDiscussNextCampaign" : "Diskusikan Campaign Kamu Selanjutnya",
  "trustedByBrands"         : "Dipercayai lebih dari {number} brands",
  "trustedByAgency"         : "Dipercayai lebih dari {number} agensi",
  "ourSolution"             : "Solusi Kami",
  "lookHowWeWork"           : "Lihat Cara Kerja",
  "ourEventPartner"         : "Our Event Partner",

  // FOR BANNER
  "bannerSaleTitle"         : "Tingkatkan Brand Anda dengan {tiger} kreatif kami.",
  "bannerSaleTitleTiger"    : "solusi pemasaran",
  "bannerSaleDesc"          : "Kami siap membantu Anda membuat dan menjalankan kampanye pemasaran yang efektif yang dirancang khusus untuk memenuhi kebutuhan Anda. Kami dapat membantu Anda mencapai tujuan pemasaran Anda, apa pun itu. Hubungi kami untuk mempelajari lebih lanjut tentang bagaimana kami dapat membantu Anda.",

  "bannerAgencyTitle"       : "Buat alur kerja Anda sebagai agensi lebih sederhana dengan platfrom Kami",
  "bannerAgencyDesc"        : "Dengan teknologi pada platform Kami membuat ringan pekerjaan Anda sebagai Agensi. Ayo diskusikan yang Anda butuhkan kepada Kami dan kami membantu Anda",

  "bannerAwarenessTitle"    : "Start getting the world to notice you.",
  "bannerAwarenessDesc"     : "Let our experts take charge of your brand awareness campaign and help you get noticed.",

  "bannerStrategicTitle"    : "Rise above your competition with a strategic marketing plan.",
  "bannerStrategicDesc"     : "Let's launch your product with a great impression on a well-targeted audience. Let's discuss your next product launch with us.",
};

export default idID;
