/* Languange EN */


var enHome = {
    "home.title"                    : "The only creator marketing platform you’ll need to",
    "home.desc"                     : "Maximize your campaign and eliminate guesswork with our cost-effective creator marketing apporach",

    "home.marketingSolution"        : "Let us help you run any {tiger} for your brand.",
    "home.marketingSolutionTiger"   : "marketing activity",
    "home.officialPartner"          : "Official Partner With",

    "home.blockTitle1"              : "Run you campaign that fits for your marketing needs",
    "home.blockDesc1"               : "Elevate your sales, deeper engagement, and enhance awareness with our talented creators.",
    "home.blockButton1"             : "Run Campaign Now",
    "home.block1statsTitle1"        : "100K+",
    "home.block1statsDesc1"         : "Creators",
    "home.block1statsDesc11"        : "nano to mega",
    "home.block1statsTitle2"        : "150M",
    "home.block1statsDesc2"         : "Total Reach",
    "home.block1statsTitle3"        : "15M+",
    "home.block1statsDesc3"         : "Total Product Click",
    "home.block1statsTitle4"        : "500+",
    "home.block1statsDesc4"         : "Campaign Completed",
    "home.block1statsTitle5"        : "900M+",
    "home.block1statsDesc5"         : "Es. Total Views",
    "home.block1statsTitle6"        : "50+",
    "home.block1statsDesc6"         : "Data Point Availables",

    "home.blockTitle2"              : "End-to-end live commerce solution",
    "home.blockDesc2"               : "Boost your customer experience ans sales by our end-to-end live commerce solution",
    "home.blockButton2"             : "Run Live Commerce",
    "home.block2statsTitle1"        : "20+",
    "home.block2statsDesc1"         : "Brand run live commerce with us",
    "home.block2statsTitle2"        : "10K Hours",
    "home.block2statsDesc2"         : "Live Capabilities",
    "home.block2statsTitle3"        : "24/7",
    "home.block2statsDesc3"         : "Live Commerce Capabilities",
    "home.block2statsTitle4"        : "15+",
    "home.block2statsDesc4"         : "Studio available",
    "home.block2statsTitle5"        : "4",
    "home.block2statsDesc5"         : "Live Platform",
    "home.block2statsTitle6"        : "100K+",
    "home.block2statsDesc6"         : "Creator Network",

    "home.unlock"                   : "Unlock {tiger} with BintanGO",
    "home.unlockTiger"              : "all possibillities",
    "home.unlockDesc"               : "Your marketing, your way. Our services blend seamlessly with your goals. Let's craft your success story!",
    
    "home.brandAwareness"           : "BRAND AWARENESS",
    "home.brandAwarenessTitle"      : "Boost your brand's visibility and reach to your ideal audience.",
    "home.brandAwarenessDesc"       : "With our platform and solution, you can easily create and manage social media campaigns that reach your target audience and boost your brand's visibility.",

    "home.engageMore"               : "ENGAGE MORE",
    "home.engageMoreTitle"          : "Create content that build interaction that leave a lasting impression",
    "home.engageMoreDesc"           : "We'll help you connect with your audience more effectively through our comprehensive campaign management services. Let's discuss your next campaign",

    "home.productLaunch"            : "PRODUCT LAUNCH",
    "home.productLaunchTitle"       : "You build, we market it. Launch your product with the right strategy",
    "home.productLaunchDesc"        : "Our team of experts will help you launch your product and make a great impression.",

    "home.growthSales"              : "GROWTH SALES",
    "home.growthSalesTitle"         : "Our creative solutions will help you grow your brand's sales",
    "home.growthSalesDesc"          : "Our platform can connects you to with 80,000 high-quality creators who can help you reach your target audience and achieve your marketing goals.",

    "home.techDriven"               : "NOT JUST A MARKETING PLATFORM",
    "home.techDrivenDesc"           : "We are your Tech-Driven Partner, provide technology that will help save your time and provide most credible data",
    "home.learnAboutTech"           : "Learn About Our Tech",

    "home.featuredOn"               : "Featured On",
    "home.helpBrand"                : "We here to help you as a",
};

export default enHome;