import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import{ changeLang } from "../redux/lang/langSlice";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
// import{ logout } from '../redux/auth/authSlice';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeDropdown:true,
            activeMenu: "",
            open: false,
            dropdownBintanGO:false,
            dropdownSolution:false,
            dropdownResource:false,
            dropdownCompany:false,
            dropdownTools:false,
            dropdownLogin:false,
        };
    }

    activeMenu(menu) {
        this.setState({
            activeMenu : menu,
            open:false,
        });
        document.body.style.overflow = 'unset';
    }

    openToggle(status) {
        this.setState({ 
            open: status
        });

        if(status==true){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'unset';
        }
    }

    handleClick = () => {
        this.setState({
            activeDropdown : false
        });
    };

    handleBoxToggle = () => {
        this.setState({
            activeDropdown : true
        });
    };

    async componentDidMount() {
        var location = window.location.pathname;
        var arLoc = location.split("/");
        this.setState({activeMenu : arLoc[1]});
    }
      
    render() {
        var langData = this.props.lang;
        return (
            <>
             <header className="site-navbar site-navbar-target" role="banner">
                <div className="container-xl d-none d-lg-block">
                    <div className="d-flex align-items-center justify-content-end menu-language pb-12">
                        <nav className="main-nav">
                            <div className="new-navbar d-flex align-items-center">
                                <ul className="nav">
                                    <li className="submenu no-chevron">
                                        <a className="" onMouseOver={this.handleBoxToggle}>
                                            {/* <img src="/assets/img/translate.svg" className="mr-3" alt="User Image" /> */}
                                            <img src={langData.lang ==="id" ?"/assets/img/ID.svg":langData.lang ==="th" ?"/assets/img/TH.svg":"/assets/img/EN.svg"} width={28} alt="User Image" />
                                        </a>
                                        <ul className="no-bullet" style={{pointerEvents: this.state.activeDropdown ? "unset" : "none"}} onClick={this.handleClick}>
                                            <li>
                                                <a href="#" onClick={() => this.props.changeLang("en")} disabled={langData.lang ==="en" ?true:false}>
                                                    <img src="/assets/img/EN.svg" width={28} className="mr-2" alt="User Image" />
                                                    <span>English</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={() => this.props.changeLang("id")} disabled={langData.lang ==="id" ?true:false}>
                                                    <img src="/assets/img/ID.svg" width={28} className="mr-2" alt="User Image" />
                                                    <span>Indonesia</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={() => this.props.changeLang("th")} disabled={langData.lang ==="th" ?true:false}>
                                                    <img src="/assets/img/TH.svg" width={28} className="mr-2" alt="User Image" />
                                                    <span>Thailand</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="submenu login">
                                        <a href="#" className="login" onMouseOver={this.handleBoxToggle}><FormattedMessage id="navbar.login" /></a>
                                        <ul style={{pointerEvents: this.state.activeDropdown ? "unset" : "none"}} onClick={this.handleClick}>
                                            <li>
                                                <a href="https://cms.bintango.id/register.php" target="_blank">Login as a Brand or Agency</a>
                                            </li>
                                            <li>
                                                <a href="https://creator.bintango.com/" target="_blank">Login as A Creator</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="container-xl">
                    <div className="d-flex justify-content-between align-items-center py-lg-12">
                        <div className="site-logo d-flex align-items-center justify-content-center">
                            <Link to="/" onClick={() => this.activeMenu("")} className="mb-0 mr-4 site-logo"><div className="logo" /><img className="logo" width={138} src={this.props.theme=="dark"?"/assets/img/logo-bintango-white.svg":"/assets/img/logo.svg"} alt="Logo" /></Link>
                        </div>
                        <div className="d-none d-lg-block">
                            <nav className="main-nav">
                                <div className="new-navbar d-flex align-items-center">
                                    <ul className="nav">
                                        <li className="submenu">
                                            <a href="#" onMouseOver={this.handleBoxToggle}><FormattedMessage id="navbar.bintangoFor" /></a>
                                            <ul className="no-bullet" style={{pointerEvents: this.state.activeDropdown ? "unset" : "none"}} onClick={this.handleClick}>
                                                <li><Link to="/brand" className="menu-item"><FormattedMessage id="navbar.forBrand" className="title" /></Link></li>
                                                <li><Link to="/agency" className="menu-item"><FormattedMessage id="navbar.forAgency" className="title" /></Link></li>
                                                <li><Link to="/creator" className="menu-item"><FormattedMessage id="navbar.forCreator" className="title" /></Link></li>
                                            </ul>
                                        </li>
                                        <li className="submenu">
                                            <a href="#" onMouseOver={this.handleBoxToggle}><FormattedMessage id="navbar.solution" /></a>
                                            <ul className="solutions" style={{pointerEvents: this.state.activeDropdown ? "unset" : "none"}} onClick={this.handleClick}>
                                                <li>
                                                    <Link to="/solution/brand-awareness" className="menu-item">
                                                        <div><FormattedMessage id="navbar.brandAwareness" /></div>
                                                        <span className="description"><FormattedMessage id="navbar.brandAwarenessDesc" /></span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/solution/engagement-to-audience" className="menu-item">
                                                        <div><FormattedMessage id="navbar.engagement" /></div>
                                                        <span className="description"><FormattedMessage id="navbar.engagementDesc" /></span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/solution/new-product-launch" className="menu-item">
                                                        <div><FormattedMessage id="navbar.product" /></div>
                                                        <span className="description"><FormattedMessage id="navbar.productDesc" /></span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/solution/growth-brand-sales" className="menu-item">
                                                        <div><FormattedMessage id="navbar.sales" /></div>
                                                        <span className="description"><FormattedMessage id="navbar.salesDesc" /></span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="scroll-to-section"><Link to="/technology-capabilities" className="menu-item"><FormattedMessage id="navbar.technology" /></Link></li>
                                        <li className="submenu">
                                            <a href="#" onMouseOver={this.handleBoxToggle}><FormattedMessage id="navbar.resource" /></a>
                                            <ul className="no-bullet" style={{pointerEvents: this.state.activeDropdown ? "unset" : "none"}} onClick={this.handleClick}>
                                                <li><Link to="/blog" className="menu-item"><FormattedMessage id="navbar.blog" /></Link></li>
                                                {/* <li><Link to="/slider" className="menu-item"><FormattedMessage id="navbar.caseStudy" /></Link></li> */}
                                                <li><a href="https://bintango.notion.site/Help-Center-FAQ-adedcb54bb164e9eb68e1ccc25f8d52f" target="_blank" className="menu-item"><FormattedMessage id="navbar.helpCenter" /></a></li>
                                            </ul>
                                        </li>
                                        <li className="submenu">
                                            <a href="#" onMouseOver={this.handleBoxToggle}><FormattedMessage id="navbar.company" /></a>
                                            <ul className="no-bullet" style={{pointerEvents: this.state.activeDropdown ? "unset" : "none"}} onClick={this.handleClick}>
                                                <li><Link to="/about-us" className="menu-item"><FormattedMessage id="navbar.about" /></Link></li>
                                                <li><Link to="/contact" className="menu-item"><FormattedMessage id="navbar.contact" /></Link></li>
                                                <li><Link to="/career" className="menu-item"><FormattedMessage id="navbar.career" /></Link></li>
                                            </ul>
                                        </li>
                                        <li className="submenu">
                                            <a href="#" onMouseOver={this.handleBoxToggle}><FormattedMessage id="navbar.tools" /></a>
                                            <ul className="no-bullet" style={{pointerEvents: this.state.activeDropdown ? "unset" : "none"}} onClick={this.handleClick}>
                                                <li><Link to="/calculator-er" className="menu-item"><FormattedMessage id="navbar.calculator" /></Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="d-none d-lg-block">
                            <Link to="/contact"><button type="button" className="btn-launcher mw-135 h-48"><FormattedMessage id="navbar.contact" /></button></Link>
                        </div>
                        <div className="d-inline-block d-lg-none ml-md-0 py-3">
                            <button className="offcanvas-toggle" onClick={() => this.openToggle(true)}>
                            <span><img src={this.props.theme=="dark"?"/assets/img/toggle-dark.svg":"/assets/img/toggle-new.svg"} /></span>
                            </button>
                        </div>
                        <div id="offcanvas" className={this.state.open?"offcanvas-section open":"offcanvas-section"}>
                            <div className="offcanvas-wrap">
                            <div className="header-menu-mobile d-flex justify-content-between align-items-center py-lg-12">
                                <div className="site-logo d-flex align-items-center justify-content-center">
                                    <Link to="/" onClick={() => this.activeMenu("")} className="mb-0 mr-4 site-logo"><div className="logo" /><img className="logo" src="/assets/img/logo.svg" alt="Logo" /></Link>
                                </div>
                                <div className="d-inline-block d-lg-none ml-md-0 py-3">
                                    <div className="toggle-close d-flex align-items-center justify-content-center" onClick={() => this.openToggle(false)}>
                                        <img src="/assets/img/close-toggle.svg" />
                                    </div>
                                </div>
                            </div>
                            <div className="inner pb-0">
                                <div className="offcanvas-menu">
                                <nav>
                                    <ul>
                                    <li className={this.state.dropdownBintanGO?"submenu-mobile r-180":"submenu-mobile"}>
                                        <div className="parent" onClick={() => this.setState({ dropdownBintanGO: this.state.dropdownBintanGO?false:true})}><a href="#"><FormattedMessage id="navbar.bintangoFor" /></a></div>
                                        <ul className={this.state.dropdownBintanGO?"no-bullet active":"no-bullet"}>
                                            <li><Link to="/brand" onClick={() => this.activeMenu("brand")} className="menu-item"><FormattedMessage id="navbar.forBrand" className="title" /></Link></li>
                                            <li><Link to="/agency" onClick={() => this.activeMenu("agency")} className="menu-item"><FormattedMessage id="navbar.forAgency" className="title" /></Link></li>
                                            <li><Link to="/creator" onClick={() => this.activeMenu("creator")} className="menu-item"><FormattedMessage id="navbar.forCreator" className="title" /></Link></li>
                                        </ul>
                                    </li>
                                    <li className={this.state.dropdownSolution?"submenu-mobile r-180":"submenu-mobile"}>
                                        <div className="parent" onClick={() => this.setState({ dropdownSolution: this.state.dropdownSolution?false:true})}><a href="#"><FormattedMessage id="navbar.solution" /></a></div>
                                        <ul className={this.state.dropdownSolution?"no-bullet active":"no-bullet"}>
                                            <li><Link to="/solution/brand-awareness" onClick={() => this.activeMenu("awareness")} className="menu-item"><FormattedMessage id="navbar.brandAwareness" /></Link></li>
                                            <li><Link to="/solution/engagement-to-audience" onClick={() => this.activeMenu("engagement")} className="menu-item"><FormattedMessage id="navbar.engagement" /></Link></li>
                                            <li><Link to="/solution/new-product-launch" onClick={() => this.activeMenu("product")} className="menu-item"><FormattedMessage id="navbar.product" /></Link></li>
                                            <li><Link to="/solution/growth-brand-sales" onClick={() => this.activeMenu("sales")} className="menu-item"><FormattedMessage id="navbar.sales" /></Link></li>
                                        </ul>
                                    </li>
                                    <li><div className="parent"><Link to="/technology-capabilities" onClick={() => this.activeMenu("technology-capabilities")} className="menu-item"><FormattedMessage id="navbar.technology" /></Link></div></li>
                                    <li className={this.state.dropdownResource?"submenu-mobile r-180":"submenu-mobile"}>
                                        <div className="parent" onClick={() => this.setState({ dropdownResource: this.state.dropdownResource?false:true})}><a href="#"><FormattedMessage id="navbar.resource" /></a></div>
                                        <ul className={this.state.dropdownResource?"no-bullet active":"no-bullet"}>
                                            <li><Link to="/blog" onClick={() => this.activeMenu("blog")} className="menu-item"><FormattedMessage id="navbar.blog" /></Link></li>
                                            {/* <li><a href="#" className="menu-item"><FormattedMessage id="navbar.caseStudy" /></a></li> */}
                                            <li><a href="https://bintango.notion.site/Help-Center-FAQ-adedcb54bb164e9eb68e1ccc25f8d52f" target="_blank" className="menu-item"><FormattedMessage id="navbar.helpCenter" /></a></li>
                                        </ul>
                                    </li>
                                    <li className={this.state.dropdownCompany?"submenu-mobile r-180":"submenu-mobile"}>
                                        <div className="parent" onClick={() => this.setState({ dropdownCompany: this.state.dropdownCompany?false:true})}><a href="#"><FormattedMessage id="navbar.company" /></a></div>
                                        <ul className={this.state.dropdownCompany?"no-bullet active":"no-bullet"}>
                                            <li><Link to="/about-us" onClick={() => this.activeMenu("about")} className="menu-item"><FormattedMessage id="navbar.about" /></Link></li>
                                            <li><Link to="/contact" onClick={() => this.activeMenu("contact")} className="menu-item"><FormattedMessage id="navbar.contact" /></Link></li>
                                            <li><Link to="/career" onClick={() => this.activeMenu("career")} className="menu-item"><FormattedMessage id="navbar.career" /></Link></li>
                                        </ul>
                                    </li>
                                    <li className={this.state.dropdownTools?"submenu-mobile r-180":"submenu-mobile"}>
                                        <div className="parent" onClick={() => this.setState({ dropdownTools: this.state.dropdownTools?false:true})}><a href="#"><FormattedMessage id="navbar.tools" /></a></div>
                                        <ul className={this.state.dropdownTools?"no-bullet active":"no-bullet"}>
                                            <li><Link to="/calculator-er" onClick={() => this.activeMenu("tools")} className="menu-item"><FormattedMessage id="navbar.calculator" /></Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/contact" onClick={() => this.activeMenu("contact")}><button type="button" className="btn-launcher w-100">Contact Us</button></Link></li>
                                    <li className={this.state.dropdownLogin?"submenu-mobile login text-center r-180":"submenu-mobile login text-center"}>
                                        <div className="parent-login" onClick={() => this.setState({ dropdownLogin: this.state.dropdownLogin?false:true})}><a href="#"><FormattedMessage id="navbar.login" /></a></div>
                                        <ul className={this.state.dropdownLogin?"login active":"login"}>
                                            <li><a href="https://cms.bintango.id/register.php" target="_blank" className="menu-item">Login as a Brand or Agency</a></li>
                                            <li><a href="https://creator.bintango.com/" target="_blank" className="menu-item">Login as A Creator</a></li>
                                        </ul>
                                    </li>
                                    </ul>
                                </nav>
                                <hr className="my-32"/>
                                <div className="d-flex align-items-center">
                                    <div className="mr-auto c-565354 fs-14">Our Social Media</div>
                                    <a className="mr-3" href="https://www.instagram.com/bintango.id/" target="_blank"><img width={32} src="/assets/img/instagram.svg" alt="Logo" /></a>
                                    <a href="https://www.tiktok.com/@bintango.id" target="_blank"><img width={32} src="/assets/img/tiktok.svg" alt="Logo" /></a>
                                </div>
                                <div className="d-flex align-items-center my-4">
                                    <div className="mr-auto c-565354 fs-14">Website Language</div>
                                    <div className={langData.lang ==="en" ?"bg-language active mr-3":"bg-language mr-3"} onClick={() => this.props.changeLang("en")}>
                                        <img src="/assets/img/EN.svg" width={21} alt="User Image" />
                                    </div>
                                    <div className={langData.lang ==="id" ?"bg-language active":"bg-language"} onClick={() => this.props.changeLang("id")}>
                                        <img src="/assets/img/ID.svg" width={21} alt="User Image" />
                                    </div>
                                    <div className={langData.lang ==="th" ?"bg-language active":"bg-language"} onClick={() => this.props.changeLang("th")}>
                                        <img src="/assets/img/TH.svg" width={21} alt="User Image" />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            </>
        );  
    }
}

const mapStateToProps = state => {
    return {
        // authData: state.auth,
        lang: state.lang,
    }
}
  
function mapDispatchToProps(dispatch) {
return({
    // loginProcess: (loginData) => {dispatch(login(loginData))},
    // logoutProcess: () => {dispatch(logout())},
    changeLang: (lang) => {
        localStorage.setItem('lang', lang);
        dispatch(changeLang(lang));
    },
})
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));