import appLocaleData from 'react-intl/locale-data/en';
import enUS from '../locales/enUS';
import enFooter from '../locales/element/enFooter';
import enNavBar from '../locales/element/enNavBar';
import enHome from '../locales/pages/enHome';
import enTerms from '../locales/pages/enTermCondition';
import enPrivacy from '../locales/pages/enPrivacy';
import enCommunity from '../locales/pages/enCommunity';
import enTermBusiness from '../locales/pages/enTermConditionBusiness';
import enForBrand from '../locales/pages/enForBrand';
import enForAgency from '../locales/pages/enForAgency';
import enForCreator from '../locales/pages/enCreator';
import enInquiryForm from '../locales/pages/enInquiryForm';
import enAwareness from '../locales/pages/enAwareness';
import enProductLaunch from '../locales/pages/enProductLaunch';
import enEngagement from '../locales/pages/enEngagement';
import enSales from '../locales/pages/enSales';
import enTechnologyCapabilities from '../locales/pages/enTechnologyCapabilities';
import enContact from '../locales/pages/enContact';
import enCalculatorER from '../locales/pages/enCalculatorER';

const EnLang = {
    messages: {
        ...enUS,
        ...enHome,
        ...enFooter,
        ...enNavBar,
        ...enTerms,
        ...enPrivacy,
        ...enCommunity,
        ...enTermBusiness,
        ...enForBrand,
        ...enForAgency,
        ...enForCreator,
        ...enInquiryForm,
        ...enAwareness,
        ...enProductLaunch,
        ...enEngagement,
        ...enSales,
        ...enTechnologyCapabilities,
        ...enContact,
        ...enCalculatorER,
    },
    locale: 'en-US',
    data: appLocaleData
};
export default EnLang;