/* Languange ID */

var idNavBar = {
  "navbar.bintangoFor"      : "BintanGO Untuk",
  "navbar.solution"         : "Solusi",
  "navbar.technology"       : "Kapabilitas Teknologi",
  "navbar.resource"         : "Resource",
  "navbar.company"          : "Perusahaan",
  "navbar.tools"            : "Tools",
  "navbar.calculator"       : "ER Calculator",
  "navbar.contact"          : "Hubungi Kami",
  "navbar.blog"             : "Blog",
  "navbar.caseStudy"        : "Case Study",
  "navbar.helpCenter"       : "Pusat Bantuan",
  "navbar.about"            : "Tentang Kami",
  "navbar.career"           : "Karir",

  "navbar.brandAwareness"   : "Tingkatkan Brand Awareness",
  "navbar.brandAwarenessDesc": "Tingkatkan visibiltas brand Anda dengan kombinasi efektif User Generated Content dan Branded Content Ads",
  "navbar.engagement"       : "Tingkatkan interaksi dengan audiens Anda.",
  "navbar.engagementDesc"   : "Tingkatkan interaksi dengan Audiens brand Anda dengan solusi Kami, Live Commerce dan User Generated Content",
  "navbar.product"          : "Peluncuran Produk Baru",
  "navbar.productDesc"      : "Luncurkan produk baru Anda dengan kampanye 360° yang menyeluruh dan strategi aktivasi yang efektif",
  "navbar.sales"            : "Tingkatkan penjualan brand Anda",
  "navbar.salesDesc"        : "Solusi kreatif Kami akan membantu Anda meningkatkan penjualan brandAnda, dengan Live Commerce dan Affiliate",

  "navbar.forBrand"         : "Brand",
  "navbar.forAgency"        : "Agency",
  "navbar.forCreator"       : "Kreator",
  "navbar.forBrandandAgency": "Untuk Brand & Agency",
  "navbar.login"            : "Masuk",
};
  
export default idNavBar;