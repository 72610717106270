import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
// import { Link } from "react-router-dom";
// import { FormattedMessage } from 'react-intl';

export default class Business extends Component {
    componentDidMount(){
        const options = {
            autoConfig: false
        };
        ReactPixel.init('382176287418608', options);
        ReactPixel.fbq('track', 'PageView');

        AOS.init({
          duration : 2000
        })
      }
    render() {
        return (
            <>
                <Helmet>
                    <title>BintanGO - Creator Powered Marketing</title>
                    <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
                    <meta property="og:image"              content="/logo-black-300.jpg" />
                    <meta property="og:url"                content={window.location.href} />
                    <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
                    <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
                </Helmet>
                <div>
                <section className="hero-section" id="hero">
                    <div className="container-xl">
                        <div className="row">
                            <div data-aos="fade-up" data-aos-duration="900" className="mt-4 mt-lg-0 col-lg-7 order-lg-first text-left">
                            <p className="title-cpm">BintanGO - Creator Powered Marketing</p>
                            <h1>Platform Untuk<br/><span className="tiger">Brand dan Influencer</span><br/>Berkolaborasi</h1>
                            <p className="mb-4 background-desc" data-aos-delay={100}>Tingkatkan visibilitas brand-mu dengan 40,000+ kreator. Kelola campaign-mu dalam satu dashboard, fleksibel, dan real time. <b>Mulai dengan Budget Rp 100.000</b></p>
                            <a href="https://cms.bintango.id/register.php" target="_blank"><div className="btn-signup">MULAI CAMPAIGN-MU</div></a>
                            </div>
                            <div className="col-lg-5 order-first">
                            <img data-aos="fade-up" data-aos-duration="900" width="100%" src="/assets/img/banner-business.png" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section className="section-analytics">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-3 col-6">
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay={100} className="card-testimoni">
                            <img src="/assets/img/icon-creators.svg" /> 
                            <p className="title">Total Creators</p> 
                            <p className="numbers">40.000 +</p> 
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay={200} className="card-testimoni">
                            <img src="/assets/img/icon-brands.svg" /> 
                            <p className="title">Brand &amp; Agency</p> 
                            <p className="numbers">200 +</p> 
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay={300} className="card-testimoni">
                            <img src="/assets/img/icon-followers.svg" /> 
                            <p className="title">Total Followers</p> 
                            <p className="numbers">150M +</p> 
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay={400} className="card-testimoni">
                            <img src="/assets/img/icon-avg-creators.svg" /> 
                            <p className="title">Avg. Creator ER</p> 
                            <p className="numbers">13.24%</p> 
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>
                    <section className="section-benefits">
                    <div className="container">
                        <div className="text-center">
                        <h1 className="title"><span className="tiger">Benefits</span> of Working <br />with BintanGO</h1>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="900" className="row align-items-center justify-content-center">
                        <div className="col-lg-5 text-right">
                            <img width="100%" src="/assets/img/benefits-1.png" /> 
                        </div>
                        <div className="col-lg-5 text-left">
                            <p className="title-benefits">Kembangkan Brand-mu dengan Creator-Powered Marketing 💪🏼</p>
                            <p className="desc-benefits">Flexible, scalable and traceable. Platform All-in-One BintanGO memiliki solusi inovatif dan data yang dapat dipercaya untuk semua kebutuhan kampanyemu</p>
                        </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="900" className="row align-items-center justify-content-center">
                        <div className="col-lg-5 order-lg-first text-left">
                            <p className="title-benefits">Hemat 10x waktu untuk menjalankan Campaign dengan Kreator ⚡️</p>
                            <p className="desc-benefits">Sederhankan proses marketingmu! Pantau dan kelola kampanyemu kapanpun, dimanapun 😆</p>
                        </div>
                        <div className="col-lg-4 order-first text-left">
                            <img width="100%" src="/assets/img/benefits-2.png" /> 
                        </div>
                        </div>
                    </div>
                    </section>
                    <section className="section-collaboration">
                    <div className="container">
                        <div className="row align-items-center">
                        <div data-aos="fade-up" data-aos-duration="900" className="col-lg-6 text-left">
                            <p className="title">Elevate Your Brand With Creator</p>
                            <p className="mb-4 desc" data-aos-delay={100}>Perkuat citra brand, tingkatkan konversi, dan perluas jangkauanmu dengan budget yang fleksibel, dan kreator yang dapat diandalkan dalam platform kami.<br/><br/>Dapatkan diskon untuk kampanye Creator Powered Marketing pertamamu</p>
                            <a href="https://cms.bintango.id/register.php" target="_blank"><div className="btn-collaboration d-lg-block d-none">MULAI CAMPAIGN-MU</div></a>
                        </div>
                        <div className="col-lg-6">
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay={100} className="d-flex align-items-center mb-4">
                            <div className="number">1</div>
                            <div className="desc">Tentukan rencana dan brief kampanyemu</div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay={200} className="d-flex align-items-center mb-4">
                            <div className="number">2</div>
                            <div className="desc">Team kami akan menghubungimu dalam waktu dekat</div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay={300} className="d-flex align-items-center mb-4">
                            <div className="number">3</div>
                            <div className="desc">Kampanye akan diluncurkan dalam aplikasi setelah melalui proses validasi</div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay={400} className="d-flex align-items-center mb-4">
                            <div className="number">4</div>
                            <div className="desc">Amati performa kampanyemu secara langsung</div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay={500} className="d-flex align-items-center mb-4">
                            <div className="number">5</div>
                            <div className="desc">Dapatkan data analytics dalam satu aplikasi!</div>
                            </div>
                        </div>
                        <a href="https://cms.bintango.id/register.php" target="_blank" className="mx-auto"><div className="btn-collaboration d-lg-none d-block">MULAI CAMPAIGN-MU</div></a>
                        </div>
                    </div>
                    </section>
                    <section className="section-featured">
                    <div className="container-xl">
                        <div className="text-center">
                        <h1 className="title">BintanGO telah diliput oleh: </h1>
                        </div>
                        <div className="row mt-lg-5 mt-4">
                        <OwlCarousel className="owl-carousel-featured" margin={40} items={1} loop={true} autoWidth={true} autoplay={true} nav={false} dots={false} 
                        >
                        {/* <div className="owl-carousel owl-carousel-featured owl-theme"> */}
                            <div className="item"><img src="/assets/img/featured/forbes.png" /></div>
                            <div className="item"><img src="/assets/img/featured/tc.png" /></div>
                            <div className="item"><img src="/assets/img/featured/dailysocial.png" /></div>
                            <div className="item"><img src="/assets/img/featured/techasia.png" /></div>
                            <div className="item"><img src="/assets/img/featured/idntimes.png" /></div>
                            <div className="item"><img src="/assets/img/featured/cnbc.png" /></div>
                            <div className="item"><img src="/assets/img/featured/marketeers.png" /></div>
                        {/* </div> */}
                        </OwlCarousel>
                        </div>
                    </div>
                    </section>
                    <section className="section-marketing">
                    <div className="container-xl">
                        <div className="row align-items-center">
                        <div data-aos="fade-up" data-aos-duration="900" className="col-lg-5 col-12 text-left">
                            <p className="title">Followers Asli. <br/>Engagement Asli.<br/><span className="tiger">Perkembangan Asli.</span></p>
                            <p className="desc">Amplifikasikan suaramu dengan kreator otentik yang sesuai dengan citra brandmu!</p>
                        </div>
                        <div className="col-lg-7 col-12">
                            <OwlCarousel className="owl-carousel-marketing" margin={20} items={1} loop={true} autoWidth={true} autoplay={true} nav={false} dots={false} 
                        >
                            {/* <div className="owl-carousel owl-carousel-marketing owl-theme"> */}
                            <div className="card-marketing text-left">
                                <p className="title">🎞️ Content Built-In &amp; Event Sponsor</p>
                                <p className="desc">Dapatkan akses ke BintanGO Creative Network, temukan suata brand-mu dengan kolaborasi kreator. 🤝</p>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">Tentukan content/ event pilihanmu</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">Sesuaikan obyektif kampanye dengan audiensmu</p>
                                </div>
                                <p className="title-study mt-3">Studi Kasus | <span className="feat">BintanGO x Nobi</span></p>
                                <p className="desc">BintanGO menyediakan beberapa pilihan bagi Nobi untuk melakukan branding, pilihan utamanya merupakan Reza Arap</p>
                                <img width="100%" src="/assets/img/marketing-1.png" />
                            </div>
                            <div className="card-marketing text-left">
                                <p className="title">🚀 Creator Boost</p>
                                <p className="desc">Dorong performa bisnismu dengan engagement nyata. Dalam aplikasi atau acara, BintanGO Creator AdNetwork tidak ada gantinya.🤳</p>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">IG Followers Boost</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">IG Post Likes</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">IG Post Comment</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">Registrasi/ Sign Up/ Download</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">Kehadiran Acara Online</p>
                                </div>
                                <p className="title-study mt-3">Studi Kasus | <span className="feat">BintanGO x Grid Network</span></p>
                                <p className="desc">BintanGO memberikan boost untuk kehadiran acara online. 100 kreator berpartisipasi secara aktif dalam acara webinar ini, memberikan komentar dan respons selama durasi acara.</p>
                                <div className="info">
                                <p>Jumlah Kreator : <span>100</span></p>
                                <p className="mt-3">Metrics: <span>Online Event Attendance</span></p>
                                </div>
                            </div>
                            <div className="card-marketing text-left">
                                <p className="title">📽 Branded Content Creation</p>
                                <p className="desc">Optimalkan UGC dengan Creator-Generated Content yang berskala dan mudah dikelola📈<br />Kultivasikan hubungan idealmu dengan customer.</p>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">Tentukan kebutuhan kampanye dan pilih profil group kreator</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">Berikan persetujuanmu untuk pilihan kreator</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">Kirim produkmu kepada kreator</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">Validasi konten yang telah dibuat oleh kreator</p>
                                </div>
                                <p className="title-study mt-3">Studi Kasus | <span className="feat">BintanGO x Fresh Beauty</span></p>
                                <p className="desc">BintanGO menyediakan User Generated Content untuk menyebarluaskan brand awareness Fresh Beauty di Indonesia</p>
                                <div className="info">
                                <p>Jumlah Kreator : <span>50</span></p>
                                <p className="mt-3">Metrics: <span>Instagram Reels &amp; Story</span></p>
                                </div>
                            </div>
                            <div className="card-marketing text-left">
                                <p className="title">🖼Creator Billboard</p>
                                <p className="desc">Eksekusi kampanye impianmu dengan ribuan kreator⏰<br />Memperkenalkan Creator AdNetwork! Cara terbaru meluncurkan iklanmu. Kreator kami akan mengunggah konten kampanyemu melalui media sosial mereka yang mentarget berbagai macam khalayak</p>
                                <div className="d-flex align-items-center mb-2">
                                <div className="number">1</div>
                                <p className="list">Pilih profil group kreator</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                <div className="number">2</div>
                                <p className="list">Tentukan budget, durasi, dan juga timeline kampanyemu</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                <div className="number">3</div>
                                <p className="list">Unggah brief kontenmu beserta brief</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                <img className="checklist" src="/assets/img/icon-checklist.svg" />
                                <p className="list">Done! ✅</p>
                                </div>
                                <p className="title-study mt-3">Studi Kasus | <span className="feat">BintanGO x Anteraja</span></p>
                                <p className="desc">BintanGO menyediakan 600 kreator untuk meng-upload materi konten promosi mellaui  Instagram dalam kurun waktu 5 hari</p>
                                <div className="info">
                                <p>Jumlah Kreator : <span>600</span></p>
                                <p className="mt-3">Metrics: <span>Instagram Story</span></p>
                                </div>
                            </div>
                            </OwlCarousel>
                        </div>
                        </div>
                    </div>
                    </section>
                    <section className="section-campaign">
                    <div className="container-xl">
                        <div className="text-center">
                        <h1 className="title">Beberapa Campaign Marketing bersama konten kreator BintanGO</h1>
                        </div>
                        <div className="row mt-5">
                        <OwlCarousel className="owl-carousel-campaign" margin={40} items={1} loop={true} autoWidth={true} autoplay={true} nav={false} dots={false} 
                        >
                        {/* <div className="owl-carousel owl-carousel-campaign owl-theme"> */}
                            <div className="item-campaign">
                            <img className="thumbnail" src="/assets/img/campaign/campaign-1.png" />
                            <p className="desc">Collaboration for Creators Attendance on Fresh Campaign</p>
                            </div>
                            <div className="item-campaign">
                            <img className="thumbnail" src="/assets/img/campaign/campaign-2.png" />
                            <p className="desc">Collaboration CoLearn with Vidi Aldiano</p>
                            </div>
                            <div className="item-campaign">
                            <img className="thumbnail" src="/assets/img/campaign/campaign-3.png" />
                            <p className="desc">Collaboration with Tante Atien</p>
                            </div>
                            <div className="item-campaign">
                            <img className="thumbnail" src="/assets/img/campaign/campaign-4.png" />
                            <p className="desc">Flash Coffee Campaign</p>
                            </div>
                            <div className="item-campaign">
                            <img className="thumbnail" src="/assets/img/campaign/campaign-1.png" />
                            <p className="desc">Collaboration for Creators Attendance on Fresh Campaign</p>
                            </div>
                            <div className="item-campaign">
                            <img className="thumbnail" src="/assets/img/campaign/campaign-2.png" />
                            <p className="desc">Collaboration CoLearn with Vidi Aldiano</p>
                            </div>
                            <div className="item-campaign">
                            <img className="thumbnail" src="/assets/img/campaign/campaign-3.png" />
                            <p className="desc">Collaboration with Tante Atien</p>
                            </div>
                            <div className="item-campaign">
                            <img className="thumbnail" src="/assets/img/campaign/campaign-4.png" />
                            <p className="desc">Flash Coffee Campaign</p>
                            </div>
                        </OwlCarousel>
                        </div>
                    </div>
                    </section>
                    <section className="section-brand">
                    <div className="container-xl">
                        <div className="text-center">
                        <h1 className="title">BintanGO Creator-Powered Brands</h1>
                        </div>
                        <div className="row mt-5">
                        <OwlCarousel className="owl-carousel-brand" margin={40} items={1} loop={true} autoWidth={true} autoplay={true} nav={false} dots={false} 
                        >
                        {/* <div className="owl-carousel owl-carousel-brand owl-theme"> */}
                            <div className="item"><img src="/assets/img/brands/antv.png" /></div>
                            <div className="item"><img src="/assets/img/brands/bankbtn.png" /></div>
                            <div className="item"><img src="/assets/img/brands/arotel.png" /></div>
                            <div className="item"><img src="/assets/img/brands/you.png" /></div>
                            <div className="item"><img src="/assets/img/brands/pigeon.png" /></div>
                            <div className="item"><img src="/assets/img/brands/aia.png" /></div>
                            <div className="item"><img src="/assets/img/brands/anteraja.png" /></div>
                            <div className="item"><img src="/assets/img/brands/nobi.png" /></div>
                            <div className="item"><img src="/assets/img/brands/tokenomy.png" /></div>
                            <div className="item"><img src="/assets/img/brands/ashta.png" /></div>
                            <div className="item"><img src="/assets/img/brands/vokraf.png" /></div>
                            <div className="item"><img src="/assets/img/brands/flashcoffee.png" /></div>
                            <div className="item"><img src="/assets/img/brands/etude.png" /></div>
                            <div className="item"><img src="/assets/img/brands/fresh.png" /></div>
                            <div className="item"><img src="/assets/img/brands/raya.png" /></div>
                        </OwlCarousel>
                        </div>
                    </div>
                    </section>
                    <section className="section-download">
                    <div className="container">
                        <div className="row align-items-center">
                        <div data-aos="fade-up" data-aos-duration="900" className="col-lg-7 order-lg-first text-left mt-3">
                            <p className="title">Tingkatkan kampanyemu melampaui potensialnya dengan Creator-Powered marketing</p>
                            <p className="desc">Temukan inspirasi dan cari tahu bagaimana BintanGO bisa membantu perkembangan brand-mu</p>
                            <a href="https://cms.bintango.id/register.php" target="_blank"><div className="btn-download">MULAI CAMPAIGN-MU</div></a>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="900" className="col-lg-5 order-first">
                            <img width="100%" src="/assets/img/banner-3.png" />
                        </div>
                        </div>
                    </div>
                    </section>
                </main>
                </div>

            </>
        );  
    }
}