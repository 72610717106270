/* Languange EN */
var idTechnologyCapabilities = {
    "technology.title"                   : "Teknologi kami memenuhi kebutuhan Anda",
    "technology.desc"                    : "Kami membantu menyederhanakan proses dan membantu bekerja lebih efisien",

    "technology.integrationTitle"        : "Integrasi Teknologi Kami",
    "technology.integrationDesc"         : "Kami ber-partner dan ber-integrasi dengan platform sosial media dan e-commerce, sehingga membantu Anda untuk menjalankan campaign lebih mudah",
    
    "technology.sosmedTitle"             : "INTEGRASI SOSIAL MEDIA",
    "technology.sosmedHeadline"          : "Proses pengambilan keputusan menggunakan data yang terpercaya",
    "technology.sosmedDesc"              : "Platform Kami ter-integrasi dengan top platform sosial media dan menggunakan datanya untuk membantu Anda untuk mencari kreator yang sesuai dengan brand dan men-sukseskan jalannya campaign",
    "technology.ecommerceTitle"          : "INTEGRASI E-COMMERCE",
    "technology.ecommerceHeadline"       : "Kelola semua produk influencer didalam satu platform",
    "technology.ecommerceDesc"           : "Kelola campaign Anda, lacak klik dan penjualan dengan mudah. Platform Kami ter-integrasi dengan E-commerce untuk membantu Anda mencapai tujuan Marketing.",
    
    "technology.canDoTitle"              : "Lihat apa yang bisa dilakukan BintanGO",
    "technology.canDoDesc"               : "Kami menggunakan teknologi untuk membantuk campaign marketing Anda, sehingga anda mendapat hasil yang memuaskan. Lihat kapabilitas Kami:",

    "technology.creatorAnalysis"         : "Analisa Kreator",
    "technology.creatorAnalysisTitle"    : "Cari kreator yang sesuai dengan campaign Anda",
    "technology.creatorAnalysisDesc"     : "Kami menggunakan data yang terpercaya untuk membantu Anda mencari kreator yang sesuai dengan campaign Anda. Kami menyediakan beragam metric yang bisa jadi pertimbangan Anda dalam memilih kreator seperti umur,gender, hingga informasi audiens",

    "technology.creatorDiscovery"         : "Pencarian Kreator",
    "technology.creatorDiscoveryTitle"    : "Cari kreator menggunakan fitur filter yang canggih dan data terpercaya",
    "technology.creatorDiscoveryDesc"     : "Cari dari 50.000+ kreator menggunakan beragam macam kriteria seperti engagement rate, informasi audiens dan informasi personal kreator. Membuat proses pencarian kreator lebih mudah.",

    "technology.creatorShortlisting"      : "Pemilihan Kreator",
    "technology.creatorShortlistingTitle" : "kurasi dari banyak kreator yang paling sesuai dengan campaign Anda",
    "technology.creatorShortlistingDesc"  : "Pilih kreator yang paling sesuai dengan campaign Anda dengan didukung oleh data yang terpercaya. Lakukan persetujuan atau penolakan kreator dengan mudah",

    "technology.reporting"                : "Real-Time Reporting",
    "technology.reportingTitle"           : "Dapatkan report campaign Anda dengan cepat",
    "technology.reportingDesc"            : "Anda tidak harus mengumpulkan screenshots. Dapatkan insight dari kreator dengan cepat hanya menggunakan link dan lacak performanya dalam satu platform",

    "technology.brandedContentAds"        : "Branded Content Ads",
    "technology.brandedContentAdsTitle"   : "Tingkatkan performa konter dari kreator dengan menggunakan Ads",
    "technology.brandedContentAdsDesc"    : "Platform Kami membantu Anda dengan mudah untuk membuat dan mengelola ads campaign.",

    "technology.weOfferTitle"             : "Kami menawarkan fleksibilatas sesuai kebutuhan Anda",
    "technology.weOfferDesc"              : "Kami bisa men-kustomisasi sesuai dengan kebutuhan Anda, lihat proses Kami mengetahui kebutuhan Anda untuk bisa menjalankannya",
    "technology.weOfferTitle1"           : "Assesment",
    "technology.weOfferDesc1"            : "Kami akan menjalankan beberapa interview denga stakeholder dari brand untuk bisa mengetahui apa saja kebutuhan mereka",
    "technology.weOfferTitle2"           : "Design",
    "technology.weOfferDesc2"            : "Kami akan membuat dashboard berdasarkan statement yang kamu sebutkan pada tahap interview",
    "technology.weOfferTitle3"           : "Deployment",
    "technology.weOfferDesc3"            : "Melakukan testing untuk menjamin produk yang dibangung sesuai kebutuhan brand",
    "technology.weOfferTitle4"           : "Team Training",
    "technology.weOfferDesc4"            : "Kami akan melakukan pelatihan untuk brand mencoba langsung platform Kami dengan mudah",
    "technology.weOfferTitle5"           : "Monitoring",
    "technology.weOfferDesc5"            : "Kami aka memonitar performa operational dan meng-identifikasi peluang yang ada",
    "technology.weOfferTitle6"           : "Evaluasi performa",
    "technology.weOfferDesc6"            : "Tim kami akan memonitor hasil dari produk yang telah digunakan, kamu dapat navigasi untuk bepindah ke brand",

    "technology.bannerTitle"  : "Ingin tau lebih banyak tentang kapabilitas Kami?",
    "technology.bannerDesc"   : "Kami bantu Anda untuk mencapain tujuan marketing dengan bantuan teknologi, dari proses pencarian kreator hingga reporting. Ayu diskusikan campaign Kamu selanjutnya",
};

export default idTechnologyCapabilities;