/* Languange TH */

var thTH = { 
  "learnMore"               : "เรียนรู้เพิ่มเติม",
  "letsDiscuss"               : "ติดต่อเรา",
  "discussWithOurTeam"               : "ติดต่อทีมงานของเรา",
  "discussWithUs"               : "มาพูดถึงเป้าหมายแบรนด์ของคุณกันดีกว่า",
  "letsDiscussYourCampaign"               : "มาพูดคุยเกี่ยวกับแคมเปญของคุณกันดีกว่า",
  "letsDiscussAdsCampaign"               : "มาพูดคุยเรื่องแคมเปญโฆษณากัน",
  "letsDiscussNextCampaign"               : "มาพูดคุยเกี่ยวกับแคมเปญถัดไปของคุณกันดีกว่า",
  "trustedByBrands"               : "เราได้รับความไว้วางใจจากแบรนด์กว่า {number} แบรนด์",
  "trustedByAgency"               : "เราได้รับความไว้วางใจจากเอเจนซี่มากกว่า {number} ราย",
  "ourSolution"               : "โซลูชั่นของเรา",
  "lookHowWeWork"               : "วิธีการทำงานของเรา",
  "ourEventPartner"               : "พันธมิตรของเรา",
  
  // FOR BANNER
  "bannerSaleTitle"               : "ยกระดับแบรนด์ของคุณด้วยโฆษณาครีเอทีพ {tiger} ของเรา",
  "bannerSaleTitleTiger"               : "โซลูชั่นทางการตลาด",
  "bannerSaleDesc"               : "เราพร้อมช่วยคุณดูแลและจัดการแคมเปญการตลาดด้วยโซลูชันที่สร้างสรรค์และมีประสิทธิภาพซึ่งปรับให้เหมาะกับเป้าหมายเฉพาะของคุณ ไม่ว่าคุณกำลังมองหาการสร้างการรับรู้ถึงแบรนด์ กระตุ้นยอดขาย หรือสร้างโอกาสในการขาย เราก็ช่วยคุณได้ ปรึกษากับทีมเราเกี่ยวกับเป้าหมายทางการตลาดของคุณและเรียนรู้ว่าเราสามารถช่วยให้คุณบรรลุเป้าหมายได้อย่างไร",
  
  "bannerAgencyTitle"               : "ทำให้ขั้นตอนการทำงานสะดวกกว่าเดิมด้วยโซลูชันที่ขับเคลื่อนด้วยเทคโนโลยีของเรา",
  "bannerAgencyDesc"               : "ให้เทคโนโลยีช่วยคุณทำเรื่องยากให้เป็นเรื่องง่ายสำหรับเอเจนซี่ของคุณ แจ้งความต้องการของคุณหรือให้เราแนะนำว่าเราจะช่วยคุณได้อย่างไร!",
  
  "bannerAwarenessTitle"               : "ถึงเวลาทำให้ทั่วโลกรู้จักคุณ",
  "bannerAwarenessDesc"               : "ให้ผู้เชี่ยวชาญของเราดูแลแคมเปญการรับรู้ถึงแบรนด์ของคุณและช่วยให้คุณเป็นที่รู้จักในตลาด",
  
  "bannerStrategicTitle"               : "เหนือกว่าคู่แข่งด้วยแผนการตลาดเชิงกลยุทธ์",
  "bannerStrategicDesc"               : "เปิดตัวผลิตภัณฑ์ของคุณด้วยการสร้างความประทับใจให้กับกลุ่มเป้าหมาย มาปรึกษาเกี่ยวกับการเปิดตัวผลิตภัณฑ์ครั้งต่อไปของคุณกับเรา",
};

export default thTH;