/* Languange EN */


var enTermBusiness = {
    //Intro
    "termBusiness.intro.title"      : "PERSYARATAN LAYANAN",

    "termBusiness.title.A"          : "KETENTUAN UMUM",
    "termBusiness.desc.A.1"         : "Persyaratan layanan yang terdapat pada situs web dan aplikasi seluler yang dioperasikan oleh BintanGO ini (“Persyaratan Layanan”) adalah perjanjian antara pengguna layanan BintanGO (“Anda” atau “Pengguna”) dan PT Stargo Digital Indonesia beserta seluruh afiliasinya, baik yang sudah ada atau akan ada di kemudian hari, yang secara bersama-sama selanjutnya disebut “BintanGO” atau “Kami”. Persyaratan Layanan ini mengatur akses dan penggunaan Anda atas produk Kami yaitu ‘Self-Service’ Creator-Enabled Platform, termasuk namun tidak terbatas pada, penggunaan jasa, layanan dan fasilitas, yang tersedia di website Kami (“Layanan”).",
    
    "termBusiness.desc.A.2"         : "Dengan menyetujui Persyaratan Layanan ini, Anda juga menyetujui Syarat dan Ketentuan dalam penggunaan website dan aplikasi BintanGO yang Kami kelola, dan Kebijakan Privasi dan setiap perubahannya, yang merupakan bagian yang tidak terpisahkan dari Persyaratan Layanan ini. Meskipun merupakan satu kesatuan, Persyaratan Layanan dari masing-masing layanan yang Kami sediakan akan berlaku dalam hal terdapat perbedaan dengan Persyaratan Layanan ini.",
    
    "termBusiness.desc.A.3"         : "Sebelum mengakses dan/atau menggunakan Layanan yang tersedia pada Aplikasi BintanGO dan situs Kami www.bintango.id, Anda harus membaca, memahami, dan menyetujui Persyaratan Layanan, Syarat dan Ketentuan dan Kebijakan Privasi. Anda juga harus mendaftarkan diri pada sistem Kami dan terverifikasi sebagai pengguna melalui proses yang Kami tetapkan sebelum dapat menggunakan Layanan yang tersedia pada laman Kami.",

    "termBusiness.title.B"          : "PENGUMPULAN INFORMASI",
    "termBusiness.desc.B"           : "Sebelum mengakses dan menggunakan Layanan BintanGO, Anda harus mendaftarkan diri Anda dengan memberikan data dan dokumen identitas (“Informasi”) yang dibutuhkan oleh Kami. BintanGO dapat memperoleh dan mengumpulkan Informasi sebagai berikut:",
    
    "termBusiness.desc.B.1"         : "Rincian Informasi",
    "termBusiness.desc.B.2"         : "Tujuan",

    "termBusiness.desc.B.1.a.1"     : "Alamat surat elektronik (email)",
    "termBusiness.desc.B.1.a.2"     : "untuk membuat Akun Pengguna pada laman Kami serta untuk proses identifikasi dan verifikasi identitas Akun Pengguna.",
    
    "termBusiness.desc.B.1.b.1"     : "Nomor WhatsApp",
    "termBusiness.desc.B.1.b.2"     : "untuk proses identifikasi dan verifikasi identitas Akun Pengguna.",
    
    "termBusiness.desc.B.1.c.1"     : "Nomor ID bagi Pengguna untuk mengakses Layanan, tidak termasuk kata sandi (password) Anda",
    "termBusiness.desc.B.1.c.2"     : "untuk membedakan penggunaan Layanan dari antar Pengguna atau dalam kondisi penggunaan perangkat yang sama oleh beberapa Pengguna; dan upaya preventif terjadinya penipuan/fraud.",
    
    "termBusiness.desc.B.1.d.1"     : "Informasi terkait usaha Anda, termasuk namun tidak terbatas, nama usaha/brand, media sosial, jenis usaha, alamat dan deskripsi usaha.",
    "termBusiness.desc.B.1.d.2"     : "untuk proses identifikasi dan verifikasi informasi usaha Pengguna; dan membantu Kami dalam proses pengerjaan Layanan dari Kami untuk Anda.",
    
    "termBusiness.desc.B.2"         : "Apabila Anda adalah perseorangan",
    "termBusiness.desc.B.2.a.1"     : "Foto Kartu Tanda Penduduk (KTP), termasuk segala informasi yang tercantum pada KTP",
    "termBusiness.desc.B.2.a.2"     : "untuk proses validasi dan verifikasi data Pengguna serta standar verifikasi bagi Pengguna; dan upaya preventif terjadinya penipuan/fraud.",

    "termBusiness.desc.B.2.b.1"     : "NPWP (Nomor Pokok Wajib Pajak)",
    "termBusiness.desc.B.2.b.2"     : "untuk dokumen pendukung dalam proses pelaporan pajak dalam hal penggunaan Layanan BintanGO.",
    
    "termBusiness.desc.B.3"         : "Apabila Anda adalah agensi",
    "termBusiness.desc.B.3.a.1"     : "Foto Kartu Tanda Penduduk (KTP), termasuk segala informasi yang tercantum pada KTP",
    "termBusiness.desc.B.3.a.2"     : "untuk proses validasi dan verifikasi data Pengguna serta standar verifikasi bagi Pengguna; dan upaya preventif terjadinya penipuan/fraud.",

    "termBusiness.desc.B.3.b.1"     : "NPWP (Nomor Pokok Wajib Pajak)",
    "termBusiness.desc.B.3.b.2"     : "untuk dokumen pendukung dalam proses pelaporan pajak dalam hal penggunaan Layanan BintanGO.",

    "termBusiness.desc.B.3.c.1"     : "Surat izin usaha terkait dengan agensi (apabila ada)",
    "termBusiness.desc.B.3.c.2"     : "untuk proses identifikasi dokumentasi dari legalitas usaha agensi dan verifikasi informasi usaha.",
    
    "termBusiness.desc.B.4"         : "Apabila Anda adalah badan hukum/usaha",
    "termBusiness.desc.B.4.a.1"     : "Foto Kartu Tanda Penduduk (KTP) dari seluruh anggota direksi, komisaris dan jajarannya (apabila ada), termasuk segala informasi yang tercantum pada KTP.",
    "termBusiness.desc.B.4.a.2"     : "untuk proses validasi dan verifikasi data Pengguna serta standar verifikasi bagi Pengguna; dan upaya preventif terjadinya penipuan/fraud.",
    
    "termBusiness.desc.B.4.b.1"     : "Dokumen legalitas perusahaan dan/atau identitas badan hukum/usaha termasuk di dalamnya akta pendirian badan hukum/usaha beserta akta perubahan anggaran dasar terakhir.",
    "termBusiness.desc.B.4.b.2"     : "untuk proses identifikasi dokumentasi dari legalitas badan hukum/usaha dan verifikasi informasi usaha.",
    
    "termBusiness.desc.B.4.c.1"     : "Surat izin yang masih berlaku berupa: \n- SIUP/TDP (jika masih berlaku);\n- NIB (pengganti SIUP/TDP);\n- Surat izin usaha sesuai bidangnya",
    
    "termBusiness.desc.B.4.d.1"     : "NPWP (Nomor Pokok Wajib Pajak)",
    "termBusiness.desc.B.4.d.2"     : "untuk dokumen pendukung dalam proses pelaporan pajak dalam hal penggunaan Layanan BintanGO.",
    

    "termBusiness.title.C"          : "PENGGUNAAN LAYANAN",
    "termBusiness.desc.C1"          : "Kami menyediakan layanan yang bermanfaat bagi Anda dengan tunduk pada persyaratan ini, yaitu layanan ‘Self-Service’ Creator-Enabled Platform. Layanan ini Kami desain untuk Anda dapat dengan mudah untuk membuat campaign Anda sendiri sesuai dengan keinginan dan tujuan campaign Anda.\n\nAnda dapat menyusun segala persyaratan dari campaign Anda secara mandiri pada halaman website BintanGO setelah registrasi akun Anda telah disetujui oleh Kami. Layanan ini dapat Anda proses dengan beberapa langkah, sebagai berikut:",
    "termBusiness.desc.C.a"         : "Langkah 1: Tujuan Campaign",
    "termBusiness.desc.C.a.desc"    : "Anda dapat memulai dengan campaign Anda dengan memilih satu atau lebih tujuan campaign Anda berdasarkan apa yang ingin Anda capai dari campaign tersebut.\n\nApabila Anda telah memilih tujuan campaign Anda, Kami akan menyesuaikan untuk opsi tipe pekerjaan yang ingin Anda inginkan sesuai tujuan campaign tersebut. Opsi tipe campaign yang Kami sediakan, Anda bisa memilih lebih dari satu tipe pekerjaan dalam satu campaign.\n\nAnda juga dapat menyesuaikan budget Anda sesuai pilihan campaign Anda dan mengatur harga satuan kreator. Apabila Anda memiliki budget lebih dari Rp 150.000.000 (seratus lima puluh juta rupiah), Anda akan dibantu oleh tim Kami untuk merancang campaign Anda secara eksklusif.",
    "termBusiness.desc.C.b"         : "Langkah 2: Campaign Info",
    "termBusiness.desc.C.b.desc"    : "Anda secara mandiri dapat mengisi rincian dari campaign Anda, termasuk namun tidak terbatas pada, nama campaign, deskripsi campaign, poster campaign dengan format dan ukuran yang telah ditentukan dalam halaman website BintanGO, dan penanggung jawab campaign.",
    "termBusiness.desc.C.c"         : "Langkah 3: Scope of Work / Lingkup Pekerjaan",
    "termBusiness.desc.C.c.desc"    : "Pada langkah ini, Anda dapat merancang jenis pekerjaan yang akan dikerjakan oleh kreator Anda dan menyampaikan seluruh rincian pekerjaan pada kreator Anda.\n\nAtur lingkup pekerjaan kreator Anda, mulai dari tenggat waktu mengumpulkan drafting sebelum melakukan campaign, mengunggah lingkup pekerjaan pada media sosial kreator, bukti pekerjaan telah selesai, dan hasil insight dari pekerjaan tersebut.",
    "termBusiness.desc.C.d"         : "Langkah 4: Campaign Requirement / Ketentuan Campaign",
    "termBusiness.desc.C.d.desc"    : "Anda dapat mengatur jangka waktu kreator mendaftarkan diri pada campaign Anda, jumlah kreator yang Anda butuhkan sesuai dengan kebutuh campaign, dan pendapatan yang Anda berikan kepada setiap kreator.\n\nLangkah ini juga dapat mengatur ketentuan kreator yang Anda butuhkan, seperti jenis kelamin, usia, kategori kreator, domisili kreator, dan pengikut sosial media kreator.",
    "termBusiness.desc.C.e"         : "Langkah 5: Summary / Ringkasan Campaign",
    "termBusiness.desc.C.e.desc"    : "Langkah ini memberikan kesempatan kepada Anda untuk mengecek kembali campaign yang telah Anda susun, termasuk periode keseluruhan campaign; periode kreator melakukan registrasi campaign; periode kreator melakukan pekerjaan campaign; periode Anda melakukan pembayaran kepada kreator; dan detail pekerjaan yang Anda berikan kepada kreator.",
    "termBusiness.desc.C.f"         : "Langkah 6: Tambahan Brief",
    "termBusiness.desc.C.f.desc"    : "Pada langkah ini, Anda wajib untuk menambahkan detail dari detail pekerjaan kreator apabila Anda memilih lebih dari 1 (satu) pekerjaan kepada kreator. Pada bagian ini, Anda wajib melampirkan segala detail pekerjaan ke dalam satu google drive dengan bentuk files atau PDF untuk setiap brief detail pekerjaan. \n\nAnda juga dapat menambahkan brief tambahan yang akan Kami munculkan pada Aplikasi BintanGO dengan mengisi pada bagian In-Apps Detail Brief agar membantu kreator Anda untuk lebih memahami lingkup pekerjaan campaign Anda.",
    "termBusiness.desc.C2"          : "Setelah Anda mengikuti dan menyelesaikan setiap proses diatas, Anda dapat menyimpan campaign Anda dan menunggu persetujuan dari Kami. Campaign Anda akan Kami tinjau, apabila telah disetujui, Kami akan mengirimkan tagihan untuk Anda melakukan pembayaran terlebih dahulu, sebagaimana akan dijelaskan pada poin dibawah ini. \n\nPemrosesan campaign Anda dan perkembangannya dapat diakses pada akun Anda dengan melihat status dari setiap campaign. Setiap kreator yang telah Anda pilih untuk melakukan pekerjaan Anda akan melakukan campaign tersebut sesuai dengan brief yang telah disusun dan akan mendapatkan imbalan dari Kami setelah selesai melakukan pekerjaan dan disetujui oleh Anda.",

    "termBusiness.title.D"          : "BIAYA DAN PEMBAYARAN",
    "termBusiness.desc.D"           : "Pengunduhan dan penggunaan Aplikasi serta laman website BintanGO adalah bebas biaya. Namun, Kami dapat mengenakan biaya untuk penggunaan Layanan ini. Layanan yang tersedia pada Aplikasi dan laman website BintanGO dapat dikenakan biaya sesuai dengan pemilihan Layanan yang telah Anda susun pada campaign Anda.\n\nPenggunaan Layanan hanya akan dapat diproses setelah Anda telah melakukan pembayaran. Kami akan menyetujui campaign Anda dan kreator dapat mulai untuk registrasi pada campaign Anda. Setiap pembayaran yang telah dilakukan tidak dapat dikembalikan dalam hal Anda membatalkan untuk menggunakan Layanan Kami.\n\nPembayaran Anda untuk Layanan Kami hanya berlaku pada 1 (satu) campaign dan tidak berlaku kelipatan untuk campaign lainnya. Anda dapat melakukan registrasi campaign baru dan susun anggaran baru terhadap campaign tersebut.\n\nAnda dapat melakukan pembayaran terhadap Layanan Kami yang tersedia pada Aplikasi BintanGO dan laman website Kami dengan metode pembayaran yang tersedia pada saat registrasi, yang dapat berubah sewaktu-waktu berdasarkan kebijakan Kami pada Persyaratan Layanan ini.",

    "termBusiness.title.E"          : "PERNYATAAN DAN JAMINAN",
    "termBusiness.desc.E"           : "You acknowledge and understand that there are restrictions and prohibited activities on the BintanGO Platform; you undertake to comply with these provisions as follows:",
    "termBusiness.desc.E.1"         : "Anda dengan ini menyatakan dan menjamin bahwa:", 
    "termBusiness.desc.E.1.a"       : "Anda telah membaca dan menyetujui Persyaratan Layanan ini, Syarat dan Ketentuan dan Kebijakan Privasi Kami;", 
    "termBusiness.desc.E.1.b"       : "Anda akan menggunakan dan/atau mengakses Aplikasi BintanGO, laman website Kami, Layanan ini hanya untuk tujuan yang sah;", 
    "termBusiness.desc.E.1.c"       : "Tidak ada materi apapun yang Anda sampaikan melalui Layanan Kami yang melanggar atau menyalahi hak-hak dari pihak ketiga manapun, termasuk hak cipta, merek dagang, privasi, publisitas atau hak-hak kepemilikan lainnya; atau mengandung materi yang melanggar hukum berdasarkan peraturan perundang-undangan;", 
    "termBusiness.desc.E.1.d"       : "Semua Informasi yang Anda berikan kepada BintanGO (termasuk namun tidak terbatas pada informasi data pribadi dan kontak) adalah akurat dan lengkap;", 
    "termBusiness.desc.E.1.e"       : "Anda memiliki kapasitas hukum, hak, kuasa dan otoritas yang diperlukan untuk menyetujui Persyaratan Layanan ini, dan Anda:", 
    "termBusiness.desc.E.1.e.a"     : "mengakses situs ini, menggunakan Layanan dan mengadakan kontrak dalam kapasitas Anda sendiri sebagai pribadi;",  
    "termBusiness.desc.E.1.e.b"     : "mengakses situs ini, menggunakan Layanan ini dan mengadakan kontrak atas nama entitas perusahaan;",  
    "termBusiness.desc.E.1.e.c"     : "berwenang untuk mengikat entitas (Anda sendiri, usaha Anda atau entitas perusahaan Anda) atas nama suatu pihak yang dengannya Anda mengadakan kontrak dan entitas tersebut setuju untuk terikat oleh Persyaratan Layanan ini.",  
    "termBusiness.desc.E.1.f"       : "Kecuali diizinkan oleh BintanGO, Anda tidak dapat mendaftar untuk lebih dari satu akun Pengguna, mendaftar untuk satu akun Pengguna atas nama individu selain diri sendiri, mengoperasi akun Pengguna atas nama atau untuk kepentingan siapapun yang tidak memenuhi syarat untuk mendaftar atau mengoperasikan akun Pengguna dengan nama mereka sendiri, atau mendaftar untuk akun Pengguna atas nama kelompok atau entitas.", 
    "termBusiness.desc.E.2"         : "Kami berhak untuk mengubah, memodifikasi, menunda atau menghentikan seluruh atau sebagian dari situs atau Layanan setiap saat. Kami juga dapat menentukan batas pada fitur-fitur tertentu atau membatasi akses Anda ke bagian(-bagian) atau seluruh situs atau Layanan tanpa pemberitahuan atau tanggung jawab.", 
    "termBusiness.desc.E.3"         : "Kami dapat dari waktu ke waktu memodifikasikan atau mengubah Persyaratan Layanan ini dengan memposting perubahan Persyaratan Layanan pada situs ini. Setiap penggunaan Layanan setelah perubahan Persyaratan Layanan ini akan dianggap sebagai penerimaan atas perubahan Persyaratan Layanan oleh Anda. Apabila Anda tidak setuju pada perubahan Persyaratan Layanan, Anda memiliki hak untuk menutup akun dan/atau berhenti menggunakan Layanan Kami. Anda dapat memeriksa Persyaratan Layanan ini dari waktu ke waktu karena perubahan, variasi dan koreksi terhadap Persyaratan Layanan yang akan mengikat Anda.", 
    "termBusiness.desc.E.4"         : "Setiap dan seluruh Informasi (termasuk namun tidak terbatas pada semua informasi pribadi atau bisnis, pesan, catatan, teks, informasi, video, foto, gambar, informasi kontak untuk Anda atau lainnya, iklan atau konten lain), atau setiap bagian daripadanya, yang Pengguna unggah, simpan, sediakan, tampilkan, sampaikan atau bagikan kepada Kami pada atau melalui situs BintanGO untuk keperluan Layanan harus mematuhi Syarat dan Ketentuan dan Persyaratan Layanan ini.", 

    "termBusiness.title.F"      : "KEKAYAAN INTELEKTUAL",
    "termBusiness.desc.F"       : "Aplikasi atau laman BintanGO dan Layanan yang tersedia, termasuk namun tidak terbatas pada, nama, logo, kode program, desain, merek dagang, teknologi, basis data, proses dan model bisnis, dilindungi oleh hak cipta, merek, paten dan hak kekayaan intelektual lainnya yang tersedia berdasarkan hukum Negara Republik Indonesia yang terdaftar baik atas nama Kami.\n\nTunduk pada Persyaratan Layanan ini, Kami memberikan Anda lisensi terbatas yang tidak eksklusif, dapat ditarik kembali, tidak dapat dialihkan (tanpa hak sublisensi) untuk (i) mengunduh, mengakses dan menggunakan Aplikasi atau laman BintanGO sebagaimana adanya, hanya pada perangkat elektronik Anda dan hanya untuk keperluan terkait penggunaan Layanan BintanGO; dan (ii) mengunduh, mengakses dan menggunakan konten, informasi dan materi terkait campaign Anda sebagai hasil dari Layanan yang Kami berikan untuk kepentingan campaign Anda dan tujuan komersial Anda. Segala hak dan hak istimewa lainnya yang tidak secara tegas diberikan dalam Persyaratan Layanan ini, adalah sepenuhnya hak Kami atau pemberi lisensi Kami.",

    "termBusiness.title.G"      : "TANGGUNG JAWAB ANDA",
    "termBusiness.desc.G"       : "Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk menggunakan atau mengakses Aplikasi atau situs BintanGO dan Layanan Kami. Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari penggunaan Layanan Kami, baik oleh Anda atau pihak lain yang menggunakan Akun Anda, dengan cara yang bertentangan dengan Persyaratan Layanan, Syarat dan Ketentuan dan Kebijakan Privasi, atau peraturan perundang-undangan yang berlaku, termasuk namun tidak terbatas untuk tujuan aktivitas kriminal, penipuan dalam bentuk apapun, pelanggaran hak kekayaan intelektual, dan/atau aktivitas lain yang merugikan publik dan/atau pihak lain manapun atau yang dapat atau dianggap dapat merusak reputasi Kami.",

    "termBusiness.title.H"      : "BATASAN TANGGUNG JAWAB KAMI",
    "termBusiness.desc.H"       : "Kami menyediakan dan memfasilitasi Layanan yang terdapat pada aplikasi dan situs BintanGO, sebagaimana adanya dan Kami tidak menyatakan atau menjamin bahwa keandalan, ketepatan waktu, kualitas, kesesuaian, ketersediaan, akurasi, kelengkapan atau keamanan dari Layanan ini dapat memenuhi kebutuhan dan akan sesuai dengan harapan Anda setiap waktu. Kami tidak bertanggung jawab atas setiap kerugian atau kerusakan yang disebabkan oleh setiap kegagalan atau kesalahan yang dilakukan oleh BintanGO ataupun kegagalan atau kesalahan Anda dalam mematuhi Persyaratan Layanan ini, Syarat dan Ketentuan atau Kebijakan Privasi Kami.\n\nKami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda atas Layanan Kami. Namun, Kami tetap melakukan pengawasan untuk tujuan memastikan kelancaran Anda dalam menggunakan Layanan Kami dan untuk memastikan kepatuhan terhadap Persyaratan Layanan ini, peraturan perundang-undangan yang berlaku, putusan pengadilan, dan/atau ketentuan lembaga administratif atau badan pemerintahan lainnya.\n\nKami tidak mempunyai kewajiban apapun, termasuk untuk mengambil tindakan lebih jauh atau tindakan hukum yang dianggap perlu oleh Anda terhadap setiap permasalahan atau perselisihan yang timbul antara Anda dan kreator. Tetapi, Kami dapat, berdasarkan diskresi Kami sepenuhnya, memfasilitasi setiap permasalahan atau perselisihan yang timbul antara Anda dan kreator dengan upaya wajar yang dapat Kami lakukan. Untuk menghindari keraguan, Kami tidak akan bertindak sebagai mediator dan hal tersebut tidak menimbulkan kewajiban lebih jauh apapun terhadap Kami.\n\nAnda dengan ini menyatakan dan setuju untuk membebaskan Kami terhadap setiap dan segala jenis kerugian, tanggung jawab, kewajiban, kerusakan, biaya, pengeluaran, tuntutan, permintaan, putusan, persidangan, denda, penalti, atau tindakan dalam bentuk apapun sehubungan dengan penggunaan Layanan Kami.",

    "termBusiness.title.I"      : "PENGAKHIRAN",
    "termBusiness.desc.I"       : "Anda setuju bahwa Kami dapat, setiap saat menurut kewenangan tunggal dan mutlak Kami, dengan atau tanpa pemberitahuan, menunda, mengakhiri, menonaktifkan atau menutup akses Anda pada, atau penggunaan dari, situs dan Layanan (atau setiap bagian, aspek atau fitur dari Layanan atau situs), mengakhiri akun Anda, menghapus akun Anda pada situs atau Layanan, tanpa alasan apapun.\n\nApabila Kami meyakini bahwa Anda telah melanggar atau bertindak tidak setiap dengan Persyaratan Layanan ini, Kami dapat mengambil tindakan sebagaimana kami anggap sesuai, termasuk namun tidak terbatas pada tindakan-tindakan berikut ini:",
    "termBusiness.desc.I.1"     : "penarikan segera, sementara atau permanen terhadap hak Anda untuk menggunakan situs BintanGO;",
    "termBusiness.desc.I.2"     : "pengeluaran peringatan untuk Anda",
    "termBusiness.desc.I.3"     : "proses hukum terhadap Anda untuk penggantian semua biaya atas dasar ganti rugi (termasuk namun tidak terbatas pada, biaya hukum dan administrasi yang wajar) yang disebabkan oleh pelanggaran;",
    "termBusiness.desc.I.4"     : "tuntutan hukum lebih lanjut terhadap Anda; atau",
    "termBusiness.desc.I.5"     : "pengungkapan informasi kepada pihak penegak hukum sebagaimana Kami secara wajar menganggap perlu.",

    "termBusiness.title.J"      : "KEADAAN KAHAR",
    "termBusiness.desc.J"       : "Peristiwa Keadaan Kahar adalah peristiwa saat suatu keadaan atau peristiwa di luar kendali wajar dari suatu pihak yang mempengaruhi masyarakat umum di negara atau wilayah pihak tersebut, dan yang mengakibatkan pihak tersebut tidak mampu mematuhi atau melakukan kewajiban tepat waktu berdasarkan Persyaratan Layanan ini. Keadaan atau peristiwa tersebut termasuk tindakan industri atau sengketa buruh, kerusuhan sipil, perang atau ancaman perang, tindakan kriminal atau teroris, tindakan atau peraturan pemerintah, kegagalan telekomunikasi atau utilitas, pemadaman listrik, kebakaran, ledakan, bencana alam fisik, epidemi, pembatasan karantina, dan tidak berfungsinya transportasi umum.\n\nTidak ada pihak yang harus bertanggung jawab atas setiap kegagalan pelaksanaan kewajibannya berdasarkan Persyaratan Layanan ini jika kegagalan tersebut diakibatkan oleh Peristiwa Keadaan Kahar, senantiasa dengan ketentuan bahwa bila memungkinkan, pihak yang terkena dampak akan melanjutkan pelaksanaan kewajiban tersebut segera setelah Peristiwa Keadaan Kahar yang menyebabkan kegagalan tersebut berhenti atau mereda.",

    "termBusiness.title.K"      : "PERJANJIAN ELEKTRONIK DAN PENGGUNAAN TANDA TANGAN ELEKTRONIK",
    "termBusiness.desc.K"     : "Anda mengerti dan setuju bahwa Persyaratan Layanan, Syarat dan Ketentuan dan Kebijakan Privasi merupakan perjanjian dalam bentuk elektronik dan tindakan Anda menekan tombol ‘daftar’ saat registrasi Akun dan tombol ‘masuk’ saat akan mengakses akun Anda merupakan persetujuan aktif Anda untuk mengikatkan diri dalam perjanjian dengan Kami sehingga keberlakuan Persyaratan Layanan, Syarat dan Ketentuan dan Kebijakan Privasi adalah sah dan mengikat secara hukum dan akan selalu berlaku sepanjang penggunaan Aplikasi / situs BintanGO dan Layanan Kami oleh Anda.\n\n Anda mengerti dan setuju bahwa tanda tangan elektronik dalam suatu transaksi dan/atau dokumen elektronik merupakan suatu bentuk persetujuan dan penerimaan Anda atas segala informasi yang terdapat di dalamnya serta mengikat Anda sama seperti tanda tangan basah. Anda dengan ini menyatakan dan menjamin keabsahan dan otentikasi atas tanda tangan elektronik yang Anda bubuhkan (apabila dibutuhkan).",

    "termBusiness.title.L"      : "HUBUNGI KAMI",
    "termBusiness.desc.L"       : "Apabila Anda memiliki pertanyaan, keluhan, komentar, atau permintaan terkait dengan Syarat dan Ketentuan ini, silahkan hubungi kami melalui kontak kami pada nomor  +6281280777870 atau email ke info@bintango.id.",
    
    //Ending
    "termBusiness.ending"  : "Saya telah membaca dan mengerti seluruh ketentuan pada Persyaratan Layanan ini dan konsekuensinya dan dengan ini menerima setiap hak, kewajiban, dan ketentuan yang diatur di dalamnya.",

};

export default enTermBusiness;