import { createSlice } from "@reduxjs/toolkit";
var langGet = localStorage.getItem('lang');
const lang = navigator.language === "id" ? "id" : "en";

const initialState = { 
    lang: langGet === null || langGet === undefined || langGet === '' ? lang : langGet
};
const langSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        changeLang(state, action) {
            return {
                ...state,
                lang: action.payload,
            };
        },
    },
});

export const { changeLang } = langSlice.actions;
export default langSlice.reducer;