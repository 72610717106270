import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollContainer from 'react-indiana-drag-scroll';
import { genAPI } from '../helpers/genAPI';
import 'owl.carousel/dist/assets/owl.carousel.min.css';  
import Navbar from '../layout/Navbar';

// Main Component
function CalculatorER(props) {
  const erInstagram = {
    averageNano : "2% - 5%",
    averageMicro: "1% - 3%",
    averageMid: "0.4% - 1.5%",
    averageMacro: "0.3% - 1%",
    goodNano: "5% - 8%",
    goodMicro: "3% - 5%",
    goodMid: "1.5% - 3%",
    goodMacro: "1% - 2.5%",
    excellentNano: ">10%",
    excellentMicro: ">5%",
    excellentMid: ">3%",
    excellentMacro: "2.5%",
  };

  const erTiktok = {
    averageNano : "2% - 5%",
    averageMicro: "1% - 3%",
    averageMid: "0.4% - 1.5%",
    averageMacro: "0.3% - 1%",
    goodNano: "5% - 8%",
    goodMicro: "3% - 5%",
    goodMid: "1.5% - 3%",
    goodMacro: "1% - 2.5%",
    excellentNano: ">10%",
    excellentMicro: ">5%",
    excellentMid: ">3%",
    excellentMacro: "2.5%",
  };

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState('');
  const [username, setUsername] = useState('');
  const [platform, setPlatform] = useState('instagram');
  const [platformStats, setPlatformStats] = useState('instagram');
  const [showER, setShowER] = useState(false);
  const [modalLimit, setModalLimit] = useState(false);
  const [stats, setStats] = useState(erInstagram);
  const [ER, setER] = useState({
    image_url : "",
    fullname: "",
    username: "",
    follower_count: "",
    total_interaction: "",
    total_post: "",
    engagement_rate: "",
  });

  function getER(id) {
    setAlert('');
    setShowER(false);
    var componentAPI = {
        method: "GET",
        url: process.env.REACT_APP_API_REFERRAL_URL + "/creator/phyllo-socmed-discovery?username="+username+"&platform="+platform+"",
        post: '',
        isAuth: ''
    }
    
    if(username!=""){
      setLoading(true)
      genAPI(componentAPI).then(data => {
        console.log("DATA",data);
        if(data.error === false)
        {
          setER(data.data);
          setShowER(true);
        }
        else
        {
          if(data.data.statusCode==429){
            setModalLimit(true);
          }else{
            setAlert(<p className='alert-error-er mt-2'><FormattedMessage id="calculator.sorry" /></p>)
          }
        }
        setLoading(false)
      });
    }else{
      setAlert(<p className='alert-error-er mt-2'><FormattedMessage id="calculator.pleaseEnterUsername" /></p>)
    }
  }

  AOS.init({
    duration : 2000
  })

  useEffect(() => {
    window.scrollTo(0, 0);
    
  }, []);

  useEffect(() => {
    setAlert('');
  }, [username]);

  useEffect(() => {
    if(modalLimit){
      document.body.style.overflowY = 'hidden';
    }else{
      document.body.style.overflowY = 'unset';
    }
  }, [modalLimit]);
  
  return (
    <>
      <Helmet>
        <title>BintanGO - Creator Powered Marketing</title>
        <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
        <meta property="og:image"              content="/logo-black-300.jpg" />
        <meta property="og:url"                content={window.location.href} />
        <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
        <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
      </Helmet>
      <div className='bg-hero-section'>
        <Navbar theme="dark" />
        <div className='main-banner-dark'>
          <div className='container-xl'>
            <div className='row justify-content-center mb-80'>
              <div className='col-xl-9 text-center'>
                <h1 className='career mb-4'><FormattedMessage id="calculator.title" /></h1>
                <p className='mb-5'><FormattedMessage id="calculator.desc" /></p>
                <div className='mx-aut form-calculator-er'>
                  <div className="d-lg-flex d-md-flex d-block align-items-center justify-content-center">
                    <div className="d-flex align-items-center w-100">
                      <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className='select-sosmed d-flex align-items-center mr-2'>
                        <img className='mr-2' src={platform=="instagram"?"/assets/img/instagram.svg":"/assets/img/tiktok.svg"} alt={platform} />
                        <img src="/assets/img/chevron-black.svg" alt="chevron" />
                      </div>
                      <ul className="dropdown-menu dropdown-menu-left py-0">
                        <li onClick={()=> setPlatform('instagram') } className={platform=="instagram"?'d-flex align-items-center active':'d-flex align-items-center'}><img className='mr-2' src="/assets/img/instagram.svg" alt="instagram" />Instagram</li>
                        <li onClick={()=> setPlatform('tiktok') } className={platform=="tiktok"?'d-flex align-items-center active':'d-flex align-items-center'}><img className='mr-2' src="/assets/img/tiktok.svg" alt="tiktok" />Tiktok</li>
                      </ul>
                      <FormattedMessage id="calculator.placeholder">
                        {(msg) => (<input 
                        className='form-control mr-lg-3 mr-md-3 mr-0 h-48 br-8'
                        type="text" 
                        placeholder={msg}
                        value={username} 
                        required
                        onChange={e => setUsername(e.target.value)} 
                      />)}
                      </FormattedMessage>
                    </div>
                    {loading?
                    <button type="button" className="btn-launcher h-48 d-flex mw-lg-135 align-items-center justify-content-center mt-lg-0 mt-md-0 mt-3" disabled><div className="loader_button"></div></button>:
                    <button onClick={()=> getER() } type="submit" className="btn-launcher h-48 mw-lg-fit mt-lg-0 mt-md-0 mt-3"><FormattedMessage id="calculator.checkCreator" /></button>
                    }
                  </div>
                  {loading?
                  <p className='alert-waiting mt-2'><FormattedMessage id="calculator.pleaseWait" /></p>:<></>}
                  {alert}
                </div>
              </div>
            </div>
          </div>
          {showER?
          <div className='er-calculator-result mb-80'>
            <div className='card-er-result mx-auto d-sm-flex d-block align-items-center'>
              <div className='mb-sm-30 mr-auto'>
                <div className='avatar-result'>
                  <img src={ER.image_url} alt="username" />
                </div>
                <p className='fullname mb-2'>{ER.fullname}</p>
                <p className='username mb-20'>@{ER.username}</p>
                <div className='d-flex align-items-center'>
                  <div className='mw-100 mr-12'>
                    <p className='number mb-2'>{ER.follower_count}</p>
                    <p className='title-er mb-0'><FormattedMessage id="calculator.followers" /></p>
                  </div>
                  <div className='mw-100 mr-12'>
                    <p className='number mb-2'>{ER.total_interaction}</p>
                    <p className='title-er mb-0'><FormattedMessage id="calculator.totalInteraction" /></p>
                  </div>
                  <div className='mw-100'>
                    <p className='number mb-2'>{ER.total_post}</p>
                    <p className='title-er mb-0'><FormattedMessage id="calculator.totalPost" /></p>
                  </div>
                </div>
              </div>
              <div className='er'>
                <div>
                  <p className='number mb-2'>{ER.engagement_rate}</p>
                  <p className='title mb-0'><FormattedMessage id="calculator.engagementRate" /></p>
                </div>
              </div>
            </div>
          </div>
          :<></>}
        </div>  
      </div>
      <div className='bg-content-white'>
        <div className='container-xl'>
          <section id="banner-calculator">
            <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="900" className='card-banner text-center'>
              <div className='row align-items-center'>
                <div className='col-lg-8 text-lg-left text-center'>
                  <h1 className='mb-20'><FormattedMessage id="calculator.bannerTitle" /></h1>
                  <p className='mb-0'><FormattedMessage id="calculator.bannerDesc" /></p>
                </div>
                <div className='col-lg-4 col-12 mt-5 mt-lg-0 text-right'>
                  <Link to="/contact"><button type="button" className="btn-launcher mw-lg-fit h-48"><FormattedMessage id="letsDiscuss" /></button></Link>
                </div>
              </div> 
            </div>
          </section>
          <section id="good-engagement-rate">
            <div className='row text-center justify-content-center mb-5'>
              <div className='col-lg-12'>
                <h1 className='mb-32'><FormattedMessage id="calculator.engagementRateTitle" /></h1>
              </div>
              <div className='col-lg-6'>
                <p className='fw-500 c-000 mb-0'><FormattedMessage id="calculator.engagementRateDesc" /></p>
              </div>
            </div>
            <div className='d-flex justify-content-center mb-4'>
              <div className='d-flex align-items-center navbar-stats-calculator'>
                <div onClick={()=> {setPlatformStats('instagram'); setStats(erInstagram)} } className={platformStats=="instagram"?'sosmed active':'sosmed'}>
                  <img src="/assets/img/instagram.svg" alt="instagram" />
                </div>
                <div onClick={()=> {setPlatformStats('tiktok'); setStats(erTiktok)} } className={platformStats=="tiktok"?'sosmed active':'sosmed'}>
                  <img src="/assets/img/tiktok.svg" alt="tiktok" />
                </div>
              </div>
            </div>
            <ScrollContainer nativeMobileScroll={false} hideScrollbars={false}>
              <div className="d-flex align-items-center">
                <div className='column-title'></div>
                <div className='column header br-topleft'>
                  <div>
                    <p className='title'><FormattedMessage id="calculator.nano" /></p>
                    <p className='desc mb-0'>5k-10k Followers</p>
                  </div>
                </div>
                <div className='column header'>
                  <div>
                    <p className='title'><FormattedMessage id="calculator.micro" /></p>
                    <p className='desc mb-0'>10k-50k Followers</p>
                  </div>
                </div>
                <div className='column header'>
                  <div>
                    <p className='title'><FormattedMessage id="calculator.mid" /></p>
                    <p className='desc mb-0'>50k-500k Followers</p>
                  </div>
                </div>
                <div className='column header br-topright'>
                  <div>
                    <p className='title'><FormattedMessage id="calculator.macro" /></p>
                    <p className='desc mb-0'>500k-1m Followers</p>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className='column-title average-title br-topleft'>
                  <p className='title mb-0'><FormattedMessage id="calculator.average" /></p>
                </div>
                <div className='column average-value'>
                  <p className='title mb-0'>{stats.averageNano}</p>
                </div>
                <div className='column average-value'>
                  <p className='title mb-0'>{stats.averageMicro}</p>
                </div>
                <div className='column average-value'>
                  <p className='title mb-0'>{stats.averageMid}</p>
                </div>
                <div className='column average-value'>
                  <p className='title mb-0'>{stats.averageMacro}</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className='column-title good-title'>
                  <p className='title mb-0'><FormattedMessage id="calculator.good" /></p>
                </div>
                <div className='column good-value'>
                  <p className='title mb-0'>{stats.goodNano}</p>
                </div>
                <div className='column good-value'>
                  <p className='title mb-0'>{stats.goodMicro}</p>
                </div>
                <div className='column good-value'>
                  <p className='title mb-0'>{stats.goodMid}</p>
                </div>
                <div className='column good-value'>
                  <p className='title mb-0'>{stats.goodMacro}</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className='column-title excellent-title br-bottomleft'>
                  <p className='title mb-0'><FormattedMessage id="calculator.excellent" /></p>
                </div>
                <div className='column excellent-value'>
                  <p className='title mb-0'>{stats.excellentNano}</p>
                </div>
                <div className='column excellent-value'>
                  <p className='title mb-0'>{stats.excellentMicro}</p>
                </div>
                <div className='column excellent-value'>
                  <p className='title mb-0'>{stats.excellentMid}</p>
                </div>
                <div className='column excellent-value br-bottomright'>
                  <p className='title mb-0'>{stats.excellentMacro}</p>
                </div>
              </div>
            </ScrollContainer>
          </section>
          <section id="faq-er">
            <h1 className='mb-64 text-center'><FormattedMessage id="calculator.faq" /></h1>
            <div className="card-bg-grey mb-32">
              <div className="accordion accordion-flush" id="accordionFlushCampaign1">
                  <div className="card-expand accordion-item">
                      <div className="accordion-header mb-0" id="flush-heading1">
                          <div className="accordion-button collapsed position-relative d-flex align-items-center" data-toggle="collapse" data-target="#flush-collapse1" aria-expanded="true" aria-controls="flush-collapse1">
                              <div className="title mr-auto"><FormattedMessage id="calculator.faqTitle1" /></div>
                          </div>
                      </div>
                      <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-parent="#accordionFlushCampaign1">
                          <div className="accordion-body">
                          <FormattedMessage id="calculator.faqDesc1" />
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="card-bg-grey mb-32">
              <div className="accordion accordion-flush" id="accordionFlushCampaign2">
                  <div className="card-expand accordion-item">
                      <div className="accordion-header mb-0" id="flush-heading2">
                          <div className="accordion-button collapsed position-relative d-flex align-items-center" data-toggle="collapse" data-target="#flush-collapse2" aria-expanded="true" aria-controls="flush-collapse2">
                              <div className="title mr-auto"><FormattedMessage id="calculator.faqTitle2" /></div>
                          </div>
                      </div>
                      <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-parent="#accordionFlushCampaign2">
                          <div className="accordion-body">
                          <FormattedMessage id="calculator.faqDesc2" />
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="card-bg-grey mb-32">
              <div className="accordion accordion-flush" id="accordionFlushCampaign3">
                  <div className="card-expand accordion-item">
                      <div className="accordion-header mb-0" id="flush-heading3">
                          <div className="accordion-button collapsed position-relative d-flex align-items-center" data-toggle="collapse" data-target="#flush-collapse3" aria-expanded="true" aria-controls="flush-collapse3">
                              <div className="title mr-auto"><FormattedMessage id="calculator.faqTitle3" /></div>
                          </div>
                      </div>
                      <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-parent="#accordionFlushCampaign3">
                          <div className="accordion-body">
                          <FormattedMessage id="calculator.faqDesc3" />
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="card-bg-grey mb-32">
              <div className="accordion accordion-flush" id="accordionFlushCampaign4">
                  <div className="card-expand accordion-item">
                      <div className="accordion-header mb-0" id="flush-heading4">
                          <div className="accordion-button collapsed position-relative d-flex align-items-center" data-toggle="collapse" data-target="#flush-collapse4" aria-expanded="true" aria-controls="flush-collapse4">
                              <div className="title mr-auto"><FormattedMessage id="calculator.faqTitle4" /></div>
                          </div>
                      </div>
                      <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-parent="#accordionFlushCampaign4">
                          <div className="accordion-body">          
                            <FormattedMessage id="calculator.faqDesc4" />
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="card-bg-grey mb-32">
              <div className="accordion accordion-flush" id="accordionFlushCampaign5">
                  <div className="card-expand accordion-item">
                      <div className="accordion-header mb-0" id="flush-heading5">
                          <div className="accordion-button collapsed position-relative d-flex align-items-center" data-toggle="collapse" data-target="#flush-collapse5" aria-expanded="true" aria-controls="flush-collapse5">
                              <div className="title mr-auto"><FormattedMessage id="calculator.faqTitle5" /></div>
                          </div>
                      </div>
                      <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-parent="#accordionFlushCampaign5">
                          <div className="accordion-body">
                          <FormattedMessage id="calculator.faqDesc5" />
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </section>
        </div>
        <div>
        {modalLimit?
        <div>
          <div className="modal-backdrop show"></div>
          <div className="modal fade-scale show d-block" style={{overflowY : "auto"}} id="LimitSearch" data-keyboard="false" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="categoryAllLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content modal-whatsapp-verify">
                  <div className="modal-body col-12">
                    <div className="text-center d-flex align-items-start mb-3">
                      <img className='mr-auto' src="/assets/img/time-limit.svg" alt="img" />
                      <img onClick={()=> setModalLimit(false) } className="close-modal" src="/assets/img/close-toggle.svg" alt="img" />
                    </div>
                    <h3 className='mb-3'><FormattedMessage id="calculator.limitTitle" /></h3>
                    <p className='mb-32'><FormattedMessage id="calculator.limitDesc" /></p>
                    <button type="button" className="btn-launcher h-48 br-4 mw-200"><FormattedMessage id="navbar.contact" /></button>
                    <hr className='my-32'/>
                    <p className='fw-600'><FormattedMessage id="calculator.moreResult" /></p>
                    <div className='d-flex'>
                      <img className='mr-1' src="/assets/img/chevron-right-tiger.svg" alt="img" />
                      <span className='fs-14 mr-3'><FormattedMessage id="calculator.moreResult1" /></span>
                      <img className='mr-1' src="/assets/img/chevron-right-tiger.svg" alt="img" />
                      <span className='fs-14 mr-3'><FormattedMessage id="calculator.moreResult2" /></span>
                      <img className='mr-1' src="/assets/img/chevron-right-tiger.svg" alt="img" />
                      <span className='fs-14'><FormattedMessage id="calculator.moreResult3" /></span>
                    </div>
                  </div>
              </div>
              </div>
          </div>
        </div>:<></>}
        </div>
      </div>
    </>
  )
}


export default CalculatorER;
