/* Languange EN */
var enCalculatorER = {
    "calculator.title"                      : "Social Media Engagement Rate Calculator",
    "calculator.desc"                       : "Discover brand and creator performance with our free engagement rate tool!",
    "calculator.placeholder"                : "Enter your social media username, Example : therock",
    "calculator.checkCreator"               : "Check Creator",
    "calculator.pleaseWait"                 : "Please wait while we search for your creator...",
    "calculator.sorry"                      : "Sorry, We cannot found your creator",
    "calculator.pleaseEnterUsername"        : "Please enter a username",
    "calculator.followers"                  : "Followers",
    "calculator.totalInteraction"           : "Total Interaction",
    "calculator.totalPost"                  : "Total Post",
    "calculator.engagementRate"             : "Engagement Rate",
    "calculator.bannerTitle"                : "Deliver a campaign that captures your audience's attention effortlessly",
    "calculator.bannerDesc"                 : " ",
    "calculator.engagementRateTitle"        : "So what is a good engagement rate?",
    "calculator.engagementRateDesc"         : "Curious about what counts as an average, good, or excellent social media engagement rate? Check out the table below!",
    "calculator.nano"                       : "Nano - Influencer",
    "calculator.micro"                      : "Micro - Influencer",
    "calculator.mid"                        : "Mid Tier - Influencer",
    "calculator.macro"                      : "Macro - Influencer",
    "calculator.average"                    : "Average",
    "calculator.good"                       : "Good",
    "calculator.excellent"                  : "Excellent",
    "calculator.faq"                        : "Frequently Asked Question about Engagement Rate",
    "calculator.faqTitle1"                  : "What is engagement rate, and why should I care about it for my online presence?",
    "calculator.faqDesc1"                   : "Engagement rate is a metric that measures how actively your audience interacts with your online content. High engagement rate indicates that your audience is interested in your content and finds it valuable and also with high engagement rate your content will more likely to be seen by more people, organically increasing your reach and brand awareness.",
    "calculator.faqTitle2"                  : "How do I calculate engagement rate, and what does it reveal about my content's performance?",
    "calculator.faqDesc2"                   : "Engagement rate reveals how actively your audience interacts with your content. A higher rate suggests your content resonates with them and prompts interaction. So, how do you know your engagement rate? Most social media platforms offer built-in analytics you can easily access. But how does it work? Calculating engagement rate is simple: divide the total interactions (likes, comments, shares, and views) by the post's reach.",
    "calculator.faqTitle3"                  : "Why brand need to look engagement rate of the creator",
    "calculator.faqDesc3"                   : "There are many reason for you why you need too see the creator engagement rate.  A high engagement rate indicates the creator has a dedicated and active audience that interacts with their content. This ensures your brand message reaches the right people, not just a large but disengaged follower base and high engagement suggests the creator's audience is receptive and trusting, potentially leading to better conversion rates when exposed to your brand through their promotions.",
    "calculator.faqTitle4"                  : "How frequently should I monitor and assess my engagement rate, and what tools can help with this?",
    "calculator.faqDesc4"                   : "The ideal frequency and tools for monitoring your engagement rate depend on your specific needs and resources. It's beneficial to experiment with different approaches and find a system that keeps you informed and allows you to adapt your strategies based on your audience's behavior. This could involve assessments every two weeks, monthly checks, or even daily monitoring during periods of high activity.\n\nHowever, remember that engagement rate is just one piece of the puzzle. For a comprehensive understanding of your online performance, it's crucial to analyze the bigger picture by exploring other metrics like reach, impressions, clicks, and conversions.",
    "calculator.faqTitle5"                  : "Is engagement rate the only metric I should consider when evaluating my content's performance, or are there other factors to keep in mind?",
    "calculator.faqDesc5"                   : "No, engagement rate is definitely not the only metric you should consider when evaluating your content's performance! While it's a valuable indicator of audience interaction, it's just one piece of the puzzle.\n\nYou can see reach and impressions tell you how many people see it in the first place.\n\nOr click through rate, If your goal is to drive traffic to your website or landing page, CTR is crucial. It tells you how many people who see your content actually click on a link you provide.",
    "calculator.limitTitle"                 : "You already reach your\n limit for today search",
    "calculator.limitDesc"                  : "Hit your daily limit? Don't worry, you're just getting started. Reach out to us to unlock unlimited searches and discover the true power of engagement.",
    "calculator.moreResult"                 : "Get more Result by accessing",
    "calculator.moreResult1"                : "100k+ creator",
    "calculator.moreResult2"                : "50+ data points",
    "calculator.moreResult3"                : "More Advanced Search",
};

export default enCalculatorER;