import React, { Component } from "react";
import { Link } from "react-router-dom";


export default class NavbarNoMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMenu: "",
            open: false,
        };
    }
      
    activeMenu(menu) {
        this.setState({
            activeMenu : menu,
            open:false,
        });
        document.body.style.overflow = 'unset';
    }

    openToggle(status) {
        this.setState({ 
            open: status
        });

        if(status==true){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'unset';
        }
    }

    render() {
        return (
            <>
             <header className="site-navbar site-navbar-target nomenu" role="banner">
                <div className="container-xl">
                    <div className="align-items-center d-flex">
                    <div className="site-logo d-flex align-items-center justify-content-center mr-auto">
                        <Link to="/" onClick={() => this.activeMenu("")} className="mb-0 mr-4 site-logo"><div className="logo" /><img className="logo" src="/assets/img/logo.svg" alt="Logo" /></Link>
                    </div>
                    <div className="d-inline-block d-lg-none ml-md-0 py-3">
                        <button className="offcanvas-toggle" onClick={() => this.openToggle(this.state.open?false:true)}>
                        <span>{this.state.open?<img src="/assets/img/close-toggle.svg" />:<img src="/assets/img/toggle.svg" />}</span>
                        </button>
                    </div>
                    <div id="offcanvas" className={this.state.open?"offcanvas-section open":"offcanvas-section"}>
                        <div className="offcanvas-wrap">
                        <div className="inner pb-0">
                            <div className="offcanvas-menu">
                            <nav>
                                <ul>
                                <li><Link to="/" onClick={() => this.activeMenu("")}>Untuk Kreator</Link></li>
                                <li><Link to="/business" onClick={() => this.activeMenu("business")}>Untuk Bisnis</Link></li>
                                </ul>
                            </nav>
                            </div>
                            <div className="offcanvas-footer">
                            <nav>
                                <ul>
                                <li><a href="https://creator.bintango.com" target="_blank" className="nav-link btn-launcher"><img className="mr-2" src="/assets/img/icon-launch.svg" alt="Logo" />LAUNCH CREATOR SPACE</a></li>
                                <li>
                                    <div className="d-flex justify-content-center">
                                    <a className="btn-download" href="https://bintango.onelink.me/wPAH/s7p6uyci" target="_blank">
                                        <div className="btn-download-apps d-flex -align-items-center">
                                            <img className="mr-2" src="/assets/img/icon-playstore.svg" alt="Logo" />
                                            <div>
                                            <div className="get-it-on">Get it on</div>
                                            <div className="title">Google play</div>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="btn-download" href="https://bintango.onelink.me/wPAH/s7p6uyci" target="_blank">
                                        <div className="btn-download-apps d-flex -align-items-center">
                                            <img className="mr-2" src="/assets/img/icon-appstore.svg" alt="Logo" />
                                            <div>
                                            <div className="get-it-on">Get it on</div>
                                            <div className="title">APP STORE</div>
                                            </div>
                                        </div>
                                    </a>
                                    </div>
                                </li>
                                </ul>
                            </nav>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </header>
            </>
        );  
    }
}