/* Languange TH */
var thForAgency = {
    "forAgency"	: "BINTANGO สำหรับเอเจนซี่",
    "forAgency.title"	: "การช่วยเหลือเอเจนซี่",
    "forAgency.desc"	: "แพลตฟอร์มของเราทำให้เอเจนซี่ทำงานง่ายและมีประสิทธิภาพมากขึ้น",

    "forAgency.titleTechDriven"	: "เราเป็นมากกว่าเอเจนซี่ของคุณ เราเป็นพันธมิตรที่ขับเคลื่อนด้วยเทคโนโลยีของคุณ",

    "forAgency.creatorManagementTitle"	: "การจัดการครีเอเตอร์",
    "forAgency.creatorManagementHeadline"	: "เชิญและจัดการครีเอเตอร์",
    "forAgency.creatorManagementDesc"	: "จัดการครีเอเตอร์ทั้งหมดของคุณได้อย่างง่ายดายในที่เดียว รับข้อมูลเชิงลึกทันทีเกี่ยวกับประสิทธิภาพของพวกเขา และทำการตัดสินใจเกี่ยวกับพวกเขาโดยอาศัยข้อมูล",

    "forAgency.creatorDiscoveryTitle"	: "การค้นพบของครีเอเตอร์",
    "forAgency.creatorDiscoveryHeadline"	: "ค้นหาครีเอเตอร์ที่สมบูรณ์แบบสำหรับแคมเปญของคุณ",
    "forAgency.creatorDiscoveryDesc"	: "เริ่มต้นการค้นหาครีเอเตอร์ที่สมบูรณ์แบบสำหรับแคมเปญแบรนด์ของคุณด้วยแพลตฟอร์มการคัดเลือกที่ขับเคลื่อนด้วยข้อมูลของเรา เราให้ข้อมูลที่เป็นปัจจุบันและน่าเชื่อถือที่สุดเพื่อช่วยคุณในการตัดสินใจอย่างมีข้อมูลเกี่ยวกับแคมเปญของคุณ",

    "forAgency.campaignManagementTitle"	: "การจัดการแคมเปญ",
    "forAgency.campaignManagementHeadline"	: "ลาก่อนสเปรดชีต สวัสดีระบบจัดการแบบครบวงจร!",
    "forAgency.campaignManagementDesc"	: "ข้ามขั้นตอนเอกสารและทำทุกอย่างบนแพลตฟอร์มเดียว ตั้งแต่การสร้างและเปิดตัวแคมเปญไปจนถึงการจัดการรายชื่อครีเอเตอร์และจัดระเบียบงานของพวกเขา",

    "forAgency.creatorShortlistingTitle"	: "การคัดเลือกครีเอเตอร์",
    "forAgency.creatorShortlistingHeadline"	: "ติดตามการอนุมัติหรือการปฏิเสธจากแบรนด์เรียลไทม์บนแพลตฟอร์ม",
    "forAgency.creatorShortlistingDesc"	: "คุณไม่จำเป็นต้องส่งสเปรดชีต Excel ที่เต็มไปด้วยครีเอเตอร์และอัปเดตอย่างต่อเนื่อง แพลตฟอร์มของเราจะติดตามครีเอเตอร์ที่ถูกปฏิเสธและอนุมัติทั้งหมดให้คุณได้อย่างรวดเร็ว ทำให้ง่ายต่อการจัดการ",

    "forAgency.campaignReportingTitle"	: "การรายงานแคมเปญ",
    "forAgency.campaignReportingHeadline"	: "ข้ามความยุ่งยากในการขอภาพหน้าจอและเพียงแค่แชร์ลิงก์ เราจะดูแลส่วนที่เหลือ",
    "forAgency.campaignReportingDesc"	: "ด้วยแพลตฟอร์มของเรา คุณสามารถรับผลลัพธ์ที่ต้องการได้อย่างง่ายดายจากการโพสต์ลิงก์ใดๆ โดยไม่ต้องถาม ประหยัดเวลาและทำงานได้อย่างมีประสิทธิภาพมากขึ้น",
};

export default thForAgency;