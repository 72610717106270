/* Languange TH */
var thContact = {
    "contact.headline"	: "ติดต่อทีมเรา",
    "contact.title"	: "เริ่มทำงานร่วมกับ BintanGO",
        
    "contact.iambrand"	: "ฉันเป็นแบรนด์",
    "contact.iambrandDesc"	: "เราพร้อมช่วยเหลือคุณในกิจกรรมทางการตลาดและบรรลุเป้าหมายการตลาดของแบรนด์ของคุณได้",
    "contact.iamagency"	: "ฉันเป็นเอเจนซี่",
    "contact.iamagencyDesc"	: "ด้วยแพลตฟอร์มของเรา เราจะช่วยคุณจัดการแคมเปญและครีเอเตอร์ได้อย่างง่ายดาย",
    "contact.iamcreator"	: "ฉันเป็นครีเอเตอร์",
    "contact.iamcreatorDesc"	: "เราช่วยให้ครีเอเตอร์หางานได้ง่ายและสนับสนุนพวกเขาให้ #GrowBeyond",
};

export default thContact;