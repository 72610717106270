// import React from "react";
import axios from 'axios';
import store from '../redux/store.js';
//import { _logout } from './login';

export function genAPI(component) {

    var loginState = store.getState().auth;
    var langState = store.getState().lang;
    var token= '';

    var headerData = {
        'Content-Type'      : 'application/json',
        'X-Platform'        : process.env.REACT_APP_API_X_PLATFORM,
        'X-Version'         : process.env.REACT_APP_API_X_VERSION,
        'X-Lang'            : langState.lang,
    }
    // console.log('loginstate', loginState);
    // console.log('component', component);
    //if(loginState.isLogin === "true" && component.isAuth !== undefined)
    if(loginState.isLogin === "true")
    {   
        token = 'Bearer '+loginState.accessToken;
        if(component.isMacAddress !== undefined){
            headerData = {
                'Content-Type'      : 'application/json',
                'mac-address'       : '-',
                'X-Platform'        : process.env.REACT_APP_API_X_PLATFORM,
                'X-Version'         : process.env.REACT_APP_API_X_VERSION,
                'X-Lang'            : langState.lang,
                'Authorization'     : token
            }
        }else{
            headerData = {
                'Content-Type'      : 'application/json',
                'X-Platform'        : process.env.REACT_APP_API_X_PLATFORM,
                'X-Version'         : process.env.REACT_APP_API_X_VERSION,
                'X-Lang'            : langState.lang,
                'Authorization'     : token
            }
        }
        
    }

    const callbackAPI = axios({
        method  : component.method,
        url     : component.url,
        headers : headerData,
    //   maxContentLength  : 20000000,
        //maxBodyLength     : 'Infinity',
        data    : component.method !== 'GET' ? component.post : ''
    }).then(res => {
        var result = res.data;
        var dataRes = {};
        // return result;
        // console.log("res", res);

        if(result.result===undefined){
            dataRes = {
                error: false,
                data : result,
                desc: '-',
                result: 1,
            };
        }else{
            if(result.result === 1)
            { 
                dataRes = {
                    error: false,
                    data : result,
                    desc: "Success Get Data",
                };
            }
            else { 
                dataRes = {
                    error: true,
                    data : result,
                    desc: result.desc,
                    result: result.result,
                };
            }
        }

        return dataRes;
    
    }).catch(error => { 
        // console.log(error.response);
        // console.log(error.response.data);
        // console.log(error.message);
        var dataRes = {};

        dataRes = {
            error: true,
            data : error.response.data,
            desc: error.message,
            result: 0,
        };

        return dataRes;
    });

    return callbackAPI;
}