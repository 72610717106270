import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
// import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

const listUniverse1 = [
    "Alexandria Samantha.jpg",
    "Alifa Thesa.jpg",
    "Alyssa Alda Tiffany.png",
    "Angeline Zeanetti.jpg",
    "Audrey Gischa.jpg",
    "Audrey Valencia.jpg",
    "Bella Feryana.jpg",
    "Bella Fridianty.jpg",
    "Bondol Jpg.jpg",
    "Caren Michele.png",
    "Carisaa Putri.jpg",
    "Claudia Isabella.png",
    "Dave Jordan.png",
    "Devannie Geraldine.png",
    "Dian Oktaviani.jpg",
    "earlyjenn.png",
    "Eugene Laurent.png",
    "Evita Kezia.png",
    "Fayola.jpg",
    "Gabriele Tjiphanata.jpg"
]

const listUniverse2 = [
    "Gabriella.jpg",
    "Gaby Felicia.png",
    "Gita Salsabila.png",
    "Graciellavx.jpg",
    "Hershaniaa.jpg",
    "Inesovelia.jpg",
    "Irene.png",
    "Jane Keisya.jpg",
    "Jeje.png",
    "Jenice Veronica.png",
    "Kamila Azzahra.jpg",
    "Keisha.png",
    "Kezia Graciella.png",
    "Kiara Farvianata.png",
    "Kimberly Natasha.png",
    "La-Tanya Alisa.jpg",
    "Mei.png",
    "Michelle Syanuar.png",
    "Nanda.jpg",
    "Nithalie Lou.jpg"
]

const listUniverse3 = [
    "Pegrina Annastasya.jpg",
    "Rere.png",
    "Sheline Lie.png",
    "Silvia Ratu.png",
    "Sophie Tahir.png",
    "Starlynn Angelica.png",
    "Stefanie Lienardo.png",
    "Stella Joe.jpg",
    "Stella Putri.jpg",
    "Stelladia.jpg",
    "Teresa Nikita.png",
    "Theresia Suryadi.jpg",
    "Tiffany Kongadian.jpg",
    "Vania Febrianne.jpg",
    "Veren Febriana.jpg",
    "Vibi Valentina.png",
    "Viora.png",
    "Viorenstia Nery.jpg",
    "Vira Melinda.png",
    "Yoselyn Eunike.png"
]

const listBrands = [
    "logo-antv.png",
    "logo-btn.png",
    // "arotel.png",
    "logo-you.png",
    "logo-pigeon.png",
    "logo-aia.png",
    "logo-anter-aja.png",
    "logo-nobi.png",
    "logo-tokenomy.png",
    // "ashta.png",
    // "vokraf.png",
    "logo-flash-coffee.png",
    "logo-etude.png",
    "logo-fresh.png",
    "logo-raya.png"
]

const listNews = [
    "forbes.png",
    "tc.png",
    "dailysocial.png",
    "techasia.png",
    "idntimes.png",
    "cnbc.png",
    "marketeers.png"
]

export default class Creator extends Component {
    componentDidMount(){
        const options = {
            autoConfig: false
        };
        ReactPixel.init('1128722624394061', options);
        ReactPixel.fbq('track', 'PageView');

        AOS.init({
          duration : 2000
        })
      
        setTimeout(function() {this.setState({loading : false})}.bind(this), 800) ;
      }
    render() {
        return (
            <>
                <Helmet>
                    <title>BintanGO - Creator Powered Marketing</title>
                    <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
                    <meta property="og:image"              content="/logo-black-300.jpg" />
                    <meta property="og:url"                content={window.location.href} />
                    <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
                    <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
                </Helmet>
                <div>
                <section className="hero-section" id="hero">
                    <div className="container-xl">
                    <div className="row">
                        <div data-aos="fade-up" data-aos-duration="900" className="mt-4 mt-lg-0 col-lg-7 order-lg-first text-left mt-lg-4">
                        <h1><FormattedMessage id="creator.titleBanner" />❤️</h1>
                        <p className="mb-4 background-desc" data-aos-delay="100"><FormattedMessage id="creator.descBanner" /></p>
                        <a href="https://creator.bintango.com/register" target="_blank" className="d-flex justify-content-lg-start justify-content-center"><div className="btn-signup">JOIN NOW</div></a>
                        <div className="d-flex justify-content-lg-start justify-content-center mt-4">
                            <a href="https://bintango.onelink.me/wPAH/s7p6uyci" target="_blank">
                                <div className="btn-download-apps d-flex -align-items-center mr-3">
                                <img className="mr-2" src="/assets/img/icon-playstore.svg" alt="Logo" />
                                <div>
                                    <div className="get-it-on">Get it on</div>
                                    <div className="title">Google play</div>
                                </div>
                                </div>
                            </a>
                            <a href="https://bintango.onelink.me/wPAH/s7p6uyci" target="_blank">
                                <div className="btn-download-apps d-flex -align-items-center">
                                <img className="mr-2" src="/assets/img/icon-appstore.svg" alt="Logo" />
                                <div>
                                    <div className="get-it-on">Get it on</div>
                                    <div className="title">APP STORE</div>
                                </div>
                                </div>
                            </a>
                        </div>
                        </div>
                        <div className="col-lg-5 order-first">
                        <img width="100%" data-aos="fade-up" data-aos-duration="900" src="/assets/img/banner-1.png" alt="Logo" />
                        </div>
                    </div>
                    </div>
                </section>
                <main id="main">
                    <section className="section-brand">
                    <div className="container-xl">
                        <div className="text-center">
                        <h1 className="title"><FormattedMessage id="creator.section5Title" /></h1>
                        </div>
                        <div className="row mt-5">
                            <OwlCarousel margin={0} items={1} loop={true} autoWidth={true} autoplay={true} nav={false} dots={false} > 
                            {
                                listBrands.map(function(image, index) {
                                return (
                                    <div className="item" key={index}><img src={"/assets/img/for-brand/" + image} /></div>
                                    );
                                })
                            }
                            </OwlCarousel>
                        </div>
                    </div>
                    </section>
                    <section className="section-grow-beyond">
                    <div className="container">
                        <div className="text-center">
                        <h1 className="title"><FormattedMessage id="creator.section2Title" /><span className="tiger">CreatorGO!</span><br /></h1>
                        <p className="title-desc"><FormattedMessage id="creator.section2Desc" /></p>
                        </div>
                        <div className="row align-items-end mt-5">
                        <div className="col-lg-3">
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className="card-beyond mb-5">
                                <img src="/assets/img/blue-star.svg" /> 
                                <p className="title"><FormattedMessage id="creator.growCard1Title" /></p>
                                <p className="desc"><FormattedMessage id="creator.growCard1Desc" /></p>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="300" className="card-beyond mb-5">
                                <img src="/assets/img/blue-star.svg" /> 
                                <p className="title"><FormattedMessage id="creator.growCard2Title" /></p>
                                <p className="desc"><FormattedMessage id="creator.growCard2Desc" /></p>
                            </div>
                        </div>
                        <div className="col-lg-6 order-last">
                            <img className="become-creator" data-aos="fade-up"  data-aos-duration="900" data-aos-delay="300" src="/assets/img/become-creator.png" width="100%" /> 
                        </div>
                        <div className="col-lg-3 order-lg-last">
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="500" className="card-beyond mb-5">
                                <img src="/assets/img/blue-star.svg" /> 
                                <p className="title"><FormattedMessage id="creator.growCard3Title" /></p>
                                <p className="desc"><FormattedMessage id="creator.growCard3Desc" /></p>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="700" className="card-beyond mb-5">
                                <img src="/assets/img/blue-star.svg" /> 
                                <p className="title"><FormattedMessage id="creator.growCard4Title" /></p>
                                <p className="desc"><FormattedMessage id="creator.growCard4Desc" /></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>
                    <section className="section-step">
                    <div className="container">
                        <div className="text-center">
                        <h1 className="title"><FormattedMessage id="creator.section4Title" /><span className="tiger"><FormattedMessage id="creator.section4Hashtag" /></span></h1>
                        </div>
                        <div className="for-desktop">
                        <div className="row mt-5 mb-5">
                            <div className="dashed-space" />
                            <div className="col-4">
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className="card-step">
                                <img className="mb-3" src="/assets/img/step-1.svg" /> 
                                <p className="title"><FormattedMessage id="creator.section4Step1Title" /></p>
                                <p className="desc"><FormattedMessage id="creator.section4Step1Desc" /></p>
                            </div>
                            </div>
                            <div className="col-4">
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className="card-step">
                                <img className="mb-3" src="/assets/img/step-2.svg" /> 
                                <p className="title"><FormattedMessage id="creator.section4Step2Title" /></p>
                                <p className="desc"><FormattedMessage id="creator.section4Step2Desc" /></p>
                            </div>
                            </div>
                            <div className="col-4">
                            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="300" className="card-step">
                                <img className="mb-3" src="/assets/img/step-3.svg" /> 
                                <p className="title"><FormattedMessage id="creator.section4Step3Title" /></p>
                                <p className="desc"><FormattedMessage id="creator.section4Step3Desc" /></p>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="for-mobile">
                        <div className="d-flex by-step">
                            <div className="step text-center bottom">
                                <img src="/assets/img/step-1.svg" />
                            </div>
                            <div>
                            <p className="title"><FormattedMessage id="creator.section4Step1Title" /></p>
                            <p className="desc"><FormattedMessage id="creator.section4Step1Desc" /></p>
                            </div>
                        </div>
                        <div className="d-flex by-step">
                            <div className="step text-center bottom">
                            <img src="/assets/img/step-2.svg" />
                            </div>
                            <div>
                            <p className="title"><FormattedMessage id="creator.section4Step2Title" /></p>
                            <p className="desc"><FormattedMessage id="creator.section4Step2Desc" /></p>
                            </div>
                        </div>
                        <div className="d-flex by-step">
                            <div className="step text-center">
                            <img src="/assets/img/step-3.svg" />
                            </div>
                            <div>
                            <p className="title"><FormattedMessage id="creator.section4Step3Title" /></p>
                            <p className="desc"><FormattedMessage id="creator.section4Step3Desc" /></p>
                            </div>
                        </div>
                        </div>
                        <a href=" https://app.bintango.com/CCzU" target="_blank"><div className="btn-apply-now"><FormattedMessage id="creator.section4ButtonApply" /></div></a>
                    </div>
                    </section>
                    <section className="section-we-believe">
                    <div className="container">
                        <div className="text-center">
                        <h1 className="title-blue"><FormattedMessage id="creator.section1Title" /></h1>
                        <h1 className="title"><FormattedMessage id="creator.section1SubTitle" /></h1>
                        <p className="title-desc"><FormattedMessage id="creator.section1Desc" /></p>
                        </div>
                        <OwlCarousel className="mt-4" margin={0} items={1} loop={true} autoWidth={true} autoplay={true} nav={false} dots={false} 
                        >  
                        <div className="card-testimoni">
                            <img className="avatar" src="/assets/img/people-laissti.png" /> 
                            <img className="mt-4 mb-2 kutip" src="/assets/img/icon-kutip.svg" />
                            <p className="desc"><FormattedMessage id="creator.testiLaissti" /></p> 
                            <div className="text-right">
                                <p className="fullname">Veronica Jalah Laissti</p>
                                <a href=" https://instagram.com/laissti" target="_blank"><p className="username">@laissti</p></a>
                            </div>
                        </div>
                        <div className="card-testimoni">
                            <img className="avatar" src="/assets/img/people-aliciaeva.png" /> 
                            <img className="mt-4 mb-2 kutip" src="/assets/img/icon-kutip.svg" />
                            <p className="desc"><FormattedMessage id="creator.testiAliciaeva" /></p> 
                            <div className="text-right">
                                <p className="fullname">Eva Alicia</p>
                                <a href=" https://instagram.com/aliciaeva" target="_blank"><p className="username">@aliciaeva</p></a>
                            </div>
                        </div>
                        <div className="card-testimoni">
                            <img className="avatar" src="/assets/img/people-devinaputrii.png" /> 
                            <img className="mt-4 mb-2 kutip" src="/assets/img/icon-kutip.svg" />
                            <p className="desc"><FormattedMessage id="creator.testiDevinaputrii" /></p> 
                            <div className="text-right">
                                <p className="fullname">Devina Putri</p>
                                <a href=" https://instagram.com/devinaputrii" target="_blank"><p className="username">@devinaputrii</p></a>
                            </div>
                        </div>
                        <div className="card-testimoni">
                            <img className="avatar" src="/assets/img/people-astaririri.png" /> 
                            <img className="mt-4 mb-2 kutip" src="/assets/img/icon-kutip.svg" />
                            <p className="desc"><FormattedMessage id="creator.testiAstaririri" /></p> 
                            <div className="text-right">
                                <p className="fullname">Astari Budi</p>
                                <a href=" https://instagram.com/astaririri" target="_blank"><p className="username">@astaririri</p></a>
                            </div>
                        </div>
                        <div className="card-testimoni">
                            <img className="avatar" src="/assets/img/people-laissti.png" /> 
                            <img className="mt-4 mb-2 kutip" src="/assets/img/icon-kutip.svg" />
                            <p className="desc"><FormattedMessage id="creator.testiLaissti" /></p> 
                            <div className="text-right">
                                <p className="fullname">Veronica Jalah Laissti</p>
                                <a href=" https://instagram.com/laissti" target="_blank"><p className="username">@laissti</p></a>
                            </div>
                        </div>
                        <div className="card-testimoni">
                            <img className="avatar" src="/assets/img/people-aliciaeva.png" /> 
                            <img className="mt-4 mb-2 kutip" src="/assets/img/icon-kutip.svg" />
                            <p className="desc"><FormattedMessage id="creator.testiAliciaeva" /></p> 
                            <div className="text-right">
                                <p className="fullname">Eva Alicia</p>
                                <a href=" https://instagram.com/aliciaeva" target="_blank"><p className="username">@aliciaeva</p></a>
                            </div>
                        </div>
                        <div className="card-testimoni">
                            <img className="avatar" src="/assets/img/people-devinaputrii.png" /> 
                            <img className="mt-4 mb-2 kutip" src="/assets/img/icon-kutip.svg" />
                            <p className="desc"><FormattedMessage id="creator.testiDevinaputrii" /></p> 
                            <div className="text-right">
                                <p className="fullname">Devina Putri</p>
                                <a href=" https://instagram.com/devinaputrii" target="_blank"><p className="username">@devinaputrii</p></a>
                            </div>
                        </div>
                        <div className="card-testimoni">
                            <img className="avatar" src="/assets/img/people-astaririri.png" /> 
                            <img className="mt-4 mb-2 kutip" src="/assets/img/icon-kutip.svg" />
                            <p className="desc"><FormattedMessage id="creator.testiAstaririri" /></p> 
                            <div className="text-right">
                                <p className="fullname">Astari Budi</p>
                                <a href=" https://instagram.com/astaririri" target="_blank"><p className="username">@astaririri</p></a>
                            </div>
                        </div>
                        </OwlCarousel>
                    </div>
                    </section>

                    {/* <section className="section-featured">
                    <div className="container-xl">
                        <div className="text-center">
                        <h1 className="title"><FormattedMessage id="creator.section3Title" /> </h1>
                        </div>
                        <div className="row mt-lg-5 mt-4">
                            <OwlCarousel margin={40} items={1} loop={true} autoWidth={true} autoplay={true} nav={false} dots={false} > 
                            {
                                listNews.map(function(image, index) {
                                return (
                                    <div className="item" key={index}><img src={"/assets/img/featured/" + image} /></div>
                                    );
                                })
                            }
                            </OwlCarousel>
                        </div>
                    </div>
                    </section> */}

                    <section className="section-universe">
                    <div className="container-xl">
                        <div className="text-center">
                        <h1 className="title"><FormattedMessage id="creator.section6Title" /></h1>
                        </div>
                        <div className="container-universe">
                        <div className="row mt-5">
                            <OwlCarousel className="owl-carousel-universe-1" margin={40} items={1} loop={true} autoWidth={true} autoplay={true} nav={false} dots={false} > 
                            {
                                listUniverse1.map(function(image, index) {
                                return (
                                    <div className="item" key={index}><img src={"/assets/img/people/" + image} /></div>
                                    );
                                })
                            }
                            </OwlCarousel>
                            <OwlCarousel className="owl-carousel-universe-2 px-lg-5 mt-4" margin={40} items={1} loop={true} autoWidth={true} autoplay={true} nav={false} dots={false} > 
                            {
                                listUniverse2.map(function(image, index) {
                                return (
                                    <div className="item" key={index}><img src={"/assets/img/people/" + image} /></div>
                                    );
                                })
                            }
                            </OwlCarousel>
                            <OwlCarousel className="owl-carousel-universe-3 mt-4" margin={40} items={1} loop={true} autoWidth={true} autoplay={true} nav={false} dots={false} > 
                            {
                                listUniverse3.map(function(image, index) {
                                return (
                                    <div className="item" key={index}><img src={"/assets/img/people/" + image} /></div>
                                    );
                                })
                            }
                            </OwlCarousel>
                        </div>
                        </div>
                    </div>
                    </section>
                </main>
                </div>
            </>
        );  
    }
}