/* Languange TH */

var thHome = {
    "home.title"	: "แพลตฟอร์มการตลาดสำหรับครีเอเตอร์ครบวงจรที่คุณต้องการ",
    "home.desc"	: "เพิ่มแคมเปญของคุณให้สูงสุดและลดขั้นตอนการทำงานที่ซับซ้อนด้วยแนวทางการตลาดสำหรับครีเอเตอร์ที่คุ้มต้นทุน",

    "home.marketingSolution"	: "ให้เราช่วยคุณดำเนินการ {tiger} สำหรับแบรนด์ของคุณ",
    "home.marketingSolutionTiger"	: "กิจกรรมทางการตลาด",
    "home.officialPartner"	: "พันธมิตรอย่างเป็นทางการกับ",

    "home.blockTitle1"	: "จัดทำแคมเปญที่เหมาะกับความต้องการทางการตลาดของคุณ",
    "home.blockDesc1"	: "ยกระดับยอดขาย การมีส่วนร่วมที่ลึกซึ้งยิ่งขึ้น และเพิ่มการรับรู้กับครีเอเตอร์ที่มีความสามารถของเรา",
    "home.blockButton1"	: "สร้างแคมเปญกับเรา",
    "home.block1statsTitle1"	: "100K+",
    "home.block1statsDesc1"	: "ครีเอเตอร์",
    "home.block1statsDesc11"	: "Nano ถึง Mega",
    "home.block1statsTitle2"	: "150ล้าน",
    "home.block1statsDesc2"	: "การเข้าถึงทั้งหมด",
    "home.block1statsTitle3"	: "15ล้าน",
    "home.block1statsDesc3"	: "คนคลิกสนใจสินค้า",
    "home.block1statsTitle4"	: "500+",
    "home.block1statsDesc4"	: "แคมเปญที่เคยร่วมงานกว่า",
    "home.block1statsTitle5"	: "900ล้าน+",
    "home.block1statsDesc5"	: "จำนวนยอดชมโดยประมาณ",
    "home.block1statsTitle6"	: "50+",
    "home.block1statsDesc6"	: "จัดเก็บข้อมูลอย่างมีประสิทธิภาพ",

    "home.blockTitle2"	: "โซลูชันการไลฟ์สดแบบครบวงจร",
    "home.blockDesc2"	: "เพิ่มประสบการณ์ของลูกค้าและยอดขายด้วยโซลูชันกาไลฟ์สดแบบครบวงจรของเรา",
    "home.blockButton2"	: "สร้างแคมเปญไลฟ์สดกับเรา",
    "home.block2statsTitle1"	: "20+",
    "home.block2statsDesc1"	: "แบรนด์ดำเนินการไลฟ์สดกับเรา",
    "home.block2statsTitle2"	: "10,000 ชั่วโมง",
    "home.block2statsDesc2"	: "ความสามารถในการไลฟ์สด",
    "home.block2statsTitle3"	: "24/7",
    "home.block2statsDesc3"	: "ความสามารถใน Live Commerce",
    "home.block2statsTitle4"	: "15+",
    "home.block2statsDesc4"	: "มีสตูดิโอ",
    "home.block2statsTitle5"	: "4",
    "home.block2statsDesc5"	: "แพลตฟอร์มไลฟ์สด",
    "home.block2statsTitle6"	: "100K+",
    "home.block2statsDesc6"	: "เครือข่ายครีเอเตอร์",

    "home.unlock"	: "ปลดล็อค {tiger} ด้วย BintanGO",
    "home.unlockTiger"	: "ทุกอย่างเป็นไปได้ทั้งหมด",
    "home.unlockDesc"	: "การตลาดของคุณ ในแบบของคุณ บริการของเราผสมผสานกับเป้าหมายของคุณได้อย่างราบรื่น มาสร้างเรื่องราวความสำเร็จของคุณกันเถอะ!",

    "home.brandAwareness"	: "การรับรู้ถึงแบรนด์",
    "home.brandAwarenessTitle"	: "เพิ่มการมองเห็นแบรนด์ของคุณและเข้าถึงผู้ชมให้ตรงกลุ่มเป้าหมาย",
    "home.brandAwarenessDesc"	: "ด้วยแพลตฟอร์มและโซลูชันของเรา คุณสามารถสร้างและจัดการแคมเปญโซเชียลมีเดียที่เข้าถึงกลุ่มเป้าหมายและเพิ่มการมองเห็นแบรนด์ของคุณได้อย่างง่ายดาย",

    "home.engageMore"	: "มีส่วนร่วมมากขึ้น",
    "home.engageMoreTitle"	: "สร้างคอนเทนต์ที่สร้างปฏิสัมพันธ์ที่ทิ้งความประทับใจไม่รู้ลืม",
    "home.engageMoreDesc"	: "เราจะช่วยให้คุณเชื่อมต่อกับผู้ชมของคุณได้อย่างมีประสิทธิภาพมากขึ้นผ่านบริการการจัดการแคมเปญที่ครอบคลุมของเรา มาหารือเกี่ยวกับแคมเปญถัดไปของคุณกันดีกว่า",

    "home.productLaunch"	: "เปิดตัวผลิตภัณฑ์",
    "home.productLaunchTitle"	: "คุณสร้าง เราก็ทำการตลาด เปิดตัวผลิตภัณฑ์ของคุณด้วยกลยุทธ์ที่เหมาะสม",
    "home.productLaunchDesc"	: "ทีมผู้เชี่ยวชาญของเราจะช่วยคุณเปิดตัวผลิตภัณฑ์และสร้างความประทับใจอย่างยิ่ง",

    "home.growthSales"	: "ยอดขายที่เติบโต",
    "home.growthSalesTitle"	: "โซลูชันที่สร้างสรรค์ของเราจะช่วยให้คุณเพิ่มยอดขายของแบรนด์ได้",
    "home.growthSalesDesc"	: "แพลตฟอร์มของเราเชื่อมโยงคุณกับครีเอเตอร์คุณภาพ 80,000 รายที่สามารถช่วยให้คุณเข้าถึงผู้ชมเป้าหมายและบรรลุเป้าหมายทางการตลาด",

    "home.techDriven"	: "ไม่ใช่แค่แพลตฟอร์มการตลาด",
    "home.techDrivenDesc"	: "เราเป็นพันธมิตรที่ขับเคลื่อนด้วยเทคโนโลยีของคุณ จัดหาเทคโนโลยีที่จะช่วยประหยัดเวลาของคุณและให้ข้อมูลที่น่าเชื่อถือที่สุด",
    "home.learnAboutTech"	: "เรียนรู้เกี่ยวกับเทคโนโลยีของเรา",

    "home.featuredOn"	: "โดดเด่นบน",
    "home.helpBrand"	: "เราอยู่ที่นี่เพื่อช่วยให้คุณเป็น",
};

export default thHome;