import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

export default class Terms extends Component {
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>BintanGO - Syarat dan Ketentuan</title>
                    <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
                    <meta property="og:image"              content="/logo-black-300.jpg" />
                    <meta property="og:url"                content={window.location.href} />
                    <meta property="og:title"              content="BintanGO - Syarat dan Ketentuan" />
                    <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
                </Helmet>
                <div>
                    <div className="header-page">
                        <h1 className="container"><FormattedMessage id="term.intro.title" /></h1>
                    </div>
                    <div className="container page-content">
                    <ol type="A" className="parent-title">
                        <li>
                        <FormattedMessage id="term.title.A" />
                        <p><FormattedMessage id="term.desc.A" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.B" />
                        <p><FormattedMessage id="term.desc.B" /></p>
                        <ol type="a" className="child-title">
                            <li><FormattedMessage id="term.desc.B.a" /></li>
                            <li>
                            <FormattedMessage id="term.desc.B.b" />
                            <ol type="i" className="child-title">
                                <li><FormattedMessage id="term.desc.B.b.i" /></li>
                                <li><FormattedMessage id="term.desc.B.b.ii" /></li>
                                <li><FormattedMessage id="term.desc.B.b.iii" /></li>
                                <li><FormattedMessage id="term.desc.B.b.iv" /></li>
                            </ol>
                            </li>
                            <li><FormattedMessage id="term.desc.B.c" /></li>
                            <li><FormattedMessage id="term.desc.B.d" /></li>
                            <li><FormattedMessage id="term.desc.B.e" /></li>
                        </ol>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.C" />
                        <p><FormattedMessage id="term.desc.C1" /></p>
                        <ol type="a" className="child-title">
                            <li><FormattedMessage id="term.desc.C.a" /></li>
                            <li><FormattedMessage id="term.desc.C.b" /></li>
                            <li><FormattedMessage id="term.desc.C.c" /></li>
                        </ol>
                        <p><FormattedMessage id="term.desc.C2" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.D" />
                        <p><FormattedMessage id="term.desc.D" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.E" />
                        <p><FormattedMessage id="term.desc.E" /></p>
                        <ol type="a" className="child-title">
                            <li><FormattedMessage id="term.desc.E.a" /></li>
                            <li><FormattedMessage id="term.desc.E.b" /></li>
                            <li><FormattedMessage id="term.desc.E.c" /></li>
                            <li><FormattedMessage id="term.desc.E.d" /></li>
                            <li><FormattedMessage id="term.desc.E.e" /></li>
                            <li><FormattedMessage id="term.desc.E.f" /></li>
                            <li><FormattedMessage id="term.desc.E.g" /></li>
                        </ol>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.F" />
                        <p><FormattedMessage id="term.desc.F" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.G" />
                        <p><FormattedMessage id="term.desc.G" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.H" />
                        <p><FormattedMessage id="term.desc.H" /></p>
                        <ol type="a" className="child-title">
                            <li><FormattedMessage id="term.desc.H.a" /></li>
                            <li><FormattedMessage id="term.desc.H.b" /></li>
                            <li><FormattedMessage id="term.desc.H.c" /></li>
                            <li><FormattedMessage id="term.desc.H.d" /></li>
                            <li><FormattedMessage id="term.desc.H.e" /></li>
                        </ol>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.I" />
                        <p><FormattedMessage id="term.desc.I" /></p>
                        <ol type="i" className="child-title">
                            <li><FormattedMessage id="term.desc.I.i" /></li>
                            <li><FormattedMessage id="term.desc.I.ii" /></li>
                            <li><FormattedMessage id="term.desc.I.iii" /></li>
                        </ol>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.J" />
                        <p><FormattedMessage id="term.desc.J" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.K" />
                        <p><FormattedMessage id="term.desc.K" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.L" />
                        <p><FormattedMessage id="term.desc.L" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.M" />
                        <ol type="1" className="child-title">
                            <li><FormattedMessage id="term.desc.M.1" /></li>
                            <li><FormattedMessage id="term.desc.M.2" /></li>
                            <li><FormattedMessage id="term.desc.M.3" /></li>
                            <li><FormattedMessage id="term.desc.M.4" /></li>
                            <li><FormattedMessage id="term.desc.M.5" /></li>
                        </ol>
                        </li>
                        <li>
                        <FormattedMessage id="term.title.N" />
                        <p><FormattedMessage id="term.desc.N" /></p>
                        </li>
                    </ol>
                    </div>
                </div>
            </>
        );  
    }
}