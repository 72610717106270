/* Languange TH */

var thCreator = {
    "creator.titleBanner"	: "ได้รายได้จากการทำสิ่งที่คุณรัก!",
    "creator.descBanner"	: "หารายได้จากการสร้างคอนเทนต์! ค้นหาโอกาสการทำงานร่วมกันใหม่ๆ กว่า 250 แบรนด์ในทุกสัปดาห์",

    "creator.section1Title"	: "พวกเราเชื่อว่า",
    "creator.section1SubTitle"	: "ทุกคนสามารถเป็นคอนเทนต์ครีเอเตอร์ แต่...",
    "creator.section1Desc"	: "คอนเทนต์ของคุณเป็นได้มากกว่าแค่การแสดงออกถึงความคิดสร้างสรรค์ของคุณ ด้วยโอกาสในการทำงานร่วมกันทุกสัปดาห์ BintanGO จะเปลี่ยนความคิดสร้างสรรค์ของคุณให้เป็นรายได้พิเศษ!",
    "creator.testiAliciaeva"	: "BintanGO กลายเป็นคนกลางของฉันกับแบรนด์ใหญ่ในอินโดนีเซีย! ฉันชอบฟีเจอร์ของมัน ซึ่งตรงกับความต้องการของฉันจริงๆ มันทำให้งานของฉันในฐานะครีเอเตอร์ง่ายขึ้นมาก",
    "creator.testiLaissti"	: "ฉันคิดว่าฟีเจอร์ “งาน” ของ BintanGO มีประโยชน์มาก! มันทำให้ฉันได้รับโอกาสในการร่วมงานกับแบรนด์มากขึ้นได้ง่ายขึ้น",
    "creator.testiDevinaputrii"	: "BintanGO ช่วยฉันได้มากในฐานะคอนเทนต์ครีเอเตอร์ ตั้งแต่การรับงาน การสร้างใบแจ้งหนี้ ไปจนถึงการรับเงิน BintanGO อยู่ที่นั่นทุกขั้นตอน ดังนั้นฉันจึงกังวลเรื่องเงินน้อยลงและมุ่งเน้นไปที่การสร้างเนื้อหาที่ดีขึ้น",
    "creator.testiAstaririri"	: "ฉันคิดว่าฟีเจอร์ข้อตกลงกับแบรนด์จาก BintanGO นั้นมีประโยชน์มาก! มันทำให้ฉันได้รับโอกาสใหม่ๆ มากมายในการทำงานร่วมกับแบรนด์ได้ง่ายขึ้น",

    "creator.section2Title"	: "มาร่วมเป็น",
    "creator.section2Desc"	: "BintanGO ช่วยคุณ #Growbeyond ในฐานะคอนเทนต์ครีเอเตอร์",
    "creator.growCard1Title"	: "ร่วมมือกับแบรนด์!",
    "creator.growCard1Desc"	: "มีงานใหม่ทุกสัปดาห์ เชื่อมต่อกับแบรนด์ที่เชื่อถือได้มากกว่า 250 แบรนด์จากอุตสาหกรรมต่างๆ",
    "creator.growCard2Title"	: "ยกระดับประสิทธิภาพ",
    "creator.growCard2Desc"	: "แอพของเรามาพร้อมกับคุณสมบัติด้านประสิทธิภาพเพื่อเพิ่มการแสดงตนบนโซเชียลมีเดียของคุณ",
    "creator.growCard3Title"	: "งาน-สำหรับคุณ",
    "creator.growCard3Desc"	: "ไม่ว่าจำนวนผู้ติดตามจะมีเท่าไร BintanGO จะมีงานให้คุณเสมอ!",
    "creator.growCard4Title"	: "การสนับสนุนชุมชน",
    "creator.growCard4Desc"	: "เข้าร่วมเป็นชุมชนครีเอเตอร์กับเราที่มีมากกว่า 60,000 คนเพื่อแชร์ แบ่งปัน และ #GrowBeyond ด้วยกัน",


    "creator.section3Title"	: "BintanGO ได้รับการสนับสนุนโดย:",

    "creator.section4Title"	: "ลดขั้นตอนวุ่นวายในการติดต่อ",
    "creator.section4Hashtag"	: "ยุ่งยากมาก",
    "creator.section4Step1Title"	: "เข้าร่วม BintanGO!",
    "creator.section4Step1Desc"	: "ดาวน์โหลด ลงทะเบียน และกรอกข้อมูลโปรไฟล์ของคุณ",
    "creator.section4Step2Title"	: "รับงาน!",
    "creator.section4Step2Desc"	: "ทุกคนสามารถสร้างรายได้! เปิดแท็บงาน หางานที่เหมาะกับคุณ แล้วเลือก “สมัคร”",
    "creator.section4Step3Title"	: "สร้างคอนเทนต์ รับรายได้เพิ่ม!",
    "creator.section4Step3Desc"	: "หลังจากที่คุณได้รับการยอมรับแล้ว คุณเพียงแค่ต้องสร้างคอนเทนต์และรอการชำระเงิน!",
    "creator.section4ButtonApply"	: "ลงทะเบียนเลย",


    "creator.section5Title"	: "แบรนด์ที่ไว้วางใจใช้ครีเอเตอร์จาก BintanGO",

    "creator.section6Title"	: "เข้าร่วมชุมชนของเรา!",
};

export default thCreator;
