import {useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  
import ScrollContainer from 'react-indiana-drag-scroll';

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function TechnologyCapabilities() {
  const sectionBintanGOCanDo = useRef(null);
  const menuScrollCreatorAnalysis = useRef(null);
  const menuScrollCreatorDiscovery = useRef(null);
  const menuScrollCreatorShortlisting = useRef(null);
  const menuScrollRealTimeReporting = useRef(null);
  const menuScrollBrandedContentAds = useRef(null);
  const [navTabs, setNavTabs] = useState(0);

  const scrollMenu = (id) => {
    setNavTabs(id)
    if(id==0){
      menuScrollCreatorAnalysis.current?.scrollIntoView({behavior: 'smooth',block: 'nearest',inline: 'center'});
    }else if(id==1){
      menuScrollCreatorDiscovery.current?.scrollIntoView({behavior: 'smooth',block: 'nearest',inline: 'center'});
    }else if(id==2){
      menuScrollCreatorShortlisting.current?.scrollIntoView({behavior: 'smooth',block: 'nearest',inline: 'center'});
    }else if(id==3){
      menuScrollRealTimeReporting.current?.scrollIntoView({behavior: 'smooth',block: 'nearest',inline: 'center'});
    }else if(id==4){
      menuScrollBrandedContentAds.current?.scrollIntoView({behavior: 'smooth',block: 'nearest',inline: 'center'});
    }
  };

  const scrollNextSection = () => {
    sectionBintanGOCanDo.current?.scrollIntoView({behavior: 'smooth',block: 'start'});
  };

  AOS.init({
    duration : 2000
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>BintanGO - Creator Powered Marketing</title>
        <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
        <meta property="og:image"              content="/logo-black-300.jpg" />
        <meta property="og:url"                content={window.location.href} />
        <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
        <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
      </Helmet>

      <div className='main-banner-technology'>
        <div className='container-xl text-center'>
          <h1 className='mb-5'><FormattedMessage id="technology.title" /></h1>
          <p className='mb-5'><FormattedMessage id="technology.desc" /></p>
          <div className='mx-auto mb-5'>
            <button onClick={()=> scrollNextSection() } type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="learnMore" /></button>
          </div>
          <img width="100%" data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='mt-4' src='/assets/img/technology-capabilities-main.png' alt=''/>
        </div>
      </div>   
      <div id='our-technology' >
        <div class="bg-content-white">
          <section id="partner" className='container-xl'>
            <div className='d-flex-desktop align-items-center'>
              <div className="title"><FormattedMessage id="trustedByBrands" values={{ number: <span className="number">500</span> }} /></div>
              <div className='carousel'>
                <div className='gradient-white'></div>
                <OwlCarousel 
                  items={5}
                  margin={20} 
                  autoplay={true} 
                  // smartSpeed={1000}
                  slideTransition={'linear'} 
                  autoplayTimeout={2000} 
                  autoplaySpeed={3000} 
                  loop={true} 
                  // autoplayHoverPause={false}
                  autoWidth={true} 
                  nav={false} 
                  dots={false} > 
                {
                    listBrands.map(function(image, index) {
                    return (
                        <div className="item" key={index}><img height={64} src={"/assets/img/for-brand/" + image} /></div>
                        );
                    })
                }
                </OwlCarousel>
              </div>
            </div>
          </section>
          <div className='container-xl'>
            <div className='text-center mb-5'>
              <h1 className='title-section mb-4'><FormattedMessage id="technology.integrationTitle" /></h1>
              <p className='desc-section'><FormattedMessage id="technology.integrationDesc" /></p>
            </div>
            <div className='row'>
              <div className='col-lg-6 mb-lg-0 mb-md-0 mb-5'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='card-technology'>
                  <img width="100%" className='mb-40 br-16' src='/assets/img/social-media-integration.png' alt=''/>
                  <h4 className='mb-3'><FormattedMessage id="technology.sosmedTitle" /></h4>
                  <h2 className='mb-32'><FormattedMessage id="technology.sosmedHeadline" /></h2>
                  <p className='mb-0'><FormattedMessage id="technology.sosmedDesc" /></p>
                </div>
              </div>
              <div className='col-lg-6'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="400" className='card-technology'>
                  <img width="100%" className='mb-40 br-16' src='/assets/img/e-commerce-integration.png' alt=''/>
                  <h4 className='mb-3'><FormattedMessage id="technology.ecommerceTitle" /></h4>
                  <h2 className='mb-32'><FormattedMessage id="technology.ecommerceHeadline" /></h2>
                  <p className='mb-0'><FormattedMessage id="technology.ecommerceDesc" /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bintango-can-do" ref={sectionBintanGOCanDo}>
        <div className='container-xl'>
          <div className='text-center mb-40'>
            <h1 className="mb-4"><FormattedMessage id="technology.canDoTitle" /></h1>
            <p><FormattedMessage id="technology.canDoDesc" /></p>
          </div>
          <div className='row'>
            <div className='col-lg-4'>
              <ScrollContainer nativeMobileScroll={false}>
              <div className='d-lg-block d-flex pb-4'>
                <div ref={menuScrollCreatorAnalysis} onClick={()=> scrollMenu(0) } className={navTabs==0?'list-bintango-can-do active d-flex align-items-center':'list-bintango-can-do d-flex align-items-center'}>
                  <img className='mr-3' src='/assets/img/temp-button-bintango-can-do.svg' alt=''/>
                  <span className='mr-auto'><FormattedMessage id="technology.creatorAnalysis" /></span>
                  {navTabs==0?<img className='ml-3 arrow-can-do' src='/assets/img/arrow-right-tiger.svg' alt=''/>:''}
                </div>
                <div ref={menuScrollCreatorDiscovery} onClick={()=> scrollMenu(1) } className={navTabs==1?'list-bintango-can-do active d-flex align-items-center':'list-bintango-can-do d-flex align-items-center'}>
                  <img className='mr-3' src='/assets/img/temp-button-bintango-can-do.svg' alt=''/>
                  <span className='mr-auto'><FormattedMessage id="technology.creatorDiscovery" /></span>
                  {navTabs==1?<img className='ml-3 arrow-can-do' src='/assets/img/arrow-right-tiger.svg' alt=''/>:''}
                </div>
                <div ref={menuScrollCreatorShortlisting} onClick={()=> scrollMenu(2) } className={navTabs==2?'list-bintango-can-do active d-flex align-items-center':'list-bintango-can-do d-flex align-items-center'}>
                  <img className='mr-3' src='/assets/img/temp-button-bintango-can-do.svg' alt=''/>
                  <span className='mr-auto'><FormattedMessage id="technology.creatorShortlisting" /></span>
                  {navTabs==2?<img className='ml-3 arrow-can-do' src='/assets/img/arrow-right-tiger.svg' alt=''/>:''}
                </div>
                <div ref={menuScrollRealTimeReporting} onClick={()=> scrollMenu(3) } className={navTabs==3?'list-bintango-can-do active d-flex align-items-center':'list-bintango-can-do d-flex align-items-center'}>
                  <img className='mr-3' src='/assets/img/temp-button-bintango-can-do.svg' alt=''/>
                  <span className='mr-auto'><FormattedMessage id="technology.reporting" /></span>
                  {navTabs==3?<img className='ml-3 arrow-can-do' src='/assets/img/arrow-right-tiger.svg' alt=''/>:''}
                </div>
                <div ref={menuScrollBrandedContentAds} onClick={()=> scrollMenu(4) } className={navTabs==4?'list-bintango-can-do active d-flex align-items-center':'list-bintango-can-do d-flex align-items-center'}>
                  <img className='mr-3' src='/assets/img/temp-button-bintango-can-do.svg' alt=''/>
                  <span className='mr-auto'><FormattedMessage id="technology.brandedContentAds" /></span>
                  {navTabs==4?<img className='ml-3 arrow-can-do' src='/assets/img/arrow-right-tiger.svg' alt=''/>:''}
                </div>
              </div>
              </ScrollContainer>
              {/* <div className='for-mobile'></div> */}
            </div>
            <div className='col-lg-8'>
              <div className='pl-lg-4 pl-md-4 pl-0'>
              {
                navTabs==0?
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-list-bintango-can-do '>
                  <img width="100%" className='mb-40' src='/assets/img/creator-analysis.png' alt=''/>
                  <h1 className='mb-4'><FormattedMessage id="technology.creatorAnalysisTitle" /></h1>
                  <p className='mb-0'><FormattedMessage id="technology.creatorAnalysisDesc" /></p>
                </div>:navTabs==1?
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-list-bintango-can-do '>
                  <img width="100%" className='mb-40' src='/assets/img/creator-discovery.png' alt=''/>
                  <h1 className='mb-4'><FormattedMessage id="technology.creatorDiscoveryTitle" /></h1>
                  <p className='mb-0'><FormattedMessage id="technology.creatorDiscoveryDesc" /></p>
                </div>:navTabs==2?
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-list-bintango-can-do '>
                  <img width="100%" className='mb-40' src='/assets/img/creator-shortlisting.png' alt=''/>
                  <h1 className='mb-4'><FormattedMessage id="technology.creatorShortlistingTitle" /></h1>
                  <p className='mb-0'><FormattedMessage id="technology.creatorShortlistingDesc" /></p>
                </div>:navTabs==3?
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-list-bintango-can-do '>
                  <img width="100%" className='mb-40' src='/assets/img/campaign-reporting.png' alt=''/>
                  <h1 className='mb-4'><FormattedMessage id="technology.reportingTitle" /></h1>
                  <p className='mb-0'><FormattedMessage id="technology.reportingDesc" /></p>
                </div>:
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-list-bintango-can-do '>
                  <img width="100%" className='mb-40' src='/assets/img/branded-content-ads.png' alt=''/>
                  <h1 className='mb-4'><FormattedMessage id="technology.brandedContentAdsTitle" /></h1>
                  <p className='mb-0'><FormattedMessage id="technology.brandedContentAdsDesc" /></p>
                </div>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="we-offer">
        <div className='container-xl'>
          <div className='text-center mb-5'>
            <h1 className='mb-4'><FormattedMessage id="technology.weOfferTitle" /></h1>
            <div className='row justify-content-center'>
              <div className='col-lg-6'>
                <p><FormattedMessage id="technology.weOfferDesc" /></p>
              </div>
            </div>
          </div>
          <div className='row rg-48'>
            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='list-offer mb-48'>
                <div className='number mb-32'>01</div>
                <h3 className='mb-20'><FormattedMessage id="technology.weOfferTitle1" /></h3>
                <p><FormattedMessage id="technology.weOfferDesc1" /></p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='list-offer mb-48'>
                <div className='number mb-32'>02</div>
                <h3 className='mb-20'><FormattedMessage id="technology.weOfferTitle2" /></h3>
                <p><FormattedMessage id="technology.weOfferDesc2" /></p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="300" className='list-offer mb-48'>
                <div className='number mb-32'>03</div>
                <h3 className='mb-20'><FormattedMessage id="technology.weOfferTitle3" /></h3>
                <p><FormattedMessage id="technology.weOfferDesc3" /></p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="400" className='list-offer mb-48'>
                <div className='number mb-32'>04</div>
                <h3 className='mb-20'><FormattedMessage id="technology.weOfferTitle4" /></h3>
                <p><FormattedMessage id="technology.weOfferDesc4" /></p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="500" className='list-offer mb-48'>
                <div className='number mb-32'>05</div>
                <h3 className='mb-20'><FormattedMessage id="technology.weOfferTitle5" /></h3>
                <p><FormattedMessage id="technology.weOfferDesc5" /></p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="600" className='list-offer mb-48'>
                <div className='number mb-32'>06</div>
                <h3 className='mb-20'><FormattedMessage id="technology.weOfferTitle6" /></h3>
                <p><FormattedMessage id="technology.weOfferDesc6" /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="banner" >
        <div className='container-xl'>
          <div className='row'>
            <div className='col-lg-12 d-flex justify-content-center'>
              <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="900" className='card-banner text-center'>
                <h1 className='mb-20'><FormattedMessage id="technology.bannerTitle" /></h1>
                <p className='mb-60'><FormattedMessage id="technology.bannerDesc" /></p>
                <Link to={"/contact"}><button type="button" className="btn-launcher h-48"><FormattedMessage id="discussWithOurTeam" /></button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


export default TechnologyCapabilities;