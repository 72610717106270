/* Languange EN */

var enUS = {
  "learnMore"               : "Learn More",
  "letsDiscuss"             : "Let's Discuss",
  "discussWithOurTeam"      : "Discuss with our team",
  "discussWithUs"           : "Let's talk about your brand goals",
  "letsDiscussYourCampaign" : "Let's talk about your campaign",
  "letsDiscussAdsCampaign"  : "Let's Discuss ads Campaign",
  "letsDiscussNextCampaign" : "Let's Discuss your next Campaign",
  "trustedByBrands"         : "We're trusted by over {number} brands",
  "trustedByAgency"         : "We're trusted by over {number} agency.",
  "ourSolution"             : "Our Solution",
  "lookHowWeWork"           : "Look How we Work",
  "ourEventPartner"         : "Our Event Partner",

  // FOR BANNER
  "bannerSaleTitle"         : "Scale up your brand with our creative {tiger}",
  "bannerSaleTitleTiger"    : "marketing solutions.",
  "bannerSaleDesc"          : "We're here to help you run and manage your marketing campaigns with creative and effective solutions that are tailored to your specific goals. Whether you're looking to increase brand awareness, drive sales, or generate leads, we've got you covered. Let's discuss your marketing goals and learn how we can help you achieve them",

  "bannerAgencyTitle"       : "Streamline your agency workflow with our tech-powered solutions.",
  "bannerAgencyDesc"        : "Let technology take the hassle out of your agency's workflow. Let's discuss your needs and see how we can help!",

  "bannerAwarenessTitle"    : "Start getting the world to notice you.",
  "bannerAwarenessDesc"     : "Let our experts take charge of your brand awareness campaign and help you get noticed.",

  "bannerStrategicTitle"    : "Rise above your competition with a strategic marketing plan.",
  "bannerStrategicDesc"     : "Let's launch your product with a great impression on a well-targeted audience. Let's discuss your next product launch with us.",
}; 

export default enUS;
