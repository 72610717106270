/* Languange EN */

var enNavBar = {
  "navbar.bintangoFor"      : "BintanGO For",
  "navbar.solution"         : "Solution",
  "navbar.technology"       : "Technology Capabilities",
  "navbar.resource"         : "Resource",
  "navbar.company"          : "Company",
  "navbar.tools"            : "Tools",
  "navbar.calculator"       : "ER Calculator",
  "navbar.contact"          : "Contact Us",
  "navbar.blog"             : "Blog",
  "navbar.caseStudy"        : "Case Study",
  "navbar.helpCenter"       : "Help Center",
  "navbar.about"            : "About Us",
  "navbar.career"           : "Career",

  "navbar.brandAwareness"   : "Enhance Your Brand Awareness",
  "navbar.brandAwarenessDesc": "Enhance your brand's visibility with our powerful combination of User-Generated Content and Branded Content Ads",
  "navbar.engagement"       : "Engage with your audience",
  "navbar.engagementDesc"   : "Enchange your audience engagement with our dynamic solutions, Live Commerce and User-Generated Content",
  "navbar.product"          : "New Product Launch",
  "navbar.productDesc"      : "Kickstart your product launch with a comprehensive 360° campaign and impactful activation strategies",
  "navbar.sales"            : "Growth Your Brand Sales",
  "navbar.salesDesc"        : "Our creative solutions will help you grow your brand's sales, with Live Commerce and Affiliate",

  "navbar.forBrand"         : "Brand",
  "navbar.forAgency"        : "Agency",
  "navbar.forCreator"       : "Creator",
  "navbar.forBrandandAgency": "For Brand & Agency",
  "navbar.login"            : "Login",
};
  
export default enNavBar;