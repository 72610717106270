import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { genAPI } from '../helpers/genAPI';
import 'owl.carousel/dist/assets/owl.carousel.min.css';  
import Navbar from '../layout/Navbar';

// import { ForBrandBanner } from '../container/forBrand/forBrandBanner';
// import { ForBrandStrategyContainer, ForBrandStrategySubTitle } from '../container/forBrand/forBrandStrategy';
// import { InquiryForm } from '../component/inquiryForm';

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function Career(props) {
  const [totalPages, setTotalPages] = useState(1);
  const [currentPages, setCurrentPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const sectionCareer = useRef(null);

  function getCareer(page) {
    setLoading(true);

    // setCurrentPages(page);

    // var page = parseInt(page)-1;
    // var componentAPI = {
    //     method: "GET",
    //     url: process.env.REACT_APP_API_DASHBOARD_URL + "/career/jobs?lang="+ props.lang.toUpperCase() +"&page="+page,
    //     post: '',
    //     isAuth: ''
    // }
    
    // genAPI(componentAPI).then(data => {

    //     if(data.error === false)
    //     {
    //         setLoading(false);
    //         setTotalPages(data.data.total_page);
    //     }
    //     else
    //     {
    //         //toastShow("error", data.data.error.message);
    //     }
    // });
  }

  const scrollNextSection = () => {
    sectionCareer.current?.scrollIntoView({behavior: 'smooth'});
  };

  AOS.init({
    duration : 2000
  })

  useEffect(() => {
    window.scrollTo(0, 0);
    getCareer(1);
  }, []);
  
  return (
    <>
      <Helmet>
        <title>BintanGO - Creator Powered Marketing</title>
        <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
        <meta property="og:image"              content="/logo-black-300.jpg" />
        <meta property="og:url"                content={window.location.href} />
        <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
        <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
      </Helmet>
      <div className='bg-hero-section'>
        <Navbar theme="dark" />
        <div className='main-banner-dark'>
          <div className='container-xl'>
            <div className='row justify-content-center mb-100'>
              <div className='col-lg-12 text-center'>
                <div className='title-banner-dark'>
                  <h3>BECOME PART OF OUR FAMILY</h3>
                  <img width={24} src="/assets/img/love.svg" className="love" alt="User Image" />
                </div>
                <h1 className='career mb-4'><span className="tiger">Grow Beyond</span> With Us</h1>
                <p className='mb-5'>Join the BintanGO Team and help us shape the future of creator marketing!</p>
                <div className='mx-aut'>
                  <button onClick={()=> scrollNextSection() } type="button" className="btn-launcher h-52">Find Jobs</button>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-3 col-6 px-0'>
                <div className='stats-solution stats-solution-first'>
                  <div className='dot-pink'></div>
                  <h3>2021</h3>
                  <p>BintanGO Launch</p>
                </div>
              </div>
              <div className='col-lg-3 col-6 px-0'>
                <div className='stats-solution border-thin border-thin-first'>
                  <div className='dot-pink'></div>
                  <h3>500++</h3>
                  <p>Brands have collaborated with us</p>
                </div>
              </div>
              <div className='col-lg-3 col-6 px-0'>
                <div className='stats-solution'>
                  <div className='dot-pink'></div>
                  <h3>50++</h3>
                  <p>People already part of BintanGO</p>
                </div>
              </div>
              <div className='col-lg-3 col-6 px-0'>
                <div className='stats-solution border-thin'>
                  <div className='dot-pink'></div>
                  <h3>100K++</h3>
                  <p>Creators join and ready to GrowBeyond</p>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
      <section id="progress" ref={sectionCareer}>
        <div className='bg-content-white'>
          {/* <div className='container-xl'>
            <h1 className='mb-80'>Let’s Join BintanGO</h1>
            <Link to="/career/detail">
            <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="900" className='card-career mb-40'>
              <div className='posted mb-3'>Posted At 05 January 2023</div>
              <div className='row align-items-center'>
                <div className='col-lg-9'>
                  <h3 className='mb-3'>Content Writer</h3>
                  <p className='mb-0'>Are words your playground? Join our team as a Content Writer and craft engaging, informative, and SEO-friendly content across various platforms. Help us tell our brand story and connect with our audience through your writing skills.</p>
                </div>
                <div className='col-lg-3 d-flex justify-content-lg-end justify-content-start mt-lg-0 mt-4'>
                  <button type="button" className="btn-launcher h-52">Apply Now</button>
                </div>
              </div>
            </div>
            </Link>
            <Link to="/career/detail">
            <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="900" className='card-career mb-60'>
              <div className='posted mb-3'>Posted At 05 January 2023</div>
              <div className='row align-items-center'>
                <div className='col-lg-9'>
                  <h3 className='mb-3'>Social Media Manager</h3>
                  <p className='mb-0'>Are words your playground? Join our team as a Content Writer and craft engaging, informative, and SEO-friendly content across various platforms. Help us tell our brand story and connect with our audience through your writing skills.</p>
                </div>
                <div className='col-lg-3 d-flex justify-content-lg-end justify-content-start mt-lg-0 mt-4'>
                  <button type="button" className="btn-launcher h-52">Apply Now</button>
                </div>
              </div>
            </div>
            </Link>
            <h1 data-aos="fade-up" data-aos-delay="200" data-aos-duration="900" className='growBeyon text-center'>#GrowBeyondinBintanGO</h1>
          </div> */}
          <div className='container-xl'>
            <div className='no-vacancies text-center'>
              <p className='growbeyon mb-2'>#GrowBeyondinBintanGO</p>
              <h1 className='mb-4'>Stay Tuned for Exciting Opportunities!</h1>
              <div className='row justify-content-center'>
                <div className='col-lg-4'>
                  <p>Our team is always growing. While there are no open positions at the moment, we're constantly on the lookout for talent. Check back soon for new career opportunities.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id='team'>
        <div className='container-xl bg-gradient-pink-bottom'>
          <div className='row'>
            <div className='col-lg-8'>
              <h1 className='mb-40'>Meet BintanGO SuperPower Team #GrowBeyond</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-5'>
              <p>We are a team brimming with passion for the world of influencers, while also being technology enthusiasts. Our objective is to harness technology to assist fellow marketers and creators.</p>
            </div>
          </div>
          <img data-aos="fade-up" data-aos-delay="100" data-aos-duration="900" className='w-100 img-team' src="/assets/img/listPhoto.png" alt="User Image" />
        </div>
      </section> */}
    </>
  )
}


export default Career;
