
import React, { Component, Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import AppLocale from './lang';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import Home from './view/home';
import ForBrand from './view/forBrand';
import ForAgency from './view/forAgency';
import ForCreator from './view/forCreator';
import ForBusiness from './view/forBusiness';
import { Blog } from './view/blog';
import { BlogDetail } from './view/blogDetail';
import Terms from './view/terms';
import TermsBusiness from './view/termsBusiness';
import Privacy from './view/privacy';
import Community from './view/community';
import BookingConfirmed from './view/bookingConfirmed';
// import Slider from './view/slider';
import AboutUs from './view/aboutUs';
import Contact from './view/contact';
import Career from './view/career';
import CareerDetail from './view/careerDetail';
import CalculatorER from './view/calculatorER';
import Engagement from './view/engagement';
import Sales from './view/sales';
import Awareness from './view/awareness';
import ProductLaunch from './view/productLaunch';
import TechnologyCapabilities from './view/technologyCapabilities';
// import MyLink from './view/mylink';
import Footer from './layout/Footer';
import Navbar from './layout/Navbar';
import NavbarNoMenu from './layout/NavbarNoMenu';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading : "d-none"
    }
  }

  async componentDidMount() {
    try {
      this.setState({loading : "d-block"});
      setTimeout(function() {this.setState({loading : "d-none"})}.bind(this), 800) ;
    }
    catch (error) {
      console.log(error);
    }
    window.scrollTo(0, 0);
  }

  render() {
    const langData = this.props.lang.lang;
    const currentAppLocale = AppLocale[langData];
    // const currentAppLocale = AppLocale["id"];
    return (
      <div>
      
      <div className="h-100">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <React.Fragment>
            <Router >
              <Suspense 
              fallback={<div id="loading" className={this.state.loading} />}
              >
                <Switch>
                  <Route exact path='/' render={(props) => 
                    <>
                      <Navbar />
                      <Home {...props} lang={langData} />
                      <Footer/>
                    </>
                  } />
                  
                  <Route exact path='/brand' render={(props) => 
                    <>
                      <Navbar />
                      <ForBrand {...props} lang={langData} />
                      <Footer/>
                    </>
                  } />

                  <Route exact path='/agency' render={(props) => 
                    <>
                      <Navbar />
                      <ForAgency {...props} lang={langData} />
                      <Footer/>
                    </>
                  } />

                  <Route exact path='/creator'>
                    <Navbar />
                    <ForCreator />
                    <Footer/>
                  </Route>

                  <Route exact path='/about-us'>
                    <AboutUs />
                    <Footer/>
                  </Route>
                  
                  <Route exact path='/career' render={(props) => 
                    <>
                      <Career {...props} lang={langData} />
                      <Footer/>
                    </>
                  } />

                  <Route exact path='/calculator-er' render={(props) => 
                    <>
                      <CalculatorER {...props} lang={langData} />
                      <Footer/>
                    </>
                  } />

                  <Route exact path='/career/detail'>
                    <Navbar />
                    <CareerDetail />
                    <Footer/>
                  </Route>

                  <Route exact path='/solution/engagement-to-audience' render={(props) => 
                    <>
                      <Navbar />
                      <Engagement {...props} />
                      <Footer/>
                    </>
                  } />

                  <Route exact path='/solution/growth-brand-sales' render={(props) => 
                    <>
                      <Navbar />
                      <Sales {...props} />
                      <Footer/>
                    </>
                  } />

                  <Route exact path='/solution/brand-awareness' render={(props) => 
                    <>
                      <Navbar />
                      <Awareness {...props} />
                      <Footer/>
                    </>
                  } />

                  <Route exact path='/solution/new-product-launch' render={(props) => 
                    <>
                      <Navbar />
                      <ProductLaunch {...props} />
                      <Footer/>
                    </>
                  } />

                  <Route exact path='/technology-capabilities'>
                    <Navbar />
                    <TechnologyCapabilities />
                    <Footer/>
                  </Route>
                  
                  <Route exact path='/business'>
                    <Navbar />
                    <ForBusiness />
                    <Footer/>
                  </Route>

                  <Route exact path='/contact'>
                    <Navbar />
                    <Contact />
                    <Footer/>
                  </Route>

                  {/* <Route exact path='/blog'>
                    <Navbar />
                    <Blog lang={langData}/>
                    <Footer/>
                  </Route> */}

                  <Route exact path='/blog' render={(props) => 
                    <>
                      <Navbar />
                      <Blog {...props} lang={langData} />
                      <Footer/>
                    </>
                  } />

                  <Route exact path='/blog/:title' render={(props) => 
                    <>
                      <Navbar />
                      <BlogDetail {...props} lang={langData} />
                      <Footer/>
                    </>
                  } />

                  {/* <Route exact path='/blog/:title'>
                    <Navbar />
                    <BlogDetail lang={langData} />
                    <Footer/>
                  </Route> */}

                  <Route exact path='/terms'>
                    <NavbarNoMenu />
                    <Terms />
                    <Footer/>
                  </Route>
                  
                  <Route exact path='/termsBusiness'>
                    <NavbarNoMenu />
                    <TermsBusiness />
                    <Footer/>
                  </Route>

                  <Route exact path='/privacy'>
                    <NavbarNoMenu />
                    <Privacy />
                    <Footer/>
                  </Route>

                  <Route exact path='/community-guideline'>
                    <NavbarNoMenu />
                    <Community />
                    <Footer/>
                  </Route>

                  <Route exact path='/booking-confirmed'>
                    {/* <NavbarNoMenu /> */}
                    <BookingConfirmed />
                    <Footer/>
                  </Route>

                  {/* <Route exact path='/slider'>
                    <Slider />
                  </Route> */}

                  {/* <Route exact path='/:celebName' render={(props) => 
                    <div>
                        <MyLink {...props} />
                    </div> 
                  } /> */}

                  <Redirect to="/" />

                </Switch>
                </Suspense>
            </Router>
          </React.Fragment>
        </IntlProvider>
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      lang: state.lang
  }
}

export default connect(mapStateToProps)(App);
