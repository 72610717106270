/* Languange TH */

var thSales = {
    "sales"	: "ยอดขายที่เติบโต",
    "sales.title"	: "โซลูชันที่สร้างสรรค์ของเราจะช่วยให้คุณเพิ่มยอดขายของแบรนด์ได้",
    "sales.desc"	: "กำลังมองหาวิธีเพิ่มยอดขายแบรนด์ของคุณอยู่ใช่ไหม? ไม่ต้องมองหาที่ไหนไกล นอกจากแพลตฟอร์มของเรา ซึ่งเชื่อมโยงคุณกับครีเอเตอร์คุณภาพกว่า 80,000 รายที่สามารถช่วยให้คุณเข้าถึงผู้ชมเป้าหมายและบรรลุเป้าหมายทางการตลาดได้",
    "sales.boost"	: "เพิ่มยอดขายของฉัน",

    "sales.affiliateTitle"	: "ยกระดับการขายแบรนด์ด้วยกลยุทธ์ที่มีประสิทธิภาพสำหรับ {tiger}",
    "sales.affiliateTitleTiger"	: "การเปลี่ยนแปลงที่สำคัญ",
    "sales.affiliateDesc"	: "เราพร้อมทำให้แคมเปญการตลาดแบบพันธมิตรของคุณประสบความสำเร็จ ตั้งแต่ลิงก์และการกระจายผลิตภัณฑ์ไปจนถึงการรายงานโดยละเอียด",

    "sales.affiliatePointTitle1"	: "เครือข่ายครีเอเตอร์",
    "sales.affiliatePointDesc1"	: "เข้าถึงมากกว่าการสร้างสรรค์เนื้อหาด้วยการร่วมมือกับครีเอเตอร์และพันธมิตรเพื่อเพิ่มการรับรู้ถึงแบรนด์",
    "sales.affiliatePointTitle2"	: "เพิ่มการแปลงของคุณ 80%",
    "sales.affiliatePointDesc2"	: "สร้างโปรแกรมการตลาดแบบ Affiliate เพื่อปรับปรุงการเข้าชมเว็บไซต์และเพิ่มยอดขาย",
    "sales.affiliatePointTitle3"	: "เชื่อถือได้มากขึ้น 82%",
    "sales.affiliatePointDesc3"	: "ผู้บริโภคมีแนวโน้มที่จะซื้อผลิตภัณฑ์ที่อินฟูเอนเซอร์โปรโมตมากขึ้น",

    "sales.ourWorkflow"	: "ขั้นตอนการทำงานของเรา",
    "sales.ourWorkflowTitle"	: "ทีม BintanGO ช่วยให้แบรนด์ของคุณประสบความสำเร็จในการทำการตลาดแบบพันธมิตรได้อย่างไร?",
    "sales.ourWorkflowDesc"	: "มาเป็นพันธมิตรกับเราเพื่อเปลี่ยนการตลาดแบบพันธมิตรของแบรนด์ของคุณให้ประสบความสำเร็จอย่างล้นหลาม ความเชี่ยวชาญของเราในการทำงานร่วมกันด้วยอินฟลูเอนเซอร์ กลยุทธ์ที่ขับเคลื่อนด้วยข้อมูล และแคมเปญส่วนบุคคลจะช่วยขับเคลื่อนความพยายามทางการตลาดแบบพันธมิตรของคุณให้ก้าวไปสู่ระดับใหม่ เราจะทำงานอย่างใกล้ชิดกับคุณเพื่อทำความเข้าใจเป้าหมายเฉพาะของแบรนด์ของคุณ และสร้างแนวทางที่ปรับให้เหมาะสมซึ่งจะช่วยเพิ่มศักยภาพทางการตลาดสำหรับพันธมิตรของคุณให้สูงสุด",
    "sales.ourWorkflowPointTitle1"	: "วางกลยุทธ์และค่าคอมมิชชั่น",
    "sales.ourWorkflowPointDesc1"	: "ก่อนที่จะเริ่มต้น เราจะสร้างกลยุทธ์ที่มีประสิทธิภาพสำหรับการดำเนินการการตลาดแบบพันธมิตรของคุณ",
    "sales.ourWorkflowPointTitle2"	: "การคัดเลือกครีเอเตอร์และการอนุมัติ",
    "sales.ourWorkflowPointDesc2"	: "เลือกครีเอเตอร์ตามข้อมูล อนุมัติ และครีเอเตอร์พร้อมที่จะทำงาน",
    "sales.ourWorkflowPointTitle3"	: "จัดส่งสินค้าให้กับครีเอเตอร์",
    "sales.ourWorkflowPointDesc3"	: "เราจะดูแลการจัดจำหน่ายผลิตภัณฑ์ของคุณให้กับครีเอเตอร์โดยไม่ต้องกังวล",
    "sales.ourWorkflowPointTitle4"	: "บรีฟครีเอเตอร์สำหรับงานที่ได้รับมอบหมาย",
    "sales.ourWorkflowPointDesc4"	: "ด้วยเทคโนโลยีและแพลตฟอร์มของเรา คุณจะไม่ต้องกังวลกับการมอบบรีฟให้กับครีเอเตอร์จำนวนมาก เราจะช่วยคุณเผยแพร่บรีฟได้อย่างง่ายดาย",
    "sales.ourWorkflowPointTitle5"	: "รับรายงานด้วยการรายงานประสิทธิภาพแบบเรียลไทม์ของเรา",
    "sales.ourWorkflowPointDesc5"	: "ติดตามประสิทธิภาพการตลาดแบบพันธมิตรได้อย่างง่ายดายด้วยการวิเคราะห์โดยละเอียด",
    "sales.ourWorkflowPointTitle6"	: "ประเมินประสิทธิภาพของการตลาดแบบพันธมิตรทุกเดือน",
    "sales.ourWorkflowPointDesc6"	: "แคมเปญการตลาดของคุณจะได้รับการประเมินโดยทีมผู้เชี่ยวชาญของเรา และเราจะเสนอคำแนะนำว่าควรดำเนินการอย่างไรต่อไป",

    "sales.affiliateStrategyTitle"	: "หาทีมที่เหมาะสมมาบริหาร {tiger} ของคุณ",
    "sales.affiliateStrategyTitleTiger"	: "การตลาดแบบพันธมิตร",
    "sales.affiliateStrategyDesc"	: "เราพร้อมช่วยคุณเพิ่มยอดขายด้วยแคมเปญการตลาดแบบพันธมิตร จัดจำหน่ายผลิตภัณฑ์ของคุณและเข้าถึงผู้ชมมากกว่า 50 ล้านคน",

    "sales.affiliateStrategyPointTitle1"	: "การจัดการแคมเปญพันธมิตร",
    "sales.affiliateStrategyPointHeadline1"	: "เผยแพร่แคมเปญพันธมิตรได้อย่างง่ายดาย",
    "sales.affiliateStrategyPointDesc1"	: "เผยแพร่ลิงก์ของคุณผ่านแอปของเราและเข้าถึงครีเอเตอร์มากกว่า 100,000++ รายที่พร้อมจะโปรโมตผลิตภัณฑ์ของคุณ",

    "sales.affiliateStrategyPointTitle2"	: "การดูแลจัดการของครีเอเตอร์",
    "sales.affiliateStrategyPointHeadline2"	: "จัดผลิตภัณฑ์ของกลุ่มเป้าหมายของคุณให้สอดคล้องกับกลุ่มเป้าหมายของผู้มีอิทธิพล",
    "sales.affiliateStrategyPointDesc2"	: "ส่งเสริมการตลาดของคุณด้วยการเลือกครีเอเตอร์ที่ขับเคลื่อนด้วยข้อมูลเพื่อเข้าถึงกลุ่มเป้าหมายของคุณอย่างมีประสิทธิภาพ",

    "sales.affiliateStrategyPointTitle3"	: "การเชื่อมต่ออีคอมเมิร์ซชั้นนำ",
    "sales.affiliateStrategyPointHeadline3"	: "เราครอบคลุมช่องทางการขาย E-Commerce อันดับต้น ๆ ของคุณ",
    "sales.affiliateStrategyPointDesc3"	: "ด้วยการเป็นพันธมิตรกับแพลตฟอร์มอีคอมเมิร์ซชั้นนำ เราจะขยายขอบเขตการเข้าถึงของแคมเปญการตลาดแบบพันธมิตรของคุณ และเชื่อมโยงคุณกับผู้ชมที่กว้างขึ้น",

    "sales.affiliateStrategyPointTitle4"	: "การติดตามและการรายงาน",
    "sales.affiliateStrategyPointHeadline4"	: "ติดตามและรับผลลัพธ์จากการตลาดแบบพันธมิตรของคุณได้อย่างง่ายดาย",
    "sales.affiliateStrategyPointDesc4"	: "รับรายงานที่ครอบคลุมเกี่ยวกับความพยายามทางการตลาดสำหรับพันธมิตรของคุณ พร้อมด้วยข้อมูลเชิงลึกอันมีค่าจากทีมผู้เชี่ยวชาญของเรา",

    "sales.affiliateBannerTitle"	: "เพิ่มยอดขายของคุณด้วยแคมเปญการตลาดแบบพันธมิตร",
    "sales.affiliateBannerDesc"	: "พร้อมที่จะขยายการเข้าถึงธุรกิจของคุณด้วยแคมเปญการตลาดแบบพันธมิตรที่มีประสิทธิภาพแล้วหรือยัง? มาเชื่อมต่อและหารือกันว่าเราสามารถช่วยให้คุณบรรลุเป้าหมายได้อย่างไร",
};

export default thSales;