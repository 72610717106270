import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  
import Navbar from '../layout/Navbar';

// import { ForBrandBanner } from '../container/forBrand/forBrandBanner';
// import { ForBrandStrategyContainer, ForBrandStrategySubTitle } from '../container/forBrand/forBrandStrategy';
// import { InquiryForm } from '../component/inquiryForm';

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function AboutUs(props) {

  AOS.init({
    duration : 2000
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Helmet>
        <title>BintanGO - Creator Powered Marketing</title>
        <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
        <meta property="og:image"              content="/logo-black-300.jpg" />
        <meta property="og:url"                content={window.location.href} />
        <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
        <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
      </Helmet>
      <div className='bg-hero-section'>
        <Navbar theme="dark" />
        <div className='main-banner-dark'>
          <div className='container-xl'>
            <div className='row justify-content-center mb-100'>
              <div className='col-lg-8'>
                <h1>We Are <br/><span className="tiger">
                Technology-Driven Creator Marketing
                {/* <Typewriter
                  options={{
                    strings: ['Technology-Driven Creator Marketing'],
                    autoStart: true,
                    loop: true,
                  }}
                /> */}
                </span> Solution For Marketer</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-3 col-6 px-0'>
                <div className='stats-solution stats-solution-first'>
                  <div className='dot-pink'></div>
                  <h3>2021</h3>
                  <p>BintanGO Launch</p>
                </div>
              </div>
              <div className='col-lg-3 col-6 px-0'>
                <div className='stats-solution border-thin border-thin-first'>
                  <div className='dot-pink'></div>
                  <h3>500++</h3>
                  <p>Brands have collaborated with us</p>
                </div>
              </div>
              <div className='col-lg-3 col-6 px-0'>
                <div className='stats-solution'>
                  <div className='dot-pink'></div>
                  <h3>50++</h3>
                  <p>People already part of BintanGO</p>
                </div>
              </div>
              <div className='col-lg-3 col-6 px-0'>
                <div className='stats-solution border-thin'>
                  <div className='dot-pink'></div>
                  <h3>100K++</h3>
                  <p>Creators join and ready to GrowBeyond</p>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
      <section id="progress">
        <div className='bg-content-white'>
          <div className='container-xl'>
            <h1 className='mb-80'>We've made some progress since our first launch, here's what we've accomplished</h1>
            <div className='contain-card-progress'>
              <div className='line-timeline'></div>
              <div className='card-progress pb-160'>
                <div className='timeline-point d-flex align-items-center'>
                  <img className='mr-3' src="/assets/img/icon-timeline-walk.svg" alt="User Image" />
                  <span>June 2021</span>
                </div>
                <div className='row align-items-center content-progress'>
                  <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="900" className='col-lg-6 mt-5 mb-lg-0 mb-5'>
                    <div className='d-flex align-items-start mb-4'>
                      <img className='mr-3' src="/assets/img/checklist-tiger.svg"/>
                      <span className='desc'>BintanGO <b>embarked</b> on an exciting journey as we launched for the very first time.</span>
                    </div>
                    <div className='d-flex align-items-start'>
                      <img className='mr-3' src="/assets/img/checklist-tiger.svg"/>
                      <span className='desc'>We secured a substantial pre-seed investment of <b>US$500k</b>, providing us with a strong foundation to drive our vision forward</span>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <img data-aos="fade-up" data-aos-delay="200" data-aos-duration="900" className="progress-img" src="/assets/img/temp-progress-1.png" alt="User Image" />
                  </div>
                </div>
              </div>
              <div className='card-progress pb-160'>
                <div className='timeline-point d-flex align-items-center'>
                  <img className='mr-3' src="/assets/img/icon-timeline-rocket.svg" alt="User Image" />
                  <span>January - March 2022</span>
                </div>
                <div className='row align-items-center content-progress'>
                  <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="900" className='col-lg-6 mt-lg-0 mt-5 mb-lg-0 mb-5'>
                    <div className='d-flex align-items-start'>
                      <img className='mr-3' src="/assets/img/checklist-tiger.svg"/>
                      <span className='desc'><b>2.5K creator</b> joined our platform & grow beyond together</span>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <img data-aos="fade-up" data-aos-delay="200" data-aos-duration="900" className="progress-img" src="/assets/img/temp-progress-2.png" alt="User Image" />
                  </div>
                </div>
              </div>
              <div className='card-progress pb-160'>
                <div className='timeline-point d-flex align-items-center'>
                  <img className='mr-3' src="/assets/img/icon-timeline-award.svg" alt="User Image" />
                  <span>July - September 2022</span>
                </div>
                <div className='row align-items-center content-progress'>
                  <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="900" className='col-lg-6 mt-5 mb-lg-0 mb-5'>
                    <div className='d-flex align-items-start mb-4'>
                      <img className='mr-3' src="/assets/img/checklist-tiger.svg"/>
                      <span className='desc'><b>20K+ creator</b> joined & keep growing</span>
                    </div>
                    <div className='d-flex align-items-start mb-4'>
                      <img className='mr-3' src="/assets/img/checklist-tiger.svg"/>
                      <span className='desc'>We’re successfully raised <b>$2.1 Million</b> in seed funding, help us to grow our creator community</span>
                    </div>
                    <div className='d-flex align-items-start'>
                      <img className='mr-3' src="/assets/img/checklist-tiger.svg"/>
                      <span className='desc'>BintanGO get <b>1st place</b> on She Loves Tech - Indonesia</span>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <img data-aos="fade-up" data-aos-delay="200" data-aos-duration="900" className="progress-img" src="/assets/img/temp-progress-3.png" alt="User Image" />
                  </div>
                </div>
              </div>
              <div className='card-progress pb-160'>
                <div className='timeline-point d-flex align-items-center'>
                  <img className='mr-3' src="/assets/img/icon-timeline-tiktok.svg" alt="User Image" />
                  <span>April - June 2023</span>
                </div>
                <div className='row align-items-center content-progress'>
                  <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="900" className='col-lg-6 mt-5 mb-lg-0 mb-5'>
                    <div className='d-flex align-items-start mb-4'>
                      <img className='mr-3' src="/assets/img/checklist-tiger.svg"/>
                      <span className='desc'><b>80k+ creator</b> has become part of BintanGO creator that ready to #GrowBeyond</span>
                    </div>
                    <div className='d-flex align-items-start'>
                      <img className='mr-3' src="/assets/img/checklist-tiger.svg"/>
                      <span className='desc'>Officially become a <b>TikTok Shop Partner</b></span>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <img data-aos="fade-up" data-aos-delay="200" data-aos-duration="900" className="progress-img" src="/assets/img/temp-progress-4.png" alt="User Image" />
                  </div>
                </div>
              </div>
            </div>
            <div className='card-progress pb-60'>
              <div className='timeline-point d-flex align-items-center'>
                <img className='mr-3' src="/assets/img/icon-timeline-loc.svg" alt="User Image" />
                <span>July - September 2023</span>
              </div>
              <div className='row align-items-center content-progress'>
                <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="900" className='col-lg-6 mt-5 mb-lg-0 mb-5'>
                  <div className='d-flex align-items-start mb-4'>
                    <img className='mr-3' src="/assets/img/checklist-tiger.svg"/>
                    <span className='desc'>We’re part of <b>Grab Ventures Velocity Batch 6</b></span>
                  </div>
                  <div className='d-flex align-items-start'>
                    <img className='mr-3' src="/assets/img/checklist-tiger.svg"/>
                    <span className='desc'>We’re successfully <b>raised $2.2 Million</b> in seed funding and will expand to Live Commerce</span>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <img data-aos="fade-up" data-aos-delay="200" data-aos-duration="900" className="progress-img" src="/assets/img/temp-progress-5.png" alt="User Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="partner" className='container-xl'>
        <div className='carousel w-100'>
          <div className='gradient'></div>
          <OwlCarousel 
            items={5}
            loop={true} 
            margin={20} 
            autoplay={true} 
            // smartSpeed={1000}
            slideTransition={'linear'} 
            autoplayTimeout={1500} 
            autoplaySpeed={3000} 
            // autoplayHoverPause={false}
            autoWidth={true} 
            nav={false} 
            dots={false} > 
          {
              listBrands.map(function(image, index) {
              return (
                  <div className="item" key={index}><img height={64} src={"/assets/img/for-brand/" + image} /></div>
                  );
              })
          }
          </OwlCarousel>
        </div>
      </section>  
      {/* <section id='team'>
        <div className='container-xl bg-gradient-pink-bottom'>
          <div className='row'>
            <div className='col-lg-8'>
              <h1 className='mb-40'>Meet BintanGO SuperPower Team #GrowBeyond</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-5'>
              <p>We are a team brimming with passion for the world of influencers, while also being technology enthusiasts. Our objective is to harness technology to assist fellow marketers and creators.</p>
            </div>
          </div>
          <img data-aos="fade-up" data-aos-delay="200" data-aos-duration="900" className='w-100 img-team' src="/assets/img/team-2.png" alt="User Image" />
        </div>
      </section> */}
      <section id="banner-team" className='container-xl'>
        <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="900" className='card-banner text-center'>
          <div className='row align-items-center'>
            <div className='col-lg-8 text-lg-left text-center'>
              <h1 className='mb-20'>Become part of BintanGO Team</h1>
              <p className='mb-0'>Join the BintanGO Team and help us shape the future of creator marketing! We use technology to help creators and brands do their jobs more easily. If you're passionate about technology and influencer marketing, this is the place for you. Check out our careers page to learn more!</p>
            </div>
            <div className='col-lg-4 d-flex justify-content-lg-end justify-content-center mt-5 mt-lg-0'>
              <Link to="/career"><button type="button" className="btn-launcher h-48">Join BintanGO</button></Link>
            </div>
          </div> 
        </div>
      </section>
    </>
  )
}


export default AboutUs;
