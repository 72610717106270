import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  

const Loader = () => (
  <div id="loading"></div>
);

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

const listNews = [
  "forbes.png",
  "tc.png",
  "dailysocial.png",
  "techasia.png",
  "idntimes.png",
  "cnbc.png",
  "marketeers.png",
  "forbes.png",
  "tc.png",
  "dailysocial.png",
  "techasia.png",
  "idntimes.png",
  "cnbc.png",
  "marketeers.png"
]

// Main Component
function Home(props) {

  const items = [
    {
      title: "home.brandAwareness",
      headline: "home.brandAwarenessTitle",
      description: "home.brandAwarenessDesc",      
      itemRef: useRef(0),
      solution1: 'User Generated Content',
      solution2: 'Branded Content Ads',
      link1: { pathname: '/solution/brand-awareness', state: {navTabs:0} },
      link2: { pathname: '/solution/brand-awareness', state: {navTabs:1} },
      img: '/assets/img/solution-brandawareness.png'
    },
    {
      title: "home.engageMore",
      headline: "home.engageMoreTitle",
      description: "home.engageMoreDesc",           
      itemRef: useRef(0),
      solution1: 'User Generated Content',
      solution2: 'Live Commerce',
      link1: { pathname: '/solution/engagement-to-audience', state: {navTabs:0} },
      link2: { pathname: '/solution/engagement-to-audience', state: {navTabs:1} },
      img: '/assets/img/solution-engagement.png'
    },
    {
      title: "home.productLaunch",
      headline: "home.productLaunchTitle",
      description: "home.productLaunchDesc",               
      itemRef: useRef(0),
      solution1: 'Activation',
      solution2: '360 Marketing',
      link: '/solution/new-product-launch',
      link1: { pathname: '/solution/new-product-launch', state: {navTabs:1} },
      link2: { pathname: '/solution/new-product-launch', state: {navTabs:0} },
      img: '/assets/img/solution-product.png'
    },
    {
      title: "home.growthSales",
      headline: "home.growthSalesTitle",
      description: "home.growthSalesDesc",             
      itemRef: useRef(0),
      solution1: 'Live Commerce',
      solution2: 'Affiliate',
      link1: { pathname: '/solution/growth-brand-sales', state: {navTabs:0} },
      link2: { pathname: '/solution/growth-brand-sales', state: {navTabs:1} },
      img: '/assets/img/solution-sales.png'
    },
  ];

  const stickyBlockContainerRef = useRef(0);
  const [activeItem, setActiveItem] = useState(0);
  const [isViewStickyBlock, setIsViewStickyBlock] = useState(false);
  const [stickyBlockLocations, setStickyBlockLocations] = useState([]);
  const sectionAgency = useRef(null);
  const [activeMS, setActiveMS] = useState(1);
  const [loading, setLoading] = useState(true);

  const handleGoToStickyBlock = (index) => {
    window.scroll({
      top: stickyBlockLocations[index],
      behavior: "smooth",
    });
  };

  const scrollNextSection = () => {
    sectionAgency.current?.scrollIntoView({behavior: 'smooth'});
  };

  AOS.init({
    duration : 2000
  })

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(function() {setLoading(false)}, 800) ;
  }, []);
  
  useEffect(() => {
    window.addEventListener("scroll", () => {
        const currentPositionTop = window.scrollY;
        if(stickyBlockContainerRef.current!=null){
          // console.log("Agencyaaa",stickyBlockContainerRef.current);
          // console.log("Agencyb",stickyBlockContainerRef.current.offsetTop);
          const stickyBlockContainerOffsetTop = stickyBlockContainerRef.current.offsetTop;
          const stickyBlockContainerOffsetBottom = stickyBlockContainerOffsetTop + stickyBlockContainerRef.current.offsetHeight;
          if (
            stickyBlockContainerOffsetTop - 200 < currentPositionTop &&
            currentPositionTop < stickyBlockContainerOffsetBottom - 700
          )
            setIsViewStickyBlock(true);
          else setIsViewStickyBlock(false);
      
          const offsetTops = [];
          for (let i = items.length - 1; i >= 0; i--) {
            const currentOffset = items[i].itemRef.current.offsetTop;
            // console.log("agency",items[i].itemRef.current.getBoundingClientRect());
            // offsetTops.push(items[i].itemRef.current.getBoundingClientRect());
            const nextOffset =
              items[i - 1]?.itemRef.current.offsetTop ?? currentOffset;
            if (currentOffset === nextOffset) {
              setActiveItem(i);
              break;
            }
          }
        }
    });
  });

  const styles = {
    learnMoreSpan: {
      minWidth: "20px",
      display: "inline-block",
      marginLeft: "1rem"
    },
    learnMoreSpanM: {
      display: "inline-block",
      marginLeft: "1rem"
    }
  };
  
  return (
    <>
      <Helmet>
        <title>BintanGO - Creator Powered Marketing</title>
        <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
        <meta property="og:image"              content="/logo-black-300.jpg" />
        <meta property="og:url"                content={window.location.href} />
        <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
        <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
      </Helmet>
      {loading ? <Loader /> :""}
      <div className='main-banner-technology'>
        <div className='container-xl text-center'>
          <h1 className='mb-5'><FormattedMessage id="home.title" /> 
          <span className='tiger'>
          <Typewriter
            options={{
              strings: props.lang=="id"?['Meningkatkan performa marketing campaign Anda', 'Menyederhanakan proses kerja Anda dengan Mudah', 'Meningkatkan performa Livecommerce Program Anda']:['Power up your Marketing Campaign', 'Streamline your Workflow with ease', 'Empower your Live Commerce Program'],
              autoStart: true,
              loop: true,
            }}
          />
          </span>
          </h1>
          <p className='mb-5'><FormattedMessage id="home.desc" /></p>
          <div className='mx-auto mb-60'>
            <button onClick={()=> scrollNextSection() } type="button" className="btn-login h-52 mx-lg-4 mx-md-4 mx-sm-4 mx-0 mb-lg-0 mb-4"><FormattedMessage id="learnMore" /></button>
            <Link to="/contact"><button type="button" className="btn-launcher h-52 fs-16 mx-lg-4 mx-md-4 mx-sm-4 mx-0"><FormattedMessage id="letsDiscuss" /></button></Link>
          </div>
        </div>
      </div>   
      <section id="partner" className='container-xl'>
        <div className='d-flex-desktop align-items-center'>
          <div className="title"><FormattedMessage id="trustedByBrands" values={{ number: <span className="number">500</span> }} /></div>
          <div className='carousel'>
            <div className='gradient'></div>
            <OwlCarousel 
              items={5}
              loop={true} 
              margin={20} 
              autoplay={true} 
              // smartSpeed={1000}
              slideTransition={'linear'} 
              autoplayTimeout={1500} 
              autoplaySpeed={3000} 
              // autoplayHoverPause={false}
              autoWidth={true} 
              nav={false} 
              dots={false} > 
            {
                listBrands.map(function(image, index) {
                return (
                    <div className="item" key={index}><img height={64} src={"/assets/img/for-brand/" + image} /></div>
                    );
                })
            }
            </OwlCarousel>
          </div>
        </div>
      </section>  
      <div id="marketing-solution">
        <div className="container-xl">
          <h1 className="text-left mb-40"><FormattedMessage id="home.marketingSolution" values={{ tiger: <span className="tiger"><FormattedMessage id="home.marketingSolutionTiger" /></span> }}  /></h1>
          <div className="position-relative">
            <div onClick={()=> setActiveMS(0) } className={activeMS==0?"card-marketing-solution active mb-40":"card-marketing-solution mb-40"}>
              <div className="content d-flex align-items-start">
                <img className='mr-40 walet' src="/assets/img/asset11.svg" alt="User Image" />
                <div>
                  <h3 className="mb-4"><FormattedMessage id="home.blockTitle1" /></h3>
                  <p className="mb-4"><FormattedMessage id="home.blockDesc1" /></p>
                  <Link to="/contact"><button type="button" className="btn-launcher h-52 fs-16 mb-4"><FormattedMessage id="home.blockButton1" /></button></Link>
                </div>
              </div>
            </div>
            {
              activeMS==0?
              <div className="stats-official-partner" data-aos="fade-up" data-aos-delay="300" data-aos-duration="900">
                {/* <div className="d-lg-flex d-md-flex d-sm-flex d-block align-items-center mb-32">
                  <span className='mr-auto mb-lg-0 mb-md-0 mb-sm-0 mb-3 desc'><FormattedMessage id="home.officialPartner" /></span>
                  <div class="d-flex align-items-center justify-content-center">
                    <img width="155px" class="mr-4" src="/assets/img/tiktok-marketing-partner.png" alt="User Image" />
                    <img src="/assets/img/meta-business-partner.svg" alt="User Image" />
                  </div>
                </div> */}
                <div className='row rg-60 text-center'>
                  <div className='col-6'>
                    <h3><FormattedMessage id="home.block1statsTitle1" /></h3>
                    <span className="mr-3 desc"><FormattedMessage id="home.block1statsDesc1" /></span>
                    <span className='nano'><FormattedMessage id="home.block1statsDesc11" /></span>
                  </div>
                  <div className='col-6'>
                    <h3><FormattedMessage id="home.block1statsTitle2" /></h3>
                    <span className='desc'><FormattedMessage id="home.block1statsDesc2" /></span>
                  </div>
                  <div className='col-6'>
                    <h3><FormattedMessage id="home.block1statsTitle3" /></h3>
                    <span className='desc'><FormattedMessage id="home.block1statsDesc3" /></span>
                  </div>
                  <div className='col-6'>
                    <h3><FormattedMessage id="home.block1statsTitle4" /></h3>
                    <span className='desc'><FormattedMessage id="home.block1statsDesc4" /></span>
                  </div>
                  <div className='col-6 mb-lg-5 mb-md-5 mb-sm-5 mb-0'>
                    <h3><FormattedMessage id="home.block1statsTitle5" /></h3>
                    <span className='desc'><FormattedMessage id="home.block1statsDesc5" /></span>
                  </div>
                  <div className='col-6'>
                    <h3><FormattedMessage id="home.block1statsTitle6" /></h3>
                    <span className='desc'><FormattedMessage id="home.block1statsDesc6" /></span>
                  </div>
                </div>
              </div>
            :
              <div className="stats-official-partner" data-aos="fade-up" data-aos-delay="300" data-aos-duration="900">
                <div className="d-lg-flex d-md-flex d-sm-flex d-block align-items-center mb-32">
                  <span className='mr-auto mb-lg-0 mb-md-0 mb-sm-0 mb-3 desc'><FormattedMessage id="home.officialPartner" /></span>
                  <div className="d-flex align-items-center justify-content-center">
                    <img width="155px" src="/assets/img/tiktok-shop-partner.svg" alt="User Image" />
                    {/* <img src="/assets/img/meta-business-partner.svg" alt="User Image" /> */}
                  </div>
                </div>
                <div className='row rg-60 text-center mb-5'>
                  <div className='col-6'>
                    <h3><FormattedMessage id="home.block2statsTitle1" /></h3>
                    <span className="desc"><FormattedMessage id="home.block2statsDesc1" /></span>
                  </div>
                  <div className='col-6'>
                    <h3><FormattedMessage id="home.block2statsTitle2" /></h3>
                    <span className='desc'><FormattedMessage id="home.block2statsDesc2" /></span>
                  </div>
                  <div className='col-6'>
                    <h3><FormattedMessage id="home.block2statsTitle3" /></h3>
                    <span className='desc'><FormattedMessage id="home.block2statsDesc3" /></span>
                  </div>
                  <div className='col-6'>
                    <h3><FormattedMessage id="home.block2statsTitle4" /></h3>
                    <span className='desc'><FormattedMessage id="home.block2statsDesc4" /></span>
                  </div>
                  <div className='col-6'>
                    <h3><FormattedMessage id="home.block2statsTitle5" /></h3>
                    <span className='desc'><FormattedMessage id="home.block2statsDesc5" /></span>
                  </div>
                  <div className='col-6'>
                    <h3><FormattedMessage id="home.block2statsTitle6" /></h3>
                    <span className='desc'><FormattedMessage id="home.block2statsDesc6" /></span>
                  </div>
                </div>
              </div>
            }
            <div onClick={()=> setActiveMS(1) } className={activeMS==1?"card-marketing-solution active":"card-marketing-solution"}>
              <div className="content d-flex align-items-start">
                <img className='mr-40 walet' src="/assets/img/asset11.svg" alt="User Image" />
                <div>
                  <h3 className="mb-4"><FormattedMessage id="home.blockTitle2" /></h3>
                  <p className="mb-4"><FormattedMessage id="home.blockDesc2" /></p>
                  <Link to="/solution/growth-brand-sales"><button type="button" className="btn-launcher h-52 fs-16 mb-4"><FormattedMessage id="home.blockButton2" /></button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="service" ref={sectionAgency}>
        <h1 className='title container-xl mb-20'><FormattedMessage id="home.unlock" values={{ tiger: <span className="tiger"><FormattedMessage id="home.unlockTiger" /></span> }}  /></h1>
        <p className='title mx-auto'><FormattedMessage id="home.unlockDesc" /></p>
        <div className='container-service' ref={stickyBlockContainerRef}
        onLoad={() => {
          if (stickyBlockLocations.length === 0) {
            const setStickyBlockLocationsValue = [];
            for (const item of items) {
              setStickyBlockLocationsValue.push(
                item.itemRef.current.getBoundingClientRect().y
              );
            }
            setStickyBlockLocations(setStickyBlockLocationsValue);
          }
        }}>
          <div style={{
            opacity: isViewStickyBlock ? 1 : 0,
          }} className="navigation-services">
            {items.map(({}, index) => {
              return (
                <div key={index} className='bullets'>
                     <img
                      onClick={() => handleGoToStickyBlock(index)}
                      style={{
                        pointerEvents: isViewStickyBlock ? "unset" : "none",
                        cursor: isViewStickyBlock ? "pointer" : "none",
                      }}
                      src={activeItem === index ? '/assets/img/section-active.svg' : '/assets/img/section-inactive.svg'}
                    ></img>
                </div>
              );
            })}
          </div>
          {items.map(({ title, headline, description, itemRef, solution1, solution2, link1, link2, img }, index) => {
            return (
              <div key={index} ref={itemRef} className={index>0?'card-service shadow':'card-service'}>
                <div className='container-xl'>
                  <div className='row d-flex align-items-center'>
                    <div className='col-lg-6'>
                      <h3 className='d-flex align-items-center mb-20'><div className='number-service-orange d-flex align-items-center justify-content-center mr-2'>0{index+1}</div><span><FormattedMessage id={title} /></span></h3>
                      <h1 className='title-service mb-24'><FormattedMessage id={headline} /></h1>
                      <p className='mb-5'><FormattedMessage id={description} /></p>
                      <div className='our-solution'><FormattedMessage id="ourSolution" /></div>
                      <div className="content-our-solution mb-5">
                        <div className='row'>
                          <div className='col-lg-6 border-right'>
                            <div className='d-flex align-items-center mb-3 mx-3'>
                              <img className='mr-4' src="/assets/img/arrow-right-tiger.svg" alt="User Image" />
                              <div className='title'>{solution1}</div>
                            </div>
                            <span style={styles.learnMoreSpan}>&nbsp;</span>
                            <Link to={link1} className='learn-more ml-4'><FormattedMessage id="learnMore" /></Link>
                          </div>
                          <div className='col-lg-6'>
                            <div className='d-flex align-items-center mb-3 mx-3'>
                              <img className='mr-4' src="/assets/img/arrow-right-tiger.svg" alt="User Image" />
                              <div className='title'>{solution2}</div>
                            </div>
                            <span style={styles.learnMoreSpan}>&nbsp;</span>
                            <Link to={link2} className='learn-more ml-4'><FormattedMessage id="learnMore" /></Link>
                          </div>
                        </div>
                      </div>
                      <Link to="/contact"><button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="discussWithOurTeam" /></button></Link>
                    </div>
                    <div className='col-lg-6'>
                      <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" src={img} alt="User Image" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className='container-service-mobile' >
          {items.map(({ title, headline, description, solution1, solution2, link1, link2, img }, index) => {
            return (
              <div key={index} className='card-service'>
                <div className='container-xl'>
                  <div className='row d-flex align-items-center'>
                    <div className='col-lg-6 mb-40'>
                      <h3 className='d-flex align-items-center mb-20'><div className='number-service-orange d-flex align-items-center justify-content-center mr-2'>0{index+1}</div><span><FormattedMessage id={title} /></span></h3>
                      <h1 className='title-service mb-24'><FormattedMessage id={headline} /></h1>
                      <p className='mb-5'><FormattedMessage id={description} /></p>
                      <div className='our-solution'><FormattedMessage id="ourSolution" /></div>
                      <div className="content-our-solution mb-5">
                        <div className='row'>
                          <div className='card-our-solution col-lg-6 col-md-6 col-sm-6 border-right'>
                            <div className='d-flex align-items-center mb-3'>
                              <img className='mr-4' src="/assets/img/arrow-right-tiger.svg" alt="User Image" />
                              <div className='title'>{solution1}</div>
                            </div>
                            <span style={styles.learnMoreSpanM}>&nbsp;</span>
                            <Link to={link1} className='learn-more ml-4'><FormattedMessage id="learnMore" /></Link>
                          </div>
                          <div className='card-our-solution col-lg-6 col-md-6 col-sm-6'>
                            <div className='d-flex align-items-center mb-3'>
                              <img className='mr-4' src="/assets/img/arrow-right-tiger.svg" alt="User Image" />
                              <div className='title'>{solution2}</div>
                            </div>
                            <span style={styles.learnMoreSpanM}>&nbsp;</span>
                            <Link to={link2} className='learn-more ml-4'><FormattedMessage id="learnMore" /></Link>
                          </div>
                        </div>
                      </div>
                      <button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="discussWithOurTeam" /></button>
                    </div>
                    <div className='col-lg-6 mb-lg-0 mb-5'>
                      <img className="w-100" data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" src={img} alt="User Image" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section id="marketing-platform">
        <div class="container-xl">
          <div class="row">
            <div class="col-lg-6 mb-lg-0 mb-5">
              <h3 class="tiger mb-20"><FormattedMessage id="home.techDriven" /></h3>
              <h3 class="mb-40"><FormattedMessage id="home.techDrivenDesc" /></h3>
              <Link to="/technology-capabilities"><button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="home.learnAboutTech" /></button></Link>
            </div>
            <div class="col-lg-6">
              <img width="100%" className='walet' data-aos="fade-up" data-aos-delay="100" data-aos-duration="900" src="/assets/img/list-campaign.png" alt="User Image" />
            </div>
          </div>
          <div class="row align-items-end mt-m-200">
            <div class="col-lg-4 d-xl-block d-lg-block d-none">
              <img width="100%" src="/assets/img/Frame-6871.svg" alt="User Image" />
            </div>
            <div class="col-lg-8 text-center">
              <img class="mb-80 waves-home" src="/assets/img/waves.svg" alt="User Image" />
              <h1  data-aos="fade-up" data-aos-delay="200" data-aos-duration="900" class="mb-5"><FormattedMessage id="home.helpBrand" /><br/>
              <span className='tiger'>
                <Typewriter
                  options={{
                    strings: props.lang=="id"?['Brand to maximize your marketing activities', 'Agency to streamline your workflow']:['Brand to maximize your marketing activities', 'Agency to streamline your workflow'],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </span> 
              </h1>
            </div>
          </div>
          <div class="row rg-40 mt-120">
            <div class="col-lg-4">
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-info-contact'>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' width={72} src={"/assets/img/imbrand.svg"} />
                  <h3 className='mb-0'><FormattedMessage id="contact.iambrand" /></h3>
                </div>
                <p className='mb-32'><FormattedMessage id="contact.iambrandDesc" /></p>
                <Link to="/brand"><button type="button" className="btn-login mw-200 h-52 fs-14">Learn More About Brand</button></Link>
              </div>
            </div>
            <div class="col-lg-4">
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='card-info-contact'>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' width={72} src={"/assets/img/imagency.svg"} />
                  <h3 className='mb-0'><FormattedMessage id="contact.iamagency" /></h3>
                </div>
                <p className='mb-32'><FormattedMessage id="contact.iamagencyDesc" /></p>
                <Link to="/agency"><button type="button" className="btn-login mw-200 h-52 fs-14">Learn More About Agency</button></Link>
              </div>
            </div>
            <div class="col-lg-4">
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='card-info-contact'>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' width={72} src={"/assets/img/imcreator.svg"} />
                  <h3 className='mb-0'><FormattedMessage id="contact.iamcreator" /></h3>
                </div>
                <p className='mb-32'><FormattedMessage id="contact.iamcreatorDesc" /></p>
                <Link to="/creator"><button type="button" className="btn-login mw-200 h-52 fs-14">Learn More About Creator</button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="featured-on">
        <div class="container-xl">
          <div className="text-center">
              <h3>BintanGO <FormattedMessage id="home.featuredOn" /></h3>
          </div>
          <div className="d-flex flex-wrap align-items-center justify-content-center mt-4">
            <OwlCarousel 
                items={5}
                loop={true} 
                margin={20} 
                autoplay={true} 
                // smartSpeed={1000}
                slideTransition={'linear'} 
                autoplayTimeout={1500} 
                autoplaySpeed={3000} 
                // autoplayHoverPause={false}
                autoWidth={true} 
                nav={false} 
                dots={false} > 
              {
                  listNews.map(function(image, index) {
                  return (
                      <div key={index}><img className="grayscale" src={"/assets/img/featured/" + image} /></div>
                      );
                  })
              }
            </OwlCarousel>
          </div>
        </div>
      </section>
      <section id="banner">
        <div className='container-xl'>
          <div className='row'>
            <div className='col-lg-12 d-flex justify-content-center'>
              <div data-aos="zoom-in" data-aos-delay="100" data-aos-duration="900" className='card-banner text-center'>
                <h1 className='mb-20'><FormattedMessage id="bannerSaleTitle" values={{ tiger: <span className="tiger"><FormattedMessage id="bannerSaleTitleTiger" /></span> }}  /></h1>
                <p className='mb-60'><FormattedMessage id="bannerSaleDesc" /></p>
                <Link to={"/contact"}><button type="button" className="btn-launcher h-48"><FormattedMessage id="discussWithUs" /></button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


export default Home;
