import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';  

// Main Component
function BrandCampaign() {
  useEffect(() => {
    AOS.init({
      duration : 2000
    })
  }, []);
  
  return (
    <>
      <div id="campaign-completed">
        <h3><FormattedMessage id="forBrand.campaignCompletedTitle" /></h3>
        <h1 className='mb-60'><FormattedMessage id="forBrand.campaignCompletedDesc" /></h1>
        <div className='row align-items-center'>
          <div className='col-lg-6'>
            <div className='row'>
              <div className='col-6'>
                <div className='p-5'>
                  <div className='title mb-2'>150M+</div>
                  <div className='desc'>Total Reach</div>
                </div>
              </div>
              <div className='col-6'>
                <div className='p-5'>
                  <div className='title mb-2'>900M+</div>
                  <div className='desc'>Total Product Click</div>
                </div>
              </div>
              <div className='col-6'>
                <div className='p-5'>
                  <div className='title mb-2'>500M+</div>
                  <div className='desc'>Total Est View</div>
                </div>
              </div>
              {/* <div className='col-6'>
                <div className='p-5'>
                  <div className='title mb-2'>150m+</div>
                  <div className='desc'>Total View</div>
                </div>
              </div> */}
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='collaboration-brand'>
              <h4 className='mb-60'><FormattedMessage id="forBrand.ccSuccessCollab" /></h4>
              <div className='row justify-content-center'>
                <div className='col-lg-4 col-md-4 col-sm-4 col-6 mb-4 text-center'>
                  <img src="/assets/img/brands/nobi.png" alt="User Image" />
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 col-6 mb-4 text-center'>
                  <img src="/assets/img/brands/tokenomy.png" alt="User Image" />
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 col-6 mb-4 text-center'>
                  <img src="/assets/img/brands/flashcoffee.png" alt="User Image" />
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 col-6 mb-4 text-center'>
                  <img src="/assets/img/brands/etude.png" alt="User Image" />
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 col-6 mb-4 text-center'>
                  <img src="/assets/img/brands/fresh.png" alt="User Image" />
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 col-6 mb-4 text-center'>
                  <img src="/assets/img/brands/raya.png" alt="User Image" />
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 col-6 mb-4 text-center'>
                  <img src="/assets/img/brands/antv.png" alt="User Image" />
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 col-6 mb-4 text-center'>
                  <img src="/assets/img/brands/bankbtn.png" alt="User Image" />
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 col-6 mb-4 text-center'>
                  <img src="/assets/img/brands/you.png" alt="User Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default BrandCampaign;