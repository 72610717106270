import {useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  
import ScrollContainer from 'react-indiana-drag-scroll';

import { InquiryFormContact } from '../component/inquiryFormContact';

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function Contact(props) {

  AOS.init({
    duration : 2000
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log("aa",nav);
  return (
    <>
      <Helmet>
        <title>BintanGO - Creator Powered Marketing</title>
        <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
        <meta property="og:image"              content="/logo-black-300.jpg" />
        <meta property="og:url"                content={window.location.href} />
        <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
        <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
      </Helmet>
      <div id="contact-us">
        <div className='container-xl'>
          <div className='row justify-content-center mb-80'>
            <div className='col-lg-5 info mt-lg-5 mt-md-5 mt-0'>
              <h3><FormattedMessage id="contact.headline" /></h3>
              <h1 className='mb-64'><FormattedMessage id="contact.title" /></h1>
              <section id="partner">
                <div className=''>
                  <div className="title w-100 mb-3 text-left"><FormattedMessage id="trustedByBrands" values={{ number: <span className="number">500</span> }} /></div>
                  <div className='carousel w-100'>
                    <div className='gradient'></div>
                    <OwlCarousel 
                      items={5}
                      loop={true} 
                      margin={20} 
                      autoplay={true} 
                      // smartSpeed={1000}
                      slideTransition={'linear'} 
                      autoplayTimeout={2000} 
                      autoplaySpeed={3000} 
                      // autoplayHoverPause={false}
                      autoWidth={true} 
                      nav={false} 
                      dots={false} > 
                    {
                        listBrands.map(function(image, index) {
                        return (
                            <div className="item" key={index}><img height={64} src={"/assets/img/for-brand/" + image} /></div>
                            );
                        })
                    }
                    </OwlCarousel>
                  </div>
                </div>
              </section>  
            </div>
            <div className='col-lg-5'>
              <InquiryFormContact />
            </div>
          </div>
          <div className='row justify-content-center pt-80'>
            <div className='col-lg-5 mb-lg-0 mb-md-0 mb-5'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-info-contact'>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' width={72} src={"/assets/img/imbrand.svg"} />
                  <h3 className='mb-0'><FormattedMessage id="contact.iambrand" /></h3>
                </div>
                <p className='mb-32'><FormattedMessage id="contact.iambrandDesc" /></p>
                <Link to="/brand"><button type="button" className="btn-login mw-200 h-52 fs-14">Learn More About Brand</button></Link>
              </div>
            </div>
            <div className='col-lg-5'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='card-info-contact'>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' width={72} src={"/assets/img/imagency.svg"} />
                  <h3 className='mb-0'><FormattedMessage id="contact.iamagency" /></h3>
                </div>
                <p className='mb-32'><FormattedMessage id="contact.iamagencyDesc" /></p>
                <Link to="/agency"><button type="button" className="btn-login mw-200 h-52 fs-14">Learn More About Agency</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default Contact;