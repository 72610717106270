/* Languange ID */
var idForBrand = {
    "forBrand"                      : "BINTANGO UNTUK BRAND",
    "forBrand.title1"               : "Kami akan membantu Anda",
    "forBrand.title2"               : "dengan strategi marketing yang efektif",
    "forBrand.typing1"              : "Meningkatkan Brand awareness",
    "forBrand.typing2"              : "Meningkat hubungan Kamu dengan Brand",
    "forBrand.typing3"              : "Meningkatkan penjualan produk/jasa",
    "forBrand.desc"                 : "Kami membantu Anda mencapai tujuan marketing Anda dengan strategi marketing yang efektif dan sesuai dengan kebutuhan Anda.",

    "forBrand.dataPoint"            : "Data siap untuk Kamu",
    "forBrand.dataPointTitle"       : "DIDUKUNG DENGAN DATA",
    "forBrand.dataPointDesc"        : "Didukung oleh teknologi untuk mengambil keputusan Marketing Campaign",
    "forBrand.campaignCompleted"    : "Campaign telah selesai",
    "forBrand.campaignCompletedTitle" : "CAMPAIGNS COMPLETED!",
    "forBrand.campaignCompletedDesc": "Sukseskan Marketing Campaign Kamu seperti top brand lainnya",

    "forBrand.marketingSolutionTitle": "Segala {tiger} ada disini",
    "forBrand.marketingSolutionTitleTiger": "kebutuhan marketing",
    "forBrand.marketingSolutionDesc": "Tim ahli Kami akan membantu menjalankan marketing pada Brand Kamu dan membantu Kamu mencapai goals Brand Marketing Kamu.",

    "forBrand.awarenessTitle"       : "LEBIH BANYAK AWARENESS",
    "forBrand.awarenessHeadline"    : "Tingkatkan brand awareness Kamu dan ",
    "forBrand.awarenessDesc"        : "Solusi brand awareness kami membuat brand Anda lebih dikenal oleh lebih banyak orang dan membantu untuk menemukan pelanggan yang tertarik dengan produk atau layanan Anda.",

    "forBrand.engageTitle"          : "TINGKATKAN ENGAGEMENT BRAND",
    "forBrand.engageHeadline"       : "Engage your audience and leave a lasting impression.",
    "forBrand.engageDesc"           : "Create engaging content, build relationships with your audience, and leave a lasting impression with our brand engagement services. Contact us today and learn how we can help you boost your brand engagement.",

    "forBrand.launchTitle"          : "LUNCURKAN PRODUK BARU",
    "forBrand.launchHeadline"       : "Luncurkanlah produk Anda dengan percaya diri dan raih target yang tepat dengan solusi kreatif kami.",
    "forBrand.launchDesc"           : "Solusi peluncuran produk kreatif kami akan membantu Anda menjangkau orang-orang yang paling mungkin tertarik dengan produk Anda dan meninggalkan kesan yang kuat. Ingin meluncurkan produk Anda dengan sukses? Dapatkan bantuan dari kami untuk meluncurkan produk baru Anda dengan sukses.",

    "forBrand.salesTitle"          : "TINGKATKAN PENJUALAN ANDA",
    "forBrand.salesHeadline"       : "Dapatkan lebih banyak penjualan untuk Brand Anda dengan pemasaran yang kreatif",
    "forBrand.salesDesc"           : "Dapatkan bantuan untuk menjalankan kampanye pemasaran yang akan meningkatkan penjualan produk atau layanan Anda. Tim ahli kami akan membantu Anda membuat kampanye pemasaran yang efektif.",

    "forBrand.testimonialTitle"     : "Yang client katakan tentang Kami",
    "forBrand.testimonialDesc"      : "Yuk lihat apa kata mereka",

    "forBrand.ccSuccessCollab"      : "Berhasil berkolaborasi dengan beragam brand",
};

export default idForBrand;