/* Languange EN */
var idAwareness = {
    "awareness"                         : "BRAND AWARENESS",
    "awareness.title"                   : "Tingkatkan kesadaran audiens ke brand Anda dan capai audiens Anda sesungguhnya",
    "awareness.desc"                    : "Dengan platform dan solusi Kami, Anda dengan mudah membuat dan mengelola campaign yang dapat mencapai target audiens dan meningkatkan kesadaran brand Anda",
    "awareness.getMore"                 : "Get More Awareness",

    "awareness.bcaTitle"                : "{tiger1} and {tiger2} together to maximize the impact of your content.",
    "awareness.bcaTitleTiger1"          : "Run campaigns",
    "awareness.bcaTitleTiger2"          : "advertising",
    "awareness.bcaDesc"                 : "UGC content and well-targeted ads are a winning combination for gaining more awareness.",

    "awareness.strategyTitle"           : "How to launch your plus ads campaign with us",
    "awareness.strategyDesc"            : "We got 4 process to launch your plus ads campaign",

    "awareness.campaignCreation"        : "CAMPAIGN CREATION",
    "awareness.campaignCreationTitle"   : "Set your partnership with creators with a simple toggle",
    "awareness.campaignCreationDesc"    : "Set your campaign to a campaign-ready partnership with a single toggle. Get matched with creators who agree to partner with your brand.",

    "awareness.automatedInvitation"     : "AUTOMATED INVITATION",
    "awareness.automatedInvitationTitle": "Eliminate the back-and-forth with creators.",
    "awareness.automatedInvitationDesc" : "Our technology reduces the need for communication between creators and brands. You don't need to invite and ask permission through messages to collaborate with them.",

    "awareness.contentManagement"       : "CONTENT MANAGEMENT",
    "awareness.contentManagementTitle"  : "All your labeled content in one place, at a glance.",
    "awareness.contentManagementDesc"   : "Manage all the content from all your campaigns that are labeled with 'partner' in one place.",

    "awareness.boostContent"            : "BOOST CONTENT",
    "awareness.boostContentTitle"       : "Help your content creators perform at their best.",
    "awareness.boostContentDesc"        : "Run ads on your labelled post",

    "awareness.ugcTitleMain"            : "SOLUSI USER GENERATED CONTENT",
    "awareness.ugcTitle"                : "Buat konten yang sejalan dengan brand Kamu dan kelola campaign Kamu dari awal hingga akhir didalam platform",
    "awareness.ugcDesc"                 : "Kami  akan membantu Anda dari proses perencanaan hingga eksekusi campaign",

    "awareness.ugcPointTitle1"          : "Creator Networks",
    "awareness.ugcPointDesc1"           : "Buat konten berkualitasi oleh kreator Kami",
    "awareness.ugcPointTitle2"          : "93% dapat meningkatkan penjualan",
    "awareness.ugcPointDesc2"           : "Untuk konsumen membeli produk, mereka mengandalkan review atau rekomendasi dari sseorang",
    "awareness.ugcPointTitle3"          : "90% lebih dipercaya",
    "awareness.ugcPointDesc3"           : "Autentik berperan penting untuk konsumen dalam memilih brand",

    "awareness.ugcStrategyTitle"        : "Manajement campaign UGC end-to-end Kami",
    "awareness.ugcStrategyDesc"         : "Kami akan membantu anda untuk membuat campaign UGC yang efektif",

    "awareness.ugcStrategyPointTitle1"  : "SOW YANG FLEKSIBEL",
    "awareness.ugcStrategyPointHeadline1": "Jalankan campaign yang sesuai dengan strategi brand Anda",
    "awareness.ugcStrategyPointDesc1"   : "Anda dapat membuat UGC campaign dengan beragam macam SOW, dari Instagram Post dan reels hingga TikTok video dan konten youtube",

    "awareness.ugcStrategyPointTitle2"  : "PEMILIHAN KREATOR",
    "awareness.ugcStrategyPointHeadline2": "Pilih kreator yang sesuai dengan campaign Anda",
    "awareness.ugcStrategyPointDesc2"   : "Pilih kreator yang sesuai dengan campaign Anda dengan menggunakan data, sehingga Anda mendapatkan kreator terbaik",

    "awareness.ugcStrategyPointTitle3"  : "PERSETUJUAN KONTEN",
    "awareness.ugcStrategyPointHeadline3": "Review dan pilih konten yang berkualitas dari kreator",
    "awareness.ugcStrategyPointDesc3"   : "Review langsung semua konten  disatu tempat yang nantinya diupload oleh kreator",

    "awareness.ugcStrategyPointTitle4"  : "DAPATKAN CAMPAIGN REPORT",
    "awareness.ugcStrategyPointHeadline4": "Tidak perlu minta screenshot lagi cukup minta link hasil pekerjaan  dan Kami akan dapat insight-nya",
    "awareness.ugcStrategyPointDesc4"   : "Dengan platform Kami, kamu dengan mudah mendaptkan hasil performa dari kreator dengan hanya munggunakan link. Hemat waktu Anda dan bekerja lebih efisien",

    "awareness.ugcBannerTitle"          : "Tingkatkan kesadaran brand Anda dengan solusi kreatif dari platform marketing",
    "awareness.ugcBannerDesc"           : "Buat campaign Anda lebih autentik dan cost-effective dengan campaign UGC dari Kami. Ayo diskusikan campaign Anda selanjutnya",

};

export default idAwareness;