/* Languange EN */


var enCommunity = {
    // Intro
    "community.intro.title"     : "PEDOMAN KOMUNITAS",
    "community.intro.desc"      : "Selamat datang di BintanGO All-in-One CreatorSpace!",
    "community.intro.desc.i"    : "Tujuan kami adalah untuk mempermudah Anda menjalankan bisnis dengan fitur-fitur di BintanGO yang dibuat untuk membantu dan mendukung Kreator, Forum hingga Brand menggunakan BintanGO untuk memudahkan bisnis Anda.\n\nDengan koneksi dan kolaborasi sebagai inti dari apa yang kami lakukan, kami ingin BintanGO menjadi ramah, menyenangkan dan berkesan bagi semua pengguna yang terlibat. Untuk alasan ini, kami telah mengembangkan Pedoman Komunitas untuk membantu melindungi BintanGO, Kreator, Forum, Brand, dan semua orang yang mengunjungi dan menggunakan situs web, aplikasi seluler dan layanan kami. Pedoman ini adalah bagian dari {terms} dan {privacy}, dan setiap istilah yang ditentukan di dalam Syarat dan Ketentuan memiliki arti yang sama pada Pedoman Komunitas.\n\nDengan semangat BintanGO sebagai tempat yang aman dan ramah bagi semua, kami meminta Anda membantu menegakkan Pedoman ini dengan memberi tahu kami jika Pedoman ini dilanggar. Apabila Anda melihat sesuatu yang dianggap menjadi pelanggaran, beri tahu kami dengan menghubungi kami melalui aplikasi atau melaporkan kepada kami melalui legal@bintango.id.",
    
    "community.title.A"         : "Peraturan Kami",
    "community.desc.A"          : "Seluruh pengguna wajib untuk mengikuti segala Pedoman Komunitas ini, termasuk mengakui bahwa perilaku yang dilarang dan konten tertentu yang dapat dibatasi atau dihapus dari situs dan/atau aplikasi BintanGO.",

    "community.title.A.1"       : "Persyaratan Usia",
    "community.desc.A.1"        : "Anda sebagai pengguna BintanGO harus berusia minimal 13 (tiga belas) tahun dan apabila Anda berusia di bawah 18 (delapan belas) tahun, Anda harus mendapat persetujuan dari orang tua atau wali sah Anda. Jangan memberikan identitas palsu atau mengirimkan informasi yang salah atau menyesatkan tentang diri Anda saat Anda mendaftar untuk akun BintanGO. Anda wajib menggunakan nama asli Anda dan foto Anda sendiri, dan tidak membuat akun palsu.\n\nApabila akun Anda sedang dalam masa penangguhan, mohon untuk tidak mendaftar akun baru selama proses perbaikan dan penegakan. Kami juga meminta Anda untuk tidak membuat banyak akun, kecuali Anda memiliki alasan yang sah untuk melakukannya.\n\nAkun BintanGO tidak boleh dipindahtangankan atau dijual kepada pihak lain. Jika Anda memiliki pertanyaan tentang akun Anda, silahkan menghubungi info@bintango.id.",
    "community.title.A.2"       : "Penggunaan Situs dan Produk BintanGO",
    "community.desc.A.2"        : "Kami meminta Anda menggunakan situs dan produk BintanGO hanya untuk tujuan penggunaannya. Anda wajib menggunakan situs dan produk BintanGO dengan tunduk pada norma dan aturan hukum yang berlaku. Anda setuju bahwa, jika pihak ketiga mengklaim bahwa materi apa pun yang Anda kontribusikan ke situs dan produk BintanGO adalah melanggar hukum, Anda akan menanggung beban untuk menetapkan bahwa materi tersebut mematuhi semua hukum yang berlaku. Meskipun BintanGO tidak selalu memantau, BintanGO memiliki hak untuk menghapus materi, memblokir akses, atau mengambil tindakan lain sehubungan dengan materi atau konten atas kebijakannya sendiri, meskipun BintanGO tidak berkewajiban untuk melakukannya. Anda tidak boleh menggunakan sumber daya komputasi BintanGO untuk menyebarkan iklan atau materi promosi apa pun yang tidak diminta.",
    "community.title.A.3"       : "Tidak Ada Ilegalitas",
    "community.desc.A.3"        : "Pedoman Komunitas kami didasarkan pada peraturan perundang-undangan yang berlaku di Indonesia. Oleh karena itu, Anda tidak boleh mempromosikan, mendorong atau terlibat dalam tindakan illegal apapun di, menggunakan atau diarahkan pada, situs atau aplikasi BintanGO. Anda tidak boleh mengirimkan permintaan atau membuat konten yang melanggar hukum, peraturan, perintah pengadilan atau proses hukum lainnya, termasuk upaya phishing, pencucian uang, pencurian dan penipuan. Kami juga melarang penggambaran, promosi, atau perdagangan obat-obatan terlarang atau zat yang dilarang lainnya di atau melalui situs dan aplikasi BintanGO. Dalam meninjau perilaku yang berpotensi illegal, BintanGO dapat (atas kebijakannya sendiri) mempertimbangkan konteks tambahan dan tingkat keparahan dari perilaku tersebut.",
    "community.title.A.4"       : "Ancaman, Kekerasan dan Bahaya",
    "community.desc.A.4"        : "Menghasut, mendorong, mempromosikan atau mengancam kekerasan, kerusakan, atau bahaya dilarang di situs dan aplikasi BintanGO, seperti halnya konten yang menggambarkan kekerasan yang mencolok atau tidak beralasan. Kami tidak mengizinkan perbuatan yang mengagungkan penganiayaan, termasuk hal-hal yang mendorong tindakan mencederai diri, bunuh diri atau hal lainnya yang bersifat bahaya.",
    "community.title.A.5"       : "Tidak ada Ujaran Kebencian",
    "community.desc.A.5"        : "BintanGO bukanlah tempat untuk konten berisi kebencian atau mempromosikan aktivitas kebencian. Kami membatasi distribusi atau menghapus konten dan akun semacam itu, seperti ujaran kebencian atau meremehkan di situs dan aplikasi BintanGO. Kami tidak mengizinkan Bahasa atau konten yang menyerang, merendahkan, mendiskriminasi atau mengancam kekerasan berdasarkan karakteristik yang dilindungi secara hukum, seperti ras, jenis kelamin, etnis, asal kebangsaan, agama, orientasi seksual, jenis kelamin, usia, kecacatan atau status veteran. Kami juga melarang konteks yang mempromosikan organisasi atau orang yang mempromosikan atau melakukan hal tersebut.",
    "community.title.A.6"       : "Tidak ada Konten Seksual",
    "community.desc.A.6"        : "BintanGO bukanlah tempat untuk mengirim atau meminta konten pornografi, tidak senonoh, cabul, tidak pantas, atau eksplisit secara seksual. Larangan konten ketelanjangan dan seksual berlaku untuk aktivitas nyata, tiruan, atau tersirat, dan termasuk konten yang dibuat atau dimanipulasi secara digital. Kami berusaha sebisa mungkin untuk membedakan pornografi dengan konten dewasa lainnya, namun kami mungkin membatasi distribusinya untuk tidak sengaja menyimpannya.",
    "community.title.A.7"       : "Pelecehan dan Perundungan",
    "community.desc.A.7"        : "BintanGO bukanlah tempat untuk menghina, menyakiti atau memusuhi individu atau kelompok orang. Kami melarang segala bentuk pelecehan dan perundungan untuk menjaga BintanGO sebagai wadah yang positif, hal ini termasuk konten yang bertujuan untuk merendahkan atau mempermalukan; mengejek orang atas tubuh, asumsi seksual, atau riwayat percintaan mereka; komentar bernada seksual tentang tubuh seseorang, permintaan, atau tawaran tindakan seksual; kritik yang berisi penyebutan nama, kata-kata kotor, dan bahasa maupun gambar yang menghina lainnya; dan mengejek seseorang karena mengalami kesedihan, duka cita, kehilangan atau kemarahan.",
    "community.title.A.8"       : "Komentar dan Pesan",
    "community.desc.A.8"        : "Pedoman Komunitas ini berlaku dalam komentar yang diposting di situs dan aplikasi BintanGO. Selain itu, komentar harus relevan. Kami dapat menghapus komentar yang melanggar pedoman ini, termasuk yang berisi materi yang tidak relevan atau tidak memiliki tujuan; spam; konten yang berisi seksual; konten melukai diri sendiri; misinformasi; aktivitas kebencian; pelecehan atau pelanggaran privasi; pelanggaran hak cipta atau merek.\n\nPedoman Komunitas ini berlaku untuk pesan yang dikirim antar-pengguna. Pesan juga harus diterima dan relevan. Selain kemampuan pengguna untuk memblokir seseorang agar tidak mengirimkan pesan tambahan yang tidak diinginkan, kami mungkin memperingatkan atau menangguhkan akun yang melanggar pedoman kami, termasuk dengan mengirimkan pesan yang berisi spam; aktivitas berbagi atau meminta konten seksual eksplisit; konten melukai diri sendiri atau bunuh diri; misinformasi; aktivitas penuh kebencian seperti hinaan rasis; konten atau perilaku yang melecehkan; dan eksploitasi informasi pribadi.",
    "community.title.A.9"       : "Hak Kekayaan Intelektual dan lainnya",
    "community.desc.A.9"        : "BintanGO menghormati hak-hak manusia di dalam dan di luar situs dan aplikasi BintanGO, sehingga setiap penggunakan diharapkan untuk tidak melanggar hak kekayaan intelektual orang lain, privasi atau hak lainnya; tidak melakukan apa pun atau mengunggah konten apa pun yang dilarang oleh undang peraturan perundang-undangan; dan tidak menggunakan nama, logo atau merek dagang BintanGO dengan cara membingungkan orang lain.",
    "community.title.A.10"      : "Keamanan Akses Situs dan Aplikasi",
    "community.desc.A.10"       : "Dalam rangka melindungi situs dan aplikasi BintanGO, kami meminta Anda untuk tidak mengakses, menggunakan, atau merusak sistem kami, atau sistem penyedia teknis kami; tidak menyusupi atau mengakali tindakan keamanan kami atau menguji kerentanan sistem atau jaringan kami; tidak menggunakan metode yang tidak terdokumentasi atau tidak didukung untuk mengakses, mencari, merusak, mengunduh, atau mengubah bagian apapun dari BintanGO; tidak mencoba untuk mengganggu orang-orang di BintanGO atau host, atau jaringan kami, misalnya dengan mengirimkan virus, membebani sistem secara berlebihan, mengirim spam atau mengirim email dalam jumlah yang besar; dan tindakan lainnya yang dilarang berkaitan dengan keamanan akses situs dan aplikasi kami.",

    "community.title.B"         : "Pelaksanaan",
    "community.desc.B.i"        : "Anda bertanggung jawab atas akun Anda dan konten apa pun yang Anda unggah atau kirimkan. Penggunaan layanan BintanGO apa pun tidak diperbolehkan untuk tujuan apa pun selain tujuan terbatas khusus yang tersedia pada situs dan aplikasi BintanGO.\n\nApabila kami mengetahui konten atau aktivitas di situs dan aplikasi BintanGO yang melanggar Syarat dan Ketentuan, termasuk Pedoman Komunitas ini, kami akan menegakkannya sesuai kebijakan kami. Selain itu, kami dapat menentukan perlu atau tidak menangguhkan sementara akun BintanGO milik Anda saat kami meninjau kemungkinan pelanggaran yang Anda lakukan.\n\nSesuai dengan pelanggaran yang Anda lakukan, BintanGO dapat menindaklanjuti hal tersebut dengan:",
    "community.desc.B.ii"       : "Kami akan mempertimbangkan faktor-faktor yang berkenaan dengan keputusan kami dalam tindak lanjut pelanggaran yang dilakukan, termasuk namun tidak terbatas, konten itu sendiri, tingkat keparahan perilaku, dan jumlah pelanggaran oleh Pengguna. Secara umum, kami tidak dapat memantau perilaku Pengguna di luar situs dan aplikasi BintanGO. Namun, kami dapat mempertimbangkannya perilaku di luar situs dan aplikasi BintanGO yang melibatkan BintanGO pada saat melakukan pelanggaran Pedoman ini. Kami juga akan mempertimbangkannya apabila perilaku di luar platform yang bersifat ekstrem, termasuk keanggotaan dalam kelompok kebencian atau organisasi teroris, penyerangan seksual, pelecehan atau perilaku berat lainnya.\n\nDalam keadaan terbatas, kami dapat, atas kebijaksanaan kami sendiri, mengizinkan pengecualian terhadap kebijakan penghapusan konten kami ketika kami menentukan bahwa materi konten tersebut melayani kepentingan publik, seperti konten yang jelas-jelas bersifat satir, ilmiah atau mendidik.\n\nHarap dicatat bahwa jika akun Anda ditangguhkan secara permanen, kami akan mengembalikan dana Anda yang disimpan pada Go-wallet, tetapi tidak akan mengembalikan dana yang disimpan oleh pihak ketiga dalam jangka waktu yang wajar.",
    "community.title.B.a"       : "mengeluarkan peringatan;",
    "community.title.B.b"       : "menghapus konten yang dilanggar dari akun Anda;",
    "community.title.B.c"       : "menangguhkan sementara akses akun Anda untuk jangka waktu tertentu;",
    "community.title.B.d"       : "menangguhkan akses akun Anda secara permanen;",
    "community.title.B.e"       : "tindakan lain atas kebijakan BintanGO; dan",
    "community.title.B.f"       : "melanjutkan kepada proses hukum yang berlaku di Indonesia",

    "community.title.C"         : "Pengajuan Banding",
    "community.title.C.1"       : "Banding",
    "community.desc.C.1"        : "Apabila Anda yakin bahwa keputusan penegakan hukum kami terhadap Anda tidak benar, Anda dapat meminta banding secara tertulis ke legal@bintango.id. Anda harus menggunakan alamat email yang sama dengan akun BintanGO Anda yang terhubung dengan keputusan penegakan kami dan menyertakan alasan terperinci mendapa Anda yakin tindakan tersebut bukan suatu pelanggaran. Seperti semua keputusan penegakan hukum lainnya, pengajuan banding merupakan kebijakan BintanGO dan keputusan ini bersifat final dan mengikat.",
    "community.title.C.2"       : "Pemulihan Setelah Penangguhan",
    "community.desc.C.2"        : "Apabila kami menangguhkan akun Anda karena melanggar Pedoman Komunitas ini, Anda dapat meminta pemulihan setelah penangguhan berakhir. Setiap permintaan pemulihan harus dibuat secara tertulis dan dikirim ke info@bintango.id dari alamat email yang terkait dengan akun BintanGO yang ditangguhkan. Permintaan pemulihan harus mencakup: (i) deskripsi terperinci alasan Anda ingin bergabung Kembali dengan BintanGO; (b) konfirmasi bahwa Anda telah membaca dan akan mematuhi Syarat dan Ketentuan, termasuk Pedoman Komunitas kami; dan (c) konfirmasi, pengakuan atau perjanjian lain yang kami minta. Permintaan untuk pemulihan tidak menjamin akun Anda dapat dipulihkan karena keputusan terakhir akan tunduk pada kebijakan BintanGO.\n\nSesuai dengan BintanGO yang selalu berkembang dengan fitur dan produk baru, kami dapat memperbaharui Pedoman Komunitas ini dari waktu ke waktu untuk mempertimbangkan kesesuaian dari fitur kami dengan Pengguna kami dalam berperilaku dan berinteraksi, dan setiap risiko atau masalah baru yang mungkin muncul sebagai akibat dari perkembangan tersebut. Silahkan periksa secara berkala untuk versi terbaru dari Pedoman Komunitas kami.",
};

export default enCommunity;
