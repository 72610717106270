import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function SalesLC() {
  const sectionStrategy = useRef(null);

  const scrollNextSection = () => {
    sectionStrategy.current?.scrollIntoView({behavior: 'smooth'});
  };

  useEffect(() => {
    AOS.init({
      duration : 2000
    })
  }, []);
  
  return (
    <>
      <div id="live-commerce" class="mt-lg-4 mt-5">
        <div className='container-xl mb-170'>
          <div className='text-center row justify-content-center'>
            <div className='col-lg-10'>
              <div className='title-live-commerce'>
                <h3 className='mr-3'><FormattedMessage id="engagement.lcHeadline" /></h3>
                <img className='partner' src="/assets/img/tiktok-shop-partner.svg" alt="User Image" />
              </div>
              <h1 className='mb-40'>Increase your sales with our complete <span className='tiger'>Live Commerce services</span></h1>
              <p className='mb-60 mx-auto'>We provide end-to-end solution for your TikTok live commerce, from the strategy until execution.</p>
              <div className='mx-auto mb-60'>
                <button onClick={()=> scrollNextSection() } type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="lookHowWeWork" /></button>
              </div>
            </div>
          </div>
          
          <section id="partner" className='container-xl'>
            <div className='d-flex-desktop align-items-center'>
              <div className="title"><FormattedMessage id="trustedByBrands" values={{ number: <span className="number">500</span> }} /></div>
              <div className='carousel'>
                <div className='gradient'></div>
                <OwlCarousel 
                  items={5}
                  loop={true} 
                  margin={20} 
                  autoplay={true} 
                  // smartSpeed={1000}
                  slideTransition={'linear'} 
                  autoplayTimeout={2000} 
                  autoplaySpeed={3000} 
                  // autoplayHoverPause={false}
                  autoWidth={true} 
                  nav={false} 
                  dots={false} > 
                {
                    listBrands.map(function(image, index) {
                    return (
                        <div className="item" key={index}><img height={64} src={"/assets/img/for-brand/" + image} /></div>
                        );
                    })
                }
                </OwlCarousel>
              </div>
            </div>
          </section>  

          <div className='row'>
            <div className='col-lg-4'>
              <div className='card-live-commerce'>
                <div className='d-flex align-items-center mb-4'>
                  <img width={75} className='mr-3' src="/assets/img/creatorNetwork-min.png" alt="User Image" />
                  <div>
                    <div className='title mb-2'><FormattedMessage id="engagement.lcBlogTitle1" /></div>
                    <span className='grey'><FormattedMessage id="engagement.lcBlogHeadline1" /></span>
                  </div>
                </div>
                <p><FormattedMessage id="engagement.lcBlogDesc1" /></p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='card-live-commerce'>
                <div className='d-flex align-items-center mb-4'>
                  <img width={75} className='mr-3' src="/assets/img/proffesionalHost-min.png" alt="User Image" />
                  <div>
                    <div className='title mb-2'><FormattedMessage id="engagement.lcBlogTitle2" /></div>
                  </div>
                </div>
                <p><FormattedMessage id="engagement.lcBlogDesc2" /></p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='card-live-commerce'>
                <div className='d-flex align-items-center mb-4'>
                  <img width={75} className='mr-3' src="/assets/img/time-min.png" alt="User Image" />
                  <div>
                    <div className='title mb-2'><FormattedMessage id="engagement.lcBlogTitle3" /></div>
                    <span className='grey'><FormattedMessage id="engagement.lcBlogHeadline3" /></span>
                  </div>
                </div>
                <p><FormattedMessage id="engagement.lcBlogDesc3" /></p>
              </div>
            </div>
          </div>

          <section id="strategy-live-commerce" ref={sectionStrategy}>
            <div className='text-center mb-80'>
              <h1 className='title-section mb-4'><FormattedMessage id="engagement.lcStrategyTitle" /></h1>
              <p className='desc-section w-60 mx-auto mb-80-mobile'><FormattedMessage id="engagement.lcStrategyDesc" /></p>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="engagement.lcStrategyTitle1" /></h3>
                <h1 className='mb-4'><FormattedMessage id="engagement.lcStrategyHeadline1" /></h1>
                <p className='mb-40'><FormattedMessage id="engagement.lcStrategyDesc1" /></p>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' src="/assets/img/point.svg" />
                  <span className='desc'><FormattedMessage id="engagement.lcStrategy1Point1" /></span>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' src="/assets/img/point.svg" />
                  <span className='desc'><FormattedMessage id="engagement.lcStrategy1Point2" /></span>
                </div>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/sales-lc-1.png" />
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/sales-lc-2.png" />
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='content-live-commerce col-lg-6 order-lg-last order-first'>
                <h3><FormattedMessage id="engagement.lcStrategyTitle2" /></h3>
                <h1 className='mb-4'><FormattedMessage id="engagement.lcStrategyHeadline2" /></h1>
                <p className='mb-40'><FormattedMessage id="engagement.lcStrategyDesc2" /></p>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' src="/assets/img/point.svg" />
                  <span className='desc'><FormattedMessage id="engagement.lcStrategy2Point1" /></span>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' src="/assets/img/point.svg" />
                  <span className='desc'><FormattedMessage id="engagement.lcStrategy2Point2" /></span>
                </div>
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="engagement.lcStrategyTitle3" /></h3>
                <h1 className='mb-4'><FormattedMessage id="engagement.lcStrategyHeadline3" /></h1>
                <p className='mb-40'><FormattedMessage id="engagement.lcStrategyDesc3" /></p>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' src="/assets/img/point.svg" />
                  <span className='desc'><FormattedMessage id="engagement.lcStrategy3Point1" /></span>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' src="/assets/img/point.svg" />
                  <span className='desc'><FormattedMessage id="engagement.lcStrategy3Point2" /></span>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' src="/assets/img/point.svg" />
                  <span className='desc'><FormattedMessage id="engagement.lcStrategy3Point3" /></span>
                </div>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/sales-lc-3.png" />
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/sales-lc-4.png" />
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='content-live-commerce col-lg-6 order-lg-last order-first'>
                <h3><FormattedMessage id="engagement.lcStrategyTitle4" /></h3>
                <h1 className='mb-4'><FormattedMessage id="engagement.lcStrategyHeadline4" /></h1>
                <p className='mb-40'><FormattedMessage id="engagement.lcStrategyDesc4" /></p>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' src="/assets/img/point.svg" />
                  <span className='desc'><FormattedMessage id="engagement.lcStrategy4Point1" /></span>
                </div>
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="engagement.lcStrategyTitle5" /></h3>
                <h1 className='mb-4'><FormattedMessage id="engagement.lcStrategyHeadline5" /></h1>
                <p className='mb-40'><FormattedMessage id="engagement.lcStrategyDesc5" /></p>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' src="/assets/img/point.svg" />
                  <span className='desc'><FormattedMessage id="engagement.lcStrategy5Point1" /></span>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <img className='mr-3' src="/assets/img/point.svg" />
                  <span className='desc'><FormattedMessage id="engagement.lcStrategy5Point2" /></span>
                </div>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/sales-lc-5.png" />
              </div>
            </div>
          </section>
          <div className='list-studio mt-lg-6 mb-170'>
            <h1 className='title mb-4'><FormattedMessage id="engagement.lcCheckOut" /></h1>
              <OwlCarousel
                    // items={4}
                    loop={true} 
                    margin={20} 
                    autoplay={true} 
                    autoWidth={true} 
                    nav={false} 
                    dots={false} > 
                <div className="item"><img data-toggle="modal" data-target="#modal-zoom" className='studio' src="/assets/img/lc-1-min.png"/></div>
                <div className="item"><img data-toggle="modal" data-target="#modal-zoom" className='studio' src="/assets/img/lc-2-min.png"/></div>
                <div className="item"><img data-toggle="modal" data-target="#modal-zoom" className='studio' src="/assets/img/lc-3-min.png"/></div>
                <div className="item"><img data-toggle="modal" data-target="#modal-zoom" className='studio' src="/assets/img/lc-4-min.png"/></div>
                <div className="item"><img data-toggle="modal" data-target="#modal-zoom" className='studio' src="/assets/img/lc-5-min.png"/></div>
              </OwlCarousel>
          </div>
          <div className='tiktok-live-commerce'>
            <div className='text-center mb-5'>
              <h3><FormattedMessage id="engagement.lcGlanceTitle" /></h3>
              <h1><FormattedMessage id="engagement.lcGlanceDesc" /></h1>
            </div>
            <div className='row align-items-center mb-5'>
              <div className='col-lg-6'>
                <h4 className='mb-4 mt-lg-0 mt-4'><FormattedMessage id="engagement.lcWhyChoose" /></h4>
                <div className='row'>
                  <div className='col-lg-4 col-md-4 col-sm-4'>
                    <div className='list-why-tiktok d-lg-block d-md-block d-sm-block d-flex'>
                      <img className='mb-40' src="/assets/img/why-tiktok-1.svg"/>
                      <div className='ml-lg-0 ml-md-0 ml-sm-0 ml-4'>
                        <div className='value mb-3'><FormattedMessage id="engagement.lcWhyChooseTitle1" /></div>
                        <span className='desc'><FormattedMessage id="engagement.lcWhyChooseDesc1" /></span>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-4'>
                    <div className='list-why-tiktok d-lg-block d-md-block d-sm-block d-flex'>
                      <img className='mb-40' src="/assets/img/why-tiktok-1.svg"/>
                      <div className='ml-lg-0 ml-md-0 ml-sm-0 ml-4'>
                        <div className='value mb-3'><FormattedMessage id="engagement.lcWhyChooseTitle2" /></div>
                        <span className='desc'><FormattedMessage id="engagement.lcWhyChooseDesc2" /></span>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-4'>
                    <div className='list-why-tiktok d-lg-block d-md-block d-sm-block d-flex'>
                      <img className='mb-40' src="/assets/img/why-tiktok-1.svg"/>
                      <div className='ml-lg-0 ml-md-0 ml-sm-0 ml-4'>
                        <div className='value mb-3'><FormattedMessage id="engagement.lcWhyChooseTitle3" /></div>
                        <span className='desc'><FormattedMessage id="engagement.lcWhyChooseDesc3" /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 order-lg-last order-first'>
                <img className='w-100 br-16' src="/assets/img/livecommerce.png" />
              </div>
            </div>
            <div className='mb-5'>
              <h4 className='mb-4'><FormattedMessage id="engagement.lcWhyNeed" /></h4>
              <div className='row'>
                <div className='col-lg-4 mb-lg-0 mb-4'>
                  <div className='d-flex align-items-center'>
                    <img className='mr-3' src="/assets/img/point.svg" />
                    <span className='fw-700'><FormattedMessage id="engagement.lcWhyNeedPoint1" /></span>
                  </div>
                </div>
                <div className='col-lg-4 mb-lg-0 mb-4'>
                  <div className='d-flex align-items-center'>
                    <img className='mr-3' src="/assets/img/point.svg" />
                    <span className='fw-700'><FormattedMessage id="engagement.lcWhyNeedPoint2" /></span>
                  </div>
                </div>
                <div className='col-lg-4 mb-lg-0 mb-4'>
                  <div className='d-flex align-items-center'>
                    <img className='mr-3' src="/assets/img/point.svg" />
                    <span className='fw-700'><FormattedMessage id="engagement.lcWhyNeedPoint3" /></span>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className='card-livecommerce mb-lg-0 mb-md-0 mb-sm-0 mb-4'>
                  <h1><FormattedMessage id="engagement.lcWhyNeedBlockTitle1" /></h1>
                  <span className='desc'><FormattedMessage id="engagement.lcWhyNeedBlockDesc1" /></span>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className='card-livecommerce mb-lg-0 mb-md-0 mb-sm-0 mb-4'>
                  <h1><FormattedMessage id="engagement.lcWhyNeedBlockTitle2" /></h1>
                  <span className='desc'><FormattedMessage id="engagement.lcWhyNeedBlockDesc1" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="banner">
          <div className='container-xl'>
            <div className='row'>
              <div className='col-lg-12 d-flex justify-content-center'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-banner text-center'>
                  <h1 className='mb-20'>Enhance your <span className='tiger'>sale</span> with our marketing solution</h1>
                  <p className='mb-60'>Our live commerce solution helps you boost your brand's sales. With our expertise in live commerce, we'll help you craft a winning strategy for your next campaign. Let's discuss your next campaign.</p>
                  <Link to={"/contact"}><button type="button" className="btn-launcher h-48">Discuss With Us</button></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="modal-zoom" class="modal" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <ReactPhotoSphereViewer src="/assets/img/360/studio1.jpg" height={'90vh'} width={"100%"}></ReactPhotoSphereViewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default SalesLC;