import React, { useState, useEffect } from "react";
// import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import ScrollContainer from 'react-indiana-drag-scroll';
import { Helmet } from 'react-helmet';
import { genAPI } from '../helpers/genAPI';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

import { InquiryForm } from '../component/inquiryForm';

function Blog(props) {

    const [sections, setSections] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [lastBlogs, setLastBlogs] = useState({
        blog_img : "",
        blog_title: "",
        blog_title_encoded: "",
        blog_summary: "",
        section_name: ""
    });

    const [activeIDSections, setActiveIDSections] = useState(props.location.query!=undefined?props.location.query.section_id:"");
    const [activeSections, setActiveSections] = useState(props.location.query!=undefined?props.location.query.section_name:"all");
    const [totalPages, setTotalPages] = useState(1);
    const [currentPages, setCurrentPages] = useState(1);
    const [loading, setLoading] = useState(true);

    function getSections() {
        var componentAPI = {
            method: "GET",
            url: process.env.REACT_APP_API_DASHBOARD_URL + "/blogs/getSections?lang=" + props.lang.toUpperCase(),
            post: '',
            isAuth: ''
        }
        
        genAPI(componentAPI).then(data => {

            if(data.error === false)
            {
                setSections(data.data.sections)
            }
            else
            {
                //toastShow("error", data.data.error.message);
            }
        });
    }

    function getPosts(sections,page) {
        setLoading(true);

        setCurrentPages(page);

        var page = parseInt(page)-1;
        var componentAPI = {
            method: "GET",
            url: process.env.REACT_APP_API_DASHBOARD_URL + "/blogs/getPosts?page_size=6&page_offset="+page+"&lang="+ props.lang.toUpperCase() +"&section_id="+sections ,
            post: '',
            isAuth: ''
        }
        
        genAPI(componentAPI).then(data => {

            if(data.error === false)
            {
                setLoading(false);
                setTotalPages(data.data.total_page);
                setBlogs(data.data.posts);
                if(lastBlogs.blog_title==""){
                    setLastBlogs(data.data.posts[0]);
                }
            }
            else
            {
                //toastShow("error", data.data.error.message);
            }
        });
    }

    function updateListBlogs(name,id="") {
        setActiveSections(name);
        setActiveIDSections(id);
        getPosts(id,1);
    }

    useEffect(() => {    
        getSections();
        getPosts(props.location.query!=undefined?props.location.query.section_id:"",1);
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <Helmet>
                <title>BintanGO - Blog</title>
                <meta name="description" content="Blog" />
                <meta property="og:image"              content="/logo-black-300.jpg" />
                <meta property="og:url"                content={window.location.href} />
                <meta property="og:title"              content="BintanGO - Blog" />
                <meta property="og:description"        content="Blog" />
            </Helmet>
            <div>
                <div className="header-page header-blog">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-7 mb-lg-0 mb-3">
                                <p className="neutral-shade mb-2 fz-18">BintanGO Articles ✍️</p>
                                <h1 className="fz-36">Ignites <span className="tiger">inspiration💡</span> and <span className="tiger">knowledge</span>🧠  for creators and brands</h1>
                            </div>
                            <div className="col-lg-5">
                                <p className="neutral-white fz-15">Discover content that not only inspires but also helps you become a better creator or brand in the world of influencer marketing. Elevate your skills and knowledge 🚀</p>
                            </div>
                        </div>
                        <div className="last-article mt-4">
                            <div className="row">
                                <div className="col-lg-7">
                                    <Link to={"/blog/"+lastBlogs.blog_title_encoded}>
                                        <div className="thumbnail-container">
                                            <LazyLoadImage src={lastBlogs.blog_img}
                                                effect="opacity" 
                                                className="thumbnail-blog"
                                                alt={lastBlogs.blog_title}
                                            />
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-5 mt-4">
                                    <div className="category-blog mb-3">{lastBlogs.section_name}</div>
                                    <Link to={"/blog/"+lastBlogs.blog_title_encoded}><h2>{lastBlogs.blog_title}</h2></Link>
                                    <p>{lastBlogs.blog_summary}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container container-list-blog">
                    <ScrollContainer nativeMobileScroll={false}>
                        <div className="menu-category-blog">
                            <div onClick={()=> updateListBlogs("all") } className={activeSections=="all"?"menu-list-blog active":"menu-list-blog"}>All</div>
                            {
                                sections.map(function(s, index){
                                  return(
                                    <div key={index} onClick={()=> updateListBlogs(s.section_name,s.id) } className={activeSections==s.section_name?"menu-list-blog active":"menu-list-blog"}>{s.section_name}</div>
                                  );
                                })
                            }
                        </div>
                    </ScrollContainer>
                    <div className="row mt-4">
                        {
                            !loading?
                            blogs.map(function(b, index){
                                return(
                                    <div key={index} className="card-blog col-lg-4">
                                        <Link to={"/blog/"+b.blog_title_encoded}>
                                            <div className="list-blog">   
                                                <div className="thumbnail-container">
                                                    <LazyLoadImage src={b.blog_img}
                                                        effect="opacity" 
                                                        className="thumbnail-blog"
                                                        alt={b.blog_title}
                                                    />
                                                </div>
                                                <div className="category-blog mt-3 mb-4">{b.section_name}</div>
                                                <h1>{b.blog_title}</h1>
                                                <p className="mb-0">{b.blog_summary}</p>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })
                            :
                            <>
                                <div className="col-lg-4 mb-4">
                                    <Skeleton height={217} />
                                    <Skeleton className="mt-3 mb-3" height={36} width={126} />
                                    <Skeleton count={6} />
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <Skeleton height={217} />
                                    <Skeleton className="mt-3 mb-3" height={36} width={126} />
                                    <Skeleton count={6} />
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <Skeleton height={217} />
                                    <Skeleton className="mt-3 mb-3" height={36} width={126} />
                                    <Skeleton count={6} />
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <Skeleton height={217} />
                                    <Skeleton className="mt-3 mb-3" height={36} width={126} />
                                    <Skeleton count={6} />
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <Skeleton height={217} />
                                    <Skeleton className="mt-3 mb-3" height={36} width={126} />
                                    <Skeleton count={6} />
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <Skeleton height={217} />
                                    <Skeleton className="mt-3 mb-3" height={36} width={126} />
                                    <Skeleton count={6} />
                                </div>
                            </>
                        }
                    </div>
                    <div className={totalPages==0 && !loading?"d-none":""}>
                        <div className="row justify-content-center align-items-center paging-blog">
                            <div className="col-lg-2 col-3">
                                <div onClick={()=> getPosts(activeIDSections,currentPages-1) } className={totalPages==1 || currentPages==1?"d-none":""}>
                                    <div className="d-flex justify-content-center">
                                        <div className="button-paging">
                                            <img src="/assets/img/chevron-left-tiger.svg" alt="Logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-6 text-center">{currentPages}/{totalPages} Page</div>
                            <div className="col-lg-2 col-3">
                                <div onClick={()=> getPosts(activeIDSections,currentPages+1) } className={totalPages==1 || currentPages>=totalPages?"d-none":""}>
                                    <div className="d-flex justify-content-center">
                                    <div className="button-paging">
                                            <img className="right" src="/assets/img/chevron-left-tiger.svg" alt="Logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Blog };