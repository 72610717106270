import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';  

// Main Component
function BrandCreators() {
  useEffect(() => {
    AOS.init({
      duration : 2000
    })
  }, []);
  
  return (
    <>
      <div id="creators">
        <h3>HYPERLOCALIZATION CREATOR</h3>
        <h1 className='mb-60'>Leveraging creators across Indonesia</h1>
        <div id='map'>
          <img className="w-100" data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" src="/assets/img/map.png" alt="User Image" />
          <div className='animation-map'>
            <div className='data data-left-bot'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='mb-4'>
                <h3>3.5%</h3>
                <span className='desc'>Avg ER</span>
              </div>
              <div className='d-flex'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="300" className='mr-5'>
                  <h3>500M+</h3>
                  <span className='desc'>Total Est. View</span>
                </div>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="400">
                  <h3>150M</h3>
                  <span className='desc'>Total Est. Reach</span>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="400" className='data data-right-top'>
              <div className='d-flex mb-4'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='mr-5'>
                  <h3>100K+</h3>
                  <span className='desc'>Creators <span className='nano'>nano to mega</span></span>
                </div>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="300">
                  <h3>2.5B</h3>
                  <span className='desc'>Total Follower</span>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="400">
                <h3>4</h3>
                <span className='desc'>Platform</span>
              </div>
            </div>
            <div data-aos="fade-right" data-aos-delay="500" data-aos-easing="ease-out-cubic" data-aos-duration="2000" className='viral'>
              <h4>Going viral in<br/><span className='tiger'>38 Provinces!</span></h4>
            </div>
          </div>
          <div className='stats-map text-center mt-4' data-aos="fade-up" data-aos-duration="900" data-aos-delay="200">
            <h1>Going viral in <span className='tiger'>38 Provinces!</span></h1>
            <div className='row'>
              <div className='col-md-4 col-sm-4 col-6 mt-50'>
                <h3>100K+</h3>
                <span className='desc'>Creators</span>
                <span className='nano'>nano to mega</span>
              </div>
              <div className='col-md-4 col-sm-4 col-6 mt-50'>
                <h3>4</h3>
                <span className='desc'>Platform</span>
              </div>
              <div className='col-md-4 col-sm-4 col-6 mt-50'>
                <h3>2.5B</h3>
                <span className='desc'>Total Follower</span>
              </div>
              <div className='col-md-4 col-sm-4 col-6 mt-50'>
                <h3>150M</h3>
                <span className='desc'>Total est. Reach</span>
              </div>
              <div className='col-md-4 col-sm-4 col-6 mt-50'>
                <h3>3.5%</h3>
                <span className='desc'>Avg ER</span>
              </div>
              <div className='col-md-4 col-sm-4 col-6 mt-50'>
                <h3>500M+</h3>
                <span className='desc'>Total Est. View</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default BrandCreators;