import {useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  
import ScrollContainer from 'react-indiana-drag-scroll';

import AwarenessUGC from './contentTabs/awarenessUGC';
import AwarenessBCA from './contentTabs/awarenessBCA1';

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function Awareness(props) {
  const referrer = props.location.state==undefined?null:props.location.state.navTabs;

  const [navTabs, setNavTabs] = useState(referrer==null?0:referrer);
  const navtabsRef = useRef(0);

  AOS.init({
    duration : 2000
  })

  const clickNavTabs = (index) => {
    setNavTabs(index);
    const element = navtabsRef;
    const actWidth = element.current.offsetWidth*index;
    const slider = document.querySelector("#social-proof .slider");
    slider.style.cssText += 'left:'+actWidth+'px';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const element = navtabsRef;
    const actWidth = element.current.offsetWidth;   
    const slider = document.querySelector("#social-proof .slider");
    slider.style.cssText += 'width:'+actWidth+'px';
    slider.style.cssText += 'left:'+actWidth*navTabs+'px';

  }, []);

  // console.log("aa",nav);
  return (
    <>
      <Helmet>
        <title>BintanGO - Creator Powered Marketing</title>
        <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
        <meta property="og:image"              content="/logo-black-300.jpg" />
        <meta property="og:url"                content={window.location.href} />
        <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
        <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
      </Helmet>

      <div className='main-banner-solution'>
        <div className='container-xl text-center'>
          <div className='title-solution'>
            <h3><FormattedMessage id="awareness" /></h3>
            <img width={32} src="/assets/img/crown.svg" className="crown" alt="User Image" />
          </div>
          <h1 className='mb-5'><FormattedMessage id="awareness.title" /></h1>
          <p className='mb-5'><FormattedMessage id="awareness.desc" /></p>
          <div className='mx-auto'>
            <button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="letsDiscussNextCampaign" /></button>
          </div>
        </div>
      </div>   
      <div id='social-proof' >
        <div className='container-xl sticky'>
        <ScrollContainer nativeMobileScroll={false}>
          <ul class="nav nav-tabs awareness">
            <div class="slider"></div>
            <li onClick={()=> clickNavTabs(0) } ref={navtabsRef}><a className={navTabs==0?'active':''}>
              <div className='d-flex align-items-center justify-content-center'>
                <img src="/assets/img/temp-icon-sp.svg" alt="User Image" />
                <div className='ml-3'>
                  <div className='title mb-2'>User Generated Content</div>
                </div>
              </div>
            </a></li>
            <li onClick={()=> clickNavTabs(1) }><a className={navTabs==1?'active':''}>
              <div className='d-flex align-items-center justify-content-center'>
                <img src="/assets/img/temp-icon-sp.svg" alt="User Image" />
                <div className='ml-3'>
                  <div className='title mb-2'>Branded Content Ads</div>
                </div>
              </div>
            </a></li>
          </ul>
        </ScrollContainer>
        </div>

        <div class="tab-content">
          {
            navTabs==0?<AwarenessUGC/>:<AwarenessBCA/>
          }
        </div>
      </div>
    </>
  )
}


export default Awareness;