/* Languange EN */
var idContact = {
    "contact.headline"                : "HUBUNGI KAMI",
    "contact.title"                   : "Mulai berkolaborasi dengan BintanGO",

    "contact.iambrand"                : "Im A Brand",
    "contact.iambrandDesc"            : "Kami dapat membantu Anda dalam aktivitas marketing apa pun dan mencapai tujuan marketing brand Anda.",
    "contact.iamagency"               : "Im An Agency",
    "contact.iamagencyDesc"           : "Membantu Anda untuk mengelola campaign dan kreator lebih mudah",
    "contact.iamcreator"              : "Im A Creator",
    "contact.iamcreatorDesc"          : "Kami membantu para kreator untuk menemukan pekerjaan dengan mudah dan mendukung mereka untuk #GrowBeyond.",
};

export default idContact;