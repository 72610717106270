import React, { useState, useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { genAPI } from '../helpers/genAPI';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toastShow } from '../component/toast';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

import { InquiryFormContact } from '../component/inquiryFormContact';

function BlogDetail(props) {
    const [article, setArticle] = useState({
        post_img : "",
        post_title: "",
        post_title_encoded: "",
        post_summary: "",
        post_content: "",
        section_name: "",
        author: "",
        created_date: ""
    });
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    function getArticle(id) {
        setLoading(true);
        var componentAPI = {
            method: "GET",
            url: process.env.REACT_APP_API_DASHBOARD_URL + "/blogs/getPostDetail?id="+id+"&lang="+ props.lang.toUpperCase(),
            post: '',
            isAuth: ''
        }
        
        genAPI(componentAPI).then(data => {
            // console.log("listblog",data);
            if(data.error === false)
            {
                setLoading(false);
                setArticle(data.data);
                getPosts(data.data.section_id);
                // setBlogs(data.data.posts);
                // setLastBlogs(data.data.posts[0])
            }
            else
            {
                //toastShow("error", data.data.error.message);
            }
        });
    }

    function getPosts(sections) {
        var componentAPI = {
            method: "GET",
            url: process.env.REACT_APP_API_DASHBOARD_URL + "/blogs/getPosts?page_size=3&page_offset=0&lang="+ props.lang.toUpperCase() +"&current_post_id="+props.match.params.title ,
            post: '',
            isAuth: ''
        }
        
        genAPI(componentAPI).then(data => {
            // console.log("listblog",data);
            if(data.error === false)
            {
                setBlogs(data.data.posts);
            }
            else
            {
                //toastShow("error", data.data.error.message);
            }
        });
    }

    function shareURL(sosmed)
    { 
      if(sosmed=="twitter"){
        var url = "https://twitter.com/share?url=";
      }else if(sosmed=="facebook"){
        var url = "https://www.facebook.com/sharer/sharer.php?u=";
      }else if(sosmed=="whatsapp"){
        var url = "https://api.whatsapp.com/send?text=";
      }else if(sosmed=="linkedin"){
        var url = "https://www.linkedin.com/shareArticle?mini=true&url=";
      }else if(sosmed=="telegram"){
        var url = "https://t.me/share/url?url=";
      }
  
    //   var left = (screen.width/2)-(550/2);
    //   var top = (screen.height/2)-(300/2);
      var left = "";
      var top = "";
  
      window.open(url + encodeURIComponent(window.location.href), '', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=550, height=300, top='+top+', left='+left);return false; 
    }

    useEffect(() => {    
        getArticle(props.match.params.title);
        window.scrollTo(0, 0);
    }, [props]);

    return (
        <>
            <Helmet>
                <title>BintanGO - {article.post_title}</title>
                <meta name="description" content="Blog" />
                <meta property="og:image"              content="/logo-black-300.jpg" />
                <meta property="og:url"                content={window.location.href} />
                <meta property="og:title"              content={article.post_title} />
                <meta property="og:description"        content={article.post_summary} />
            </Helmet>
            <div>
                <div className="header-page header-blog-detail">
                    <div className="container">
                        <div className="header-article">
                            <div className="row align-items-center">
                                <div className="col-lg-7 order-lg-first">
                                    {
                                        !loading?
                                        <>
                                            <p className="fz-14 mb-32">
                                                <Link to={"/blog"}><u>Blog</u></Link>
                                                <span className="mx-2">/</span> 
                                                <Link to={{
                                                    pathname: `/blog`, 
                                                    query:{section_id: article.section_id, section_name: article.section_name}
                                                }}><u>{article.section_name}</u></Link>
                                            </p>
                                            <div className="category-blog mb-4">{article.section_name}</div>
                                            <h2 className="mb-4">{article.post_title}</h2>
                                            <p>{article.post_summary}</p>
                                        </>
                                        :
                                        <>
                                        <Skeleton width={100} height={30} className="mb-4" />
                                        <Skeleton width={100} height={40} className="mb-3" />
                                        <Skeleton count={3} height={30} />
                                        <div className="mt-4"></div>
                                        <Skeleton count={3} />
                                        </>
                                    }
                                    
                                </div>
                                <div className="col-lg-5 order-first mb-4 mb-lg-0">
                                    {
                                        !loading?
                                        <div className="background-thumbnail-blog">
                                            <LazyLoadImage src={article.post_img}
                                                effect="opacity" 
                                                className="thumbnail-blog-main p-12"
                                                alt={article.post_title}
                                            />
                                        </div>
                                        :
                                        <Skeleton height={334} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="author-info">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="author-name col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
                                By {article.author}
                            </div>
                            <div className="date-article col-lg-6 d-flex justify-content-lg-end justify-content-center">
                                <div className="d-flex">
                                    <div className="d-flex align-items-center mr-5"><img className="mr-2" src="/assets/img/icon-date.svg" alt="Logo" /><span>{article.created_date}</span></div>
                                    <div className="d-flex align-items-center"><img className="mr-2" src="/assets/img/icon-time.svg" alt="Logo" /><span>{article.time_diff} Minute</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="article">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="sticky-top d-lg-block d-flex align-items-center">
                                            <p className="share-article mb-0 mb-lg-4 mr-auto">Let Others Know</p>
                                            <div className="d-flex">
                                                <div onClick={()=> shareURL("facebook") } className="button-share-article d-flex align-items-center justify-content-center mr-2"><img src="/assets/img/icon-facebook.svg" alt="Logo" /></div>
                                                <div onClick={()=> shareURL("linkedin") } className="button-share-article d-flex align-items-center justify-content-center mr-2"><img src="/assets/img/icon-linkedin.svg" alt="Logo" /></div>
                                                <div onClick={()=> shareURL("twitter") } className="button-share-article d-flex align-items-center justify-content-center mr-2"><img src="/assets/img/icon-twitter.svg" alt="Logo" /></div>
                                                <div onClick={() => {navigator.clipboard.writeText(process.env.REACT_APP_CORPORATE + props.location.pathname); toastShow("success", "Success copy link")}} className="button-share-article d-flex align-items-center justify-content-center"><img src="/assets/img/icon-copy-link.svg" alt="Logo" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !loading?
                                        <div id="blog-content" className="col-lg-8" dangerouslySetInnerHTML={{__html: article.post_content}}></div>
                                        :
                                        <div className="col-lg-8">
                                            <Skeleton height={40} className="mb-3"/>
                                            <Skeleton count={10} />
                                            <Skeleton height={40} className="mb-3"/>
                                            <Skeleton count={10} />
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <hr className="my-5"/>
                    <div className="d-flex align-items-center mb-4 mb-lg-2">
                        <h2 className="mr-auto read-more-title mb-0">Read More Articles</h2>
                        <Link to="/blog">
                            <p className="view-all mb-0">View All</p>
                        </Link>
                    </div>
                    <div className="row">
                        {
                            blogs.length>0?
                            blogs.map(function(b, index){
                                return(
                                    <div key={index} className="col-lg-4 mb-4">
                                        <Link to={"/blog/"+b.blog_title_encoded}>
                                            <div className="list-blog">   
                                                <div className="thumbnail-container">
                                                    <LazyLoadImage src={b.blog_img}
                                                        effect="opacity" 
                                                        className="thumbnail-blog"
                                                        alt={b.blog_title}
                                                    />
                                                </div>
                                                <div className="category-blog mt-3 mb-4">{b.section_name}</div>
                                                <h1>{b.blog_title}</h1>
                                                <p className="mb-0">{b.blog_summary}</p>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            }):<div>Tidak ada data</div>
                        }
                    </div>
                </div>
                <div id="contact-us" className="mt-120">
                    <div className="container">
                        <div className="row">
                            <div className='col-lg-6 info mt-lg-5 mt-md-5 mt-0'>
                                <h3><FormattedMessage id="contact.headline" /></h3>
                                <h1 className='mb-64'><FormattedMessage id="contact.title" /></h1>  
                            </div>
                            <div className='col-lg-6'>
                                <InquiryFormContact />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { BlogDetail };