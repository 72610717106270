import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';
import idLang from './entries/id-ID';
import thLang from './entries/th-TH';

const AppLocale = {
    en: enLang,
    id: idLang,
    th: thLang
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.id.data);
addLocaleData(AppLocale.th.data);

export default AppLocale;
