import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function ProductLaunchActivation() {
  const sectionStrategy = useRef(null);

  const scrollNextSection = () => {
    sectionStrategy.current?.scrollIntoView({behavior: 'smooth',block: 'start'});
  };

  useEffect(() => {
    AOS.init({
      duration : 2000
    })
  }, []);
  
  return (
    <>
      <div id="live-commerce" class="mt-lg-4 mt-5">
        <div className='container-xl mb-170'>
          <div className='title-live-commerce text-center'>
            <h3 className='mr-3'>BRAND ACTIVATION CAMPAIGN</h3>
            <h1 className='mb-40'><FormattedMessage id="productLaunch.activation" values={{ tiger: <span className="tiger"><FormattedMessage id="productLaunch.activationTitleTiger1" /></span>}} /></h1>
          </div>
          <div className='text-center row justify-content-center'>
            <div className='col-lg-8'>
              <p className='mb-60 mx-auto'><FormattedMessage id="productLaunch.activationDesc" /></p>
              <div className='mx-auto mb-60'>
                <button onClick={()=> scrollNextSection() } type="button" className="btn-login mw-200 h-52 mb-lg-0 mb-md-0 mb-4"><FormattedMessage id="lookHowWeWork" /></button>
                <Link to="/contact"><button type="button" className="btn-launcher mw-200 h-52 mx-lg-4 mx-md-4 mx-sm-4 mx-0"><FormattedMessage id="letsDiscuss" /></button></Link>
              </div>
            </div>
          </div>
          
          <section id="partner" className='container-xl mb-0'>
            <div className="title-new w-100 text-center mb-4"><FormattedMessage id="ourEventPartner" /></div>
            <div className='d-flex justify-content-center'>
              <img className='mr-5' height={64} src={"/assets/img/kompas.png"} />
              <img height={64} src={"/assets/img/loket.png"} />
            </div>
          </section>  

          <div id='dont-use-360'>
            <div className='row'>
              <div className='col-lg-6 mb-lg-0 mb-md-0 mb-5'>
                <h1 className='mb-32'><FormattedMessage id="productLaunch.activationImportant" /></h1>
                <Link to="/contact"><button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="letsDiscuss" /></button></Link>
              </div>
              <div className='col-lg-6'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='list-dont-use-360 mb-64'>
                  <h4 className='mb-4'><FormattedMessage id="productLaunch.activationImportantTitle1" /></h4>
                  <p className='mb-0'><FormattedMessage id="productLaunch.activationImportantDesc1" /></p>
                  <img src="/assets/img/build-brand-awareness.svg" alt="User Image" />
                </div>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='list-dont-use-360 mb-64'>
                  <h4 className='mb-4'><FormattedMessage id="productLaunch.activationImportantTitle2" /></h4>
                  <p className='mb-0'><FormattedMessage id="productLaunch.activationImportantDesc2" /></p>
                  <img src="/assets/img/engage-directly-to-your-customer.svg" alt="User Image" />
                </div>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="300" className='list-dont-use-360'>
                  <h4 className='mb-4'><FormattedMessage id="productLaunch.activationImportantTitle3" /></h4>
                  <p className='mb-0'><FormattedMessage id="productLaunch.activationImportantDesc3" /></p>
                  <img src="/assets/img/create-a-memorable-experience.svg" alt="User Image" />
                </div>
              </div>
            </div>
          </div>
          <section id="strategy-live-commerce" ref={sectionStrategy}>
            <div className='text-center mb-80'>
              <h1 className='title-section mb-4'><FormattedMessage id="productLaunch.strategyActivationTitle" /></h1>
              <p className='desc-section w-60 mx-auto mb-80-mobile'><FormattedMessage id="productLaunch.strategyActivationDesc" /></p>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="productLaunch.gatheringInformation" /></h3>
                <h1 className='mb-4'><FormattedMessage id="productLaunch.gatheringInformationTitle" /></h1>
                <p className='mb-40'><FormattedMessage id="productLaunch.gatheringInformationDesc" /></p>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/gathering-information.png" />
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/ideation.png" />
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='content-live-commerce col-lg-6 order-lg-last order-first'>
                <h3><FormattedMessage id="productLaunch.ideation" /></h3>
                <h1 className='mb-4'><FormattedMessage id="productLaunch.ideationTitle" /></h1>
                <p className='mb-40'><FormattedMessage id="productLaunch.ideationDesc" /></p>
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="productLaunch.execute" /></h3>
                <h1 className='mb-4'><FormattedMessage id="productLaunch.executeTitle" /></h1>
                <p className='mb-40'><FormattedMessage id="productLaunch.executeDesc" /></p>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/execute.png" />
              </div>
            </div>
          </section>
          {/* <div id="case-studies">
            <div className='row justify-content-center'>
              <div className='col-lg-10 mb-32'>
                <div className='d-flex align-items-end'>
                  <h1 className='mr-auto mb-0'>Case Studies</h1>
                  <p className='mb-0'>See All Case Study</p>
                </div>
              </div>
              <div className='col-lg-5 mb-lg-0 mb-md-0 mb-5'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-case-studies'>
                  <div className='info mb-20'>Product Launch</div>
                  <h3 className='mb-4'>Gaming Gold - Influencer Marketing in the Gaming Industry</h3>
                  <p className='mb-32'>A lifestyle apparel brand collaborated with fitness influencers to promote their activewear collection.</p>
                  <div className='row'>
                    <div className='col-4'>
                      <img className='mb-2' src="/assets/img/icon-reach.svg" alt="User Image" />
                      <span className='title mb-1'>Reach</span>
                      <span className='value'>1.2 Mill</span>
                    </div>
                    <div className='col-4'>
                      <img className='mb-2' src="/assets/img/icon-view.svg" alt="User Image" />
                      <span className='title mb-1'>View</span>
                      <span className='value'>900.233</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-5'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='card-case-studies'>
                  <div className='info mb-20'>Product Launch</div>
                  <h3 className='mb-4'>Fitness Company, Wellness Journey with Influencers</h3>
                  <p className='mb-32'>Discover how a fitness and nutrition brand collaborated with wellness influencers to inspire healthier lifestyles. The campaign focused on guiding followers through fitness routines, nutrition tips, and mental wellness practices, promoting overall well-being.</p>
                  <div className='row'>
                    <div className='col-4'>
                      <img className='mb-2' src="/assets/img/icon-reach.svg" alt="User Image" />
                      <span className='title mb-1'>Reach</span>
                      <span className='value'>1.2 Mill</span>
                    </div>
                    <div className='col-4'>
                      <img className='mb-2' src="/assets/img/icon-view.svg" alt="User Image" />
                      <span className='title mb-1'>View</span>
                      <span className='value'>900.233</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <section id="banner">
          <div className='container-xl'>
            <div className='row'>
              <div className='col-lg-12 d-flex justify-content-center'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-banner text-center'>
                  <h1 className='mb-20'><FormattedMessage id="bannerStrategicTitle" /></h1>
                  <p className='mb-60'><FormattedMessage id="bannerStrategicDesc" /></p>
                  <Link to={"/contact"}><button type="button" className="btn-launcher h-48"><FormattedMessage id="letsDiscuss" /></button></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}


export default ProductLaunchActivation;