import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function ForAgency(props) {

  const items = [
    {
      title: "forAgency.creatorManagementTitle",
      headline: "forAgency.creatorManagementHeadline",
      description: "forAgency.creatorManagementDesc",
      itemRef: useRef(0),
      img: '/assets/img/agency-1.png'
    },
    {
      title: "forAgency.creatorDiscoveryTitle",
      headline: "forAgency.creatorDiscoveryHeadline",
      description: "forAgency.creatorDiscoveryDesc",
      itemRef: useRef(0),
      img: '/assets/img/agency-2.png'
    },
    {
      title: "forAgency.campaignManagementTitle",
      headline: "forAgency.campaignManagementHeadline",
      description: "forAgency.campaignManagementDesc",
      itemRef: useRef(0),
      img: '/assets/img/agency-3.png'
    },
    {
      title: "forAgency.creatorShortlistingTitle",
      headline: "forAgency.creatorShortlistingHeadline",
      description: "forAgency.creatorShortlistingDesc",
      itemRef: useRef(0),
      img: '/assets/img/agency-4.png'
    },
    {
      title: "forAgency.campaignReportingTitle",
      headline: "forAgency.campaignReportingHeadline",
      description: "forAgency.campaignReportingDesc",
      itemRef: useRef(0),
      img: '/assets/img/agency-5.png'
    },
  ];

  const stickyBlockContainerRef = useRef(0);
  const [activeItem, setActiveItem] = useState(0);
  const [isViewStickyBlock, setIsViewStickyBlock] = useState(false);
  const [stickyBlockLocations, setStickyBlockLocations] = useState([]);
  const sectionAgency = useRef(null);

  const handleGoToStickyBlock = (index) => {
    console.log("index",index);
    window.scroll({
      top: stickyBlockLocations[index],
      behavior: "smooth",
    });
  };

  const scrollNextSection = () => {
    sectionAgency.current?.scrollIntoView({behavior: 'smooth'});
  };

  AOS.init({
    duration : 2000
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    window.addEventListener("scroll", () => {
        const currentPositionTop = window.scrollY;
        if(stickyBlockContainerRef.current!=null){
          // console.log("Agencyaaa",stickyBlockContainerRef.current);
          // console.log("Agencyb",stickyBlockContainerRef.current.offsetTop);
          const stickyBlockContainerOffsetTop = stickyBlockContainerRef.current.offsetTop;
          const stickyBlockContainerOffsetBottom = stickyBlockContainerOffsetTop + stickyBlockContainerRef.current.offsetHeight;
          if (
            stickyBlockContainerOffsetTop - 200 < currentPositionTop &&
            currentPositionTop < stickyBlockContainerOffsetBottom - 700
          )
            setIsViewStickyBlock(true);
          else setIsViewStickyBlock(false);
      
          const offsetTops = [];
          for (let i = items.length - 1; i >= 0; i--) {
            const currentOffset = items[i].itemRef.current.offsetTop;
            // console.log("agency",items[i].itemRef.current.getBoundingClientRect());
            // offsetTops.push(items[i].itemRef.current.getBoundingClientRect());
            const nextOffset =
              items[i - 1]?.itemRef.current.offsetTop ?? currentOffset;
            if (currentOffset === nextOffset) {
              setActiveItem(i);
              break;
            }
          }
        }
    });
  });
  
  return (
    <>
      <Helmet>
        <title>BintanGO - Creator Powered Marketing</title>
        <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
        <meta property="og:image"              content="/logo-black-300.jpg" />
        <meta property="og:url"                content={window.location.href} />
        <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
        <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
      </Helmet>

      <div className='main-banner'>
        <div className='container-xl'>
        <h3 className='d-flex align-items-center'><FormattedMessage id="forAgency" /> <img width={32} src="/assets/img/crown.svg" className="ml-2" alt="User Image" /></h3>
          <h1 className='mb-5'><FormattedMessage id="forAgency.title" /><br/>
          <span className='tiger'>
          <Typewriter
            options={{
              strings: props.lang=="id"?['Menemukan kreator yang sesuai dengan client', 'Mengelola campaign dengan mudah', 'Melacak performa kreator secara real-time']:['Find the perfect creators for their clients', 'Manage and organize campaigns with ease', 'Track creator performance in real time'],
              autoStart: true,
              loop: true,
            }}
          />
          </span></h1>
          <p className='mb-5 w-60'><FormattedMessage id="forAgency.desc" /></p>
          <div className='row'>
            <div className='col-lg-auto col-md-auto col-sm-auto'>
              <button onClick={()=> scrollNextSection() } type="button" className="btn-login mw-200 h-52"><FormattedMessage id="learnMore" /></button>
            </div>
            <div className='col-lg-auto col-md-auto col-sm-auto order-lg-last order-first mb-4 mb-lg-0 mb-sm-0'>
            <Link to="/contact"><button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="letsDiscuss" /></button></Link>
            </div>
          </div>
        </div>
      </div>   
      <section id="partner" className='container-xl'>
        <div className='d-flex-desktop align-items-center'>
          <div className="title"><FormattedMessage id="trustedByAgency" values={{ number: <span className="number">20</span> }} /></div>
          <div className='carousel'>
            <div className='gradient'></div>
            <OwlCarousel 
              items={5}
              loop={true} 
              margin={20} 
              autoplay={true} 
              // smartSpeed={1000}
              slideTransition={'linear'} 
              autoplayTimeout={1500} 
              autoplaySpeed={3000} 
              // autoplayHoverPause={false}
              autoWidth={true} 
              nav={false} 
              dots={false} > 
            {
                listBrands.map(function(image, index) {
                return (
                    <div className="item" key={index}><img height={64} src={"/assets/img/for-brand/" + image} /></div>
                    );
                })
            }
            </OwlCarousel>
          </div>
        </div>
      </section>  
      <section id="service" ref={sectionAgency}>
        <h1 className='title container-xl mb-60'><FormattedMessage id="forAgency.titleTechDriven" /></h1>
        <div className='container-service' ref={stickyBlockContainerRef}
        onLoad={() => {
          if (stickyBlockLocations.length === 0) {
            const setStickyBlockLocationsValue = [];
            for (const item of items) {
              setStickyBlockLocationsValue.push(
                item.itemRef.current.getBoundingClientRect().y
              );
            }
            setStickyBlockLocations(setStickyBlockLocationsValue);
          }
        }}>
          <div style={{
            opacity: isViewStickyBlock ? 1 : 0,
          }} className="navigation-services">
            {items.map(({}, index) => {
              return (
                <div key={index} className='bullets'>
                     <img
                      onClick={() => handleGoToStickyBlock(index)}
                      style={{
                        pointerEvents: isViewStickyBlock ? "unset" : "none",
                        cursor: isViewStickyBlock ? "pointer" : "none",
                      }}
                      src={activeItem === index ? '/assets/img/section-active.svg' : '/assets/img/section-inactive.svg'}
                    ></img>
                </div>
              );
            })}
          </div>
          {items.map(({ title, headline, description, itemRef, img }, index) => {
            return (
              <div key={index} ref={itemRef} className={index>0?'card-service shadow':'card-service'}>
                {/* <img className='img-service' data-aos="fade-right" data-aos-delay="100" src="/assets/img/services.png" alt="User Image" /> */}
                <div className='container-xl w-100'>
                  <div className='row'>
                    <div className='col-lg-5 col-md-5'>
                      <h3 className='d-flex align-items-center mb-20'><div className='number-service d-flex align-items-center justify-content-center mr-2'>0{index+1}</div><span><FormattedMessage id={title} /></span></h3>
                      <h1 className='title-service mb-24'><FormattedMessage id={headline} /></h1>
                      <p className='mb-5'><FormattedMessage id={description} /></p>
                      <Link to="/contact"><button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="discussWithOurTeam" /></button></Link>
                    </div>
                    <div className='col-lg-6'>
                      <img className='' data-aos="fade-up" data-aos-delay="100" data-aos-duration="900" src={img} alt="User Image" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className='container-service-mobile'>
          {items.map(({ title, headline, description, img }, index) => {
            return (
              <div key={index} className='card-service'>
                <div className='container-xl w-100'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 mb-40'>
                      <h3 className='d-flex align-items-center mb-20'><div className='number-service d-flex align-items-center justify-content-center mr-2'>0{index+1}</div><span><FormattedMessage id={title} /></span></h3>
                      <h1 className='title-service mb-24'><FormattedMessage id={headline} /></h1>
                      <p className='mb-5'><FormattedMessage id={description} /></p>
                      <button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="discussWithOurTeam" /></button>
                    </div>
                    <div className='col-lg-6'>
                      <img className="w-100" data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" src={img} alt="User Image" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {/* <section id="testimonial" className='container-xl'>
        <div className='row align-items-center'>
          <div className='col-lg-4'>
            <h3 className='mb-20'><FormattedMessage id="forBrand.testimonialTitle" /></h3>
            <p className='mb-40'><FormattedMessage id="forBrand.testimonialDesc" /></p>
            <div className="owl-carousel owl-carousel owl-theme owl-loaded owl-drag nav-for-desktop">
              <div className="nav-testimonial owl-nav text-left"></div>
            </div>
          </div>
          <div className='col-lg-8'>
            <img className='mb-20 quote' src="/assets/img/quote.svg" alt="User Image" />
            <OwlCarousel 
              items={1}
              loop={true} 
              margin={0} 
              autoplay={true} 
              autoplaySpeed={2000} 
              navSpeed={2000}
              navContainer={'.nav-testimonial'}
              nav={true} 
              dots={false} > 
                <div className='card-carousel'>
                  <div className='ml-lg-5 ml-md-5 ml-0'>
                    <h2 className='mb-40'>We were amazed by the level of customization BintanGO provided. They tailored their strategies to suit our unique challenges, and the outcomes were outstanding.”</h2>
                    <div className='d-flex align-items-center'>
                      <img className='avatar mr-3' src="/assets/img/people/Alifa Thesa.jpg" alt="User Image" />
                      <div>
                        <div className='fullname'>Farel Darari Deksano</div>
                        <div className='position'>Marketing Manager</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-carousel'>
                  <div className='ml-lg-5 ml-md-5 ml-0'>
                    <h2 className='mb-40'>Donec sollicitudin molestie malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h2>
                    <div className='d-flex align-items-center'>
                      <img className='avatar mr-3' src="/assets/img/people/Eugene Laurent.png" alt="User Image" />
                      <div>
                        <div className='fullname'>Safar Septyadi</div>
                        <div className='position'>FrontEnd Developer</div>
                      </div>
                    </div>
                  </div>
                </div>
            </OwlCarousel>
            <div className="owl-carousel owl-carousel owl-theme owl-loaded owl-drag nav-for-mobile">
              <div className="nav-testimonial owl-nav text-left"></div>
            </div>
          </div>
        </div>
      </section> */}
      <section id="banner">
        <div className='container-xl'>
          <div className='row'>
            <div className='col-lg-12 d-flex justify-content-center'>
              <div data-aos="zoom-in" data-aos-delay="100" data-aos-duration="900" className='card-banner text-center'>
                <h1 className='mb-20'><FormattedMessage id="bannerAgencyTitle" /></h1>
                <p className='mb-60'><FormattedMessage id="bannerAgencyDesc" /></p>
                <Link to={"/contact"}><button type="button" className="btn-launcher h-48"><FormattedMessage id="discussWithOurTeam" /></button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


export default ForAgency;
