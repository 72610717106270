/* Languange TH */

var thProductLaunch = {
    "productLaunch"	: "เปิดตัวผลิตภัณฑ์",
    "productLaunch.title"	: "คุณสร้าง เราทำการตลาด เปิดตัวผลิตภัณฑ์ของคุณด้วยกลยุทธ์ที่เหมาะสม",
    "productLaunch.desc"	: "ทีมผู้เชี่ยวชาญของเราจะช่วยคุณเปิดตัวผลิตภัณฑ์และสร้างความประทับใจอย่างยิ่ง",
    "productLaunch.launchMyProduct"	: "เปิดตัวผลิตภัณฑ์ของฉัน",

    "productLaunch.360degree"	: "เปิดตัวผลิตภัณฑ์ของคุณด้วย {tiger} ที่จะครองตลาดของคุณ",
    "productLaunch.360degreeTitleTiger1"	: "แคมเปญการตลาดแบบ 360 องศา",
    "productLaunch.360degreeDesc"	: "กลยุทธ์การตลาดที่ครอบคลุมของเราช่วยให้คุณวางแผน ดำเนินการ และวัดผลแคมเปญเปิดตัวผลิตภัณฑ์ของคุณในทุกช่องทาง เข้าถึงกลุ่มเป้าหมาย และรับประกันความสำเร็จ",

    "productLaunch.increaseTitle"	: "27% เพิ่มความภักดีต่อแบรนด์",
    "productLaunch.increaseDesc"	: "บริษัทที่ใช้แคมเปญแบบครอบคลุมจะได้รับลูกค้าประจำมากขึ้น",
    "productLaunch.boostTitle"	: "89% เพิ่ม ROI ของคุณ",
    "productLaunch.boostDesc"	: "ผู้บริโภคเลือกแบรนด์ที่พวกเขาชอบเพราะจริงใจและน่าเชื่อถือ",

    "productLaunch.dontUse"	: "จะเกิดอะไรขึ้นถ้าคุณไม่ใช้แคมเปญการตลาดแบบ 360 องศา?",
    "productLaunch.dontUseTitle1"	: "พลาดโอกาส",
    "productLaunch.dontUseDesc1"	: "อย่าข้ามการทำแคมเปญการตลาดแบบ 360 องศาสำหรับผลิตภัณฑ์ใหม่ของคุณ! แบรนด์ของคุณอาจถูกบดบังและล้มเหลวในการสร้างการรับรู้จากสิ่งที่สมควรได้รับ",
    "productLaunch.dontUseTitle2"	: "การสื่อสารที่ไม่ต่อเนื่อง",
    "productLaunch.dontUseDesc2"	: "แคมเปญการตลาดแบบ 360 องศาช่วยให้แน่ใจว่าการสื่อสารของคุณให้กับลูกค้าเหมือนกันในทุกช่องทาง ช่วยให้ลูกค้าในอนาคตเข้าใจการนำเสนอคุณค่าที่เป็นเอกลักษณ์ของผลิตภัณฑ์ของคุณ",
    "productLaunch.dontUseTitle3"	: "การเข้าถึงและการมีส่วนร่วมมีจำกัด",
    "productLaunch.dontUseDesc3"	: "เข้าถึงผู้คนมากขึ้นและเพิ่มการมีส่วนร่วมด้วยแคมเปญการตลาดแบบ 360 องศาที่กำหนดเป้าหมายหลายช่องทาง",

    "productLaunch.strategyTitle"	: "กระบวนการของเราสำหรับการเปิดตัวผลิตภัณฑ์ใหม่ที่ประสบความสำเร็จของคุณ",
    "productLaunch.strategyDesc"	: "เรามีกระบวนการที่ได้รับการพิสูจน์แล้วในการนำผลิตภัณฑ์ของคุณจากแนวคิดไปสู่การเปิดตัวและอื่นๆ อีกมากมาย ภาพรวมทีละขั้นตอนมีดังนี้",

    "productLaunch.planningStrategizing"	: "การวางแผนและการวางกลยุทธ์",
    "productLaunch.planningStrategizingTitle"	: "กำหนดขั้นตอนสำหรับหนทางความสำเร็จ",
    "productLaunch.planningStrategizingDesc"	: "เริ่มต้นด้วยการกำหนดเป้าหมาย ระบุกลุ่มเป้าหมาย และพัฒนากลยุทธ์การส่งข้อความที่จะทำให้ผลิตภัณฑ์ของคุณโดดเด่นจากผู้คน",

    "productLaunch.preLaunchActivity"	: "กิจกรรมก่อนเปิดตัว",
    "productLaunch.preLaunchActivityTitle"	: "สร้างความฮือฮาและความคาดหวัง",
    "productLaunch.preLaunchActivityDesc"	: "ก่อนที่คุณจะเปิดตัวผลิตภัณฑ์ มาเริ่มสร้างความฮือฮาและความตื่นเต้นกันก่อน เราจะสร้างตัวอย่างและทีเซอร์ จัดการแข่งขันและการแจกของรางวัลบนโซเชียลมีเดีย และร่วมมือกับอินฟูเอ็นเซอร์และผู้เชี่ยวชาญคนอื่นๆ เพื่อกระจายข่าวออกไป",

    "productLaunch.amplification"	: "การขยายและความยั่งยืน",
    "productLaunch.amplificationTitle"	: "เปิดตัวผลิตภัณฑ์ของคุณอย่างรวดเร็วและรักษาโมเมนตัมต่อไป",
    "productLaunch.amplificationDesc"	: "เป็นวันเปิดตัว! เราจะส่งอีเมลเปิดตัว แสดงโฆษณาแบบชำระเงิน และโปรโมตผลิตภัณฑ์ของคุณบนโซเชียลมีเดียเพื่อประชาสัมพันธ์ไปยังกลุ่มเป้าหมายของคุณ และเราจะสร้างความฮือฮาอย่างต่อเนื่องด้วยเนื้อหาและการมีส่วนร่วมที่สดใหม่",

    "productLaunch.tracking"	: "การติดตามและการวิเคราะห์หลังแคมเปญ",
    "productLaunch.trackingTitle"	: "ติดตามผลลัพธ์ของคุณและเรียนรู้เพื่ออนาคต",
    "productLaunch.trackingDesc"	: "เราจะติดตามความคืบหน้าของคุณตลอดทั้งแคมเปญและวิเคราะห์ผลลัพธ์ของคุณหลังจากสิ้นสุดเพื่อเรียนรู้และปรับปรุงสำหรับการเปิดตัวผลิตภัณฑ์ครั้งต่อไปของคุณ",

    "productLaunch.activation"	: "สร้าง {tiger} ให้กับลูกค้าของคุณ",
    "productLaunch.activationTitleTiger1"	: "ประสบการณ์ที่น่าจดจำ",
    "productLaunch.activationDesc"	: "มาเปิดตัวผลิตภัณฑ์ของคุณด้วยวิธีที่สร้างสรรค์ซึ่งจะสร้างความประทับใจไม่รู้ลืมให้กับลูกค้าของคุณ",

    "productLaunch.activationImportant"	: "ทำไมการเปิดใช้งานแบรนด์จึงมีความสำคัญ?",
    "productLaunch.activationImportantTitle1"	: "สร้างการรับรู้ถึงแบรนด์",
    "productLaunch.activationImportantDesc1"	: "ด้วยกลยุทธ์การตลาดแบบตรงจุด คุณสามารถสร้างการรับรู้ถึงแบรนด์ที่น่าประทับใจได้",
    "productLaunch.activationImportantTitle2"	: "มีส่วนร่วมโดยตรงกับลูกค้าของคุณ",
    "productLaunch.activationImportantDesc2"	: "การเปิดใช้งานแบรนด์ช่วยให้ลูกค้าโต้ตอบกับแบรนด์ของคุณได้โดยตรงและทดลองใช้ทันที สัมผัสประสบการณ์สิ่งที่พวกเขาไม่เคยสัมผัสมาก่อน",
    "productLaunch.activationImportantTitle3"	: "สร้างประสบการณ์ที่น่าจดจำ",
    "productLaunch.activationImportantDesc3"	: "การเปิดใช้งานแบรนด์นั้นยอดเยี่ยมมาก! เนื่องจากมีกิจกรรมที่น่าตื่นเต้นมากมายเกิดขึ้น ลูกค้าของคุณจะไม่สามารถลืมแบรนด์ของคุณได้",

    "productLaunch.strategyActivationTitle"	: "กระบวนการทีละขั้นตอนของเราในการเริ่มต้นการเปิดใช้งานแบรนด์ของคุณ",
    "productLaunch.strategyActivationDesc"	: "เรามั่นใจว่าเราครอบคลุมความต้องการในการเปิดตัวผลิตภัณฑ์ใหม่ทั้งหมดของคุณ",

    "productLaunch.gatheringInformation"	: "รวบรวมข้อมูล",
    "productLaunch.gatheringInformationTitle"	: "รับรายละเอียดของแบรนด์ของคุณ",
    "productLaunch.gatheringInformationDesc"	: "เราจะรวบรวมข้อมูลเช่นเป้าหมายและความต้องการของคุณเพื่อกำหนดกลยุทธ์การเปิดใช้งานแบรนด์ที่ดีที่สุดสำหรับคุณ",

    "productLaunch.ideation"	: "สร้างไอเดีย",
    "productLaunch.ideationTitle"	: "โซลูชั่นที่สร้างสรรค์ที่ทำให้คุณเป็นที่จดจำแก่ลูกค้าของคุณ",
    "productLaunch.ideationDesc"	: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus efficitur odio et purus tempus tincidunt. Donec et nisi at mi eleifend ullamcorper.",

    "productLaunch.execute"	: "ดำเนินการ",
    "productLaunch.executeTitle"	: "ทีมผู้เชี่ยวชาญของเราจะจัดการกระบวนการตั้งแต่ต้นทางถึงปลายทางทั้งหมดให้กับคุณ",
    "productLaunch.executeDesc"	: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus efficitur odio et purus tempus tincidunt. Donec et nisi at mi eleifend ullamcorper.",
};

export default thProductLaunch;