/* Languange TH */

var thInquiryForm = {
    "form.titleFirst"	: "จุดประกาย สร้างอิทธิพลของแบรนด์ของคุณ",
    "form.titleSecond"	: "เชื่อมต่อกับครีเอเตอร์ชั้นนำที่สามารถทำได้",
    "form.titleSecondTiger1"	: "เพิ่มการมีมองเห็น",
    "form.and"	: "และ",
    "form.titleSecondTiger2"	: "เพิ่มการมีส่วนร่วม",
    "form.titleDesc"	: "ติดต่อเราตอนนี้และเราพร้อมช่วยเหลือยกระดับแบรนด์ของคุณผ่าน Creator Marketing",

    "form.picInformation"	: "ข้อมูล PIC",
    "form.businessInformation"	: "ข้อมูลทางธุรกิจ",
    "form.yourName"	: "ชื่อของคุณ*",
    "form.yourEmail"	: "อีเมลของคุณ*",
    "form.yourPhone"	: "เบอร์โทรศัพทย์*",
    "form.yourCompany"	: "ชื่อบริษัทของคุณ*",
    "form.yourLink"	: "เว็บไซต์บริษัทหรือลิงค์โซเชียลมีเดีย*",
    "form.yourNeed"	: "คุณต้องการบริการด้านใดจาก BintanGO?",
    "form.creatorMarketing"	: "การตลาดสำหรับครีเอเตอร์",
    "form.creatorMarketingDesc"	: "เราจะช่วยคุณจัดทำแคมเปญและทำงานร่วมกับครีเอเตอร์ และเพิ่มการรับรู้ การมีส่วนร่วม หรือบรรลุเป้าหมายทางการตลาดของคุณ",
    "form.liveCommerce"	: "การไลฟ์สดขายของ",
    "form.liveCommerceDesc"	: "เราจะช่วยคุณดำเนินการค้าขายสดเพื่อเพิ่มยอดขายหรือโต้ตอบกับผู้ชมของคุณโดยตรง",
    "form.yourBudget"	: "การประมาณงบประมาณแคมเปญ (Rp)*",
    "form.yourMessage"	: "ข้อความของคุณ (ไม่บังคับ)",
    "form.next"	: "ต่อไป",
    "form.back"	: "กลับ",
    "form.send"	: "ส่ง",
    "form.sending"	: "กำลังส่ง",
};
export default thInquiryForm;
