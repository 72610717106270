import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  
import ScrollContainer from 'react-indiana-drag-scroll';

import BrandCreators from './contentTabs/brandCreators';
import BrandData from './contentTabs/brandData';
import BrandCampaign from './contentTabs/brandCampaign';

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function ForBrand(props) {
  const items = [
    {
      title: "forBrand.awarenessTitle",
      headline: "forBrand.awarenessHeadline",
      description: "forBrand.awarenessDesc",
      itemRef: useRef(0),
      solution1: 'User Generated Content',
      solution2: 'Branded Content Ads',
      link1: { pathname: '/solution/brand-awareness', state: {navTabs:0} },
      link2: { pathname: '/solution/brand-awareness', state: {navTabs:1} },
      img: '/assets/img/solution-brandawareness.png'
    },
    {
      title: "forBrand.engageTitle",
      headline: "forBrand.engageHeadline",
      description: "forBrand.engageDesc",
      itemRef: useRef(0),
      solution1: 'UGC',
      solution2: 'Live Commerce',
      link1: { pathname: '/solution/engagement-to-audience', state: {navTabs:0} },
      link2: { pathname: '/solution/engagement-to-audience', state: {navTabs:1} },
      img: '/assets/img/solution-engagement.png'
    },
    {
      title: "forBrand.launchTitle",
      headline: "forBrand.launchHeadline",
      description: "forBrand.launchDesc",
      itemRef: useRef(0),
      solution1: 'Activation',
      solution2: '360 Marketing',
      link: '/solution/new-product-launch',
      link1: { pathname: '/solution/new-product-launch', state: {navTabs:0} },
      link2: { pathname: '/solution/new-product-launch', state: {navTabs:1} },
      img: '/assets/img/solution-product.png'
    },
    {
      title: "forBrand.salesTitle",
      headline: "forBrand.salesHeadline",
      description: "forBrand.salesDesc",
      itemRef: useRef(0),
      solution1: 'Live Commerce',
      solution2: 'Affiliate',
      link1: { pathname: '/solution/growth-brand-sales', state: {navTabs:0} },
      link2: { pathname: '/solution/growth-brand-sales', state: {navTabs:1} },
      img: '/assets/img/solution-sales.png'
    },
  ];

  const [navTabs, setNavTabs] = useState(0);
  const stickyBlockContainerRef = useRef(0);
  const navtabsRef = useRef(0);
  const [activeItem, setActiveItem] = useState(0);
  const [isViewStickyBlock, setIsViewStickyBlock] = useState(false);
  const [stickyBlockLocations, setStickyBlockLocations] = useState([]);
  const sectionNavTabs = useRef(null);

  const handleGoToStickyBlock = (index) => {
    // console.log("index",index);
    window.scroll({
      top: stickyBlockLocations[index],
      behavior: "smooth",
    });
  };

  const clickNavTabs = (index) => {
    setNavTabs(index);
    const element = navtabsRef;
    const actWidth = element.current.offsetWidth*index;
    const slider = document.querySelector("#social-proof .slider");
    slider.style.cssText += 'left:'+actWidth+'px';
  };

  const scrollNextSection = () => {
    sectionNavTabs.current?.scrollIntoView({behavior: 'smooth'});
  };

  AOS.init({
    duration : 2000
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const currentPositionTop = window.scrollY;
      if(stickyBlockContainerRef.current!=null){
        // console.log("aaa",stickyBlockContainerRef.current);
        // console.log("b",stickyBlockContainerRef.current.offsetTop);
        const stickyBlockContainerOffsetTop = stickyBlockContainerRef.current.offsetTop;
        const stickyBlockContainerOffsetBottom = stickyBlockContainerOffsetTop + stickyBlockContainerRef.current.offsetHeight;
        if (
          stickyBlockContainerOffsetTop - 200 < currentPositionTop &&
          currentPositionTop < stickyBlockContainerOffsetBottom - 700
        )
          setIsViewStickyBlock(true);
        else setIsViewStickyBlock(false);
    
        const offsetTops = [];
        for (let i = items.length - 1; i >= 0; i--) {
          const currentOffset = items[i].itemRef.current.offsetTop;
          // console.log("Brand OffsetTops",items[i].itemRef.current.getBoundingClientRect());
          // offsetTops.push(items[i].itemRef.current.getBoundingClientRect());
          const nextOffset =
            items[i - 1]?.itemRef.current.offsetTop ?? currentOffset;
          if (currentOffset === nextOffset) {
            setActiveItem(i);
            break;
          }
        }
      }
    });
    const element = navtabsRef;
    const actWidth = element.current.offsetWidth;   
    const slider = document.querySelector("#social-proof .slider");
    slider.style.cssText += 'width:'+actWidth+'px';
  });

  const styles = {
    learnMoreSpan: {
      minWidth: "20px",
      display: "inline-block",
      marginLeft: "1rem"
    },
    learnMoreSpanM: {
      display: "inline-block",
      marginLeft: "1rem"
    }
  };
  
  return (
    <>
      <Helmet>
        <title>BintanGO - Creator Powered Marketing</title>
        <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
        <meta property="og:image"              content="/logo-black-300.jpg" />
        <meta property="og:url"                content={window.location.href} />
        <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
        <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
      </Helmet>

      <div className='main-banner-brand'>
        <div className='container-xl'>
          <h3 className='d-flex align-items-center'><FormattedMessage id="forBrand" /> <img width={32} src="/assets/img/crown.svg" className="ml-2" alt="User Image" /></h3>
          <h1 className='mb-5'><FormattedMessage id="forBrand.title1" /><br/>
          <span className="tiger">
          <Typewriter
            options={{
              strings: props.lang=="id"?['Meningkatkan Brand awareness', 'Meningkatkan hubungan Kamu dengan Audiens', 'Meningkatkan penjualan produk/jasa']:['Boost your brand awareness', 'Enhance your engagement & interaction', 'Increase your sales'],
              autoStart: true,
              loop: true,
            }}
          />
          </span>
          <FormattedMessage id="forBrand.title2" /></h1>
          <p className='mb-5 w-60'><FormattedMessage id="forBrand.desc" /></p>
          <div className='row'>
            <div className='col-lg-auto col-md-auto col-sm-auto'>
              <button onClick={()=> scrollNextSection() } type="button" className="btn-login mw-200 h-52"><FormattedMessage id="learnMore" /></button>
            </div>
            <div className='col-lg-auto col-md-auto col-sm-auto order-lg-last order-first mb-4 mb-lg-0 mb-sm-0'>
            <Link to="/contact"><button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="letsDiscuss" /></button></Link>
            </div>
          </div>
        </div>
      </div>   
      <section id="partner" className='container-xl'>
        <div className='d-flex-desktop align-items-center'>
          <div className="title"><FormattedMessage id="trustedByBrands" values={{ number: <span className="number">500</span> }} /></div>
          <div className='carousel'>
            <div className='gradient'></div>
            <OwlCarousel 
              items={5}
              margin={20} 
              autoplay={true} 
              // smartSpeed={1000}
              slideTransition={'linear'} 
              autoplayTimeout={2000} 
              autoplaySpeed={3000} 
              loop={true} 
              // autoplayHoverPause={false}
              autoWidth={true} 
              nav={false} 
              dots={false} > 
            {
                listBrands.map(function(image, index) {
                return (
                    <div className="item" key={index}><img height={64} src={"/assets/img/for-brand/" + image} /></div>
                    );
                })
            }
            </OwlCarousel>
          </div>
        </div>
      </section>  
      <div id='social-proof' className='container-xl container-xl-alt' ref={sectionNavTabs}>
        <ScrollContainer nativeMobileScroll={false}>
          <ul class="nav nav-tabs">
            <div class="slider"></div>
            <li onClick={()=> clickNavTabs(0) } ref={navtabsRef}><a className={navTabs==0?'active':''}>
              <div className='d-flex align-items-center justify-content-center'>
                <img src="/assets/img/icon-brand-creators.svg" alt="User Image" />
                <div className='ml-3'>
                  <div className='title mb-2'>100k++</div>
                  <div className='desc'>Creators</div>
                </div>
              </div>
            </a></li>
            <li onClick={()=> clickNavTabs(1) }><a className={navTabs==1?'active':''}>
              <div className='d-flex align-items-center justify-content-center'>
                <img src="/assets/img/icon-brand-data.svg" alt="User Image" />
                <div className='ml-3'>
                  <div className='title mb-2'>50++</div>
                  <div className='desc'><FormattedMessage id="forBrand.dataPoint" /></div>
                </div>
              </div>
            </a></li>
            <li onClick={()=> clickNavTabs(2) }><a className={navTabs==2?'active':''}>
              <div className='d-flex align-items-center justify-content-center'>
                <img src="/assets/img/icon-brand-campaign.svg" alt="User Image" />
                <div className='ml-3'>
                  <div className='title mb-2'>500++</div>
                  <div className='desc'><FormattedMessage id="forBrand.campaignCompleted" /></div>
                </div>
              </div>
            </a></li>
          </ul>
        </ScrollContainer>
        <div class="tab-content tab-brand">
          {
            navTabs==0?
            <BrandCreators/>:navTabs==1?<BrandData/>:
            <BrandCampaign/>
          }
        </div>
      </div>
      <section id="service">
        <h1 className='title container-xl mb-20'><FormattedMessage id="forBrand.marketingSolutionTitle" values={{ tiger: <span className="tiger"><FormattedMessage id="forBrand.marketingSolutionTitleTiger" /></span> }}  /></h1>
        <p className='title mx-auto'><FormattedMessage id="forBrand.marketingSolutionDesc" /></p>
        <div className='container-service' ref={stickyBlockContainerRef}
        onLoad={() => {
          if (stickyBlockLocations.length === 0) {
            const setStickyBlockLocationsValue = [];
            for (const item of items) {
              setStickyBlockLocationsValue.push(
                item.itemRef.current.getBoundingClientRect().y
              );
            }
            setStickyBlockLocations(setStickyBlockLocationsValue);
          }
        }}>
          <div style={{
            opacity: isViewStickyBlock ? 1 : 0,
          }} className="navigation-services">
            {items.map(({}, index) => {
              return (
                <div key={index} className='bullets'>
                     <img
                      onClick={() => handleGoToStickyBlock(index)}
                      style={{
                        pointerEvents: isViewStickyBlock ? "unset" : "none",
                        cursor: isViewStickyBlock ? "pointer" : "none",
                      }}
                      src={activeItem === index ? '/assets/img/section-active.svg' : '/assets/img/section-inactive.svg'}
                    ></img>
                </div>
              );
            })}
          </div>
          {items.map(({ title, headline, description, itemRef, solution1, solution2, link1, link2, img }, index) => {
            return (
              <div key={index} ref={itemRef} className={index>0?'card-service shadow':'card-service'}>
                <div className='container-xl'>
                  <div className='row d-flex align-items-center'>
                    <div className='col-lg-6'>
                      <h3 className='d-flex align-items-center mb-20'><div className='number-service-orange d-flex align-items-center justify-content-center mr-2'>0{index+1}</div><span><FormattedMessage id={title} /></span></h3>
                      <h1 className='title-service mb-24'><FormattedMessage id={headline} /></h1>
                      <p className='mb-5'><FormattedMessage id={description} /></p>
                      <div className='our-solution'><FormattedMessage id="ourSolution" /></div>
                      <div className="content-our-solution mb-5">
                        <div className='row'>
                          <div className='col-lg-6 border-right'>
                            <div className='d-flex align-items-center mb-3 mx-3'>
                              <img className='mr-4' src="/assets/img/arrow-right-tiger.svg" alt="User Image" />
                              <div className='title'>{solution1}</div>
                            </div>
                            <span style={styles.learnMoreSpan}>&nbsp;</span>
                            <Link to={link1} className='learn-more ml-4'><FormattedMessage id="learnMore" /></Link>
                          </div>
                          <div className='col-lg-6'>
                            <div className='d-flex align-items-center mb-3 mx-3'>
                              <img className='mr-4' src="/assets/img/arrow-right-tiger.svg" alt="User Image" />
                              <div className='title'>{solution2}</div>
                            </div>
                            <span style={styles.learnMoreSpan}>&nbsp;</span>
                            <Link to={link2} className='learn-more ml-4'><FormattedMessage id="learnMore" /></Link>
                          </div>
                        </div>
                      </div>
                      <Link to="/contact"><button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="letsDiscussYourCampaign" /></button></Link>
                    </div>
                    <div className='col-lg-6'>
                      <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" src={img} alt="User Image" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className='container-service-mobile' >
          {items.map(({ title, headline, description, solution1, solution2, link1, link2, img }, index) => {
            return (
              <div key={index} className='card-service'>
                <div className='container-xl'>
                  <div className='row d-flex align-items-center'>
                    <div className='col-lg-6 mb-40'>
                      <h3 className='d-flex align-items-center mb-20'><div className='number-service-orange d-flex align-items-center justify-content-center mr-2'>0{index+1}</div><span><FormattedMessage id={title} /></span></h3>
                      <h1 className='title-service mb-24'><FormattedMessage id={headline} /></h1>
                      <p className='mb-5'><FormattedMessage id={description} /></p>
                      <div className='our-solution'><FormattedMessage id="ourSolution" /></div>
                      <div className="content-our-solution mb-5">
                        <div className='row'>
                          <div className='card-our-solution col-lg-6 col-md-6 col-sm-6 border-right'>
                            <div className='d-flex align-items-center mb-3'>
                              <img className='mr-4' src="/assets/img/arrow-right-tiger.svg" alt="User Image" />
                              <div className='title'>{solution1}</div>
                            </div>
                            <span style={styles.learnMoreSpanM}>&nbsp;</span>
                            <Link to={link1} className='learn-more ml-4'><FormattedMessage id="learnMore" /></Link>
                          </div>
                          <div className='card-our-solution col-lg-6 col-md-6 col-sm-6'>
                            <div className='d-flex align-items-center mb-3'>
                              <img className='mr-4' src="/assets/img/arrow-right-tiger.svg" alt="User Image" />
                              <div className='title'>{solution2}</div>
                            </div>
                            <span style={styles.learnMoreSpanM}>&nbsp;</span>
                            <Link to={link2} className='learn-more ml-4'><FormattedMessage id="learnMore" /></Link>
                          </div>
                        </div>
                      </div>
                      <button type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="letsDiscussYourCampaign" /></button>
                    </div>
                    <div className='col-lg-6 mb-lg-0 mb-5'>
                      <img className="w-100" data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" src={img} alt="User Image" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {/* <section id="testimonial" className='container-xl'>
        <div className='row align-items-center'>
          <div className='col-lg-4'>
            <h3 className='mb-20'><FormattedMessage id="forBrand.testimonialTitle" /></h3>
            <p className='mb-40'><FormattedMessage id="forBrand.testimonialDesc" /></p>
            <div className="owl-carousel owl-carousel owl-theme owl-loaded owl-drag nav-for-desktop">
              <div className="nav-testimonial owl-nav text-left"></div>
            </div>
          </div>
          <div className='col-lg-8'>
            <img className='mb-20 quote' src="/assets/img/quote.svg" alt="User Image" />
            <OwlCarousel 
              items={1}
              loop={true} 
              margin={0} 
              autoplay={true} 
              autoplaySpeed={2000} 
              navSpeed={2000}
              navContainer={'.nav-testimonial'}
              nav={true} 
              dots={false} > 
                <div className='card-carousel'>
                  <div className='ml-lg-5 ml-md-5 ml-0'>
                    <h2 className='mb-40'>We were amazed by the level of customization BintanGO provided. They tailored their strategies to suit our unique challenges, and the outcomes were outstanding.”</h2>
                    <div className='d-flex align-items-center'>
                      <img className='avatar mr-3' src="/assets/img/people/Alifa Thesa.jpg" alt="User Image" />
                      <div>
                        <div className='fullname'>Farel Darari Deksano</div>
                        <div className='position'>Marketing Manager</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-carousel'>
                  <div className='ml-lg-5 ml-md-5 ml-0'>
                    <h2 className='mb-40'>Donec sollicitudin molestie malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h2>
                    <div className='d-flex align-items-center'>
                      <img className='avatar mr-3' src="/assets/img/people/Eugene Laurent.png" alt="User Image" />
                      <div>
                        <div className='fullname'>Safar Septyadi</div>
                        <div className='position'>FrontEnd Developer</div>
                      </div>
                    </div>
                  </div>
                </div>
            </OwlCarousel>
            <div className="owl-carousel owl-carousel owl-theme owl-loaded owl-drag nav-for-mobile">
              <div className="nav-testimonial owl-nav text-left"></div>
            </div>
          </div>
        </div>
      </section> */}
      <section id="banner" >
        <div className='container-xl'>
          <div className='row'>
            <div className='col-lg-12 d-flex justify-content-center'>
              <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="900" className='card-banner text-center'>
                <h1 className='mb-20'><FormattedMessage id="bannerSaleTitle" values={{ tiger: <span className="tiger"><FormattedMessage id="bannerSaleTitleTiger" /></span> }}  /></h1>
                <p className='mb-60'><FormattedMessage id="bannerSaleDesc" /></p>
                <Link to={"/contact"}><button type="button" className="btn-launcher h-48"><FormattedMessage id="discussWithUs" /></button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


export default ForBrand;