/* Languange EN */
var enEngagement = {
    "engagement"                         : "ENGAGE MORE",
    "engagement.title"                   : "Create content that build interaction",
    "engagement.desc"                    : "We'll help you connect with your audience more effectively through our comprehensive campaign management services. Let's discuss your next campaign",

    "engagement.ugcTitle"                : "Create authentic, brand-aligned content with our end-to-endUGC campaign management platform",
    "engagement.ugcDesc"                 : "We'll take care of everything from planning your UGC campaign to measuring its success",

    "engagement.ugcPointTitle1"          : "Creator Networks",
    "engagement.ugcPointDesc1"           : "Create high-quality content from our creators",
    "engagement.ugcPointTitle2"          : "93% Increase sales",
    "engagement.ugcPointDesc2"           : "Consumers Rely on Reviews and Recommendations from Real Individuals Before Purchasing",
    "engagement.ugcPointTitle3"          : "90% More Trusted",
    "engagement.ugcPointDesc3"           : "Authenticity is a Crucial Factor for consumers on Choosing the Brands they like",

    "engagement.ugcStrategyTitle"        : "Our end-to-end UGC campaign Management",
    "engagement.ugcStrategyDesc"         : "Discover how we can help you create a buzz with effective UGC campaigns.",

    "engagement.ugcStrategyPointTitle1"  : "FLEXIBLE SOW",
    "engagement.ugcStrategyPointHeadline1": "Launch flexible UGC campaigns that suit with your strategy",
    "engagement.ugcStrategyPointDesc1"   : "Create engaging UGC campaigns with a variety of formats, from Instagram posts and reels to TikTok videos and YouTube content.",

    "engagement.ugcStrategyPointTitle2"  : "CREATOR SELECTION",
    "engagement.ugcStrategyPointHeadline2": "Choose a creator that suits your campaign",
    "engagement.ugcStrategyPointDesc2"   : "Choose the perfect creators for your campaign and make informed decisions with our data-driven insights.",

    "engagement.ugcStrategyPointTitle3"  : "CONTENT APPROVAL",
    "engagement.ugcStrategyPointHeadline3": "Review and approve high-quality content from your hand-picked creators",
    "engagement.ugcStrategyPointDesc3"   : "Review langsung semua konten  disatu tempat yang nantinya diupload oleh kreator dengan mudah",

    "engagement.ugcStrategyPointTitle4"  : "GET CAMPAIGN REPORT",
    "engagement.ugcStrategyPointHeadline4": "Skip the hassle of asking for screenshots and just share the link. We'll take care of the rest",
    "engagement.ugcStrategyPointDesc4"   : "With our platform, you can easily get the results you need from any link post, without having to ask. Saves your time and work more efficiently.",

    "engagement.ugcBannerTitle"          : "Boost your brand awareness with our marketing platform and creative solutions",
    "engagement.ugcBannerDesc"           : "Make your campaign more engaging, authentic, and cost-effective with UGC with will targeted strategy, Let's discuss your next campaign.",

    "engagement.lcHeadline"             : "LIVE COMMERCE SOLUTION",
    "engagement.lcTitle"                : "Get A real interaction",
    "engagement.lcDesc"                 : "Run Live Commerce with us to build a deeper connection with your audience. We offer an end-to-end solution for Live Commerce",

    "engagement.lcBlogTitle1"           : "100K+",
    "engagement.lcBlogHeadline1"        : "Creators Networks",
    "engagement.lcBlogDesc1"            : "Reach beyond content creations by partnering up with creators & affiliate to boost the brand awareness.",
    "engagement.lcBlogTitle2"           : "Professional Host",
    "engagement.lcBlogDesc2"            : "With our talented and well prepare host, we’re ready to promote & boost you product sales",
    "engagement.lcBlogTitle3"           : "10K Hours+",
    "engagement.lcBlogHeadline3"        : "Live Commerce Capabilities",
    "engagement.lcBlogDesc3"            : "Generate engaging content by interact directly with your potential customers on the live commerce session.",

    "engagement.lcStrategyTitle"        : "Our approach for your Live Commerce",
    "engagement.lcStrategyDesc"         : "Your marketing, your way. Our services blend seamlessly with your goals. Let's craft your success story!",

    "engagement.lcStrategyTitle1"       : "PRODUCT STRATEGY",
    "engagement.lcStrategyHeadline1"    : "In depth analysis of your product",
    "engagement.lcStrategyDesc1"        : "Our expert team will guide your brand step into the world of Live Commerce, We will:",
    "engagement.lcStrategy1Point1"      : "Do product & competitor analysis",
    "engagement.lcStrategy1Point2"      : "Curating products and deciding how they will be promoted",

    "engagement.lcStrategyTitle2"       : "HOST STRATEGY",
    "engagement.lcStrategyHeadline2"    : "Selecting and managing the host for your live commerce",
    "engagement.lcStrategyDesc2"        : "With our impressive 100K+ creators and skilled hosts, we'll find the perfect host to match your brand's personality.",
    "engagement.lcStrategy2Point1"      : "Picking the perfect host for your Live Commerce",
    "engagement.lcStrategy2Point2"      : "Preparing the host with the product knowledge about the brands",

    "engagement.lcStrategyTitle3"       : "LIVE COMMERCE PLANNING",
    "engagement.lcStrategyHeadline3"    : "We’ll prepare all work, from asset to studio",
    "engagement.lcStrategyDesc3"        : "Our BintanGO expert team will take care of all the heavy lifting, leaving you with nothing to fret about. Here's what we'll get ready for you:",
    "engagement.lcStrategy3Point1"      : "Preparing all of your live commerce asset such as motion, background,etc",
    "engagement.lcStrategy3Point2"      : "All the script and all the gimmick inside our live",
    "engagement.lcStrategy3Point3"      : "Preparing how the studio will arrange",

    "engagement.lcStrategyTitle4"       : "OPERATIONAL STRATEGY",
    "engagement.lcStrategyHeadline4"    : "Relax, we've got all the operations and technical stuff covered for you",
    "engagement.lcStrategyDesc4"        : "No worries, our team's got you covered for all live operations, from supporting your hosts to tackling any technical hiccups that might pop up along the way.",
    "engagement.lcStrategy4Point1"      : "Preparing all of your live commerce asset such as motion, background,etc",

    "engagement.lcStrategyTitle5"       : "OPTIMIZE & REPORT",
    "engagement.lcStrategyHeadline5"    : "We'll gather up all the Live results for you",
    "engagement.lcStrategyDesc5"        : "We believe data will guide our next steps. Our expert team will analyze and report directly to you.",
    "engagement.lcStrategy5Point1"      : "Analysis the result and optimize it",
    "engagement.lcStrategy5Point2"      : "We will do weekly report",

    "engagement.lcCheckOut"             : "Check out our live commerce studio",
    "engagement.lcGlanceTitle"          : "Live Commerce at a Glance",
    "engagement.lcGlanceDesc"           : "Live Commerce is an innovative and engaging way for businesses to connect with their audience and sell products in real-time on the commerce platform.",
    "engagement.lcWhyChoose"            : "Sooo.. Why you choose Live Commerce?",
    "engagement.lcWhyChooseTitle1"      : "57%",
    "engagement.lcWhyChooseDesc1"       : "Indonesia consumer engage in live commerce",
    "engagement.lcWhyChooseTitle2"      : "70%",
    "engagement.lcWhyChooseDesc2"       : "Consumers who watch live commerce sessions make a purchase",
    "engagement.lcWhyChooseTitle3"      : "20+ Min",
    "engagement.lcWhyChooseDesc3"       : "AVG users spent time on watching live commerce in a day",

    "engagement.lcWhyNeed"              : "Why you need livecommerce?",
    "engagement.lcWhyNeedPoint1"        : "Increase awareness for your brand",
    "engagement.lcWhyNeedPoint2"        : "Real-time interaction with your brand",
    "engagement.lcWhyNeedPoint3"        : "Generate revenue from livestream",

    "engagement.lcWhyNeedBlockTitle1"   : "GMV are increase 150%",
    "engagement.lcWhyNeedBlockDesc1"    : "For Live Commerce in the period of 2022 to 2023",
    "engagement.lcWhyNeedBlockTitle2"   : "68% Higher Conversion Rate",
    "engagement.lcWhyNeedBlockDesc2"    : "Compare to traditional e-commerce",

    "engagement.lcBannerTitle"          : "Boost your brand awareness with our marketing platform and creative solutions",
    "engagement.lcBannerDesc"           : "Make your campaign more engaging, authentic, and cost-effective with UGC with will targeted strategy, Let's discuss your next campaign.",

};

export default enEngagement;