import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';  

// Main Component
function BrandData() {
  useEffect(() => {
    AOS.init({
      duration : 2000
    })
  }, []);
  
  return (
    <>
      <div id="data-available">
        <h3><FormattedMessage id="forBrand.dataPointTitle" /></h3>
        <h1 className='mb-60'><FormattedMessage id="forBrand.dataPointDesc" /></h1>
        <div id='map'>
          <img className="w-100" data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" src="/assets/img/social-proof-section-2.png" alt="User Image" />
        </div>
      </div>
    </>
  )
}


export default BrandData;