/* Languange TH */
var thForBrand = {
    "forBrand"	: "Bintango สำหรับแบรนด์",
    "forBrand.title1"	: "เราพร้อมช่วยให้คุณเติบโต",
    "forBrand.title2"	: "ด้วยกลยุทธ์ทางการตลาดของเรา",
    "forBrand.typing1"	: "เพิ่มการรับรู้ถึงแบรนด์ของคุณ",
    "forBrand.typing2"	: "ปรับปรุงการมีส่วนร่วมและการมีปฏิสัมพันธ์ของคุณ",
    "forBrand.typing3"	: "เพิ่มยอดขายของคุณ",
    "forBrand.desc"	: "เราจะช่วยให้คุณบรรลุเป้าหมายของแบรนด์ด้วยกลยุทธ์การตลาดที่ครอบคลุมของเรา",

    "forBrand.dataPoint"	: "จุดข้อมูลสำหรับคุณ",
    "forBrand.dataPointTitle"	: "ได้รับการสนับสนุนโดยข้อมูล",
    "forBrand.dataPointDesc"	: "การตัดสินใจเกี่ยวกับแคมเปญที่ขับเคลื่อนด้วยเทคโนโลยี",
    "forBrand.campaignCompleted"	: "แคมเปญเสร็จสมบูรณ์",
    "forBrand.campaignCompletedTitle"	: "แคมเปญเสร็จสมบูรณ์!",
    "forBrand.campaignCompletedDesc"	: "บรรลุผลสำเร็จของแบรนด์ชั้นนำ",

    "forBrand.marketingSolutionTitle"	: "{tiger} แบบครบวงจรสำหรับแบรนด์ของคุณ",
    "forBrand.marketingSolutionTitleTiger"	: "โซลูชั่นทางการตลาด",
    "forBrand.marketingSolutionDesc"	: "การตลาดของคุณ ความเชี่ยวชาญของเรา เราจะร่วมกันสร้างเรื่องราวความสำเร็จของคุณ",

    "forBrand.awarenessTitle"	: "รับการรับรู้มากขึ้น",
    "forBrand.awarenessHeadline"	: "เพิ่มการรับรู้ถึงแบรนด์ของคุณและเข้าถึงลูกค้าในอุดมคติของคุณ",
    "forBrand.awarenessDesc"	: "โซลูชันการรับรู้ถึงแบรนด์ของเราช่วยส่งเสริมแบรนด์ของคุณ โดยแนะนำให้รู้จักกับผู้ชมในวงกว้างขึ้น และช่วยให้คุณค้นหาลูกค้าที่ใส่ใจในสิ่งที่คุณนำเสนออย่างแท้จริง",

    "forBrand.engageTitle"	: "เพิ่มการมีส่วนร่วมของคุณ",
    "forBrand.engageHeadline"	: "ดึงดูดผู้ชมและสร้างความประทับใจไม่รู้ลืม",
    "forBrand.engageDesc"	: "สร้างเนื้อหาที่น่าสนใจ สร้างความสัมพันธ์กับผู้ชมของคุณ และสร้างความประทับใจไม่รู้ลืมด้วยบริการการมีส่วนร่วมกับแบรนด์ของเรา ติดต่อเราวันนี้และเรียนรู้ว่าเราสามารถช่วยคุณเพิ่มการมีส่วนร่วมกับแบรนด์ของคุณได้อย่างไร",

    "forBrand.launchTitle"	: "เปิดตัวผลิตภัณฑ์",
    "forBrand.launchHeadline"	: "เปิดตัวผลิตภัณฑ์ของคุณด้วยความมั่นใจและเข้าถึงเป้าหมายที่เหมาะสมด้วยโซลูชันที่สร้างสรรค์ของเรา",
    "forBrand.launchDesc"	: "โซลูชันการเปิดตัวผลิตภัณฑ์ที่สร้างสรรค์ของเราจะช่วยให้คุณเข้าถึงกลุ่มเป้าหมายที่เหมาะสมและสร้างความประทับใจไม่รู้ลืม พร้อมที่จะเปิดตัวผลิตภัณฑ์ของคุณอย่างปังแล้วหรือยัง? ติดต่อเราและพูดคุยเกี่ยวกับผลิตภัณฑ์ใหม่ของคุณ",

    "forBrand.salesTitle"	: "เพิ่มยอดขายของคุณ",
    "forBrand.salesHeadline"	: "เพิ่มยอดขายให้กับแบรนด์ของคุณด้วยโซลูชั่นการตลาดที่สร้างสรรค์ของเรา",
    "forBrand.salesDesc"	: "ดำเนินแคมเปญการตลาดที่เพิ่มยอดขายแบรนด์ของคุณกับเรา เรานำเสนอโซลูชั่นที่ดีที่สุดสำหรับแบรนด์ของคุณจากทีมผู้เชี่ยวชาญของเรา",

    "forBrand.testimonialTitle"	: "ลูกค้าพูดถึงเรา",
    "forBrand.testimonialDesc"	: "มาฟังเรื่องราวความสำเร็จของลูกค้าของเรากันดีกว่า",

    "forBrand.ccSuccessCollab"	: "ประสบความสำเร็จในการร่วมงานกับแบรนด์ต่างๆ",
};

export default thForBrand;