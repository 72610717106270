/* Languange EN */
var idProductLaunch = {
    "productLaunch"                         : "PRODUCT LAUNCH",
    "productLaunch.title"                   : "You Build, We Market it. launch your product with the right strategy",
    "productLaunch.desc"                    : "Our team of experts will help you launch your product and make a great impression.",
    "productLaunch.launchMyProduct"         : "Launch my Product",

    "productLaunch.360degree"               : "Launch your product with a {tiger} that will dominate your market.",
    "productLaunch.360degreeTitleTiger1"    : "360-degree marketing campaign",
    "productLaunch.360degreeDesc"           : "Our comprehensive marketing strategy helps you plan, execute, and measure your product launch campaign across all channels, reaching your target audience and ensuring success.",

    "productLaunch.increaseTitle"           : "27% increase brand loyalty",
    "productLaunch.increaseDesc"            : "Companies that run comprehensive campaigns get more loyal customers.",
    "productLaunch.boostTitle"              : "89% Boost your ROI",
    "productLaunch.boostDesc"               : "Consumers choose the brands they like because they're authentic.",

    "productLaunch.dontUse"                 : "What could happen if you don't use a 360-degree marketing campaign?",
    "productLaunch.dontUseTitle1"           : "Missed opportunities",
    "productLaunch.dontUseDesc1"            : "Don't skip a 360-degree marketing campaign for your new product! It could get overshadowed and fail to make the impact it deserves.",
    "productLaunch.dontUseTitle2"           : "Disjointed messaging",
    "productLaunch.dontUseDesc2"            : "A 360-degree marketing campaign ensures that your message is the same across all channels, helping potential customers understand your product's unique value proposition.",
    "productLaunch.dontUseTitle3"           : "Limited reach and engagement",
    "productLaunch.dontUseDesc3"            : "Reach more people and boost engagement with a 360-degree marketing campaign that targets multiple channels.",

    "productLaunch.strategyTitle"           : "Our Process for your Successful New Product Launch",
    "productLaunch.strategyDesc"            : "We have a proven process for taking your product from concept to launch, and beyond. Here's a step-by-step overview:",

    "productLaunch.planningStrategizing"        : "PLANNING & STRATEGIZING",
    "productLaunch.planningStrategizingTitle"   : "Set the stage for success",
    "productLaunch.planningStrategizingDesc"    : "Let's start by defining your goals, identifying your target audience, and developing a messaging strategy that will make your product stand out from the crowd.",

    "productLaunch.preLaunchActivity"     : "PRE-LAUNCH ACTIVITY",
    "productLaunch.preLaunchActivityTitle": "Generate buzz and anticipation",
    "productLaunch.preLaunchActivityDesc" : "Before you launch your product, let's start building buzz and excitement. We'll create sneak peeks and teasers, run social media contests and giveaways, and partner with influencers and other experts to get the word out.",

    "productLaunch.amplification"           : "AMPLIFICATION & SUSTAIN",
    "productLaunch.amplificationTitle"  : "Launch your product with a bang and keep the momentum going.",
    "productLaunch.amplificationDesc"   : "It's launch day! We'll send launch emails, run paid ads, and promote your product on social media to get the word out to your target audience. And we'll keep the buzz going with fresh content and engagement.",

    "productLaunch.tracking"            : "TRACKING & POST-CAMPAIGN ANALYSIS",
    "productLaunch.trackingTitle"       : "Track your results and learn for the future.",
    "productLaunch.trackingDesc"        : "We'll track your progress throughout the campaign and analyze your results after it's over to learn and improve for your next product launch.",

    "productLaunch.activation"               : "Create a {tiger} for your customers.",
    "productLaunch.activationTitleTiger1"    : "memorable experience",
    "productLaunch.activationDesc"           : "Let's launch your product in a creative way that will leave a lasting impression on your customers.",

    "productLaunch.activationImportant"          : "Why is brand activation important?",
    "productLaunch.activationImportantTitle1"           : "Build Brand Awareness",
    "productLaunch.activationImportantDesc1"            : "With a winning strategy, you can create impressive brand awareness.",
    "productLaunch.activationImportantTitle2"           : "Engage directly to your customer",
    "productLaunch.activationImportantDesc2"            : "Brand activation lets your customers interact with your brand directly and try it out right away, experiencing things they've never felt before.",
    "productLaunch.activationImportantTitle3"           : "Create a memorable experience",
    "productLaunch.activationImportantDesc3"            : "Brand activation is a blast! With so many exciting activities happening, your customers won't be able to forget about your brand.",

    "productLaunch.strategyActivationTitle"           : "Our step-by-step process for starting your brand activation",
    "productLaunch.strategyActivationDesc"            : "We ensure we cover all your new product launch needs.",

    "productLaunch.gatheringInformation"        : "GATHERING INFORMATION",
    "productLaunch.gatheringInformationTitle"   : "Get the detail of your brands",
    "productLaunch.gatheringInformationDesc"    : "We'll collect information like your goals and needs to determine the best brand activation strategy for you.",

    "productLaunch.ideation"     : "IDEATION",
    "productLaunch.ideationTitle": "Creative solutions that make you memorable to your customers.",
    "productLaunch.ideationDesc" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus efficitur odio et purus tempus tincidunt. Donec et nisi at mi eleifend ullamcorper.",

    "productLaunch.execute"       : "EXECUTE",
    "productLaunch.executeTitle"  : "Our expert team will manage the entire end-to-end process for you.",
    "productLaunch.executeDesc"   : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus efficitur odio et purus tempus tincidunt. Donec et nisi at mi eleifend ullamcorper.",
};

export default idProductLaunch;