/* Languange EN */
var enTechnologyCapabilities = {
    "technology.title"                   : "Our Versatile Technology Meets Your Specific Needs",
    "technology.desc"                    : "We help streamlines your processes, eliminating inefficiencies, and ensuring a consistent revenue stream",
    "technology.integrationTitle"        : "Our Technology Integration",
    "technology.integrationDesc"         : "Our technology integrates with social media and top e-commerce platforms, so you can run seamless campaigns that drive results",
    "technology.sosmedTitle"             : "SOCIAL MEDIA INTEGRATION",
    "technology.sosmedHeadline"          : "Data Driven Decision with our credible data",
    "technology.sosmedDesc"              : "We take the guesswork out of influencer marketing. Our platform integrates with top social media platforms and uses data to help you find the perfect influencers for your brand and run successful campaigns.",
    "technology.ecommerceTitle"          : "E-COMMERCE INTEGRATION",
    "technology.ecommerceHeadline"       : "Manage your influencer product within platform",
    "technology.ecommerceDesc"           : "Manage your campaigns, track clicks and sales with ease. Our influencer marketing platform integrates with top e-commerce platforms to help you achieve your marketing goals.",
    "technology.canDoTitle"              : "See what BintanGO can do",
    "technology.canDoDesc"               : "We use technology to power your marketing campaigns, so you can get the results you need. Check out our capabilities",
    "technology.creatorAnalysis"         : "Creator Analysis",
    "technology.creatorAnalysisTitle"    : "Find the right influencers Our tech analyzes influencers",
    "technology.creatorAnalysisDesc"     : "We use credible data to select the right influencers for your brand campaigns. Our technology analyzes influencers across a variety of metrics, including audience demographics, engagement rates, and brand alignment, to help you ensure that you are working with the right creators to achieve your marketing goals",
    "technology.creatorDiscovery"         : "Creator Discovery",
    "technology.creatorDiscoveryTitle"    : "Discover Top influencers using Advanced filters & credible data",
    "technology.creatorDiscoveryDesc"     : "Search through over 50,000+ influencers using a variety of criteria, including audience demographics, engagement rates, and brand alignment. This makes it easy to identify the creators who are most likely to resonate with your target audience and drive results.",
    "technology.creatorShortlisting"      : "Creator Shortlisting",
    "technology.creatorShortlistingTitle" : "Curated your creator that match with you campaign needs",
    "technology.creatorShortlistingDesc"  : "Choose the creators that best fit your campaign, backed by credible data. Approve or reject them with ease.",
    "technology.reporting"                : "Real-Time Reporting",
    "technology.reportingTitle"           : "Get Instant Report for your campaign",
    "technology.reportingDesc"            : "You no longer need to collect screenshots. Get immediate insights into your creator's performance with our all-in-one dashboard.",
    "technology.brandedContentAds"        : "Branded Content Ads",
    "technology.brandedContentAdsTitle"   : "Boost your creator work with ads to get more reach, traffic, and engagement.",
    "technology.brandedContentAdsDesc"    : "Our ad platform makes it easy to create and manage effective ad campaigns for your creator work. With a variety of targeting options and budgeting tools, you can create campaigns that reach the right people and get the results you want.",
    "technology.weOfferTitle"             : "We offer flexibility based on your tech needs",
    "technology.weOfferDesc"              : "We can customize based on your needs. Check out our process from knowing what you need to launching it.",
    "technology.weOfferTitle1"           : "Assesment",
    "technology.weOfferDesc1"            : "We will conduct multiple interviews with various key stakeholder in the company to facilitate the adoption of our service",
    "technology.weOfferTitle2"           : "Design",
    "technology.weOfferDesc2"            : "We will design the dashboard in accordance with the problem statement",
    "technology.weOfferTitle3"           : "Deployment",
    "technology.weOfferDesc3"            : "Conduct product & business User Acceptance Testing (UAT) to ensure the resolution of key stakeholder issues",
    "technology.weOfferTitle4"           : "Team Training",
    "technology.weOfferDesc4"            : "We will assist team onboarding,ensuring the client team’s successful adoption of the thechnology for maximum utilization and scalability",
    "technology.weOfferTitle5"           : "Monitoring",
    "technology.weOfferDesc5"            : "We will monitor operational performance and identify opportunities for enhancing technology and pursuing new growth prospects",
    "technology.weOfferTitle6"           : "Evaluate Performance of the Affiliate Marketing monthly",
    "technology.weOfferDesc6"            : "Evaluate the performance of the campaign and improve if needed",
    "technology.bannerTitle"  : "Do you want to know more about our capabilities?",
    "technology.bannerDesc"   : "Let us help you reach your marketing goals with our technology capabilities, from discovery to reporting. We've got you covered. Let's discuss your next campaign!",
};
export default enTechnologyCapabilities;