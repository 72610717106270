/* Languange EN */
var idSales = {
    "sales"                         : "GROWTH SALES",
    "sales.title"                   : "Our creative solutions will help you grow your brand's sales",
    "sales.desc"                    : "Looking for a way to boost your brand sales? Look no further than our platform, which connects you with 80,000 high-quality creators who can help you reach your target audience and achieve your marketing goals.",
    "sales.boost"                   : "Boost my sales",

    "sales.affiliateTitle"                : "Elevate Brand Sales with Efficient Strategies for a {tiger}",
    "sales.affiliateTitleTiger"           : "Significant Impact",
    "sales.affiliateDesc"                 : "We're here to make your affiliate marketing campaigns a success, from link and product distribution to detailed reporting.",

    "sales.affiliatePointTitle1"          : "Creator Networks",
    "sales.affiliatePointDesc1"           : "Reach beyond content creations by partnering up with creators & affiliate to boost the brand awareness.",
    "sales.affiliatePointTitle2"          : "80% boost your conversion",
    "sales.affiliatePointDesc2"           : "Ran an affiliate marketing program to improve website traffic and increase sales.",
    "sales.affiliatePointTitle3"          : "82% more trusted",
    "sales.affiliatePointDesc3"           : "Consumers are more inclined to buy products that influencers promote",

    "sales.ourWorkflow"                   : "OUR WORKFLOW",
    "sales.ourWorkflowTitle"              : "How BintanGO Team help your brand succeed in affiliate marketing?",
    "sales.ourWorkflowDesc"               : "Partner with us to transform your brand's affiliate marketing into a resounding success. Our expertise in influencer collaborations, data-driven strategies, and personalized campaigns will propel your affiliate marketing efforts to new heights. We'll work closely with you to understand your brand's specific goals and craft a tailored approach that maximizes your affiliate marketing potential.",
    "sales.ourWorkflowPointTitle1"        : "Strategize the placement & comission",
    "sales.ourWorkflowPointDesc1"         : "Before starting, we will create an effective strategy for running your affiliate marketing",
    "sales.ourWorkflowPointTitle2"        : "Creator shortlisting & approval",
    "sales.ourWorkflowPointDesc2"         : "Select creators based on data, Approve and creators are ready to work",
    "sales.ourWorkflowPointTitle3"        : "Send good and distribute to creator",
    "sales.ourWorkflowPointDesc3"         : "We'll take care of distributing your product to creators, no need to worry",
    "sales.ourWorkflowPointTitle4"        : "Briefing creator for the work",
    "sales.ourWorkflowPointDesc4"         : "With our technology and platform, you won't have to worry about giving briefs to many creators, we'll help you spread briefs easily",
    "sales.ourWorkflowPointTitle5"        : "Get report with our real time performance reporting",
    "sales.ourWorkflowPointDesc5"         : "Track affiliate marketing performance easily with detailed analytics",
    "sales.ourWorkflowPointTitle6"        : "Evaluate performance of the affiliate marketing monthly",
    "sales.ourWorkflowPointDesc6"         : "Your marketing campaign will be evaluated by our team of experts, and we will offer suggestions on what actions to take next",

    "sales.affiliateStrategyTitle"        : "Get the right team to run your {tiger}",
    "sales.affiliateStrategyTitleTiger"   : "affiliate marketing",
    "sales.affiliateStrategyDesc"         : "We’re here to help you to enhance your sales with our Affiliate Marketing campaign, distribute your product and reach more than 50M+ Audience.",

    "sales.affiliateStrategyPointTitle1"  : "AFFILIATE CAMPAIGN MANAGEMENT",
    "sales.affiliateStrategyPointHeadline1": "Distribute affiliate campaign easily",
    "sales.affiliateStrategyPointDesc1"   : "Distribute your links through our apps and get reach more than 100K creator++ that ready to promote your product",

    "sales.affiliateStrategyPointTitle2"  : "CREATOR CURATION",
    "sales.affiliateStrategyPointHeadline2": "Align your target audience's product with the influencer's audience",
    "sales.affiliateStrategyPointDesc2"   : "Empower your marketing with data-driven creator selection to reach your target audience effectively.",

    "sales.affiliateStrategyPointTitle3"  : "TOP E-COMMERCE INTEGRATION",
    "sales.affiliateStrategyPointHeadline3": "We cover your top sales channel E-Commerce",
    "sales.affiliateStrategyPointDesc3"   : "By partnering with leading e-commerce platforms, we expand the reach of your affiliate marketing campaigns, connecting you with a wider audience.",

    "sales.affiliateStrategyPointTitle4"  : "TRACKING & REPORTING",
    "sales.affiliateStrategyPointHeadline4": "Easily track and get the result from your affiliate marketing",
    "sales.affiliateStrategyPointDesc4"   : "Receive a comprehensive report on your affiliate marketing efforts along with valuable insights from our expert team.",

    "sales.affiliateBannerTitle"          : "Enhance your sales with affiliate marketing campaign",
    "sales.affiliateBannerDesc"           : "Ready to amplify your business reach with an effective affiliate marketing campaign? Let's connect and discuss how we can help you achieve your goals.",
};

export default idSales;