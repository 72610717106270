import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class Footer extends Component {
    render() {
        return (
            <>
            <a href="https://api.whatsapp.com/send?phone=6281280777870&text=Halo%2C%20saya%20lihat%20di%20website%20dan%20tertarik%20untuk%20tau%20lebih%20detail%20mengenai%20service%20BintanGO" className="whatsapp-float d-flex align-items-center justify-content-center" target="_blank">
                <img className="logo" src="/assets/img/whatsapp-logo.svg" alt="Logo" />
            </a>
            <footer>
                <div className="container-xl">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-4">
                            <img className="logo" src="/assets/img/logo.svg" alt="Logo" />
                            <p className="desc my-4"><FormattedMessage id="footer.desc" /></p>
                            <div className="d-flex align-items-center">
                                <img className="logo mr-lg-3 mr-md-3 mr-sm-3 mr-2" src="/assets/img/email-black.svg" alt="Logo" />
                                <span>info@bintango.id</span>
                                <div className="separator"></div>
                                <img className="logo mr-lg-3 mr-md-3 mr-sm-3 mr-2" src="/assets/img/whatsapp-black.svg" alt="Logo" />
                                <span>+6281280777870</span>
                            </div>
                        </div>
                        <div className="col-lg-2 d-flex justify-content-end">
                            <div className="partner">
                                <span className="title">Official Partner of</span>
                                <img className="mt-3 d-block mx-auto" src="/assets/img/tiktok-shop-partner.svg" alt="Logo" />
                            </div>
                        </div>
                    </div>
                    <hr className="my-40" />
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="title-menu mb-24"><FormattedMessage id="navbar.bintangoFor" /></div>
                            <div className="mb-24"><Link to="/brand"><span><FormattedMessage id="navbar.forBrand" /></span></Link></div>
                            <div className="mb-24"><Link to="/agency"><span><FormattedMessage id="navbar.forAgency" /></span></Link></div>
                            <div className="mb-24"><Link to="/creator"><span><FormattedMessage id="navbar.forCreator" /></span></Link></div>
                        </div>
                        <div className="col-lg-3">
                            <div className="title-menu mb-24"><FormattedMessage id="navbar.solution" /></div>
                            <div className="mb-24"><Link to="/solution/brand-awareness"><span><FormattedMessage id="navbar.brandAwareness" /></span></Link></div>
                            <div className="mb-24"><Link to="/solution/growth-brand-sales"><span><FormattedMessage id="navbar.sales" /></span></Link></div>
                            <div className="mb-24"><Link to="/solution/engagement-to-audience"><span><FormattedMessage id="navbar.engagement" /></span></Link></div>
                            <div className="mb-24"><Link to="/solution/new-product-launch"><span><FormattedMessage id="navbar.product" /></span></Link></div>
                        </div>
                        <div className="col-lg-3">
                            <div className="title-menu mb-24"><FormattedMessage id="navbar.resource" /></div>
                            <div className="mb-24"><Link to="/blog"><span><FormattedMessage id="navbar.blog" /></span></Link></div>
                            {/* <div className="mb-24"><Link to="/"><span><FormattedMessage id="navbar.caseStudy" /> <img className="ml-2" src="/assets/img/link.svg" alt="Logo" /></span></Link></div> */}
                            <div className="mb-24"><Link to="/"><span><FormattedMessage id="navbar.helpCenter" /></span></Link></div>
                        </div>
                        <div className="col-lg-3">
                            <div className="title-menu mb-24"><FormattedMessage id="navbar.company" /></div>
                            <div className="mb-24"><Link to="/about-us"><span><FormattedMessage id="navbar.about" /></span></Link></div>
                            <div className="mb-24"><Link to="/contact"><span><FormattedMessage id="navbar.contact" /></span></Link></div>
                            <div className="mb-24"><Link to="/career"><span><FormattedMessage id="navbar.career" /></span></Link></div>
                        </div>
                    </div>
                    <div className="row justify-content-between align-items-center mt-4">
                        <div className="col-lg-3 text-lg-left text-center order-lg-first order-last mt-lg-0 mt-4">
                            <span className="copyright">© Crafted by BintanGO 2023</span>
                        </div>
                        <div className="col-lg-3 text-lg-right text-center">
                            <a className="mr-3" href="https://www.instagram.com/bintango.id/" target="_blank"><img src="/assets/img/instagram.svg" alt="Logo" /></a>
                            <a href="https://www.tiktok.com/@bintango.id" target="_blank"><img src="/assets/img/tiktok.svg" alt="Logo" /></a>
                        </div>
                    </div>
                </div>
            </footer>
            <ToastContainer />
            </>
        );  
    }
}