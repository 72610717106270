/* Languange EN */
var enForBrand = {
    "forBrand"                      : "BINTANGO FOR BRAND",
    "forBrand.title1"               : "We’re here to help you to",
    "forBrand.title2"               : "with our marketing strategy",
    "forBrand.typing1"              : "Boost your brand awareness",
    "forBrand.typing2"              : "Enhance your engagement & interaction",
    "forBrand.typing3"              : "Increase your sales",
    "forBrand.desc"                 : "We'll help you achieve your brand goals with our comprehensive marketing strategies.",

    "forBrand.dataPoint"            : "Data point for you",
    "forBrand.dataPointTitle"       : "BACKED BY DATA",
    "forBrand.dataPointDesc"        : "Tech-powered campaign decisions",
    "forBrand.campaignCompleted"    : "Campaign Completed",
    "forBrand.campaignCompletedTitle" : "CAMPAIGNS COMPLETED!",
    "forBrand.campaignCompletedDesc": "Achieve top brand results",

    "forBrand.marketingSolutionTitle": "One-stop {tiger} for your brand",
    "forBrand.marketingSolutionTitleTiger": "marketing solutions",
    "forBrand.marketingSolutionDesc": "Your marketing, our expertise. Together, we'll craft your success story",
    
    "forBrand.awarenessTitle"       : "GET MORE AWARENESS",
    "forBrand.awarenessHeadline"    : "Increase your brand awareness and reach your ideal customers.",
    "forBrand.awarenessDesc"        : "Our Brand Awareness solutions give your brand a boost, introducing it to a wider audience and helping you find customers who truly care about what you offer",

    "forBrand.engageTitle"          : "BOOOST YOUR ENGAGEMENT",
    "forBrand.engageHeadline"       : "Engage your audience and leave a lasting impression.",
    "forBrand.engageDesc"           : "Create engaging content, build relationships with your audience, and leave a lasting impression with our brand engagement services. Contact us today and learn how we can help you boost your brand engagement.",

    "forBrand.launchTitle"          : "PRODUCT LAUNCH",
    "forBrand.launchHeadline"       : "Launch your product with confidence and reach the right target with our creative solutions.",
    "forBrand.launchDesc"           : "Our creative product launch solutions will help you reach the right target audience and make a lasting impression. Ready to launch your product with a bang? Contact us and let's talk about your new product",

    "forBrand.salesTitle"           : "INCREASE YOUR SALE",
    "forBrand.salesHeadline"        : "Boost your brand sales growth with our creative marketing solutions.",
    "forBrand.salesDesc"            : "Run marketing campaigns that boost your brand sales with us. We offer the best solutions for your brand from our team of experts.",

    "forBrand.testimonialTitle"     : "Hear what our clients have to say",
    "forBrand.testimonialDesc"      : "Let's hear our clients' success stories.",

    "forBrand.ccSuccessCollab"      : "Successfully collaborating with various brands",
};

export default enForBrand;