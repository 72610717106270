import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function AwarenessBCA() {
  const sectionStrategy = useRef(null);

  const scrollNextSection = () => {
    sectionStrategy.current?.scrollIntoView({behavior: 'smooth',block: 'start'});
  };

  useEffect(() => {
    AOS.init({
      duration : 2000
    })
  }, []);
  
  return (
    <>
      <div id="live-commerce" class="mt-4">
        <div className='container-xl mb-170'>
          <div className='text-center row justify-content-center'>
            <div className='col-lg-12'>
              <div className='title-live-commerce'>
                <h3 className='mr-3'>BRANDED CONTENT ADS</h3>
              </div>
              <h1 className='mb-40'><FormattedMessage id="awareness.bcaTitle" values={{ tiger1: <span className="tiger"><FormattedMessage id="awareness.bcaTitleTiger1" /></span>, tiger2: <span className="tiger"><FormattedMessage id="awareness.bcaTitleTiger2" /></span>}} /></h1>
              <p className='mb-60'><FormattedMessage id="awareness.bcaDesc" /></p>
              <div className='mx-auto mb-60'>
                <button onClick={()=> scrollNextSection() } type="button" className="btn-login mw-200 h-52 mx-lg-4 mx-md-4 mx-sm-4 mx-0 mb-lg-0 mb-4"><FormattedMessage id="lookHowWeWork" /></button>
                <Link to="/contact"><button type="button" className="btn-launcher mw-200 h-52 mx-lg-4 mx-md-4 mx-sm-4 mx-0"><FormattedMessage id="letsDiscussAdsCampaign" /></button></Link>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <div className='stats-branded-content-ads'>
                <div className='row'>
                  <div className='col-lg-4 mb-lg-0 mb-5 text-center'>
                    <div className='value mb-2'>19%</div>
                    <span className='desc'>Reduction on CPA’s</span>
                  </div>
                  <div className='col-lg-4 mb-lg-0 mb-5 text-center'>
                    <div className='value mb-2'>53%</div>
                    <span className='desc'>Higher Click-Throuh Rate</span>
                  </div>
                  <div className='col-lg-4 text-center'>
                    <div className='value mb-2'>99%</div>
                    <span className='desc'>Probability to outperform BAU ads only</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section id="strategy-live-commerce" ref={sectionStrategy}>
            <div className='text-center mb-5'>
              <h1 className='title-section mb-4'><FormattedMessage id="awareness.strategyTitle" /></h1>
              <p className='desc-section'><FormattedMessage id="awareness.strategyDesc" /></p>
            </div>
            <div className='row my-100 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="awareness.campaignCreation" /></h3>
                <h1 className='mb-4'><FormattedMessage id="awareness.campaignCreationTitle" /></h1>
                <p className='mb-40'><FormattedMessage id="awareness.campaignCreationDesc" /></p>
              </div>
              <div className='col-lg-6'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100 br-16' src="/assets/img/awareness-bca-1.png" />
              </div>
            </div>
            <div className='row my-100 align-items-center'>
              <div className='col-lg-6'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100 br-16' src="/assets/img/awareness-bca-2.png" />
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='content-live-commerce col-lg-6 order-lg-last order-first'>
                <h3><FormattedMessage id="awareness.automatedInvitation" /></h3>
                <h1 className='mb-4'><FormattedMessage id="awareness.automatedInvitationTitle" /></h1>
                <p className='mb-40'><FormattedMessage id="awareness.automatedInvitationDesc" /></p>
              </div>
            </div>
            <div className='row my-100 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="awareness.contentManagement" /></h3>
                <h1 className='mb-4'><FormattedMessage id="awareness.contentManagementTitle" /></h1>
                <p className='mb-40'><FormattedMessage id="awareness.contentManagementDesc" /></p>
              </div>
              <div className='col-lg-6'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100 br-16' src="/assets/img/awareness-bca-3.png" />
              </div>
            </div>
            <div className='row my-100 align-items-center'>
              <div className='col-lg-6'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100 br-16' src="/assets/img/awareness-bca-4.png" />
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='content-live-commerce col-lg-6 order-lg-last order-first'>
                <h3><FormattedMessage id="awareness.boostContent" /></h3>
                <h1 className='mb-4'><FormattedMessage id="awareness.boostContentTitle" /></h1>
                <p className='mb-40'><FormattedMessage id="awareness.boostContentDesc" /></p>
              </div>
            </div>
          </section>
        </div>
        <section id="banner">
          <div className='container-xl'>
            <div className='row'>
              <div className='col-lg-12 d-flex justify-content-center'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-banner text-center'>
                  <h1 className='mb-20'><FormattedMessage id="bannerAwarenessTitle" /></h1>
                  <p className='mb-60'><FormattedMessage id="bannerAwarenessDesc" /></p>
                  <Link to={"/contact"}><button type="button" className="btn-launcher h-48"><FormattedMessage id="discussWithOurTeam" /></button></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}


export default AwarenessBCA;