/* Languange EN */
var enInquiryForm = {
    "form.titleFirst" : "Ignite your brand influence",
    "form.titleSecond" : "Connect with top creators that can ",
    "form.titleSecondTiger1" : "amplify exposure",
    "form.and" : "and",
    "form.titleSecondTiger2" : "boost engagement",
    "form.titleDesc"  : "Contact us now and unleash your brand’s true potential through Creator Marketing!",
    "form.picInformation" : "PIC Information",
    "form.businessInformation" : "Business Information",
    "form.yourName" : "Your Name*",
    "form.yourEmail" : "Your Email*",
    "form.yourPhone" : "Phone Number*",
    "form.yourCompany" : "Your Company*",
    "form.yourLink" : "Company Website or social media Link*",
    "form.instagramUsername" : "Instagram Username*",
    "form.tiktokUsername" : "TikTok Username",
    "form.yourNeed" : "What do you need from BintanGO?",
    "form.creatorMarketing" : "Creator Marketing",
    "form.creatorMarketingDesc" : "We'll help you to run a campaigns and collaborate with creators and increase your awareness, engagement or achieve your marketing goals",
    "form.liveCommerce" : "Live Commerce",
    "form.liveCommerceDesc" : "We'll help you to run live commerce to boost your sales or interact directly with your audience",
    "form.currency" : "Currency",
    "form.yourBudget" : "Budget Estimation*",
    "form.yourMessage" : "Your Message (Opsional)",
    "form.next" : "NEXT",
    "form.back" : "BACK",
    "form.send" : "SEND",
    "form.sending" : "SENDING",
};
export default enInquiryForm;
