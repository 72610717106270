import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function SalesAffiliate() {
  const sectionStrategy = useRef(null);

  const scrollNextSection = () => {
    sectionStrategy.current?.scrollIntoView({behavior: 'smooth',block: 'start'});
  };

  useEffect(() => {
    AOS.init({
      duration : 2000
    })
  }, []);
  
  return (
    <>
      <div id="live-commerce">
        <div className='container-xl mb-80'>
          <div className='title-live-commerce text-center'>
            <h3 className='mr-3'>AFFILIATE MARKETING SOLUTION</h3>
            <div className="row justify-content-center">
              <div className='col-lg-10'>
                <h1 className='mb-40'><FormattedMessage id="sales.affiliateTitle" values={{ tiger: <span className="tiger"><FormattedMessage id="sales.affiliateTitleTiger" /></span>}} /></h1>
              </div>
            </div>
          </div>
          <div className='text-center row justify-content-center'>
            <div className='col-lg-7'>
              <p className='mb-60 mx-auto'><FormattedMessage id="sales.affiliateDesc" /></p>
              <div className='mx-auto mb-60'>
                <button onClick={()=> scrollNextSection() } type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="lookHowWeWork" /></button>
              </div>
            </div>
          </div>
          <section id="partner">
            <div className='d-flex-desktop align-items-center'>
              <div className="title"><FormattedMessage id="trustedByBrands" values={{ number: <span className="number">500</span> }} /></div>
              <div className='carousel'>
                <div className='gradient'></div>
                <OwlCarousel 
                  items={5}
                  loop={true} 
                  margin={20} 
                  autoplay={true} 
                  // smartSpeed={1000}
                  slideTransition={'linear'} 
                  autoplayTimeout={2000} 
                  autoplaySpeed={3000} 
                  // autoplayHoverPause={false}
                  autoWidth={true} 
                  nav={false} 
                  dots={false} > 
                {
                    listBrands.map(function(image, index) {
                    return (
                        <div className="item" key={index}><img height={64} src={"/assets/img/for-brand/" + image} /></div>
                        );
                    })
                }
                </OwlCarousel>
              </div>
            </div>
          </section>  
          <div className='row'>
            <div className='col-lg-4 mb-lg-0 mb-5'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-live-commerce'>
                <div className='d-flex align-items-center mb-4'>
                  <img width={75} className='mr-3' src="/assets/img/creatorNetwork-min.png" alt="User Image" />
                  <div className="d-block">
                    <div className='title mb-2'>100K+</div>
                    <span className='grey'><FormattedMessage id="sales.affiliatePointTitle1" /></span>
                  </div>
                </div>
                <p><FormattedMessage id="sales.affiliatePointDesc1" /></p>
              </div>
            </div>
            <div className='col-lg-4 mb-lg-0 mb-5'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='card-live-commerce'>
                <div className='d-flex align-items-center mb-4'>
                  <img width={75} className='mr-3' src="/assets/img/icon-product-launch-1.svg" alt="User Image" />
                  <div className='title mb-2'><FormattedMessage id="sales.affiliatePointTitle2" /></div>
                </div>
                <p><FormattedMessage id="sales.affiliatePointDesc2" /></p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="300" className='card-live-commerce'>
                <div className='d-flex align-items-center mb-4'>
                  <img width={75} className='mr-3' src="/assets/img/icon-product-launch-2.svg" alt="User Image" />
                  <div className='title mb-2'><FormattedMessage id="sales.affiliatePointTitle3" /></div>
                </div>
                <p><FormattedMessage id="sales.affiliatePointDesc3" /></p>
              </div>
            </div>
          </div>
        </div>
        <div id='our-workflow'>
          <div className='container-xl'>
            <div className='row align-items-center'>
              <div className='col-lg-6 mb-lg-0 mb-5'>
                <h3><FormattedMessage id="sales.ourWorkflow" /></h3>
                <h1 className='mb-32'><FormattedMessage id="sales.ourWorkflowTitle" /></h1>
                <p className="mb-0"><FormattedMessage id="sales.ourWorkflowDesc" /></p>
              </div>
              <div className='col-lg-6'>
                <img width="100%" src="/assets/img/our-workflow.png" alt="User Image" />
              </div>
            </div>
            <div id="we-offer" className='row rg-48'>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='list-offer mb-48'>
                  <div className='number mb-32'>01</div>
                  <h3 className='mb-20'><FormattedMessage id="sales.ourWorkflowPointTitle1" /></h3>
                  <p><FormattedMessage id="sales.ourWorkflowPointDesc1" /></p>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='list-offer mb-48'>
                  <div className='number mb-32'>02</div>
                  <h3 className='mb-20'><FormattedMessage id="sales.ourWorkflowPointTitle2" /></h3>
                  <p><FormattedMessage id="sales.ourWorkflowPointDesc2" /></p>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="300" className='list-offer mb-48'>
                  <div className='number mb-32'>03</div>
                  <h3 className='mb-20'><FormattedMessage id="sales.ourWorkflowPointTitle3" /></h3>
                  <p><FormattedMessage id="sales.ourWorkflowPointDesc3" /></p>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="400" className='list-offer mb-48'>
                  <div className='number mb-32'>04</div>
                  <h3 className='mb-20'><FormattedMessage id="sales.ourWorkflowPointTitle4" /></h3>
                  <p><FormattedMessage id="sales.ourWorkflowPointDesc4" /></p>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="500" className='list-offer mb-48'>
                  <div className='number mb-32'>05</div>
                  <h3 className='mb-20'><FormattedMessage id="sales.ourWorkflowPointTitle5" /></h3>
                  <p><FormattedMessage id="sales.ourWorkflowPointDesc5" /></p>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="600" className='list-offer mb-48'>
                  <div className='number mb-32'>06</div>
                  <h3 className='mb-20'><FormattedMessage id="sales.ourWorkflowPointTitle6" /></h3>
                  <p><FormattedMessage id="sales.ourWorkflowPointDesc6" /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="strategy-live-commerce" ref={sectionStrategy}>
          <div className='container-xl'>
            <div className='row justify-content-center text-center mb-80'>
              <div className="col-lg-9">
                <h1 className='title-section mb-4'><FormattedMessage id="sales.affiliateStrategyTitle" values={{ tiger: <span className="tiger"><FormattedMessage id="sales.affiliateStrategyTitleTiger" /></span>}} /></h1>
                <p className='desc mx-auto mb-80-mobile'><FormattedMessage id="sales.affiliateStrategyDesc" /></p>
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="sales.affiliateStrategyPointTitle1" /></h3>
                <h1 className='mb-4'><FormattedMessage id="sales.affiliateStrategyPointHeadline1" /></h1>
                <p className='mb-40'><FormattedMessage id="sales.affiliateStrategyPointDesc1" /></p>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/affiliate-1.png" />
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/affiliate-2.png" />
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='content-live-commerce col-lg-6 order-lg-last order-first'>
                <h3><FormattedMessage id="sales.affiliateStrategyPointTitle2" /></h3>
                <h1 className='mb-4'><FormattedMessage id="sales.affiliateStrategyPointHeadline2" /></h1>
                <p className='mb-40'><FormattedMessage id="sales.affiliateStrategyPointDesc2" /></p>
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="sales.affiliateStrategyPointTitle3" /></h3>
                <h1 className='mb-4'><FormattedMessage id="sales.affiliateStrategyPointHeadline3" /></h1>
                <p className='mb-40'><FormattedMessage id="sales.affiliateStrategyPointDesc3" /></p>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/affiliate-3.png" />
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/affiliate-4.png" />
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='content-live-commerce col-lg-6 order-lg-last order-first'>
                <h3><FormattedMessage id="sales.affiliateStrategyPointTitle4" /></h3>
                <h1 className='mb-4'><FormattedMessage id="sales.affiliateStrategyPointHeadline4" /></h1>
                <p className='mb-40'><FormattedMessage id="sales.affiliateStrategyPointDesc4" /></p>
              </div>
            </div>
          </div>
        </section>
        <section id="banner">
          <div className='container-xl'>
            <div className='row'>
              <div className='col-lg-12 d-flex justify-content-center'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-banner text-center'>
                  <h1 className='mb-20'><FormattedMessage id="sales.affiliateBannerTitle" /></h1>
                  <p className='mb-60'><FormattedMessage id="sales.affiliateBannerDesc" /></p>
                  <Link to={"/contact"}><button type="button" className="btn-launcher h-48"><FormattedMessage id="discussWithUs" /></button></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}


export default SalesAffiliate;