/* Languange EN */
var enAwareness = {
    "awareness"                         : "BRAND AWARENESS",
    "awareness.title"                   : "Boost your brand's visibility and reach your ideal audience",
    "awareness.desc"                    : "With our platform and solution, you can easily create and manage social media campaigns that reach your target audience and boost your brand's visibility.",
    "awareness.getMore"                 : "Get More Awareness",

    "awareness.bcaTitle"                : "{tiger1} and {tiger2} together to maximize the impact of your content.",
    "awareness.bcaTitleTiger1"          : "Run campaigns",
    "awareness.bcaTitleTiger2"          : "advertising",
    "awareness.bcaDesc"                 : "UGC content and well-targeted ads are a winning combination for gaining more awareness.",

    "awareness.strategyTitle"           : "How to launch your plus ads campaign with us",
    "awareness.strategyDesc"            : "We got 4 process to launch your plus ads campaign",

    "awareness.campaignCreation"        : "CAMPAIGN CREATION",
    "awareness.campaignCreationTitle"   : "Set your partnership with creators with a simple toggle",
    "awareness.campaignCreationDesc"    : "Set your campaign to a campaign-ready partnership with a single toggle. Get matched with creators who agree to partner with your brand.",

    "awareness.automatedInvitation"     : "AUTOMATED INVITATION",
    "awareness.automatedInvitationTitle": "Eliminate the back-and-forth with creators.",
    "awareness.automatedInvitationDesc" : "Our technology reduces the need for communication between creators and brands. You don't need to invite and ask permission through messages to collaborate with them.",

    "awareness.contentManagement"       : "CONTENT MANAGEMENT",
    "awareness.contentManagementTitle"  : "All your labeled content in one place, at a glance.",
    "awareness.contentManagementDesc"   : "Manage all the content from all your campaigns that are labeled with 'partner' in one place.",

    "awareness.boostContent"            : "BOOST CONTENT",
    "awareness.boostContentTitle"       : "Help your content creators perform at their best.",
    "awareness.boostContentDesc"        : "Run ads on your labelled post",

    "awareness.ugcTitleMain"            : "USER GENERATED CONTENT SOLUTION",
    "awareness.ugcTitle"                : "Create authentic, brand-aligned content with our end-to-end UGC campaign management platform",
    "awareness.ugcDesc"                 : "We'll take care of everything from planning your UGC campaign to measuring its success",

    "awareness.ugcPointTitle1"          : "Creator Networks",
    "awareness.ugcPointDesc1"           : "Create high-quality content from our creators",
    "awareness.ugcPointTitle2"          : "93% Increase sales",
    "awareness.ugcPointDesc2"           : "Consumers rely on reviews and recommendations from real individuals before purchasing",
    "awareness.ugcPointTitle3"          : "90% more trusted",
    "awareness.ugcPointDesc3"           : "Authenticity is a crucial factor for consumers on choosing the brands they like",

    "awareness.ugcStrategyTitle"        : "Our end-to-end UGC campaign Management",
    "awareness.ugcStrategyDesc"         : "Discover how we can help you create a buzz with effective UGC campaigns.",

    "awareness.ugcStrategyPointTitle1"  : "FLEXIBLE SOW",
    "awareness.ugcStrategyPointHeadline1": "Launch flexible UGC campaigns that suit with your strategy",
    "awareness.ugcStrategyPointDesc1"   : "Create engaging UGC campaigns with a variety of formats, from Instagram posts and reels to TikTok videos and YouTube content.",

    "awareness.ugcStrategyPointTitle2"  : "CREATOR SELECTION",
    "awareness.ugcStrategyPointHeadline2": "Choose a creator that suits your campaign",
    "awareness.ugcStrategyPointDesc2"   : "Choose the perfect creators for your campaign and make informed decisions with our data-driven insights.",

    "awareness.ugcStrategyPointTitle3"  : "CONTENT APPROVAL",
    "awareness.ugcStrategyPointHeadline3": "Review and approve high-quality content from your hand-picked creators",
    "awareness.ugcStrategyPointDesc3"   : "Review all content directly in one place that later will be uploaded by the creator",

    "awareness.ugcStrategyPointTitle4"  : "GET CAMPAIGN REPORT",
    "awareness.ugcStrategyPointHeadline4": "Skip the hassle of asking for screenshots and just share the link. We'll take care of the rest",
    "awareness.ugcStrategyPointDesc4"   : "With our platform, you can easily get the results you need from any link post, without having to ask. Saves your time and work more efficiently.",

    "awareness.ugcBannerTitle"          : "Boost your brand awareness with our marketing platform and creative solutions",
    "awareness.ugcBannerDesc"           : "Make your campaign more engaging, authentic, and cost-effective with UGC with will targeted strategy, Let's discuss your next campaign.",
};

export default enAwareness;