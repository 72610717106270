/* Languange EN */
var idEngagement = {
    "engagement"                         : "TINGKATKAN INTERAKSI",
    "engagement.title"                   : "Buat konten yang membangun interaksi",
    "engagement.desc"                    : "Kami akan membantu Anda untuk membangun hubungan dengan audiens lebih efektif dengan solusi campaign yang baik. Ayo diskusikan campaign Anda selanjutnya",

    "engagement.ugcTitle"                : "Create authentic, brand-aligned content with our end-to-endUGC campaign management platform",
    "engagement.ugcDesc"                 : "We'll take care of everything from planning your UGC campaign to measuring its success",

    "engagement.ugcPointTitle1"          : "Creator Networks",
    "engagement.ugcPointDesc1"           : "Create high-quality content from our creators",
    "engagement.ugcPointTitle2"          : "93% Increase sales",
    "engagement.ugcPointDesc2"           : "Consumers Rely on Reviews and Recommendations from Real Individuals Before Purchasing",
    "engagement.ugcPointTitle3"          : "90% More Trusted",
    "engagement.ugcPointDesc3"           : "Authenticity is a Crucial Factor for consumers on Choosing the Brands they like",

    "engagement.ugcStrategyTitle"        : "Our end-to-end UGC campaign Management",
    "engagement.ugcStrategyDesc"         : "Discover how we can help you create a buzz with effective UGC campaigns.",

    "engagement.ugcStrategyPointTitle1"  : "FLEXIBLE SOW",
    "engagement.ugcStrategyPointHeadline1": "Launch flexible UGC campaigns that suit with your strategy",
    "engagement.ugcStrategyPointDesc1"   : "Create engaging UGC campaigns with a variety of formats, from Instagram posts and reels to TikTok videos and YouTube content.",

    "engagement.ugcStrategyPointTitle2"  : "CREATOR SELECTION",
    "engagement.ugcStrategyPointHeadline2": "Choose a creator that suits your campaign",
    "engagement.ugcStrategyPointDesc2"   : "Choose the perfect creators for your campaign and make informed decisions with our data-driven insights.",

    "engagement.ugcStrategyPointTitle3"  : "CONTENT APPROVAL",
    "engagement.ugcStrategyPointHeadline3": "Review and approve high-quality content from your hand-picked creators",
    "engagement.ugcStrategyPointDesc3"   : "Review langsung semua konten  disatu tempat yang nantinya diupload oleh kreator dengan mudah",

    "engagement.ugcStrategyPointTitle4"  : "GET CAMPAIGN REPORT",
    "engagement.ugcStrategyPointHeadline4": "Skip the hassle of asking for screenshots and just share the link. We'll take care of the rest",
    "engagement.ugcStrategyPointDesc4"   : "With our platform, you can easily get the results you need from any link post, without having to ask. Saves your time and work more efficiently.",

    "engagement.ugcBannerTitle"          : "Boost your brand awareness with our marketing platform and creative solutions",
    "engagement.ugcBannerDesc"           : "Make your campaign more engaging, authentic, and cost-effective with UGC with will targeted strategy, Let's discuss your next campaign.",

    "engagement.lcHeadline"             : "SOLUSI LIVE COMMERCE",
    "engagement.lcTitle"                : "Dapatkan Interaksi Secara Langsung",
    "engagement.lcDesc"                 : "Jalankan Live Commerce bersama kami untuk membangun koneksi yang lebih dalam dengan audiens Anda. Kami menawarkan solusi end-to-end untuk Live Commerce.",

    "engagement.lcBlogTitle1"           : "100K+",
    "engagement.lcBlogHeadline1"        : "Jaringan Kreator",
    "engagement.lcBlogDesc1"            : "Capai lebih dari pembuatan konten dengan bermitra dengan para kreator & afiliasi untuk meningkatkan kesadaran brand.",
    "engagement.lcBlogTitle2"           : "Host Profesional",
    "engagement.lcBlogDesc2"            : "Dengan host kami yang berbakat dan siap, kami siap untuk mempromosikan & meningkatkan penjualan produk Anda",
    "engagement.lcBlogTitle3"           : "10K Jam+",
    "engagement.lcBlogHeadline3"        : "Kemampuan Live Commerce",
    "engagement.lcBlogDesc3"            : "Hasilkan konten menarik dengan berinteraksi langsung dengan calon pelanggan Anda dalam sesi live commerce.",

    "engagement.lcStrategyTitle"        : "Pendekatan kami untuk Live Commerce Anda",
    "engagement.lcStrategyDesc"         : "Pemasaran Anda, Cara Anda. Layanan kami menyatu dengan lancar dengan tujuan Anda. Mari Ciptakan Kisah Keberhasilan Anda!",

    "engagement.lcStrategyTitle1"       : "STRATEGI PRODUK",
    "engagement.lcStrategyHeadline1"    : "Analisis Mendalam tentang Produk Anda",
    "engagement.lcStrategyDesc1"        : "Tim ahli kami akan membimbing brand Anda memasuki dunia Live Commerce, Kami akan:",
    "engagement.lcStrategy1Point1"      : "Melakukan Analisis Produk & Pesaing",
    "engagement.lcStrategy1Point2"      : "Kurasi produk dan menentukan bagaimana akan dipromosikan",

    "engagement.lcStrategyTitle2"       : "STRATEGI HOST",
    "engagement.lcStrategyHeadline2"    : "Memilih dan Mengelola Host untuk Live Commerce Anda",
    "engagement.lcStrategyDesc2"        : "Dengan lebih dari 100 ribu KOL dan host yang berbakat, kami akan menemukan host yang sempurna sesuai dengan keperluan brand Anda.",
    "engagement.lcStrategy2Point1"      : "Memilih host yang sempurna untuk Live Commerce Anda",
    "engagement.lcStrategy2Point2"      : "Menyiapkan host dengan pengetahuan produk tentang brand Anda.",

    "engagement.lcStrategyTitle3"       : "PERENCANAAN LIVE COMMERCE",
    "engagement.lcStrategyHeadline3"    : "Kami Akan Menyiapkan Semua Pekerjaan, Mulai dari Aset hingga Studio",
    "engagement.lcStrategyDesc3"        : "Tim ahli BintanGO kami akan menangani semua tugas, sehingga Anda tidak perlu khawatir. Berikut yang akan kami siapkan untuk Anda:",
    "engagement.lcStrategy3Point1"      : "Menyiapkan semua aset live commerce Anda seperti motion, latar belakang, dll.",
    "engagement.lcStrategy3Point2"      : "Semua skrip dan semua trik di dalam Live",
    "engagement.lcStrategy3Point3"      : "Menyiapkan pengaturan studio",

    "engagement.lcStrategyTitle4"       : "STRATEGI OPERASIONAL",
    "engagement.lcStrategyHeadline4"    : "Tenang, kami telah menangani semua keperluan operasional dan hal teknis untuk Anda",
    "engagement.lcStrategyDesc4"        : "Jangan khawatir, tim kami telah menangani semua operasional langsung untuk Anda, mulai dari para host hingga mengatasi segala hambatan teknis yang mungkin muncul.",
    "engagement.lcStrategy4Point1"      : "Menyiapkan semua aset live commerce Anda seperti motion, latar belakang, dll.",

    "engagement.lcStrategyTitle5"       : "OPTIMALISASI & LAPORAN",
    "engagement.lcStrategyHeadline5"    : "Kami akan mengumpulkan semua hasil Live untuk Anda",
    "engagement.lcStrategyDesc5"        : "Kami percaya bahwa data akan membawa lketahap berikutnya. Tim ahli kami akan menganalisis dan melaporkan langsung kepada Anda.",
    "engagement.lcStrategy5Point1"      : "Analisis hasil dan optimalkan",
    "engagement.lcStrategy5Point2"      : "Kami akan membuat laporan mingguan.",

    "engagement.lcCheckOut"             : "Lihat studio live commerce kami",
    "engagement.lcGlanceTitle"          : "Live Commerce dalam Sorotan",
    "engagement.lcGlanceDesc"           : "Live Commerce adalah cara inovatif dan menarik bagi bisnis untuk terhubung dengan audiens mereka dan menjual produk secara real-time di platform e-commerce",
    "engagement.lcWhyChoose"            : "Jadii.. Kenapa harus memilih Live Commerce?",
    "engagement.lcWhyChooseTitle1"      : "57%",
    "engagement.lcWhyChooseDesc1"       : "Konsumer Indonesia tertarik berbelanja melalui Live Commerce",
    "engagement.lcWhyChooseTitle2"      : "70%",
    "engagement.lcWhyChooseDesc2"       : "Konsumer yang menonton Live Commerce melakukan Pembelian",
    "engagement.lcWhyChooseTitle3"      : "20+ Min",
    "engagement.lcWhyChooseDesc3"       : "Rata - rata Konsumer menghabiskan waktu untuk menonton Live Commerce setiap harinya",

    "engagement.lcWhyNeed"              : "Kenapa Kamu membutuhkan Live Commerce?",
    "engagement.lcWhyNeedPoint1"        : "Meningkatkan Kesadaran Konsumen terhadap Brand",
    "engagement.lcWhyNeedPoint2"        : "Interaksi secara Real-time dengan Brand",
    "engagement.lcWhyNeedPoint3"        : "Menghasilkan pendapatan dari Livestream",

    "engagement.lcWhyNeedBlockTitle1"   : "GMV meningkat 150%",
    "engagement.lcWhyNeedBlockDesc1"    : "Untuk Live Commerce dalam periode 2022 hingga 2023.",
    "engagement.lcWhyNeedBlockTitle2"   : "68% Tingkat Konversi Lebih Tinggi",
    "engagement.lcWhyNeedBlockDesc2"    : "Dibandingkan dengan penjualan di e-commerce",

    "engagement.lcBannerTitle"          : "Boost your brand awareness with our marketing platform and creative solutions",
    "engagement.lcBannerDesc"           : "Make your campaign more engaging, authentic, and cost-effective with UGC with will targeted strategy, Let's discuss your next campaign.",
};

export default idEngagement;