/* Languange ID */


var idCreator = {
    "creator.titleBanner" : "Buat hobi kamu jadi pemasukan!",
    "creator.descBanner"  : "Dapatkan uang dengan membuat konten! Temukan peluang kolaborasi baru setiap minggunya dari 250+ brands",
    
    "creator.section1Title"    : "KITA PERCAYA",
    "creator.section1SubTitle" : "Semua orang merupakan kreator konten, namun..",
    "creator.section1Desc"  : "Kontenmu bisa lebih dari hanya ekspresi kreativitasmu. Dengan peluang kolaborasi setiap minggunya, BintanGO akan mengubah kreativitasmu menjadi sarana penghasilan tambahan!",
    "creator.testiAliciaeva" : "BintanGO mampu menjadi media perantara aku dengan Brand ternama di Indonesia. BintanGO memberikan fitur-fitur yang sukai dengan kebutuhan kita sebagai kreator dan itu membantu banget!",
    "creator.testiLaissti" : "Menurut aku fitur jobs dari BintanGO helpful banget! Aku jadi lebih mudah mendapatkan banyak peluang baru untuk kerjasama dengan Brand.",
    "creator.testiDevinaputrii" : "BintanGO sangat membantu aku sebagai Content Creator, mulai dari kemudahan mendapatkan jobs lalu dalam pembuatan Invoice dan pembayaran semuanya di sudah di urus sama BintanGO. Jadi gak perlu pusing lagi, tinggal focus buat konten.",
    "creator.testiAstaririri" : "Menurut aku fitur brand deals dari BintanGO helpful banget! Aku jadi lebih mudah mendapatkan banyak peluang baru untuk kerjasama dengan Brand.",

    "creator.section2Title"  : "Mulai jadi ",
    "creator.section2Desc"   : "BintanGO membantu kamu #GrowBeyond sebagai kreator konten",
    "creator.growCard1Title": "Kolaborasi bersama Brand!",
    "creator.growCard1Desc"  : "Pekerjaan baru setiap minggunya! Dapatkan peluang dari 250+ brands dari berbagai industri",
    "creator.growCard2Title": "Tingkatkan Performa",
    "creator.growCard2Desc"  : "Aplikasi kami memiliki fitur produktivitas yang membantu kamu meningkatkan performa media sosial!",
    "creator.growCard3Title": "For-You-Jobs",
    "creator.growCard3Desc"  : "Berapapun follower-mu, apapun sosmed favoritmu, BintanGO akan menyediakan pekerjaan yang pas untukmu!",
    "creator.growCard4Title": "Dukungan Komunitas",
    "creator.growCard4Desc"  : "Jalin kerja sama dengan 60,000+ kreator yang siap berbagi ilmu dan #GrowBeyond bersama.",

    "creator.section3Title" : "BintanGO telah diliput oleh:",

    "creator.section4Title" : "Penghasilan lebih besar, usaha ",
    "creator.section4Hashtag" : "lebih kecil",
    "creator.section4Step1Title" : "Bergabung bersama BintanGO!",
    "creator.section4Step1Desc" : "Download, daftar, dan lengkapi profilmu",
    "creator.section4Step2Title" : "Dapat pekerjaan!",
    "creator.section4Step2Desc" : "Semua kreator bisa dapat uang! Buka tab jobs, temukan yang cocok, dan tap “Apply”",
    "creator.section4Step3Title" : "Buat Konten, Dapet Bayaran!",
    "creator.section4Step3Desc" : "Setelah kamu diterima, kamu tinggal buat konten, dan tunggu bayaran!",
    "creator.section4ButtonApply" : "APPLY SEKARANG",
    

    "creator.section5Title"  : "BintanGO Creator-Powered Brands",

    "creator.section6Title"  : "Bergabunglah dengan komunitas kami!",    
};

export default idCreator;
