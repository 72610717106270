/* Languange TH */

var thNavBar = {
  "navbar.bintangoFor"      : "BintanGO สำหรับ",
  "navbar.solution"      : "โซลูชัน",
  "navbar.technology"      : "ความสามารถด้านเทคโนโลยี",
  "navbar.resource"      : "แหล่งข้อมูล",
  "navbar.company"      : "บริษัท",
  "navbar.tools"            : "เครื่องมือ",
  "navbar.calculator"       : "ER Calculator",
  "navbar.contact"      : "ติดต่อเรา",
  "navbar.blog"      : "บล็อก",
  "navbar.caseStudy"      : "ตัวอย่างความสำเร็จ",
  "navbar.helpCenter"      : "ศูนย์ช่วยเหลือ",
  "navbar.about"      : "เกี่ยวกับเรา",
  "navbar.career"      : "ตำแหน่งงาน",
  "navbar.brandAwareness"      : "เพิ่มการรับรู้ถึงแบรนด์ของคุณ",
  "navbar.brandAwarenessDesc"      : "เพิ่มการมองเห็นแบรนด์ของคุณด้วยการผสานระหว่างคอนเทนต์จากผู้ใช้จริงและคอนเทนต์สร้างขึ้นโดยแบรนด์เพื่อให้มีประสิทธิภาพสูงสุด",
  "navbar.engagement"      : "มีส่วนร่วมกับผู้ชมของคุณ",
  "navbar.engagementDesc"      : "เพิ่มการมีส่วนร่วมของผู้ชมด้วยโซลูชันแบบไดนามิก การไลฟ์สด และคอนเทนต์ที่เกิดจากผู้ใช้จริง",
  "navbar.product"      : "เปิดตัวผลิตภัณฑ์ใหม่",
  "navbar.productDesc"      : "เริ่มต้นการเปิดตัวผลิตภัณฑ์ของคุณด้วยแคมเปญ 360° ที่ครอบคลุมและกลยุทธ์ที่มีประสิทธิภาพ",
  "navbar.sales"      : "เพิ่มยอดขายแบรนด์ของคุณ",
  "navbar.salesDesc"      : "ครีเอทีพโซลูชันของเราจะช่วยให้คุณเพิ่มยอดขายของแบรนด์ผ่าน Live Commerce และ Affiliate",
  "navbar.forBrand"      : "แบรนด์",
  "navbar.forAgency"      : "เอเจนซี่",
  "navbar.forCreator"      : "ครีเอเตอร์",
  "navbar.forBrandandAgency"      : "สำหรับแบรนด์และเอเจนซี่",
  "navbar.login"      : "เข้าสู่ระบบ",
};

export default thNavBar;