/* Languange ID */


var idInquiryForm = {
    "form.titleFirst" : "Tebarkan pesona brand kamu!",
    "form.titleSecond" : "Kerja sama dengan kreator berkualitas tinggi yang dapat ",
    "form.titleSecondTiger1" : "meningkatkan visibilitas",
    "form.and" : "dan",
    "form.titleSecondTiger2" : "engagement brand-mu",
    "form.titleDesc"  : "Hubungi kami sekarang dan tunjukkan potensl sejati brand kamu melalui creator marketing!",
    
    "form.picInformation" : "Informasi PIC",
    "form.businessInformation" : "Informasi Bisnis",
    "form.yourName" : "Nama Kamu*",
    "form.yourEmail" : "Email Kamu*",
    "form.yourPhone" : "Nomor Telepon*",
    "form.yourCompany" : "Perusahaan Anda*",
    "form.yourLink" : "Link Perusahaan atau Link Sosial Media*",
    "form.instagramUsername" : "Instagram Username*",
    "form.tiktokUsername" : "TikTok Username",
    "form.yourNeed" : "Apa yang Anda butuhkan dari BintanGO",
    "form.creatorMarketing" : "Creator Marketing",
    "form.creatorMarketingDesc" : "Kami akan membantu Anda untuk menjalankan campaign dan berkolaborasi dengan kreator untuk meningkatkan awareness, engagement, atau tujuan marketing Anda lainnya",
    "form.liveCommerce" : "Live Commerce",
    "form.liveCommerceDesc" : "Kami akan membantu Anda untuk menjalankan live commerce untuk meningkatkan penjualan produk Anda ataupun meningkatkan interaksi Anda dengan audiens",
    "form.currency" : "Kurs",
    "form.yourBudget" : "Perkiraan Budget*",
    "form.yourMessage" : "Pesan Anda (Opsional)*",
    "form.next" : "LANJUT",
    "form.back" : "KEMBALI",
    "form.send" : "KIRIM",
    "form.sending" : "MENGIRIM",
};

export default idInquiryForm;
