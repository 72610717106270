/* Languange EN */


var enTermCondition = {
    "term.intro.title"  : "TERMS & CONDITIONS",
    "term.title.A"      : "INTRODUCTION",
    "term.desc.A"       : "Hello CreatorGO! We are delighted that you have joined BintanGO!\n\nThank you for visiting Our Platform. BintanGO is a digital platform from PT Stargo Digital Indonesia that is accessible through your mobile application or the www.bintango.com site, which provides opportunities for content creators to develop their potential. Before using or accessing this Platform, we hope that you have read all of these Terms and Conditions carefully.\n\nThese Terms and Conditions govern your access and use of the websites and mobile applications operated by BintanGO. By continuing or accessing this Platform, you acknowledge that you have read, understood, and agreed to all content in these Terms and Conditions as an Agreement.\n\nFrom time to time, BintanGO may modify, delete or add to the Platform or the Terms and Conditions. Continued use of the Platform after changes to the Platform and the Terms and/or Conditions constitutes acceptance of those changes.",
    "term.title.B"      : "TERMS OF USE THE PLATFORM",
    "term.desc.B"       : "As a condition of using the BintanGO Platform, you with this acknowledge, represent, and affirm that the following are true, correct, and not misleading when you use Our Services on the BintanGO Platform, thus:",
    "term.desc.B.a"     : "You have read, understood, and agreed to the Terms and Conditions, Privacy Policy, and Community Guidelines;",
    "term.desc.B.b"     : "You are at least 13 (thirteen) years old, and if you are under 18 (eighteen) years old, you must have the consent of your parent or legal guardian and a maximum of 60 (sixty) years, own legal capacity, and therefore may perform legal actions for and on your behalf, as follows:",
    "term.desc.B.b.i"   : "use the Service;",
    "term.desc.B.b.ii"  : "agree on the matter as referred to in the section a above;",
    "term.desc.B.b.iii" : "provide Personal Data to Us and agree to process Your Personal Data in accordance with the purposes specified in the Privacy Policy; and",
    "term.desc.B.b.iv"  : "Sign all required documents (if any).",
    "term.desc.B.c"     : "If you have a BintanGO account, you will maintain your account information and will supervise and be fully responsible for the use of your account by anyone other than you;",
    "term.desc.B.d"     : "You are the owner of Personal Data and/or a party who has obtained consent to provide Personal Data from the owner of Personal Data to use Our Services on the BintanGO Platform; and",
    "term.desc.B.e"     : "All Personal Data and other information you provide is accurate, correct, and not misleading.",
    "term.title.C"      : "PRIVACY POLICY & DISCLOSURE",
    "term.desc.C1"      : "Under certain circumstances, BintanGO may disclose information about you to relevant third parties if BintanGO determines, in our sole discretion, that such disclosure is necessary to:",
    "term.desc.C.a"     : "Protect BintanGO systems, assets, employees, members, and users;",
    "term.desc.C.b"     : "Ensuring the integrity, business operations, and systems of BintanGO; and/or",
    "term.desc.C.c"     : "Comply with valid and enforceable requests, subpoenas, or court orders.",
    "term.desc.C2"      : "BintanGO may comply with or ignore such requests or orders at our sole discretion. BintanGO's right to disclose information, including but not limited to user profile information (namely name, email address, etc.), IP addressing and traffic information, usage history, and posted Content, shall take precedence over any provisions of BintanGO.",
    "term.title.D"      : "PLATFORM CONTENT",
    "term.desc.D"       : "All materials, including images, details, data, illustrations, designs, icons, photos, video clips, text, software, graphics, scripts, logos, and other materials that form part of the BintanGO Platform (collectively, the “Content” ) are owned exclusively (either directly or indirectly) by BintanGO, its affiliates and/or content providers. Content is protected by copyrights, trademarks, service marks, patents, industrial designs, and other intellectual property or proprietary rights owned by BintanGO. Any rights not expressly granted in the Terms and Conditions are reserved for BintanGO.\n\nAny BintanGO marks, logos, and designs appearing on any BintanGO Platform are the exclusive trademarks and service marks (either directly or indirectly) of BintanGO. No license or rights to such marks, logos, designs, and other proprietary rights of BintanGO are granted or granted to you.",
    "term.title.E"      : "RESTRICTIONS AND PROHIBITED ACTIVITIES",
    "term.desc.E"       : "You acknowledge and understand that there are restrictions and prohibited activities on the BintanGO Platform; you undertake to comply with these provisions as follows:",
    "term.desc.E.a"     : "This Platform and its Content, as well as BintanGO as its infrastructure used to provide Content, are the property of BintanGO and should be used solely for your own personal and non-commercial use. Any other use of the Platform or Content is strictly prohibited, including but not limited to the modification, deletion, transmission, publication, distribution, disbursement, uploading, uploading, redistributing, re-licensing, selling, copying, republishing, or any other distribution without written permission from BintanGO or its owner;",
    "term.desc.E.b"     : "Without prior written consent, you may not use any framing techniques to include the BintanGO trademark or logo or use meta tags or other hidden text. Without our prior written permission, you may not frame, mirror, link, or combine any part of this Platform. The use of spiders, robots, and similar data collection or extraction tools is strictly prohibited on the BintanGO Platform;",
    "term.desc.E.c"     : "You do not register for more than one User Account, for one User Account on behalf of an individual other than yourself, operate a User account on behalf of or for the benefit of anyone who does not meet the requirements to register or use the Service;",
    "term.desc.E.d"     : "You may not share your User Account with others without your consent;",
    "term.desc.E.e"     : "You agree to use this Platform only for lawful purposes. You are prohibited from posting or transmitting to or through the Platform any material that is unlawful, harmful, threatening, abusive, harassing, libelous, vulgar, obscene, obscene, hateful, or any other material that may give rise to civil or criminal liability under the provisions of the current regulations.",
    "term.desc.E.f"     : "You may view and print a copy of the Content displayed on the Platform, and download a copy of any Content intended for download, only for your personal use, but you may not modify the Content in any way, including, without limitation, removing or altering copyright or other proprietary notices. Rights, title, and interest in the Content are not transferred to you by copying or downloading the materials. The information provided on this Platform is free of charge (unless otherwise specified) and is for informational purposes only and does not create a business relationship or professional service between you and BintanGO; and",
    "term.desc.E.g"     : "BintanGO has the right to delete room lists and user accounts and terminate and remove unwarranted content or activities from the web Platform without notice. BintanGO also has the right to take legal action against perpetrators for losses caused by these activities and other prohibited activities.",
    "term.title.F"      : "INFORMATION ACCURACY",
    "term.desc.F"       : "BintanGO uses reasonable efforts to provide accurate and current information, but BintanGO does not guarantee that any information contained in this Platform is correct, complete, reliable, current, or error-free or that the Platform will be virus-free. BintanGO is also not responsible for any errors or omissions in this Platform. Although BintanGO may change the Content, make improvements to the Platform, or correct errors or omissions at any time, BintanGO is not obligated to notify you. Any use you make of the information provided on this Platform is entirely at your own risk, and BintanGO will not be responsible for any data loss, loss of profits, or any other damage or loss resulting from your use of this Platform.",
    "term.title.G"      : "LINKS TO THIRD-PARTY SITES",
    "term.desc.G"       : "This platform may contain hyperlinks to websites operated by parties other than BintanGO. BintanGO does not control such websites and is not responsible for their content or the privacy or other practices of such websites. Including a hyperlink to such websites does not imply endorsement of the materials on those websites or any relationship with their operators. In some cases, you may be asked by a third-party site to link your profile on BintanGO to a profile on another third-party site. Choosing to do so is optional, and the decision to allow this information to be linked can be disabled (with third-party sites) at any time.",
    "term.title.H"      : "DISCLAIMER OF WARRANTIES AND LIMITATIONS OF LIABILITY",
    "term.desc.H"       : "This section limits BintanGO's liability to you for any problems that may arise in connection with your use of the Platform, which is as follows:",
    "term.desc.H.a"     : "Information, software, products, and services published on this Platform may include inaccuracies or errors, including space availability and pricing errors. BintanGO does not guarantee accuracy and disclaims all responsibility for any errors or other inaccuracies relating to the information and product descriptions contained herein or any commercial terms displayed on this Platform. In addition, BintanGO reserves the right to correct any availability and pricing errors on our Platform. BintanGO makes no representations about the suitability of the information, software, products, and services on this Platform for any purpose.",
    "term.desc.H.b"     : "All such information, software, products, and services are provided 'as is' without warranty of any kind. BintanGO does not warrant that this Platform, its servers, or any email sent from BintanGO, is free from viruses or other harmful components. BintanGO does not warrant that this information, software, products, and services, including all implied warranties and conditions of merchantability, and fitness for a particular purpose, are proprietary and non-infringing.",
    "term.desc.H.c"     : "Under no circumstances shall BintanGO be liable for any direct, indirect, punitive, incidental, special, or consequential damages arising out of, or in any way related to, your access to, display, or use of this Platform or by any delay or inability to access, display or use this Platform (including but not limited to, your belief in the reviews and opinions that appear on this Platform; computer viruses, information, software, related sites, products and services obtained through this Platform; or arising from access to, display or use of this Platform) whether based on the theory of negligence, contract, tort, strict liability or otherwise and even if BintanGO has been advised of the possibility of such damages.",
    "term.desc.H.d"     : "If BintanGO is found to be responsible for any loss or damage arising out of or related to your use of the Platform or our services. In that case, BintanGO's liability will not exceed, in aggregate, the transaction fees paid to BintanGO for the transaction(s) on this Platform that gave rise to the claim. The limitation of liability reflects the allocation of risk between the parties. The restrictions outlined in this section will remain in effect even if the limited remedies specified in these terms fail to achieve their fundamental purpose. The limitations of liability given in these terms apply to the benefit of BintanGO.",
    "term.desc.H.e"     : "Specific laws do not allow the limitation of implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or limitations may not be used, but liability will be limited to the fullest extent permitted by law.",
    "term.title.I"      : "INDEMNIFICATION",
    "term.desc.I"       : "You agree to defend, indemnify and hold harmless BintanGO against any claims, causes of action, demands, remedies, losses, damages, fines, penalties, or other costs or expenses of any kind or nature, including but not limited to legal and accounting fees incurred reasonable, brought by a third party as a result of:",
    "term.desc.I.i"     : "Your violation of the Terms and Conditions, Privacy Policy, Community Guidelines, or documents referenced herein;",
    "term.desc.I.ii"    : "Your violation of any law or third party rights; or",
    "term.desc.I.iii"   : "Your use of this Platform.",
    "term.title.J"      : "FEEDBACK AND OTHER SUGGESTIONS",
    "term.desc.J"       : "All feedback, ideas, and other suggestions you submit through this Platform will be treated as non-confidential and non-proprietary. It may be disclosed or used by BintanGO without the obligation to compensate you. All personal data provided to BintanGO will be handled under our Privacy Policy.",
    "term.title.K"      : "JURISDICTION AND GOVERNING LAW",
    "term.desc.K"       : "This Platform and Content are operated by an Indonesian entity, and these Terms and Conditions are governed by the laws of the Republic of Indonesia. They are displayed solely to promote the available BintanGO services. BintanGO reserves the right to seek all available remedies by law and justice for violations of these Terms and Conditions, including but not limited to the right to block access from certain IP addresses. You hereby consent to exclusive jurisdiction and venue of the courts in Indonesia and establish the fairness and convenience of proceedings in such courts for all disputes arising out of or relating to the use of this Platform. You agree that all claims you may have against BintanGO arising out of or relating to this Platform must be heard and resolved in a court of competent subject jurisdiction in Indonesia. Use of this Platform is unlawful in any jurisdiction that does not enforce all provisions of these Terms and Conditions, including, without limitation, this paragraph. Our failure to enforce any rights under these Terms and Conditions will not constitute a waiver of any terms or conditions.",
    "term.title.L"      : "DISPUTE RESOLUTION",
    "term.desc.L"       : "In principle, all disputes arising from the content or implementation of these Terms and Conditions will be resolved amicably and through deliberation and consensus by the parties. Suppose the dispute resolution does not reach an agreement. In that case, the parties agree to resolve it through the South Jakarta District Court or the Indonesian National Arbitration Board (BANI) under the applicable procedural law.",
    "term.title.M"      : "MISCELLANEOUS",
    "term.desc.M.1"     : "The implementation of these Terms and Conditions is subject to applicable laws and legal processes, and nothing in these Terms and Conditions limits our right to comply with law enforcement or other government or legal requests or requirements relating to your use of this Platform or information provided by us, in connection with such use.",
    "term.desc.M.2"     : "Suppose there is or is found to be any provision in these terms and conditions that cannot be implemented based on the applicable laws and regulations. In that case, this will not affect the applicability of the other provisions of these Terms and Conditions.",
    "term.desc.M.3"     : "These Terms and Conditions (and other provisions referenced herein) constitute the entire Terms and Conditions between you and BintanGO concerning the use of this Platform and supersede all previous or concurrent communications and proposals, whether electronic, oral, or written, between the customer and BintanGO in connection with this Platform. A printed version of these Terms and Conditions and any notice given in electronic form will be admissible in any judicial or administrative proceeding under or relating to these Terms and Conditions to the same extent and subject to the same provisions as other original business documents and records. Produced and maintained in print.",
    "term.desc.M.4"     : "The fictitious names of companies, products, people, characters, and/or data mentioned on this Platform are not intended to represent real individuals, companies, products, or events.",
    "term.desc.M.5"     : "We may change these Terms and Conditions from time to time and provide you with notice of such changes. The amended terms and conditions will apply to the use of this Platform from the date of publication of the amended Terms and Conditions. Therefore, you are encouraged to check this page regularly so that if you continue to use, access, or use this Platform, you are deemed to have acknowledged, understood, and agreed to the Changes.",
    "term.title.N"      : "CONTACT",
    "term.desc.N"       : "If you have any questions, complaints, comments, or requests related to these Terms and Conditions, please contact us via our contact number at +6281280777870 or email to info@bintango.id.",
    "term.intro.desc1"  : "This Terms & Conditions is an agreement between Users (“You”, “Your” or “Yourself”) and PT Stargo Digital Indonesia, a limited liability company duly established and legally operating under the laws of the Republic of Indonesia and domiciled in DKI Jakarta, Indonesia and/or its affiliates (“BINTANGO”). This Terms & Conditions governs Your access and use of the website and mobile app operated by BINTANGO (hereinafter collectively referred to as the “Platform” or “Platforms”). By accessing and using or browsing the Platform, You acknowledge that You have read, understood and agree to be bound to these Terms & Conditions and all the prevailing and applicable laws and regulations. If You do not agree to be bound by these Terms & Conditions, then You must not use this Platform. From time to time, BINTANGO may make modifications, deletions or additions to the Platform or the Terms & Conditions. Your continued use of the Platform following the posting of any changes to the Platform and/or Terms & Conditions constitutes acceptance of those changes.",
    "term.ppdisclosures.title"  : "Privacy Policy & Disclosures",
    "term.ppdisclosures.desc1"   : "Please review our Privacy Policy concurrently with this Terms & Conditions, as it is incorporated and an integral part of  this Terms & Conditions and shall apply to Your visit to the Platform, unless covered by a separate privacy policy.",
    "term.ppdisclosures.desc2"   : "In certain circumstances BINTANGO may disclose information about You to relevant third parties if BINTANGO determine, based on our sole discretion, such disclosure is necessary to (a) protect BINTANGO’s systems, assets, employees, members and customers; (b) ensure the integrity and operation of BINTANGO’s business and systems; or (c) comply with legitimate and enforceable governmental requests, subpoenas or court orders. BINTANGO may choose to comply with or disregard such requests or orders in our sole discretion. BINTANGO’s right to disclose information, including but not limited to user profile information (i.e. name, email address, etc.), IP addressing and traffic information, usage history, and posted Content, shall take precedence over any terms of BINTANGO’s Privacy Policy.",
    "term.platformuse.title" : "Use of the Platform",
    "term.platformuse.desc1" : "As a condition of Your use of the Platforms, You warrant that (i) all information supplied by You on the Platforms are true, accurate, current and complete, (ii) if You have a BINTANGO account, You will safeguard Your account information and will supervise and be completely responsible for any use of Your account by anyone other than You, and (iii) You are eighteen (18) years of age or older in order to register for an account and contribute to our Platform. BINTANGO does not knowingly collect the information of anyone under the age of Eighteen (18). BINTANGO retain the right at our sole discretion to deny access to anyone to this Platform and the services BINTANGO offer, at any time and for any reason, including, but not limited to, violation of this Terms & Conditions.",
    "term.platformCt.title" : "Platform Contents",
    "term.platformCt.desc1" : "All materials, including images, details, data, illustrations, designs, icons, photographs, video clips, text, software, graphics, scripts, logos, and other materials that are part of the Platforms (collectively referred as the “Contents”) are owned exclusively (either directly or indirectly) by BINTANGO, its affiliates, and/or its content providers. The Contents are protected by copyrights, trademarks, service marks, patents, industrial design and other intellectual or ownership rights owned by BINTANGO. Any rights not expressly granted in the Terms & Conditions are reserved for the BINTANGO.",
    "term.platformCt.desc2" : "Any BINTANGO marks, logos and designs appearing on any of BINTANGO Platforms are the exclusive trademarks and service marks (either directly or indirectly) of BINTANGO. No license to or right in any such marks, logos and designs and other proprietary rights of the BINTANGO is granted to or conferred upon You.",
    "term.platformCt.desc3" : "All other trademarks and service marks appearing on any of BINTANGO Platforms that are not owned by BINTANGO are the property of their respective owners.",
    "term.restriction.title" : "Restrictions & Prohibited Activities",
    "term.restriction.desc1" : "This Platform and its Contents, as BINTANGOll as its infrastructure used to provide the Contents, is proprietary to BINTANGO and must be used solely for Your own personal, non-commercial use. Any other use of the Platform or the Contents are strictly prohibited, including without limitation to, modification, removal, deletion, transmission, publication, distribution, proxy cashing, uploading, posting, redistribution, re-licensing, selling, duplicating, republication or any other dissemination without the express written permission of BINTANGO or the owner. You may not use any framing techniques to enclose any trademarks or logos of BINTANGO nor use any meta tags or other hidden text without our prior written consent. You may not frame, mirror, link, or otherwise incorporate any part of this Platform without our prior written authorization. Use of spiders, robots and similar data gathering and extraction tools is expressly and strictly prohibited. ",
    "term.restriction.desc2" : "You may view and print a copy of the Contents displayed on the Platform, and download a copy of any Contents that is designated for downloading, for Your personal use only but You may not alter the Contents in any way, including, without limitation, removing or modifying any copyright or other ownership notices. The rights, title and interest in the Contents are not transferred to You by copying or downloading the material. The information provided on this Platform is free of charge (unless otherwise stipulated) and for informational purposes only and does not create a business or professional services relationship between You and BINTANGO.",
    "term.restriction.desc3" : "BINTANGO reserve the right to remove space listings, user account and terminate and remove unwarranted content or activity from the webPlatform without notice. BINTANGO also reserves the right and may pursue legal action against perpetrators for damages caused by these and other prohibited activities.",
    "term.online.title" : "Online Conduct",
    "term.online.desc1" : "You agree to use this Platform only for lawful purposes. You are prohibited from posting or transmitting to or through the Platform any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, profane, hateful material, or any other material that could give rise to civil or criminal liability under the prevailing laws and regulations. BINTANGO may disclose any content or electronic communication of any kind (including Your profile, e-mail address and other information) (1) to satisfy any law, regulation, or government request; (2) if the disclosure is necessary or appropriate to operate the Platform; or (3) to protect the rights or property of BINTANGO, its affiliates, and You.",
    "term.accuracy.title" : "Accuracy of Information",
    "term.accuracy.desc1" : "BINTANGO uses reasonable efforts to furnish accurate and up-to-date information, but BINTANGO do not guarantee that any information contained in this Platform is accurate, complete, reliable, current or error-free or that the Platform will be free from viruses. BINTANGO also are not responsible for any errors or omissions in this Platform. Although BINTANGO may modify the Contents, make improvements to the Platform or correct any error or omission at any time and without notice to You, BINTANGO are not obligated to do so. BINTANGO will endeavor to update information in a timely manner but BINTANGO will not be liable for any inaccuracies. Any use You make of the information provided on this Platform is entirely at Your own risk and BINTANGO will not be liable for any loss of data, loss of profits or any other damages or losses resulting from Your use of this Platform.",
    "term.links.title" : "Links to Third Party sites",
    "term.links.desc1" : "This Platform may contain hyperlinks to websites operated by parties other than BINTANGO. Such hyperlinks are provided for Your reference only. BINTANGO do not control such websites and are not responsible for their contents or the privacy or other practices of such websites. Further, it is up to You to take precautions to ensure that whatever links You select or software You download (whether from this Platform or other websites) is free of such items as viruses, worms, trojan horses, defects and other items of a destructive nature. Our inclusion of hyperlinks to such websites does not imply any endorsement of the material on such websites or any association with their operators. In some cases, You may be asked by a third-party site to link Your profile on BINTANGO to a profile on another third-party site. Choosing to do so is purely optional, and the decision to allow this information to be linked can be disabled (with the third-party site) at any time.",
    "term.disclaimer.title" : "Disclaimer of Warranties & Limitations of Liability",
    "term.disclaimer.desc1" : "Please read this section carefully. This section limits BINTANGO’s liability to You for issues that may arise in connection with Your use of the Platforms. If You do not understand the terms in this section or elsewhere in the Terms & Conditions, please consult a lawyer for clarification before accessing or using this Platform.",
    "term.disclaimer.desc2" : "The information, software, products, and services published on this Platform may include inaccuracies or errors, including space availability and pricing errors. BINTANGO, its subsidiaries and corporate affiliates, do not guarantee the accuracy of, and disclaim all liability for, any errors or other inaccuracies relating to the information and description of the products contained herein, or any commercial terms displayed on this Platform (including, without limitation, the pricing, availability, photographs, list of products, reviews and ratings, etc.). In addition, BINTANGO expressly reserves the right to correct any availability and pricing errors on our Platform and/or on pending reservations made under an incorrect price. BINTANGO makes no representations about the suitability of the information, software, products, and services contained on this Platform for any purpose, and the inclusion or offering of any products or services on this Platform does not constitute any endorsement or recommendation of such products or services by BINTANGO. All such information, software, products, and services are provided \"as is\" without warranty of any kind. BINTANGO disclaim all warranties and conditions that this Platform, its servers or any email sent from BINTANGO, are free of viruses or other harmful components. BINTANGO hereby disclaims all warranties and conditions with regard to this information, software, products, and services, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title, and noninfringement. BINTANGO has no liability and will make no refund in the event of any delay, cancellation, overbooking, strike, force majeure or other causes beyond their direct control, and they have no responsibility for any additional expense, omissions, delays, re-routing or acts of any government or authority.",
    "term.disclaimer.desc3" : "In no event shall BINTANGO (or their officers, directors, employees, agents and affiliates) be liable for any direct, indirect, punitive, incidental, special, or consequential damages arising out of, or in any way connected with, Your access to, display of or use of this Platform or with the delay or inability to access, display or use this Platform (including, but not limited to, Your reliance upon reviews and opinions appearing on this Platform; any computer viruses, information, software, linked sites, products, and services obtained through this Platform; or otherwise arising out of the access to, display of or use of this Platform) whether based on a theory of negligence, contract, tort, strict liability, or otherwise, and even if BINTANGO has been advised of the possibility of such damages.",
    "term.disclaimer.desc4" : "If BINTANGO is found liable for any loss or damage that arises out of or is in any way connected with Your use of our Platforms or services, then BINTANGO’s liabilities will in no event exceed, in the aggregate of the transaction fees paid to BINTANGO for the transaction(s) on this Platform giving rise to the claim.",
    "term.disclaimer.desc5" : "The limitation of liability reflects the allocation of risk between the parties. The limitations specified in this section will survive and apply even if any limited remedy specified in these terms is found to have failed of its essential purpose. The limitations of liability provided in these terms inure to the benefit of BINTANGO.",
    "term.disclaimer.desc6" : "Certain laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to You, some or all of the above disclaimers, exclusions or limitations may not apply but liability shall be limited to the fullest extent permitted by law.",
    "term.indemnification.title" : "Indemnification",
    "term.indemnification.desc1" : "You agree to defend and indemnify BINTANGO and any of their officers, directors, employees, affiliates and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:",
    "term.indemnification.list1" : "Your breach of this Terms & Conditions or the documents referenced herein;",
    "term.indemnification.list2" : "Your violation of any law or the rights of a third party; or",
    "term.indemnification.list3" : "Your use of this Platform.",
    "term.remedies.title" : "Remedies for Violations",
    "term.remedies.desc1" : "BINTANGO reserves the right to seek all remedies available at law and in equity for violations of these Terms & Conditions, including but not limited to the right to block access from a particular IP address.",
    "term.feedback.title" : "Feedback and Other Suggestions",
    "term.feedback.desc1" : "All feedback, ideas and other suggestions submitted by You through this Platform will be treated as non-confidential and non-proprietary and may be disclosed or used by BINTANGO for any purpose whatsoever, without any obligation to compensate You. All personal data provided to BINTANGO will be handled in accordance with our Privacy Policy.",
    "term.jurisdiction.title" : "Jurisdiction and Governing Law",
    "term.jurisdiction.desc1" : "This Platform and the Contents are operated by an Indonesian entity and this Terms & Conditions is governed by the laws of the Republic of Indonesia and are displayed solely for the purpose of promoting BINTANGO’s available services. You hereby consent to the exclusive jurisdiction and venue of courts in Indonesia and stipulate to the fairness and convenience of proceedings in such courts for all disputes arising out of or relating to the use of this Platform. You agree that all claims You may have against BINTANGO arising from or relating to this Platform must be heard and resolved in a court of competent subject matter jurisdiction located in Indonesia. The use of this Platform is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms & Conditions, including, without limitation, this paragraph. Our failure to enforce any rights under these Terms & Conditions will not constitute a waiver of any terms or conditions of these Terms & Conditions.",
    "term.dispute.title" : "Dispute Resolution",
    "term.dispute.desc1" : "Subject to the following clause, any claim or controversy related to these Terms & Conditions or relating to this Platform will be settled by arbitration. The decision of the arbitrator will be binding on the parties. To the fullest extent permitted by applicable law, no arbitration will be joined to an arbitration involving any other party subject to these Terms & Conditions, whether through class arbitration proceedings or otherwise.",
    "term.miscellaneous.title" : "Miscellaneous",
    "term.miscellaneous.desc1" : "Our performance of this Terms & Conditions is subject to prevailing laws and legal process, and nothing contained in this Terms & Conditions limits our right to comply with law enforcement or other governmental or legal requests or requirements relating to Your use of this Platform or information provided to or gathered by us with respect to such use.",
    "term.miscellaneous.desc2" : "If any part of this Terms & Conditions is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, the determination shall not affect the rest of the Terms & Conditions, and the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remaining provisions in the Terms & Conditions shall continue in effect. Regardless of any statute or law to the contrary, to the extent of the applicable law, any claim or cause of action arising out of or related to use of this Platform or under these Terms & Conditions must be filed within one (1) year after such claim or cause of action arose or be forever barred. The section titles in these Terms & Conditions are for convenience only.",
    "term.miscellaneous.desc3" : "This Terms & Conditions (and any other Terms & Conditions referenced herein) constitutes the entire Terms & Conditions between You and BINTANGO with respect to the use of this Platform and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between the customer and BINTANGO with respect to this Platform. A printed version of this Terms & Conditions and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this Terms & Conditions to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.",
    "term.miscellaneous.desc4" : "Fictitious names of companies, products, people, characters, and/or data mentioned on this Platform are not intended to represent any real individual, company, product, or event.",
    "term.miscellaneous.desc5" : "Any rights not expressly granted herein are reserved.",
    "term.contact.title" : "Contact",
    "term.contact.desc1" : "If You have any questions, complaints, or claims with respect to the Platforms, You may contact us via email to legal@bintango.id.",

};

export default enTermCondition;