import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  

const listBrands = [
  "logo-antv.png",
  "logo-btn.png",
  // "arotel.png",
  "logo-you.png",
  "logo-pigeon.png",
  "logo-aia.png",
  "logo-anter-aja.png",
  "logo-nobi.png",
  "logo-tokenomy.png",
  // "ashta.png",
  // "vokraf.png",
  "logo-flash-coffee.png",
  "logo-etude.png",
  "logo-fresh.png",
  "logo-raya.png"
]

// Main Component
function AwarenessUGC() {
  const sectionStrategy = useRef(null);

  const scrollNextSection = () => {
    sectionStrategy.current?.scrollIntoView({behavior: 'smooth'});
  };

  useEffect(() => {
    AOS.init({
      duration : 2000
    })
  }, []);
  
  return (
    <>
      <div id="live-commerce" class="mt-lg-4 mt-5">
        <div className='container-xl mb-170'>
          <div className='text-center row justify-content-center'>
            <div className='col-lg-12'>
              <div className='title-live-commerce'>
                <h3><FormattedMessage id="awareness.ugcTitleMain" /></h3>
              </div>
              <h1 className='mb-40'><FormattedMessage id="awareness.ugcTitle" /></h1>
              <p className='mb-60'><FormattedMessage id="awareness.ugcDesc" /></p>
              <div className='mx-auto mb-60'>
                <button onClick={()=> scrollNextSection() } type="button" className="btn-launcher mw-200 h-52"><FormattedMessage id="lookHowWeWork" /></button>
              </div>
            </div>
          </div>
          
          <section id="partner" className='container-xl'>
            <div className='d-flex-desktop align-items-center'>
              <div className="title"><FormattedMessage id="trustedByBrands" values={{ number: <span className="number">500</span> }} /></div>
              <div className='carousel'>
                <div className='gradient'></div>
                <OwlCarousel 
                  items={5}
                  loop={true} 
                  margin={20} 
                  autoplay={true} 
                  // smartSpeed={1000}
                  slideTransition={'linear'} 
                  autoplayTimeout={1500} 
                  autoplaySpeed={3000} 
                  // autoplayHoverPause={false}
                  autoWidth={true} 
                  nav={false} 
                  dots={false} > 
                {
                    listBrands.map(function(image, index) {
                    return (
                        <div className="item" key={index}><img height={64} src={"/assets/img/for-brand/" + image} /></div>
                        );
                    })
                }
                </OwlCarousel>
              </div>
            </div>
          </section>  

          <div className='row'>
            <div className='col-lg-4'>
              <div className='card-live-commerce'>
                <div className='d-flex align-items-center mb-4'>
                  <img width={75} className='mr-3' src="/assets/img/creatorNetwork-min.png" alt="User Image" />
                  <div>
                    <div className='title mb-2'>100K+ </div>
                    <span className='grey'><FormattedMessage id="awareness.ugcPointTitle1" /></span>
                  </div>
                </div>
                <p><FormattedMessage id="awareness.ugcPointDesc1" /></p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='card-live-commerce'>
                <div className='d-flex align-items-center mb-4'>
                  <img width={75} className='mr-3' src="/assets/img/proffesionalHost-min.png" alt="User Image" />
                  <div>
                    <div className='title mb-2'><FormattedMessage id="awareness.ugcPointTitle2" /></div>
                  </div>
                </div>
                <p><FormattedMessage id="awareness.ugcPointDesc2" /></p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='card-live-commerce'>
                <div className='d-flex align-items-center mb-4'>
                  <img width={75} className='mr-3' src="/assets/img/time-min.png" alt="User Image" />
                  <div>
                    <div className='title mb-2'><FormattedMessage id="awareness.ugcPointTitle3" /></div>
                    {/* <span className='grey'>Live Commerce Capabilities</span> */}
                  </div>
                </div>
                <p><FormattedMessage id="awareness.ugcPointDesc3" /></p>
              </div>
            </div>
          </div>

          <section id="strategy-live-commerce" ref={sectionStrategy}>
            <div className='text-center mb-80'>
              <h1 className='title-section mb-4'><FormattedMessage id="awareness.ugcStrategyTitle" /></h1>
              <p className='desc-section w-60 mx-auto mb-80-mobile'><FormattedMessage id="awareness.ugcStrategyDesc" /></p>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="awareness.ugcStrategyPointTitle1" /></h3>
                <h1 className='mb-4'><FormattedMessage id="awareness.ugcStrategyPointHeadline1" /></h1>
                <p className='mb-40'><FormattedMessage id="awareness.ugcStrategyPointDesc1" /></p>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/ugc-1.png" />
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/ugc-2.png" />
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='content-live-commerce col-lg-6 order-lg-last order-first'>
                <h3><FormattedMessage id="awareness.ugcStrategyPointTitle2" /></h3>
                <h1 className='mb-4'><FormattedMessage id="awareness.ugcStrategyPointHeadline2" /></h1>
                <p className='mb-40'><FormattedMessage id="awareness.ugcStrategyPointDesc2" /></p>
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="awareness.ugcStrategyPointTitle3" /></h3>
                <h1 className='mb-4'><FormattedMessage id="awareness.ugcStrategyPointHeadline3" /></h1>
                <p className='mb-40'><FormattedMessage id="awareness.ugcStrategyPointDesc3" /></p>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/ugc-3.png" />
              </div>
            </div>
            <div className='row my-4 mb-140 align-items-center'>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/ugc-4.png" />
              </div>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='content-live-commerce col-lg-6 order-lg-last order-first'>
                <h3><FormattedMessage id="awareness.ugcStrategyPointTitle4" /></h3>
                <h1 className='mb-4'><FormattedMessage id="awareness.ugcStrategyPointHeadline4" /></h1>
                <p className='mb-40'><FormattedMessage id="awareness.ugcStrategyPointDesc4" /></p>
              </div>
            </div>
            {/* <div className='row my-4 mb-140 align-items-center'>
              <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='content-live-commerce col-lg-6'>
                <h3><FormattedMessage id="awareness.ugcStrategyPointTitle1" /></h3>
                <h1 className='mb-4'>Lorem Ipsum Dolor</h1>
                <p className='mb-40'>We believe data will guide our next steps. Our expert team will analyze and report directly to you.</p>
              </div>
              <div className='col-lg-6 mb-5 mb-lg-0'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='w-100' src="/assets/img/sales-lc-5.png" />
              </div>
            </div> */}
          </section>
          
          {/* <section id='ugc-campaign'>
            <div className='text-center mb-5'>
              <h3 class="title-section">UGC CAMPAIGN AT A GLANCE</h3>
              <h1>In User-Generated Content, the creator creates content related to the brand</h1>
            </div>
            <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" className='row align-items-center mb-5'>
              <div className='col-lg-6'>
                <h4 className='mb-40 mt-lg-0 mt-4'>Why your brand need implement UGC?</h4>
                <div class="d-flex align-items-center mb-4">
                  <img class="mr-3" src="/assets/img/Rectangle-42.png" />
                  <div>
                    <h4 class="mb-3">Creating trust between consumer and brands</h4>
                    <p class="mb-0"><b>93%</b> of Consumers Rely on Reviews and Recommendations from Real Individuals Before Purchasing</p>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-4">
                  <img class="mr-3" src="/assets/img/Rectangle-42.png" />
                  <div>
                    <h4 class="mb-3">More authentic, more trust</h4>
                    <p class="mb-0">For <b>90%</b> of Consumers, Authenticity is a Crucial Factor in Choosing the Brands they like</p>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-4">
                  <img class="mr-3" src="/assets/img/Rectangle-42.png" />
                  <div>
                    <h4 class="mb-3">Creating trust between consumer and brands</h4>
                    <p class="mb-0"><b>93%</b> of Consumers Rely on Reviews and Recommendations from Real Individuals Before Purchasing</p>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-4">
                  <img class="mr-3" src="/assets/img/Rectangle-42.png" />
                  <div>
                    <h4 class="mb-3">Creating trust between consumer and brands</h4>
                    <p class="mb-0"><b>93%</b> of Consumers Rely on Reviews and Recommendations from Real Individuals Before Purchasing</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 order-lg-last order-first'>
                <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='w-100' src="/assets/img/Frame-6722.png" />
              </div>
            </div>
          </section> */}
        </div>
        <section id="banner">
          <div className='container-xl'>
            <div className='row'>
              <div className='col-lg-12 d-flex justify-content-center'>
                <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="100" className='card-banner text-center'>
                  <h1 className='mb-20'><FormattedMessage id="awareness.ugcBannerTitle" /></h1>
                  <p className='mb-60'><FormattedMessage id="awareness.ugcBannerDesc" /></p>
                  <Link to={"/contact"}><button type="button" className="btn-launcher h-48"><FormattedMessage id="discussWithOurTeam" /></button></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}


export default AwarenessUGC;