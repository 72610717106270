import { toast } from 'react-toastify';

var toastConfig = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

const CloseButton = ({ closeToast }) => (
    <div className="c-pointer font-weight-bold p-2 my-auto" onClick={closeToast}>Close</div>
  );

const iconClose = ({ closeToast }) => (
    <img onClick={closeToast} className="mr-2" src="/assets/img/toast-close.svg" />
  );

function toastShow(mode, message) {
    switch(mode) {
        case "error" :
            toast(
                message
                , {
                closeButton: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    fontFamily: 'Neutrif Pro',
                    padding: "7px 17px",
                    background: "#c82333",
                    borderRadius: "16px",
                    height: "fit-content",
                    color : "white",
                    minHeight: "auto",
                    fontSize: "14px",
                    lineHeight: "18px",
                }
            });
            break;
        case "warning" :
            toast.warn(message, toastConfig);
            break;
        case "info" :
            toast(
                message
                , {
                closeButton: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    fontFamily: 'NeutrifPro',
                    padding: "7px 17px",
                    background: "#ECECEC",
                    borderRadius: "16px",
                    height: "fit-content",
                    color : "black",
                    minHeight: "auto",
                    fontSize: "14px",
                    lineHeight: "18px",
                }
            });
            break;
        case "success" :
            toast(
                message
                , {
                closeButton: iconClose,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    fontFamily: 'Neutrif Pro',
                    padding: "7px 17px",
                    background: "#E9F6F6",
                    border:"1px solid #25A8A0",
                    borderRadius: "16px",
                    height: "fit-content",
                    color : "#25A8A0",
                    minHeight: "auto",
                    fontSize: "14px",
                    lineHeight: "18px",
                }
            });
            break;
        case "dark" :
            toast.dark(message, toastConfig);
            break;
        default :
            toast(message, toastConfig);
            break;
        
    }
}

export { toastShow };