/* Languange TH */
var thEngagement = {
    "engagement"	: "มีส่วนร่วมมากขึ้น",
    "engagement.title"	: "สร้างคอนเทนต์ที่สร้างปฏิสัมพันธ์",
    "engagement.desc"	: "เราจะช่วยให้คุณเชื่อมต่อกับผู้ชมได้อย่างมีประสิทธิภาพมากขึ้นผ่านบริการการจัดการแคมเปญที่ครอบคลุมของเรา มาหารือเกี่ยวกับแคมเปญถัดไปของคุณกันดีกว่า",

    "engagement.ugcTitle"	: "สร้างคอนเทนต์ที่น่าเชื่อถือและสอดคล้องกับแบรนด์ด้วยแพลตฟอร์มการจัดการแคมเปญ UGC แบบ end-to-end ของเรา",
    "engagement.ugcDesc"	: "เราจะดูแลทุกอย่างตั้งแต่การวางแผนแคมเปญ UGC ของคุณไปจนถึงการวัดความสำเร็จ",

    "engagement.ugcPointTitle1"	: "เครือข่ายครีเอเตอร์",
    "engagement.ugcPointDesc1"	: "สร้างคอนเทนต์คุณภาพสูงจากครีเอเตอร์ของเรา",
    "engagement.ugcPointTitle2"	: "เพิ่มยอดขายกว่า 93%",
    "engagement.ugcPointDesc2"	: "ลูกค้าเชื่อรีวิวและคำแนะนำจากบุคคลจริงก่อนตัดสินใจซื้อ",
    "engagement.ugcPointTitle3"	: "เชื่อถือได้มากขึ้น 90%",
    "engagement.ugcPointDesc3"	: "ความถูกต้องเป็นปัจจัยสำคัญสำหรับผู้บริโภคในการเลือกแบรนด์ที่พวกเขาชอบ",

    "engagement.ugcStrategyTitle"	: "การจัดการแคมเปญ UGC แบบครบวงจรของเรา",
    "engagement.ugcStrategyDesc"	: "ค้นพบวิธีที่เราสามารถช่วยคุณสร้างกระแสด้วยแคมเปญ UGC ที่มีประสิทธิภาพ",

    "engagement.ugcStrategyPointTitle1"	: "การทำงานที่ยืดหยุ่น",
    "engagement.ugcStrategyPointHeadline1"	: "เปิดตัวแคมเปญ UGC ที่ยืดหยุ่นซึ่งเหมาะสมกับกลยุทธ์ของคุณ",
    "engagement.ugcStrategyPointDesc1"	: "สร้างแคมเปญ UGC ที่น่าสนใจด้วยรูปแบบที่หลากหลาย ตั้งแต่โพสต์และคลิป Instagram ไปจนถึงวิดีโอ TikTok และเนื้อหา YouTube",

    "engagement.ugcStrategyPointTitle2"	: "การคัดเลือกครีเอเตอร์",
    "engagement.ugcStrategyPointHeadline2"	: "เลือกครีเอเตอร์ที่เหมาะกับแคมเปญของคุณ",
    "engagement.ugcStrategyPointDesc2"	: "เลือกครีเอเตอร์ที่สมบูรณ์แบบสำหรับแคมเปญของคุณและทำการตัดสินใจอย่างมีข้อมูลด้วยข้อมูลเชิงลึกที่ขับเคลื่อนด้วยข้อมูลของเรา",

    "engagement.ugcStrategyPointTitle3"	: "การอนุมัติเนื้อหา",
    "engagement.ugcStrategyPointHeadline3"	: "ตรวจสอบและอนุมัติเนื้อหาคุณภาพสูงจากครีเอเตอร์ที่คุณเลือก",
    "engagement.ugcStrategyPointDesc3"	: "ตรวจสอบ langsung semua konten disatu tempat yang nantinya diupload oleh kreator dengan mudah",

    "engagement.ugcStrategyPointTitle4"	: "รับรายงานแคมเปญ",
    "engagement.ugcStrategyPointHeadline4"	: "ข้ามความยุ่งยากในการขอภาพหน้าจอและเพียงแค่แชร์ลิงก์ เราจะดูแลส่วนที่เหลือ",
    "engagement.ugcStrategyPointDesc4"	: "ด้วยแพลตฟอร์มของเรา คุณสามารถรับผลลัพธ์ที่ต้องการได้อย่างง่ายดายจากการโพสต์ลิงก์ใดๆ โดยไม่ต้องถาม ประหยัดเวลาและทำงานได้อย่างมีประสิทธิภาพมากขึ้น",

    "engagement.ugcBannerTitle"	: "เพิ่มการรับรู้ถึงแบรนด์ของคุณด้วยแพลตฟอร์มการตลาดและโซลูชันที่สร้างสรรค์ของเรา",
    "engagement.ugcBannerDesc"	: "ทำให้แคมเปญของคุณมีส่วนร่วม น่าเชื่อถือ และคุ้มค่ามากขึ้นด้วย UGC ด้วยกลยุทธ์ที่กำหนดเป้าหมาย เรามาหารือเกี่ยวกับแคมเปญถัดไปของคุณ",

    "engagement.lcHeadline"	: "โซลูชั่นการไลฟ์สด",
    "engagement.lcTitle"	: "ได้รับปฏิสัมพันธ์ โต้ตอบที่แท้จริง",
    "engagement.lcDesc"	: "ดำเนินธุรกิจ Live Commerce กับเราเพื่อสร้างความสัมพันธ์ที่ลึกซึ้งยิ่งขึ้นกับผู้ชมของคุณ เรานำเสนอโซลูชั่นแบบ end-to-end สำหรับ Live Commerce",

    "engagement.lcBlogTitle1"	: "100K+",
    "engagement.lcBlogHeadline1"	: "เครือข่ายครีเอเตอร์",
    "engagement.lcBlogDesc1"	: "เข้าถึงมากกว่าการสร้างสรรค์เนื้อหาด้วยการร่วมมือกับครีเอเตอร์และพันธมิตรเพื่อเพิ่มการรับรู้ถึงแบรนด์",
    "engagement.lcBlogTitle2"	: "นักไลฟ์มืออาชีพ",
    "engagement.lcBlogDesc2"	: "นักไลฟ์ที่มีความสามารถและเตรียมพร้อมมาอย่างดี เราพร้อมที่จะโปรโมตและเพิ่มยอดขายผลิตภัณฑ์ให้กับคุณ",
    "engagement.lcBlogTitle3"	: "10,000 ชั่วโมง+",
    "engagement.lcBlogHeadline3"	: "ความสามารถในการไลฟ์สด",
    "engagement.lcBlogDesc3"	: "สร้างคอนเทนต์ที่น่าสนใจโดยการโต้ตอบโดยตรงกับผู้ที่อาจเป็นลูกค้าของคุณในเซสชันการไลฟ์สด",

    "engagement.lcStrategyTitle"	: "แนวทางของเราสำหรับ Live Commerce ของคุณ",
    "engagement.lcStrategyDesc"	: "การตลาดของคุณ ในแบบของคุณ บริการของเราผสมผสานกับเป้าหมายของคุณได้อย่างราบรื่น มาสร้างเรื่องราวความสำเร็จของคุณกันเถอะ!",

    "engagement.lcStrategyTitle1"	: "กลยุทธ์ด้านผลิตภัณฑ์",
    "engagement.lcStrategyHeadline1"	: "การวิเคราะห์เชิงลึกเกี่ยวกับผลิตภัณฑ์ของคุณ",
    "engagement.lcStrategyDesc1"	: "ทีมผู้เชี่ยวชาญของเราจะแนะนำแบรนด์ของคุณให้ก้าวเข้าสู่โลกแห่ง Live Commerce เราจะ:",
    "engagement.lcStrategy1Point1"	: "ทำการวิเคราะห์ผลิตภัณฑ์และคู่แข่ง",
    "engagement.lcStrategy1Point2"	: "การดูแลจัดการผลิตภัณฑ์และการตัดสินใจว่าจะโปรโมตอย่างไร",

    "engagement.lcStrategyTitle2"	: "กลยุทธ์นักไลฟ์",
    "engagement.lcStrategyHeadline2"	: "การเลือกและการจัดการนักไลฟ์สำหรับการไลฟ์สดของคุณ",
    "engagement.lcStrategyDesc2"	: "ด้วยครีเอเตอร์ที่น่าประทับใจกว่า 100,000 คนและนักไลฟ์ที่มีทักษะ เราจะค้นหานักไลฟ์ที่สมบูรณ์แบบที่ตรงกับบุคลิกของแบรนด์ของคุณ",
    "engagement.lcStrategy2Point1"	: "เลือกนักไลฟ์ที่สมบูรณ์แบบสำหรับ Live Commerce ของคุณ",
    "engagement.lcStrategy2Point2"	: "เตรียมพร้อมสำหรับนักไลฟ์ให้ความรู้เกี่ยวกับผลิตภัณฑ์เกี่ยวกับแบรนด์",

    "engagement.lcStrategyTitle3"	: "การวางแผนการไลฟ์สด",
    "engagement.lcStrategyHeadline3"	: "เราจะเตรียมงานทั้งหมดตั้งแต่เนื้อหาไปจนถึงสตูดิโอ",
    "engagement.lcStrategyDesc3"	: "ทีมผู้เชี่ยวชาญ BintanGO ของเราจะดูแลงานหนักทั้งหมด ทำให้คุณไม่ต้องกังวลอีกต่อไป เราจะเตรียมสิ่งที่เราเตรียมไว้ให้คุณดังนี้:",
    "engagement.lcStrategy3Point1"	: "การเตรียมข้อมูลสำหรับการไลฟ์สดทั้งหมดของคุณ เช่น ภาพเคลื่อนไหว พื้นหลัง ฯลฯ",
    "engagement.lcStrategy3Point2"	: "สคริปต์ทั้งหมดและกิมมิคทั้งหมดภายในไลฟ์ของเรา",
    "engagement.lcStrategy3Point3"	: "การเตรียมการทางสตูดิโอจะจัดเตรียมอย่างไร",

    "engagement.lcStrategyTitle4"	: "กลยุทธ์การดำเนินงาน",
    "engagement.lcStrategyHeadline4"	: "ไม่ต้องกังวลไป เรามีการดำเนินการและด้านเทคนิคทั้งหมดไว้ให้คุณแล้ว",
    "engagement.lcStrategyDesc4"	: "หมดห่วง! ทีมของเราพร้อมให้ความช่วยเหลือคุณสำหรับการปฏิบัติงานจริงทั้งหมด ตั้งแต่การสนับสนุนนักไลฟ์ของคุณไปจนถึงการจัดการปัญหาทางเทคนิคที่อาจเกิดขึ้นระหว่างทาง",
    "engagement.lcStrategy4Point1"	: "การข้อมูลสำหรับไลฟ์สดทั้งหมดของคุณ เช่น ภาพเคลื่อนไหว พื้นหลัง ฯลฯ",

    "engagement.lcStrategyTitle5"	: "เพิ่มประสิทธิภาพและรายงาน",
    "engagement.lcStrategyHeadline5"	: "เราจะรวบรวมผลการไลฟ์สดทั้งหมดไว้ให้คุณ",
    "engagement.lcStrategyDesc5"	: "เราเชื่อว่าข้อมูลจะเป็นแนวทางในขั้นตอนต่อไปของเรา ทีมผู้เชี่ยวชาญของเราจะวิเคราะห์และรายงานตรงต่อคุณ",
    "engagement.lcStrategy5Point1"	: "วิเคราะห์ผลลัพธ์และปรับให้เหมาะสม",
    "engagement.lcStrategy5Point2"	: "เราจะทำรายงานประจำสัปดาห์",

    "engagement.lcCheckOut"	: "ตรวจสอบสตูดิโอไลฟ์ของเรา",
    "engagement.lcGlanceTitle"	: "สรุปการไลฟ์สด",
    "engagement.lcGlanceDesc"	: "Live Commerce เป็นวิธีการที่เป็นนวัตกรรมและน่าดึงดูดสำหรับธุรกิจในการเชื่อมต่อกับผู้ชมและขายผลิตภัณฑ์แบบเรียลไทม์บนแพลตฟอร์มการค้า",
    "engagement.lcWhyChoose"	: "ทำไม.. คุณถึงต้องลองใช้บริการ Live Commerce?",
    "engagement.lcWhyChooseTitle1"	: "57%",
    "engagement.lcWhyChooseDesc1"	: "ผู้บริโภคชาวไทยเซียมีส่วนร่วมในไลฟ์สดขายของ",
    "engagement.lcWhyChooseTitle2"	: "70%",
    "engagement.lcWhyChooseDesc2"	: "ผู้บริโภคตัดสินใจซื้อสินค้าทันทีในระหว่างไลฟ์",
    "engagement.lcWhyChooseTitle3"	: "20+ นาที",
    "engagement.lcWhyChooseDesc3"	: "ต่อผู้ชมใช้เวลาดูการค้าสดในหนึ่งวันโดยประมาณ",

    "engagement.lcWhyNeed"	: "ทำไมคุณถึงต้องการไลฟ์คอมเมิร์ช?",
    "engagement.lcWhyNeedPoint1"	: "เพิ่มการรับรู้ให้กับแบรนด์ของคุณ",
    "engagement.lcWhyNeedPoint2"	: "การโต้ตอบแบบเรียลไทม์กับแบรนด์ของคุณ",
    "engagement.lcWhyNeedPoint3"	: "สร้างรายได้จากการถ่ายทอดสด",

    "engagement.lcWhyNeedBlockTitle1"	: "GMV เพิ่มขึ้น 150%",
    "engagement.lcWhyNeedBlockDesc1"	: "สำหรับ Live Commerce ในช่วงปี 2565 ถึง 2566",
    "engagement.lcWhyNeedBlockTitle2"	: "อัตราการเติบโตสูงขึ้น 68%",
    "engagement.lcWhyNeedBlockDesc2"	: "เปรียบเทียบกับอีคอมเมิร์ซแบบดั้งเดิม",

    "engagement.lcBannerTitle"	: "เพิ่มการรับรู้ถึงแบรนด์ของคุณด้วยแพลตฟอร์มการตลาดและโซลูชันที่สร้างสรรค์ของเรา",
    "engagement.lcBannerDesc"	: "ทำให้แคมเปญของคุณมีส่วนร่วม น่าเชื่อถือ และคุ้มค่ามากขึ้นด้วย UGC ด้วยกลยุทธ์ที่กำหนดเป้าหมาย เรามาหารือเกี่ยวกับแคมเปญถัดไปของคุณ",
};

export default thEngagement;