/* Languange TH */
var thAwareness = {
    "awareness"                         : "การรับรู้ถึงแบรนด์",
    "awareness.title"                         : "เพิ่มการมองเห็นแบรนด์ของคุณและเข้าถึงผู้ชมให้ตรงกลุ่มเป้าหมาย",
    "awareness.desc"                         : "ด้วยแพลตฟอร์มและโซลูชันของเรา คุณสามารถสร้างและจัดการแคมเปญโซเชียลมีเดียที่เข้าถึงกลุ่มเป้าหมายและเพิ่มการมองเห็นแบรนด์ของคุณได้อย่างง่ายดาย",
    "awareness.getMore"                         : "ต้องการสร้างการรับรู้ถึงแบรนด์",

    "awareness.bcaTitle"                         : "{tiger1} และ {tiger2} ร่วมมือกันเพื่อเพิ่มประสิทธิภาพในคอนเทนต์ของคุณให้มากที่สุด",
    "awareness.bcaTitleTiger1"                         : "เรียกใช้แคมเปญ",
    "awareness.bcaTitleTiger2"                         : "การโฆษณา",
    "awareness.bcaDesc"                         : "เนื้อหา UGC และโฆษณาที่ตรงกลุ่มเป้าหมายเป็นส่วนผสมที่ลงตัวสำหรับการรับรู้ที่มากขึ้น",

    "awareness.strategyTitle"                         : "วิธีเปิดตัวแคมเปญ Plus Ads กับเรา",
    "awareness.strategyDesc"                         : "เรามี 4 กระบวนการในการเปิดตัวแคมเปญ Plus Ads ของคุณ",

    "awareness.campaignCreation"                         : "การสร้างแคมเปญ",
    "awareness.campaignCreationTitle"                         : "สร้างความสัมพันธ์ของคุณกับผู้ครีเอเตอร์",
    "awareness.campaignCreationDesc"                         : "ตั้งค่าแคมเปญของคุณพร้อมจับคู่กับครีเอเตอร์ที่สนใจตกลงเป็นพันธมิตรกับแบรนด์ของคุณ",

    "awareness.automatedInvitation"                         : "คำเชิญอัตโนมัติ",
    "awareness.automatedInvitationTitle"                         : "หมดปัญหาการติดต่อที่ซับซ้อนกับครีเอเตอร์",
    "awareness.automatedInvitationDesc"                         : "เทคโนโลยีของเราลดขั้นตอนเพื่ออำนวยความสะดวกระหว่างคุณและครีเอเตอร์ คุณไม่จำเป็นต้องเชิญและขออนุญาตผ่านข้อความเพื่อทำงานร่วมกับพวกเขา",

    "awareness.contentManagement"                         : "การจัดการเนื้อหา",
    "awareness.contentManagementTitle"                         : "เนื้อหาที่มีป้ายกำกับทั้งหมดรวมอยู่ในที่เดียว",
    "awareness.contentManagementDesc"                         : "จัดการเนื้อหาทั้งหมดจากแคมเปญทั้งหมดของคุณที่มีป้ายกำกับว่า 'พันธมิตร' ได้ในที่เดียว",

    "awareness.boostContent"                         : "บูสเนื้อหา",
    "awareness.boostContentTitle"                         : "ช่วยให้ครีเอเตอร์สร้างคอนเทนต์ให้คุณได้ดีที่สุด",
    "awareness.boostContentDesc"                         : "แสดงโฆษณาบนโพสต์ที่มีป้ายกำกับของคุณ",

    "awareness.ugcTitleMain"                         : "โซลูชันการสร้างคอนเทนต์จากผู้ใช้จริง",
    "awareness.ugcTitle"                         : "สร้างเนื้อหาที่น่าเชื่อถือและสอดคล้องกับแบรนด์ด้วยแพลตฟอร์มการจัดการแคมเปญ UGC แบบครบวงจรของเรา",
    "awareness.ugcDesc"                         : "เราจะดูแลทุกอย่างตั้งแต่การวางแผนแคมเปญ UGC ของคุณไปจนถึงการวัดความสำเร็จ",

    "awareness.ugcPointTitle1"                         : "เครือข่ายครีเอเตอร์",
    "awareness.ugcPointDesc1"                         : "สร้างคอนเทตต์ที่มีประสิทธิภาพจากผู้ครีเอเตอร์ของเรา",
    "awareness.ugcPointTitle2"                         : "เพิ่มยอดขายกว่า 93%",
    "awareness.ugcPointDesc2"                         : "ลูกค้าเชื่อจากรีวิวและคำแนะนำจากบุคคลจริงก่อนตัดสินใจซื้อ",
    "awareness.ugcPointTitle3"                         : "เชื่อถือได้มากขึ้น 90%",
    "awareness.ugcPointDesc3"                         : "ความจริงใจและน่าเชื่อถือเป็นปัจจัยสำคัญสำหรับผู้บริโภคในการเลือกแบรนด์ที่พวกเขาชอบ",

    "awareness.ugcStrategyTitle"                         : "การจัดการแคมเปญ UGC แบบครบวงจรของเรา",
    "awareness.ugcStrategyDesc"                         : "ค้นพบวิธีที่เราสามารถช่วยคุณสร้างกระแสด้วยแคมเปญ UGC ที่มีประสิทธิภาพ",

    "awareness.ugcStrategyPointTitle1"                         : "การทำงานที่ยืดหยุ่นได้",
    "awareness.ugcStrategyPointHeadline1"                         : "เปิดตัวแคมเปญ UGC ที่ยืดหยุ่นซึ่งเหมาะสมกับกลยุทธ์ของคุณ",
    "awareness.ugcStrategyPointDesc1"                         : "สร้างแคมเปญ UGC ที่น่าสนใจด้วยรูปแบบที่หลากหลาย ตั้งแต่โพสต์และคลิป Instagram ไปจนถึงวิดีโอ TikTok และเนื้อหา YouTube",

    "awareness.ugcStrategyPointTitle2"                         : "การคัดเลือกครีเอเตอร์",
    "awareness.ugcStrategyPointHeadline2"                         : "เลือกครีเอเตอร์ที่เหมาะกับแคมเปญของคุณ",
    "awareness.ugcStrategyPointDesc2"                         : "เลือกครีเอเตอร์ที่สมบูรณ์แบบสำหรับแคมเปญของคุณและทำการตัดสินใจอย่างมีข้อมูลด้วยข้อมูลเชิงลึกที่ขับเคลื่อนด้วยข้อมูลของเรา",

    "awareness.ugcStrategyPointTitle3"                         : "การอนุมัติเนื้อหา",
    "awareness.ugcStrategyPointHeadline3"                         : "ตรวจสอบและอนุมัติเนื้อหาคุณภาพสูงจากครีเอเตอร์ที่คุณเลือก",
    "awareness.ugcStrategyPointDesc3"                         : "ตรวจสอบเนื้อหาทั้งหมดโดยตรงในที่เดียวซึ่งครีเอเตอร์จะอัปโหลดในภายหลัง",

    "awareness.ugcStrategyPointTitle4"                         : "รับรายงานผลแคมเปญ",
    "awareness.ugcStrategyPointHeadline4"                         : "ลดขั้นตอนความยุ่งยากในการขอภาพหน้าจอและเพียงแค่แชร์ลิงก์ เราจะดูแลส่วนที่เหลือ",
    "awareness.ugcStrategyPointDesc4"                         : "ด้วยที่แพลตฟอร์มของเรา คุณสามารถรับรายงายผลลัพธ์ที่ต้องการได้อย่างง่ายดายจากการโพสต์ลิงก์ใดๆ โดยไม่ต้องถามครีเอเตอร์ ประหยัดเวลาและทำงานได้อย่างมีประสิทธิภาพมากขึ้น",

    "awareness.ugcBannerTitle"                         : "เพิ่มการรับรู้ถึงแบรนด์ของคุณด้วยแพลตฟอร์มการตลาดและโซลูชันที่สร้างสรรค์ของเรา",
    "awareness.ugcBannerDesc"                         : "ทำให้แคมเปญของคุณมีส่วนร่วม น่าเชื่อถือ และคุ้มค่ามากขึ้นด้วย UGC ด้วยกลยุทธ์ที่กำหนดเป้าหมาย เรามาหารือเกี่ยวกับแคมเปญถัดไปของคุณ",
};

export default thAwareness;