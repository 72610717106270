/* Languange EN */
var enForAgency = {
    "forAgency"                      : "BINTANGO FOR AGENCY",
    "forAgency.title"                : "Helping agencies",
    "forAgency.desc"                 : "Our platform make it easier and more efficient for agencies to work.",

    "forAgency.titleTechDriven"      : "We're more than just your agency, we're your tech-driven partners.",

    "forAgency.creatorManagementTitle"       : "CREATOR MANAGEMENT",
    "forAgency.creatorManagementHeadline"    : "Invite & manage creator",
    "forAgency.creatorManagementDesc"        : "Easily manage all your creators in one place, get instant insights into their performance, and make data-driven decisions about them.",

    "forAgency.creatorDiscoveryTitle"       : "CREATOR DISCOVERY",
    "forAgency.creatorDiscoveryHeadline"    : "Find the perfect creator for your campaign",
    "forAgency.creatorDiscoveryDesc"        : "Start your search for the perfect creators for your brand campaign with our data-driven selection platform. We provide the most up-to-date and reliable data to help you make informed decisions about your campaign.",

    "forAgency.campaignManagementTitle"       : "CAMPAIGN MANAGEMENT",
    "forAgency.campaignManagementHeadline"    : "Goodbye spreadsheets, hello all-in-one management!",
    "forAgency.campaignManagementDesc"        : "Skip the paperwork and do it all on one platform, from creating and launching your campaign to managing your creator list and organizing their tasks.",

    "forAgency.creatorShortlistingTitle"       : "CREATOR SHORTLISTING",
    "forAgency.creatorShortlistingHeadline"    : "Track approval or rejection from brand realtime on the platform",
    "forAgency.creatorShortlistingDesc"        : "You don't need to send an Excel spreadsheet full of creators and update it constantly. Our platform tracks all the rejected and approved creators for you at a glance, making it easy to manage.",

    "forAgency.campaignReportingTitle"       : "CAMPAIGN REPORTING",
    "forAgency.campaignReportingHeadline"    : "Skip the hassle of asking for screenshots and just share the link. We'll take care of the rest.",
    "forAgency.campaignReportingDesc"        : "With our platform, you can easily get the results you need from any link post, without having to ask. Saves your time and work more efficiently.",
};

export default enForAgency;