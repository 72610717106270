/* Languange ID */
var idForAgency = {
    "forAgency"                      : "BINTANGO UNTUK AGENSI",
    "forAgency.title"                : "Membantu agensi untuk",
    "forAgency.desc"                 : "Platform Kami mambantu pekerjaan agensi menjadi lebih mudah dan efisien",

    "forAgency.titleTechDriven"      : "Kami lebih dari sekadar agensi, kami adalah parter Anda berbasis teknologi.",

    "forAgency.creatorManagementTitle"       : "MANAJEMEN KREATOR",
    "forAgency.creatorManagementHeadline"    : "Undang & kelola kreator kamu",
    "forAgency.creatorManagementDesc"        : "Kelola semua kreator Anda di satu tempat, dapatkan insight dari perfroma mereka dan buatlah keputusan berdasarkan data",

    "forAgency.creatorDiscoveryTitle"       : "PENCARIAN KREATOR",
    "forAgency.creatorDiscoveryHeadline"    : "Cari kreator yang sesuai dengan campaign Kamu",
    "forAgency.creatorDiscoveryDesc"        : "Cari kreator yang sesuai dengan brand campaign Kamu dengan pencarian berdasarkan data didalam platform kami. Kami menyediakan data yang ter-update dan terpercaya untuk membantu Kamu menjalankan campaign",

    "forAgency.campaignManagementTitle"       : "MANAJEMEN CAMPAIGN",
    "forAgency.campaignManagementHeadline"    : "Sudah tidak perlu pake spreadsheet, semua ada didalam satu platform",
    "forAgency.campaignManagementDesc"        : "Kerjakan semua dalam 1 platform, mulai dari membuat campaign dan campaign berjalan hingga mengelola semua kreator beserta konten yang dibuat",

    "forAgency.creatorShortlistingTitle"       : "SHORTLIST KREATOR",
    "forAgency.creatorShortlistingHeadline"    : "Melacak penerimaan dan penolakan kreator dari brand secara real-time didalam platform",
    "forAgency.creatorShortlistingDesc"        : "Sudah tidak perlu mengirim Excel berisikan kreator dan men-update-nya setiap hari. Platform kami membantu melacak penerimaan atau penolakan kreator dari brand dengan mudah",

    "forAgency.campaignReportingTitle"       : "CAMPAIGN REPORTING",
    "forAgency.campaignReportingHeadline"    : "Tidak perlu minta screenshot insight lagi, cukup minta link post dan semua insight akan Kami dapat",
    "forAgency.campaignReportingDesc"        : "Dengan platform Kami Anda dengan mudah menpatkan performa post dari kreator hanya dengan link, tanpa harus meminta screenshot insight dari kreator. Hemat lebih banyak waktu dan Anda dapat bekerja lebih efisien",
};

export default idForAgency;