/* Languange EN */


var enCreator = {
    "creator.titleBanner" : "Get paid doing what you love!",
    "creator.descBanner"  : "Earn money from creating content! Find fresh new collaboration opportunities every week from 250+ brands.",
    
    "creator.section1Title"    : "WE BELIEVE",
    "creator.section1SubTitle" : "Everyone is a content creator, but...",
    "creator.section1Desc"  : "Your content can be more than just an expression of your creativity. With collaboration opportunities every week, BintanGO will turn your creativity into a means of extra income!",
    "creator.testiAliciaeva" : "BintanGO has became my mediator with big brands in Indonesia! I love their features, that really suits my needs, it makes my job as a creator so much easier.",
    "creator.testiLaissti" : "I think BintanGO’s “Jobs” feature is super helpful! It’s made it easier for me to get more opportunities to work with brands",
    "creator.testiDevinaputrii" : "BintanGO really helped me as a Content Creator. From getting jobs, creating invoices, to getting paid, BintanGO was there every step of the way, so i could worry less about money and focus on making better content.",
    "creator.testiAstaririri" : "I think the brand deals feature from BintanGO is really helpful! It makes it easier for me to get many new opportunities to collaborate with Brands.",

    "creator.section2Title"  : "Become A ",
    "creator.section2Desc"   : "BintanGO helps you #Growbeyond as content creators",
    "creator.growCard1Title": "Collab with Brands!",
    "creator.growCard1Desc"  : "New jobs every week. Connect with 250+ trusted brands from various industries.",
    "creator.growCard2Title": "Elevate Performance",
    "creator.growCard2Desc"  : "Our apps come with productivity features to grow your social media presence",
    "creator.growCard3Title": "For-You-Jobs",
    "creator.growCard3Desc"  : "No matter your follower numbers or social media platform, BintanGO will always have a job for you!",
    "creator.growCard4Title": "Community Support",
    "creator.growCard4Desc"  : "Join a community of 60,000+ creators to connect, share, and #GrowBeyond together.",
    

    "creator.section3Title" : "BintanGO has been covered by:",

    "creator.section4Title" : "Incrase your hustle without ",
    "creator.section4Hashtag" : "Much Hassle",
    "creator.section4Step1Title" : "Join BintanGO!",
    "creator.section4Step1Desc" : "Download, register, and complete your profile information.",
    "creator.section4Step2Title" : "Get jobs!",
    "creator.section4Step2Desc" : "Everyone can earn money! Open the job tab ,find a job that suits you, and tap “apply”",
    "creator.section4Step3Title" : "Make Content, Get Paid!",
    "creator.section4Step3Desc" : "After you’ve been accepted, you just need to create content and await payment!",
    "creator.section4ButtonApply" : "APPLY NOW",
    

    "creator.section5Title"  : "BintanGO Creator-Powered Brands",

    "creator.section6Title"  : "Join our community!",
};

export default enCreator;
