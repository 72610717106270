import {useRef, useState, useEffect } from 'react';
import { Link  } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.min.css';  

// Main Component
function CareerDetail() {

  AOS.init({
    duration : 2000
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>BintanGO - Creator Powered Marketing</title>
        <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
        <meta property="og:image"              content="/logo-black-300.jpg" />
        <meta property="og:url"                content={window.location.href} />
        <meta property="og:title"              content="BintanGO - Creator Powered Marketing" />
        <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
      </Helmet>

      <div className='main-banner-solution career-detail'>
        <div className='container-xl text-center'>
          <h3>JOIN BINTANGO AS</h3>
          <h1 className='mb-5'>Senior Product Manager</h1>
        </div>
      </div>   
      <section id="career-detail">
        <div className='container-xl'>
          <div className='row'>
            <div className='col-lg-9'>
              <h3 className='mb-4'>Jobs Description</h3>
              <p className='mb-40'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent suscipit suscipit ipsum id posuere. Aliquam ac cursus velit. Sed posuere porta augue ut mattis. Nam placerat et felis in imperdiet. Curabitur tincidunt pellentesque quam id ultrices. Pellentesque fermentum elit vel lacus cursus, vitae lacinia libero commodo. Nullam quis massa eget neque lobortis placerat sit amet id neque. Aliquam hendrerit nunc non risus faucibus ultricies.</p>
              <h3 className='mb-4'>Jobs Responsibilities</h3>
              <ul className='mb-40'>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
              </ul>
              <h3 className='mb-4'>Jobs Requirement</h3>
              <ul className='mb-40'>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
              </ul>
              <h3 className='mb-4'>Perk and Benefits</h3>
              <ul className='mb-40'>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
                <li>Develop and execute creative social media content plans aligned with clients' goals and target audience.</li>
              </ul>
              <p className='note'>If you're passionate about social media and eager to contribute to impactful digital marketing campaigns, we'd love to hear from you! Join us in shaping the online presence of our clients and making a lasting impact in the digital world.</p>
            </div>
            <div className='col-lg-3 mt-lg-0 mt-5'>
              <div className='apply-jobs'>
                <h3 className='mb-40'>Apply to join our team</h3>
                <button type="button" className="btn-launcher w-100 h-52">Apply jobs</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


export default CareerDetail;