/* Languange ID */


var idHome = {
    "home.title"                    : "Platform marketing kreator yang Anda butuhkan untuk",
    "home.desc"                     : "Maksimalkan performa campaign Anda dengan pendekatan kreator marketing yang berdasarkan data dan hemat biaya",

    "home.marketingSolution"        : "Membantu Anda untuk menjalankan segala {tiger} untuk brand Anda",
    "home.marketingSolutionTiger"   : "aktivitas marketing",
    "home.officialPartner"          : "Partner Resmi Dengan",

    "home.blockTitle1"              : "Jalankan campaign yang sesuai dengan kebutuhan marketing Anda",
    "home.blockDesc1"               : "Tingkatkan penjualan, interaksi dengan audiens lebih dalam, dan tingkatkan kesadaran brand dengan kreator Kami",
    "home.blockButton1"             : "Run Campaign Now",
    "home.block1statsTitle1"        : "100K+",
    "home.block1statsDesc1"         : "Kreator",
    "home.block1statsDesc11"        : "nano sampai mega",
    "home.block1statsTitle2"        : "150jt",
    "home.block1statsDesc2"         : "Total Reach",
    "home.block1statsTitle3"        : "15jt+",
    "home.block1statsDesc3"         : "Total Produk Klik",
    "home.block1statsTitle4"        : "500+",
    "home.block1statsDesc4"         : "Campaign Selesai",
    "home.block1statsTitle5"        : "900jt+",
    "home.block1statsDesc5"         : "Est. Total Tonton",
    "home.block1statsTitle6"        : "50+",
    "home.block1statsDesc6"         : "Jumlah data yang tersedia",

    "home.blockTitle2"              : "End-to-end live commerce solution",
    "home.blockDesc2"               : "Boost your customer experience ans sales by our end-to-end live commerce solution",
    "home.blockButton2"             : "Run Live Commerce",
    "home.block2statsTitle1"        : "20+",
    "home.block2statsDesc1"         : "Brand run live commerce with us",
    "home.block2statsTitle2"        : "10K Jam",
    "home.block2statsDesc2"         : "kemampuan Live",
    "home.block2statsTitle3"        : "24/7",
    "home.block2statsDesc3"         : "Live Commerce Capabilities",
    "home.block2statsTitle4"        : "15+",
    "home.block2statsDesc4"         : "Studio available",
    "home.block2statsTitle5"        : "4",
    "home.block2statsDesc5"         : "Live Platform",
    "home.block2statsTitle6"        : "100K+",
    "home.block2statsDesc6"         : "Creator Network",

    "home.unlock"                   : "Di BintanGO semua {tiger} Kami Bisa",
    "home.unlockTiger"              : "aktivitas marketing",
    "home.unlockDesc"               : "Kami dapat membantu Anda untuk memenuhi kebutuhan aktifitas marketing Anda dan mencapai tujuan marketing Anda",
    
    "home.brandAwareness"           : "KESADARAN BRAND",
    "home.brandAwarenessTitle"      : "Tingkatkan kesadaran audiens ke brand Anda dan capai audiens Anda sesungguhnya",
    "home.brandAwarenessDesc"       : "Dengan platform dan solusi Kami, Anda dengan mudah membuat dan mengelola campaign yang dapat mencapai target audiens dan meningkatkan kesadaran brand Anda",

    "home.engageMore"               : "TINGKATKAN INTERAKSI",
    "home.engageMoreTitle"          : "Buat konten yang membangun interaksi",
    "home.engageMoreDesc"           : "Kami akan membantu Anda untuk membangun hubungan dengan audiens lebih efektif dengan solusi campaign yang baik. Ayo diskusikan campaign Anda selanjutnya",

    "home.productLaunch"            : "PELUNCURAN PRODUK",
    "home.productLaunchTitle"       : "Luncurkan produk Anda dengan strategi yang tepat",
    "home.productLaunchDesc"        : "Tim ahli Kami akan membantu Anda untuk meluncurkan produk ke pasaran dan akan membangun impresi yang baik terhadap produk baru Anda",

    "home.growthSales"              : "TINGKATKAN PENJUALAN",
    "home.growthSalesTitle"         : "Solusi Kami dapat membantu Anda untuk meningkatkan penjualan Brand Anda",
    "home.growthSalesDesc"          : "Platform kami dapat menghubungkan ke 100.000 kreator yang dapat membantu untuk mencapai tujuan marketing Anda",

    "home.techDriven"               : "BUKAN MARKETING PLATFORM BIASA",
    "home.techDrivenDesc"           : "Kami adalah teknologi partner Anda, Kami menyediakan teknologi yang dapat membantu menghemat waktu Anda dan menyediakan data yang terpercaya",
    "home.learnAboutTech"           : "Pelajari Tentang Teknologi Kami",

    "home.featuredOn"               : "Featured On",
    "home.helpBrand"                : "We here to help you as a",
};

export default idHome;
