/* Languange ID */


var idPrivacy = {
    // Intro
    "privacy.intro.title"  : "KEBIJAKAN PRIVASI",
    "privacy.intro.desc"    : "Halo CreatorGO! BintanGO sangat menjunjung tinggi privasi Anda dan berkomitmen untuk menjaga kepercayaan Anda dengan melindungi informasi pribadi Anda.\n\nKebijakan Privasi ini menjelaskan bagaimana perlakukan BintanGO terhadap setiap Data Pribadi yang Anda berikan kepada Kami sehubungan dengan penggunaan Anda atas Layanan kami melalui Platform BintanGO, atau layanan lainnya yang merujuk atau menautkan ke Kebijakan Privasi ini.\n\nAnda diwajibkan untuk membaca Kebijakan Privasi ini dengan seksama sebelum menggunakan Platform ini. Apabila Anda melanjutkan untuk menggunakan Layanan ini melalui Platform BintanGO, maka Anda mengakui bahwa Anda telah membaca, memahami dan menyetujui ketentuan dalam Kebijakan Privasi ini, dan oleh karena itu Anda memberikan hak kepada BintanGO untuk mengolah, mengumpulkan, mengolah, menganalisa, menyimpan, menampilkan, mentransfer, mengungkapkan dan/atau menghapus Data Pribadi Anda untuk tujuan sebagaimana dimaksud dalam Kebijakan Privasi ini sesuai dengan ketentuan peraturan perundang-undangan yang berlaku di Indonesia, termasuk namun tidak terbatas pada:",
    "privacy.intro.desc.i"  : "Undang-undang Republik Indonesia Nomor 19 Tahun 2016 juncto Undang-undang Nomor 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik;",
    "privacy.intro.desc.ii" : "Peraturan Pemerintah Republik Indonesia Nomor 71 Tahun 2019 tentang Penyelenggaraan Sistem dan Transaksi Elektronik;",
    "privacy.intro.desc.iii": "Peraturan Menteri Komunikasi dan Informatika Republik Indonesia Nomor 20 Tahun 2016 tentang Perlindungan Data Pribadi dalam Sistem Elektronik; dan",
    "privacy.intro.desc.iv" : "setiap undang-undang dan peraturan terkait lainnya, termasuk setiap amandemen, tambahan atau variasi dari setiap peraturan perundang-undangan di atas dari waktu ke waktu.",
    
    "privacy.title.1"      : "Ruang Lingkup Kebijakan Privasi",
    "privacy.desc.1"       : "Kebijakan Privasi ini berlaku untuk seluruh aspek dari situs dan aplikasi BintanGO serta informasi yang mungkin kami kumpulkan secara luring.\n\nSitus ini dapat menautkan atau menggabungkan dengan situs web atau konten yang dihosting dan disajikan oleh pihak ketiga yang tidak kami kendalikan, seperti situs web property online, platform, media sosial, atau sistem, yang masing-masing diatur oleh kebajikan privasi dan praktik bisnis dari pihak ketiga. Anda memahami dan setuju bahwa BintanGO tidak bertanggung jawab atas keamanan, privasi, atau praktik bisnis pihak ketiga mana pun.",

    "privacy.title.2"      : "Informasi yang Dikumpulkan",
    "privacy.desc.2"       : "Kami mengumpulkan informasi dari Anda secara langsung, secara otomatis saat Anda mengunjungi situs dan aplikasi BintanGO, serta kami menerima dari pihak ketiga (apabila ada). Secara umum, BintanGO akan mengidentifikasi, menghubungi atau menemukan orang atau perangkat terkait dengan informasi tersebut (“Informasi Pribadi” atau “Data Pribadi”) yang dilindungi oleh hukum sesuai dengan yurisdiksi tempat tinggal Anda. \n\nBeberapa informasi dapat dianggap sebagai Informasi Pribadi berdasarkan berbagai undang-undang yang berlaku. Kami menganggap informasi yang mengidentifikasi Anda sebagai individu tertentu yang teridentifikasi sebagai informasi pribadi (seperti nama, nomor telepon, dan alamat email Anda), serta informasi tambahan, termasuk alamat IP dan pengidentifikasi cookie, jika diwajibkan oleh hukum yang berlaku. Apabila kami menggabungkan informasi yang kami anggap non-pribadi dengan informasi pribadi, kami akan memperlakukan informasi gabungan tersebut sebagai informasi pribadi berdasarkan Kebijakan Privasi ini. Anda dapat memilih untuk tidak memberikan informasi kepada kami tetapi secara umum, beberapa informasi tentang Anda diperlukan agar Anda dapat menerima Layanan BintanGO, mengajukan pertanyaan kepada kami, atau mengajukan permintaan lain pada Platform BintanGO.",
    "privacy.title.2.a"    : "Informasi yang Anda Berikan",
    "privacy.desc.2.a"     : "BintanGO dapat mengumpulkan Informasi Pribadi dari Anda ketika Anda:",
    "privacy.desc.2.a.i"   : "Mendaftar Akun di, Masuk ke, atau berinteraksi dengan situs dan aplikasi BintanGO. Kami mengumpulkan nama dan alamat email Anda dan informasi lain yang Anda berikan, seperti nomor telepon, saat Anda mendaftar atau masuk, memperbarui, atau menambahkan informasi atau konten ke akun Anda. Kami mewajibkan Anda untuk membuat nama pengguna dan kata sandi jika Anda membuat akun, meskipun Anda dapat mengaktifkan dengan metode lain, seperti melalui akun email Anda lainnya atau ID Apple Anda. BintanGO juga mengumpulkan informasi tertentu yang berkaitan dengan akun platform sosial media Anda untuk masuk atau menautkan ke akun BintanGO Anda (seperti nama pengguna, jumlah pengikut, atau informasi lainnya apabila dibutuhkan).",
    "privacy.desc.2.a.ii"  : "Berpartisipasi dalam Kontes atau Kegiatan Promosi lainnya. BintanGO mengumpulkan informasi yang Anda berikan saat Anda mengikuti kontes atau kegiatan promosi lainnya (“Promosi”) melalui situs dan aplikasi BintanGO. Setiap Promosi memiliki syarat dan ketentuan atau aturan resminya sendiri, yang wajib Anda tinjau karena terdapat persyaratan lainnya yang wajib untuk dipenuhi.",
    "privacy.desc.2.a.iii" : "Pengajuan Produk EarlyPay. BintanGO dengan ini mewajibkan bagi Anda dalam menggunakan untuk produk EarlyPay, untuk menambahkan beberapa Informasi Pribadi tambahan, termasuk namun tidak terbatas pada,Formulir Aplikasi EarlyPay; Foto Kartu Tanda Penduduk (KTP) atau tanda pengenal lainnya, termasuk segala informasi yang tercantum pada KTP; Mutasi bank untuk 6 (enam) bulan terakhir atas nama Anda; Dokumen legalitas perusahaan dan/atau identitas badan hukum/usaha (apabila Anda merupakan badan hukum/usaha); Kontrak dari proyek yang akan dibiayai; Rincian proyek yang akan dibiayai; Identitas lengkap penjamin (apabila ada); dan informasi lainnya yang akan dimintakan apabila dirasa perlu oleh BintanGO.",
    "privacy.desc.2.a.iv"  : "Hubungi Layanan Pelanggan melalui Situs atau Aplikasi. BintanGO mengumpulkan informasi yang Anda berikan sehubungan dengan kami menyediakan layanan pelanggan kepada Anda.",
    "privacy.title.2.b"    : "Informasi dari Pihak Ketiga",
    "privacy.desc.2.b"     : "BintanGO juga dapat memperoleh informasi, seperti rincian kontak (misalnya nama dan alamat email), alamat IP, informasi cookie, informasi perangkat keras dan perangkat lunak, dan informasi teknis lainnya tentang Anda dari pihak ketiga, seperti pengguna situs lainnya yang mengunggah atau menyinkronkan kontak, perusahaan analitik, platform media sosial, dan mitra bisnis pihak ketiga lainnya.\n\nBintanGO dan mitra bisnis pihak ketiga kami dapat mengumpulkan informasi tentang kunjungan dan aktivitas Anda di situs kami serta situs web dan layanan lainnya. Kami dapat menggabungkan informasi tersebut dengan informasi pribadi lainnya yang telah kami kumpulkan dari atau tentang Anda. Kami dapat membagikan informasi tertentu ini dengan mitra bisnis pihak ketiga untuk tujuan serupa.",

    "privacy.title.3"      : "Tujuan Penggunaan Informasi",
    "privacy.desc.3"       : "Melalui platform ini, BintanGO dapat menggunakan Informasi Pribadi Anda yang dikumpulkan untuk salah satu tujuan berikut sebagaimana diizinkan oleh hukum dan peraturan yang berlaku (“Tujuan”):",
    "privacy.desc.3.a"     : "untuk mengidentifikasi Anda dan mendaftarkan Anda sebagai pengguna platform BintanGO dan untuk mengelola, memverifikasi, menonaktifkan atau mengelola akun Anda;",
    "privacy.desc.3.b"     : "untuk memfasilitasi atau mengaktifkan verifikasi apa pun yang dianggap perlu oleh BintanGO berdasarkan kebijaksanaan BintanGO sebelum mendaftarkan Anda sebagai pengguna BintanGO; ",
    "privacy.desc.3.c"     : "untuk memelihara, mengembangkan, menguji, meningkatkan, dan mempersonalisasikan platform BintanGO untuk memenuhi dan preferensi Anda untuk pengalaman yang lebih baik; ",
    "privacy.desc.3.d"     : "untuk menghubungi Anda tentang masalah administratif, seperti Syarat dan Ketentuan yang berlaku atau masalah lainnya; dan",
    "privacy.desc.3.e"     : "untuk alasan lain yang (i) kami ungkapkan ketika meminta Anda untuk memberikan informasi Anda; (ii) dengan persetujuan Anda; atau (iii) seperti yang dijelaskan dalam Kebijakan Privasi ini.",
    "privacy.desc.3(i)"    : "Berdasarkan informasi yang dikumpulkan dari Anda, BintanGO juga dapat menggunakan Informasi Pribadi Anda untuk mengirimkan kepada Anda informasi tentang produk, layanan, iklan, voucher, survey, dan informasi penawaran khusus dan/atau promosi BintanGO lainnya, yang tunduk pada BintanGO telah memperoleh persetujuan Anda sebelumnya sesuai dengan hukum dan peraturan perundang-undangan yang berlaku.",

    "privacy.title.4"      : "Hak Anda",
    "privacy.desc.4"       : "Berdasarkan hukum dan peraturan perundang-undangan yang berlaku, Anda memiliki hak-hak berikut:",
    "privacy.desc.4.a"     : "Akses ke Informasi Pribadi Anda. Anda memiliki hak untuk meminta salinan informasi pribadi yang kami miliki tentang Anda.",
    "privacy.desc.4.b"     : "Memperbaiki Informasi Pribadi Anda. Kami ingin memiliki Informasi Pribadi yang akurat tentang Anda, sehingga silahkan hubungi kami apabila menurut Anda informasi yang kami simpan tidak mutakhir dan kami akan memperbaikinya untuk Anda.",
    "privacy.desc.4.c"     : "Penghapusan Informasi Pribadi Anda. Anda berhak meminta kami untuk menghapus Informasi Pribadi Anda apabila tidak diperlukan lagi untuk tujuan pengumpulannya. Anda telah menarik persetujuan Anda, Anda memiliki keberatan yang sah terhadap kami menggunakan Informasi Pribadi Anda atau penggunaan Informasi Pribadi Anda oleh kami bertentangan dengan hukum atau kewajiban hukum kami lainnya.",
    "privacy.desc.4.d"     : "Keberatan untuk Tujuan Penggunaan Informasi. Anda berhak setiap saat untuk meminta kami berhenti menggunakan Informasi Pribadi Anda untuk tujuan pemasaran. Selain itu, apabila kami menggunakan Informasi Pribadi Anda dengan alasan kepentingan yang sah dan Anda keberatan, maka kami akan berhenti menggunakan Informasi Pribadi Anda kecuali ada alasan yang sah untuk melanjutkan.",
    "privacy.desc.4.e"     : "Pembatasan Penggunaan Informasi. Dalam beberapa kasus, Anda mungkin meminta kami untuk membatasi bagaimana kami menggunakan Informasi Pribadi Anda. Hak ini mungkin berlaku, misalnya, ketika kami memeriksa keakuratan informasi pribadi tentang Anda yang kami pegang atau menilai validitas dari setiap keberatan yang Anda buat atas penggunaan informasi Anda oleh kami. Hak tersebut mungkin juga berlaku jika tidak ada lagi dasar untuk menggunakan Informasi Pribadi Anda tetapi Anda tidak ingin kami menghapus informasi tersebut. Kami akan menggunakan Informasi Pribadi yang relevan dengan persetujuan Anda, untuk klaim hukum atau di mana ada alasan kepentingan publik lainnya untuk melakukannya.",
    "privacy.desc.4.f"     : "Pemrosesan Otomatis. Apabila kami menggunakan Informasi Pribadi Anda secara otomatis untuk membuat keputusan yang mempengaruhi Anda secara signifikan, Anda berhak meminta agar keputusan tersebut ditinjau oleh individu yang dapat mewakili Anda dan menentang keputusan tersebut. Hak ini hanya berlaku jika kami menggunakan informasi Anda dengan persetujuan Anda atau sebagai bagian dari hubungan kontraktual dengan Anda.",
    "privacy.desc.4.g"     : "Penarikan Persetujuan Penggunaan Informasi. Apabila kami menggunakan Informasi Pribadi Anda dengan persetujuan Anda, Anda dapat menarik persetujuan tersebut kapan saja dan kami akan berhenti menggunakan Informasi Pribadi Anda untuk tujuan yang memberikan persetujuan tersebut. Penarikan tersebut tidak akan mempengaruhi pemrosesan sebelumnya yang telah terjadi.",

    "privacy.title.5"      : "Cookie dan Penanda Digital Lainnya",
    "privacy.desc.5"       : "Ketika Anda mengakses dan/atau menggunakan Platform BintanGO atau berinteraksi dengan kami, maka secara otomatis kami dapat mengumpulkan informasi melalui penggunaan cookie dan penanda digital lainnya. Cookie dan penanda digital adalah file komputer kecil yang dapat disimpan di hard drive komputer Anda atau disematkan di halaman Platform BintanGO yang memungkinkan kami mengidentifikasi Anda dan melacak kunjungan Anda di Platform BintanGO.\n\nKami dapat menggunakan mitra bisnis pihak ketiga untuk mengumpulkan dan memproses Informasi Pribadi Anda melalui penggunaan cookie dan penanda digital lainnya atas nama BintanGO. Anda dapat menonaktifkan cookie di komputer Anda dengan mengubah pengaturan pada menu preferensi atau opsi di browser atau perangkat Anda. Jika Anda memilih untuk menonaktifkan cookie, Anda mungkin tidak dapat mengakses area tertentu dari Platform.",

    "privacy.title.6"      : "Transfer Informasi Lintas Batas",
    "privacy.desc.6"       : "BintanGO dapat membuat kerja sama dan/atau terlibat dengan penyedia pihak ketiga untuk mendukung dan/atau membantu kami dalam melakukan dan/atau melaksanakan penyediaan layanan BintanGO. Oleh karenanya, kami mungkin harus membagikan Data Pribadi Anda kepada pihak ketiga tersebut untuk memungkinkan penyediaan layanan yang diminta dan kepada Anda. Selain itu, BintanGO dapat membagikan Data Pribadi Anda dengan afiliasinya untuk tujuan konsolidasi data, penyimpanan, manajemen informasi pelanggan yang disederhanakan, pelaporan dan penggunaan internal lainnya.\n\nBintanGO mungkin diminta untuk berbagi informasi kepada pihak ketiga sesuai dengan peraturan perundang-undangan yang berlaku. Misalnya, BintanGO diminta untuk mengungkapkan Informasi Pribadi sebagai akibat dari perintah pengadilan, panggilan pengadilan, atau surat perintah. Selain itu, BintanGO dapat, sesuai dengan hukum yang berlaku, secara sukarela memberikan informasi untuk membantu penyelidikan penegakan hukum atau jika pengungkapan diperlukan untuk melindungi sistem kami, bisnis kami, atau hak orang lain.\n\nSebagai perhatian bagi Anda, BintanGO tidak akan memberikan alamat email Anda kepada mitra bisnis BintanGO dan BintanGO hanya akan membagikan Data Pribadi Anda melalui cara-cara yang diatur di sini dan cara-cara yang diperbolehkan oleh peraturan perundang-undangan yang berlaku.",

    "privacy.title.7"      : "Transisi Bisnis",
    "privacy.desc.7"       : "Apabila BintanGO mengalami transisi bisnis, seperti merger atau akuisisi oleh perusahaan lain atau penjualan seluruh atau sebagian aset kami, Informasi Pribadi Anda kemungkinan akan menjadi salah satu aset yang dialihkan.",

    "privacy.title.8"      : "Keamanan Informasi",
    "privacy.desc.8"       : "Kami tidak menjamin atau menjamin keamanan server BintanGO dan kami juga tidak dapat menjamin bahwa informasi yang Anda berikan melalui Platform BintanGO tidak akan disadap saat dikirimkan melalui internet. Kami mengikuti standar teknis industri yang berlaku umum untuk melindungi Data Pribadi Anda yang dikirimkan kepada kami, baik selama transmisi maupun setelah BintanGO menerimanya. Saat Anda memasukkan informasi sensitif, BintanGO mengenkripsi informasi tersebut menggunakan teknologi lapisan soket aman.\n\nDalam pemrosesan Informasi Pribadi Anda untuk tujuan pemasaran atau dengan persetujuan Anda, BintanGO memproses data sampai Anda meminta kami untuk berhenti dan untuk waktu yang singkat setelah ini (untuk memungkinkan kami untuk melaksanakan permintaan Anda). BintanGO juga mencatat fakta Anda telah meminta kami untuk tidak mengirimkan pemasaran langsung kepada Anda atau memproses data Anda tanpa batas waktu sehingga kami dapat memenuhi permintaan Anda di masa mendatang. Apabila BintanGO memproses informasi pribadi sehubungan dengan pelaksanaan kontrak atau penyediaan layanan, atau untuk kompetisi, BintanGO menyimpan informasi tersebut sesuai dengan prosedur kepatuhan internal kami yang diterapkan dari waktu ke waktu.",

    "privacy.title.9"      : "Perubahan dan Pembaharuan",
    "privacy.desc.9"       : "Dalam rangka memastikan Kebijakan Privasi ini sesuai dengan peraturan perundang-undangan yang berlaku, BintanGO berkomitmen untuk mengubah atau memperbaharui Kebijakan Privasi ini sewaktu-waktu dan Anda diwajibkan untuk memeriksa Kebijakan Privasi ini secara berkala. Terhadap hal tersebut, kami juga dapat memberikan pemberitahuan kepada Anda mengenai perubahan atau pembaharuan terhadap Kebijakan Privasi ini, akan tetapi apabila Anda telah melanjutkan untuk menggunakan Platform BintanGO dan Layanan kami setelah kami melakukan perubahan baik sebagian maupun keseluruhan dari Kebijakan Privasi ini, maka Anda dianggap telah mengakui, menyetujui dan telah memperoleh persetujuan dari pihak ketiga terkait, terhadap perubahan tersebut dan Anda dengan sendirinya tunduk dan mengikatkan diri dengan perubahan Kebijakan Privasi tersebut.",

    "privacy.title.10"      : "Hubungi Kami",
    "privacy.desc.10"       : "Apabila Anda memiliki pertanyaan, keluhan, komentar, atau permintaan terkait dengan Kebijakan Privasi ini atau Anda ingin mendapatkan akses dan/atau melakukan perubahan terhadap Data Pribadi Anda, silahkan hubungi kami melalui kontak kami pada nomor  +6281280777870 atau email ke info@bintango.id.",
    "privacy.desc.11.title" : "Layanan Pengaduan Konsumen",
    "privacy.desc.11"       : "PT STARGO DIGITAL INDONESIA\n+6281280777870 / info@bintango.id\n\nDirektorat Jenderal Perlindungan Konsumen dan Tertib Niaga\nKementerian Perdagangan RI\nWhatsApp: +62 853 1111 1010",

    "privacy.intro.desc1"  : "BINTANGO berkomitmen untuk menjaga kepercayaan Anda dengan melindungi informasi pribadi Anda. Kebijakan Privasi ini menjelaskan bagaimana PT Stargo Digital Indonesia dan afiliasinya (“BINTANGO”) mengumpulkan, menggunakan, mengungkapkan, dan memproses informasi pribadi Anda sehubungan dengan penggunaan Anda atas Platform BINTANGO (“Platform” atau “Platform”), atau layanan lainnya yang merujuk atau menautkan ke Kebijakan Privasi ini.",
    
    // Type Information
    "privacy.getinfo.fullTitle"        : "Jenis Informasi yang Dikumpulkan dan Digunakan BINTANGO",
    "privacy.getinfo.desc1"            : "Secara umum, BINTANGO menerima dan mengumpulkan informasi apa pun yang Anda masukkan di Platform kami atau diberikan kepada kami dengan cara lain apa pun. Ini termasuk informasi yang mengidentifikasi atau dapat digunakan untuk mengidentifikasi, menghubungi, atau menemukan orang atau perangkat yang terkait dengan informasi tersebut (“Informasi Pribadi” atau “Data Pribadi”) yang dilindungi undang-undang di yurisdiksi tempat Anda tinggal. Anda dapat memilih untuk tidak memberikan informasi kepada kami, tetapi secara umum, beberapa informasi tentang Anda diperlukan agar Anda dapat menerima layanan yang disediakan BINTANGO, mengajukan pertanyaan kepada kami, atau mengajukan permintaan lain pada Platform kami yang disediakan BINTANGO.",
    "privacy.getinfo.desc2"            : "Platform mengumpulkan informasi melalui berbagai cara yang berbeda dan untuk tujuan yang berbeda sebagai berikut. Jika Anda memilih untuk mendaftar ke Platform mana pun untuk menerima pembaruan dari kami, untuk mengelola akun Anda dan/atau menggunakan portal layanan mandiri kami, maka Anda akan diminta untuk memberikan Informasi Pribadi. BINTANGO akan menggunakan informasi ini sesuai dengan kepentingan bisnis kami yang sah untuk menyediakan layanan kami dan/atau untuk menghubungi Anda tentang layanan yang disediakan BINTANGO di mana Anda telah menyatakan minatnya.",
    "privacy.getinfo.desc3"            : "Platform secara otomatis mengumpulkan informasi teknis tentang kunjungan Anda, seperti jenis browser, penyedia layanan Internet, jenis platform, alamat protokol internet (IP), halaman rujukan/keluar, sistem operasi, dan cap tanggal/waktu. BINTANGO mengumpulkan informasi ini untuk pelaporan tentang Platform untuk menganalisis tren, mendiagnosis masalah dengan server kami dan mengelola Platform, untuk melacak pergerakan dan penggunaan pengguna, dan untuk mengumpulkan informasi demografis yang luas.",
    "privacy.getinfo.desc4"            : "Anda juga memiliki opsi untuk memberikan informasi demografis (seperti jenis bisnis, ukuran bisnis, lokasi, dll.). BINTANGO menggunakan informasi demografis ini untuk memahami kebutuhan dan minat Anda dan untuk memberikan Anda pengalaman yang lebih personal di lokasi BINTANGO. Informasi tersebut digunakan oleh kami untuk memproses pesanan Anda, memungkinkan partisipasi dalam promosi (tergantung pada preferensi pemasaran Anda), dan agar BINTANGO dapat memberikan layanan kami kepada Anda.",
    "privacy.getinfo.desc5"            : "Jika BINTANGO berbicara kepada Anda melalui telepon, Anda dapat memberikan kami data pribadi selama panggilan. Panggilan tersebut dapat direkam untuk tujuan pelatihan dan kualitas. Anda akan diberi tahu di awal panggilan jika akan direkam, memberi Anda kesempatan untuk mengakhiri panggilan jika Anda tidak nyaman merekam.",
    
    // Do with Information
    "privacy.doinfo.fullTitle"   : "Apa yang BINTANGO Lakukan dengan Informasi yang Dikumpulkan BINTANGO",
    "privacy.doinfo.desc1"       : "Melalui Platform ini, BINTANGO dapat menggunakan Informasi Pribadi Anda yang dikumpulkan untuk salah satu tujuan berikut sebagaimana diizinkan oleh hukum dan peraturan yang berlaku (“Tujuan”):",
    "privacy.doinfo.list1"       : "untuk mengidentifikasi Anda dan mendaftarkan Anda sebagai pengguna Platform dan untuk mengelola, memverifikasi, menonaktifkan atau mengelola akun Anda;",
    "privacy.doinfo.list2"       : "untuk memfasilitasi atau mengaktifkan verifikasi apa pun yang dianggap perlu oleh BINTANGO berdasarkan kebijaksanaan BINTANGO sebelum mendaftarkan Anda sebagai pengguna BINTANGO;",
    "privacy.doinfo.list3"       : "untuk memproses dan memfasilitasi pesanan dan transaksi pembayaran yang Anda lakukan, termasuk transaksi yang dilakukan melalui payment getaway yang BINTANGO tetapkan untuk membantu menyediakan gateway pembayaran; dan",
    "privacy.doinfo.list4"       : "untuk memelihara, mengembangkan, menguji, meningkatkan, dan mempersonalisasikan Platform untuk memenuhi kebutuhan dan preferensi Anda untuk pengalaman yang lebih baik.",
    "privacy.doinfo.desc2"       : "Berdasarkan informasi yang dikumpulkan dari Anda sehubungan dengan transaksi pembayaran, informasi tersebut hanya akan digunakan untuk menagih Anda atas pesanan yang telah Anda beli. BINTANGO juga dapat menggunakan Informasi Pribadi Anda untuk mengirimkan kepada Anda informasi tentang produk, layanan, iklan, voucher, survei, dan informasi penawaran khusus dan/atau promosi BINTANGO lainnya, yang tunduk pada BINTANGO telah memperoleh persetujuan Anda sebelumnya sesuai dengan hukum yang berlaku dan regulasi.",

    // Rights
    "privacy.rights.fullTitle"   : "Hak Anda",
    "privacy.rights.desc1"   : "Berdasarkan hukum yang berlaku, Anda mempunyai hak-hak berikut:",
    "privacy.rights.list1"   : "Akses ke informasi Anda: Anda memiliki hak untuk meminta salinan informasi pribadi yang kami miliki tentang Anda.",
    "privacy.rights.list2"   : "Memperbaiki informasi Anda: Kami ingin memiliki informasi pribadi yang akurat tentang Anda. Silakan hubungi kami jika menurut Anda informasi pribadi yang kami simpan tidak mutakhir atau benar dan kami akan memperbaikinya untuk Anda.",
    "privacy.rights.list3"   : "Penghapusan informasi Anda: Anda berhak meminta kami untuk menghapus informasi pribadi tentang Anda jika tidak lagi diperlukan untuk tujuan pengumpulannya, Anda telah menarik persetujuan Anda, Anda memiliki keberatan yang sah terhadap kami menggunakan informasi pribadi Anda, atau penggunaan informasi pribadi Anda oleh kami bertentangan dengan hukum atau kewajiban hukum kami lainnya.",
    "privacy.rights.list4"   : "Keberatan bagaimana kami dapat menggunakan informasi Anda: Anda berhak setiap saat untuk meminta kami berhenti menggunakan informasi pribadi Anda untuk tujuan pemasaran langsung. Selain itu, jika kami menggunakan informasi pribadi Anda dengan alasan kepentingan yang sah, maka, jika Anda meminta kami, kami akan berhenti menggunakan informasi pribadi tersebut kecuali ada alasan yang sah untuk melanjutkan.",
    "privacy.rights.list5"   : "Membatasi bagaimana kami dapat menggunakan informasi Anda: Dalam beberapa kasus, Anda mungkin meminta kami untuk membatasi bagaimana kami menggunakan informasi pribadi Anda. Hak ini mungkin berlaku, misalnya, ketika kami memeriksa keakuratan informasi pribadi tentang Anda yang kami pegang atau menilai validitas dari setiap keberatan yang Anda buat atas penggunaan informasi Anda oleh kami. Hak tersebut mungkin juga berlaku jika tidak ada lagi dasar untuk menggunakan informasi pribadi Anda tetapi Anda tidak ingin kami menghapus informasi tersebut. Dimana hak ini untuk dilaksanakan secara sah, kami hanya dapat menggunakan informasi pribadi yang relevan dengan persetujuan Anda, untuk klaim hukum atau di mana ada alasan kepentingan publik lainnya untuk melakukannya.",
    "privacy.rights.list6"   : "Pemrosesan otomatis: Jika kami menggunakan informasi pribadi Anda secara otomatis untuk membuat keputusan yang memengaruhi Anda secara signifikan, Anda berhak meminta agar keputusan tersebut ditinjau oleh individu yang dapat Anda wakili dan menentang keputusan tersebut. Hak ini hanya berlaku jika kami menggunakan informasi Anda dengan persetujuan Anda atau sebagai bagian dari hubungan kontraktual dengan Anda.",
    "privacy.rights.list7"   : "Penarikan persetujuan menggunakan informasi pribadi Anda: Jika kami menggunakan informasi pribadi Anda dengan persetujuan Anda, Anda dapat menarik persetujuan tersebut kapan saja dan kami akan berhenti menggunakan informasi pribadi Anda untuk tujuan yang memberikan persetujuan tersebut. Namun, perhatikan bahwa ini tidak akan memengaruhi pemrosesan apa pun yang telah terjadi.",
    "privacy.rights.desc2"   : "Silakan hubungi kami jika Anda ingin menggunakan salah satu dari hak-hak ini. Anda dapat menemukan detail kontak di bawah ini.",

    // Cookie and Digital Marker
    "privacy.cookie.fullTitle"   : "Cookie dan Penanda Digital Lainnya",
    "privacy.cookie.desc1"       : "Kapan pun Anda mengakses dan/atau menggunakan Platform, maka secara otomatis dapat mengumpulkan informasi melalui penggunaan cookie dan penanda digital lainnya. Cookie dan penanda digital adalah file komputer kecil yang dapat disimpan di hard drive komputer Anda atau disematkan di halaman Platform kami yang memungkinkan kami mengidentifikasi Anda dan melacak kunjungan Anda di Platform. BINTANGO dapat menggunakan layanan pihak ketiga untuk mengumpulkan dan memproses Informasi Pribadi melalui penggunaan cookie dan penanda digital lainnya atas nama BINTANGO. Anda dapat menonaktifkan cookie di komputer Anda dengan mengubah pengaturan pada menu preferensi atau opsi di browser atau perangkat Anda. Jika Anda memilih untuk menonaktifkan cookie, Anda mungkin tidak dapat mengakses area tertentu dari Platform.",
 
    // Electronic Communication
    "privacy.com.fullTitle"   : "Komunikasi Elektronik",
    "privacy.com.desc1"       : "Jika Anda mengirimkan pertanyaan Anda, BINTANGO akan mengirimkan tanggapan dan memberikan Anda informasi tentang layanan kami yang Anda minta. BINTANGO akan berkomunikasi dengan Anda melalui email atau telepon dan akan melakukan segala upaya untuk menghormati preferensi Anda.",

    // Information Sharing
    "privacy.shareinfo.fullTitle"   : "Berbagi Informasi dan Transfer Lintas Batas",
    "privacy.shareinfo.desc1"       : "BINTANGO dapat membuat kontrak dan/atau terlibat dengan penyedia pihak ketiga untuk mendukung dan/atau membantu BINTANGO dalam melakukan dan/atau melaksanakan penyediaan layanan BINTANGO, oleh karena itu, BINTANGO mungkin harus membagikan Data Pribadi Anda dengan pihak ketiga tersebut untuk memungkinkan penyediaan layanan yang diminta dan kepada Anda. Selain itu, BINTANGO dapat membagikan Data Pribadi Anda dengan afiliasi untuk tujuan konsolidasi data, penyimpanan, manajemen informasi pelanggan yang disederhanakan, pelaporan, dan penggunaan internal lainnya.",
    "privacy.shareinfo.desc2"       : "BINTANGO mungkin diminta untuk berbagi informasi dengan pihak ketiga sesuai dengan peraturan perundang-undangan yang berlaku. Misalnya, BINTANGO mungkin diminta untuk mengungkapkan Informasi Pribadi sebagai akibat dari perintah pengadilan, panggilan pengadilan, atau surat perintah. Selain itu, BINTANGO dapat, sesuai dengan hukum yang berlaku, secara sukarela memberikan informasi untuk membantu penyelidikan penegakan hukum atau jika pengungkapan diperlukan untuk melindungi sistem kami, bisnis kami, atau hak orang lain.",
    "privacy.shareinfo.desc3"       : "Mohon diperhatikan bahwa BINTANGO tidak memberikan alamat email Anda kepada mitra bisnis BINTANGO dan BINTANGO hanya akan membagikan Data Pribadi Anda melalui cara-cara yang diatur di sini dan cara-cara yang diperbolehkan oleh peraturan perlindungan data yang berlaku.",
    
    // Bussiness Transition
    "privacy.bizztrans.fullTitle"   : "Transisi Bisnis",
    "privacy.bizztrans.desc1"       : "Jika BINTANGO mengalami transisi bisnis, seperti merger atau akuisisi oleh perusahaan lain atau penjualan seluruh atau sebagian aset kami, Informasi Pribadi Anda kemungkinan akan menjadi salah satu aset yang dialihkan.",

    // Secure Information
    "privacy.security.fullTitle"   : "Menjaga Keamanan Informasi Anda",
    "privacy.security.desc1"       : "BINTANGO tidak menjamin atau menjamin keamanan server BINTANGO dan BINTANGO juga tidak dapat menjamin bahwa informasi yang Anda berikan melalui Platform tidak akan disadap saat dikirimkan melalui Internet. BINTANGO mengikuti standar teknis industri yang berlaku umum untuk melindungi data pribadi yang dikirimkan kepada kami, baik selama transmisi maupun setelah BINTANGO menerimanya. Saat Anda memasukkan informasi sensitif, BINTANGO mengenkripsi informasi tersebut menggunakan teknologi lapisan soket aman.",
    "privacy.security.desc2"       : "Dimana BINTANGO memproses informasi pribadi untuk tujuan pemasaran atau dengan persetujuan Anda, BINTANGO memproses data sampai Anda meminta kami untuk berhenti dan untuk waktu yang singkat setelah ini (untuk memungkinkan kami untuk melaksanakan permintaan Anda). BINTANGO juga mencatat fakta bahwa Anda telah meminta kami untuk tidak mengirimkan pemasaran langsung kepada Anda atau memproses data Anda tanpa batas waktu sehingga BINTANGO dapat memenuhi permintaan Anda di masa mendatang. Apabila BINTANGO memproses informasi pribadi sehubungan dengan pelaksanaan kontrak atau penyediaan layanan, atau untuk kompetisi, BINTANGO menyimpan informasi tersebut sesuai dengan prosedur kepatuhan internal kami yang diterapkan dari waktu ke waktu.",

    // Privacy Policy Changes
    "privacy.changes.fullTitle"  : "Perubahan pada Kebijakan Privasi Ini",
    "privacy.changes.desc1"      : "Harap baca Kebijakan Privasi ini dengan cermat dan kunjungi kembali halaman ini dari waktu ke waktu untuk meninjau setiap perubahan yang mungkin telah dibuat. Semua perubahan akan secara otomatis berlaku pada tanggal yang ditetapkan dalam Kebijakan Privasi yang diposting kecuali ditentukan lain.",

    // Terms & Condition
    "privacy.terms.fullTitle"  : "Syarat & Ketentuan",
    "privacy.terms.desc1"      : "Jika Anda memilih untuk mengunjungi Platform kami, kunjungan Anda dan setiap perselisihan privasi tunduk pada Kebijakan Privasi ini dan Syarat & Ketentuan, termasuk, namun tidak terbatas pada, penolakan garansi, batasan tanggung jawab, dan arbitrase perselisihan. Jika Anda memiliki pertanyaan tentang penggunaan informasi pribadi Anda oleh kami, silakan hubungi informasi kontak kami di Platform.",

};

export default idPrivacy;
