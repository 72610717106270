import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

export default class Privacy extends Component {
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>BintanGO - Kebijakan Privasi</title>
                    <meta name="description" content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
                    <meta property="og:image"              content="/logo-black-300.jpg" />
                    <meta property="og:url"                content={window.location.href} />
                    <meta property="og:title"              content="BintanGO - Kebijakan Privasi" />
                    <meta property="og:description"        content="Influencer Marketing Platform terbaik di Indonesia, lebih dari 40.000 Kreator dan 100 Brand sudah bergabung. Buat campaignmu jadi fleksibel, terlacak dan terukur." />
                </Helmet>
                <div>
                    <div className="header-page">
                        <h1 className="container"><FormattedMessage id="privacy.intro.title" /></h1>
                    </div>
                    <div className="container page-content">
                    <p className="text-justify"><FormattedMessage id="privacy.intro.desc" /></p>
                    <ol type="i">
                    <li><FormattedMessage id="privacy.intro.desc.i" /></li>
                    <li><FormattedMessage id="privacy.intro.desc.ii" /></li>
                    <li><FormattedMessage id="privacy.intro.desc.iii" /></li>
                    <li><FormattedMessage id="privacy.intro.desc.iv" /></li>
                    </ol>
                    <ol type="1" className="parent-title mt-3">
                        <li>
                        <FormattedMessage id="privacy.title.1" />
                        <p><FormattedMessage id="privacy.desc.1" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="privacy.title.2" />
                        <p><FormattedMessage id="privacy.desc.2" /></p>
                        <ol type="a" className="child-title">
                            <li>
                            <FormattedMessage id="privacy.title.2.a" />
                            <p><FormattedMessage id="privacy.desc.2.a" /></p>
                            <ol type="i" className="child-title">
                                <li><FormattedMessage id="privacy.desc.2.a.i" /></li>
                                <li><FormattedMessage id="privacy.desc.2.a.ii" /></li>
                                <li><FormattedMessage id="privacy.desc.2.a.iii" /></li>
                                <li><FormattedMessage id="privacy.desc.2.a.iv" /></li>
                            </ol>
                            </li>
                            <li><FormattedMessage id="privacy.desc.2.b" /></li>
                        </ol>
                        </li>
                        <li>
                        <FormattedMessage id="privacy.title.3" />
                        <p><FormattedMessage id="privacy.desc.3" /></p>
                        <ol type="a" className="child-title">
                            <li><FormattedMessage id="privacy.desc.3.a" /></li>
                            <li><FormattedMessage id="privacy.desc.3.b" /></li>
                            <li><FormattedMessage id="privacy.desc.3.c" /></li>
                            <li><FormattedMessage id="privacy.desc.3.d" /></li>
                        </ol>
                        <p><FormattedMessage id="privacy.desc.3(i)" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="privacy.title.4" />
                        <p><FormattedMessage id="privacy.desc.4" /></p>
                        <ol type="a" className="child-title">
                            <li><FormattedMessage id="privacy.desc.4.a" /></li>
                            <li><FormattedMessage id="privacy.desc.4.b" /></li>
                            <li><FormattedMessage id="privacy.desc.4.c" /></li>
                            <li><FormattedMessage id="privacy.desc.4.d" /></li>
                            <li><FormattedMessage id="privacy.desc.4.e" /></li>
                            <li><FormattedMessage id="privacy.desc.4.f" /></li>
                            <li><FormattedMessage id="privacy.desc.4.g" /></li>
                        </ol>
                        </li>
                        <li>
                        <FormattedMessage id="privacy.title.5" />
                        <p><FormattedMessage id="privacy.desc.5" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="privacy.title.6" />
                        <p><FormattedMessage id="privacy.desc.6" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="privacy.title.7" />
                        <p><FormattedMessage id="privacy.desc.7" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="privacy.title.8" />
                        <p><FormattedMessage id="privacy.desc.8" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="privacy.title.9" />
                        <p><FormattedMessage id="privacy.desc.9" /></p>
                        </li>
                        <li>
                        <FormattedMessage id="privacy.title.10" />
                        <p><FormattedMessage id="privacy.desc.10" /></p>
                        <p className="mb-0"><b><FormattedMessage id="privacy.desc.11.title" /></b></p>
                        <p className="mt-0"><FormattedMessage id="privacy.desc.11" /></p>
                        </li>
                    </ol>
                    </div>
                </div>
            </>
        );  
    }
}