import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";

export default class Community extends Component {
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <div>
                    <div className="header-page">
                        <h1 className="container"><FormattedMessage id="community.intro.title" /></h1>
                    </div>
                    <div className="container page-content">
                    <p className="text-justify"><b><FormattedMessage id="community.intro.desc"/></b></p>
                    <p className="text-justify"><FormattedMessage id="community.intro.desc.i" 
                      values={{
                        terms: (<Link to="/terms" className="link">Syarat dan Ketentuan</Link>),
                        privacy: (<Link to="/privacy" className="link">Kebijakan Privasi</Link>),
                      }}/></p>
                    <ol type="A" className="parent-title mt-3">
                        <li>
                            <FormattedMessage id="community.title.A" />
                            <p><FormattedMessage id="community.desc.A" /></p>
                            <ol type="1" className="child-title">
                                <li>
                                    <FormattedMessage id="community.title.A.1" />
                                    <p><FormattedMessage id="community.desc.A.1" /></p>
                                </li>
                                <li>
                                    <FormattedMessage id="community.title.A.2" />
                                    <p><FormattedMessage id="community.desc.A.2" /></p>
                                </li>
                                <li>
                                    <FormattedMessage id="community.title.A.3" />
                                    <p><FormattedMessage id="community.desc.A.3" /></p>
                                </li>
                                <li>
                                    <FormattedMessage id="community.title.A.4" />
                                    <p><FormattedMessage id="community.desc.A.4" /></p>
                                </li>
                                <li>
                                    <FormattedMessage id="community.title.A.5" />
                                    <p><FormattedMessage id="community.desc.A.5" /></p>
                                </li>
                                <li>
                                    <FormattedMessage id="community.title.A.6" />
                                    <p><FormattedMessage id="community.desc.A.6" /></p>
                                </li>
                                <li>
                                    <FormattedMessage id="community.title.A.7" />
                                    <p><FormattedMessage id="community.desc.A.7" /></p>
                                </li>
                                <li>
                                    <FormattedMessage id="community.title.A.8" />
                                    <p><FormattedMessage id="community.desc.A.8" /></p>
                                </li>
                                <li>
                                    <FormattedMessage id="community.title.A.9" />
                                    <p><FormattedMessage id="community.desc.A.9" /></p>
                                </li>
                                <li>
                                    <FormattedMessage id="community.title.A.10" />
                                    <p><FormattedMessage id="community.desc.A.10" /></p>
                                </li>

                            </ol>
                        </li>
                        <li>
                        <FormattedMessage id="community.title.B" />
                        <p><FormattedMessage id="community.desc.B.i" /></p>
                        <ol type="a" className="child-title">
                            <li><FormattedMessage id="community.title.B.a" /></li>
                            <li><FormattedMessage id="community.title.B.b" /></li>
                            <li><FormattedMessage id="community.title.B.c" /></li>
                            <li><FormattedMessage id="community.title.B.d" /></li>
                            <li><FormattedMessage id="community.title.B.e" /></li>
                            <li><FormattedMessage id="community.title.B.f" /></li>
                        </ol>
                        <p><FormattedMessage id="community.desc.B.ii" /></p>
                        </li>
                        <li>
                            <FormattedMessage id="community.title.C" />
                            <ol type="1" className="child-title mt-2">
                                <li>
                                    <FormattedMessage id="community.title.C.1" />
                                    <p><FormattedMessage id="community.desc.C.1" /></p>
                                </li>
                                <li>
                                    <FormattedMessage id="community.title.C.2" />
                                    <p><FormattedMessage id="community.desc.C.2" /></p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    </div>
                </div>
            </>
        );  
    }
}